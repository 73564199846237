import React, { Component, Fragment } from "react";
import { Modal, Form, Input } from "antd";
import { observer } from "mobx-react";

import { SearchForm, ChooserTable, Toolbar, mapColumn } from "components/FullTable";

import "./ChooserModal.less";

const FormItem = Form.Item;
const Search = Input.Search;

@observer
export default class ChooserModal extends Component {
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const { searchPlaceholder = "Buscar opção" } = this.props;
    const inputProps = {
      readOnly: dataSource.isLoading,
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          query: "text",
        }}>
        {({ form, submitForm }) => (
          <Fragment>
            <FormItem style={{ flex: 1 }}>
              {form.getFieldDecorator("query")(
                <Search
                  placeholder={searchPlaceholder}
                  enterButton
                  onSearch={() => setTimeout(submitForm, 30)}
                  {...inputProps}
                />
              )}
            </FormItem>
            {!!dataSource.hasFilter && (
              <FormItem>
                <Toolbar.Button
                  size="default"
                  icon="close"
                  ghost={true}
                  onClick={() => onUpdateDataSource(null, {}, null)}
                />
              </FormItem>
            )}
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const {
      dataSource,
      columns = [],
      beforeComponent,
      footer,
      allowMultipleSelection = false,
      width = 790,
      visible = false,
      loading = false,
      errorMessage = "Não foi possível obter suas opções no momento",
      rowKey = item => item.key,
      title = "Selecione uma Opção",
      searchPlaceholder,
      onCancel,
      ...otherProps
    } = this.props;

    return (
      <Modal
        centered
        title={title}
        visible={visible}
        footer={footer}
        onCancel={onCancel}
        width={width}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="chooser-modal">
        <ChooserTable
          className="chooser-table__compact"
          loading={loading}
          allowMultipleSelection={allowMultipleSelection}
          beforeComponent={beforeComponent || this.renderSearchForm}
          paginationProps={{
            style: {
              float: "none",
              textAlign: "center",
              margin: "15px 0 0",
            },
          }}
          rowKey={rowKey}
          columns={columns.map(mapColumn)}
          dataSource={dataSource}
          errorMessage={errorMessage}
          {...otherProps}
        />
      </Modal>
    );
  }
}
