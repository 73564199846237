import React, { PureComponent, Fragment } from "react";
import { Modal, Table, Icon } from "antd";
import styled from "styled-components";

import { n } from "@util";

function p(v) {
  return !!v ? `${v}`.replace(".", ",") : "0";
}

const CLASSES = {
  "01": "Solvente",
  "02": "Complemento",
  "03": "Tinta",
};

const TableProporcao = ({ proporcao, ...props }) => (
  <Table
    pagination={false}
    columns={[
      {
        title: "Classe",
        dataIndex: "classe",
        className: "text-left",
        render: value => `${value} - ${CLASSES[value]}`,
      },
      { title: "Volume", dataIndex: "volume", className: "text-center", width: 106, render: value => n(value) },
      {
        title: "Proporção",
        dataIndex: "proporcao",
        className: "text-center",
        width: 106,
        render: value => `${n(value)}%`,
      },
    ]}
    dataSource={proporcao}
    rowKey={item => `${item.classe}`}
    size="small"
    {...props}
  />
);

const TotalGroup = styled.div`
  flex: 1;
  padding: 5px 3px;
  text-align: center;
`;

const TotalItem = styled.div.attrs({
  className: "text-nowrap",
})`
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  cursor: default;
`;

const TotalItemTitle = styled(TotalItem)`
  font-size: 11px;
  letter-spacing: -0.3px;
  margin-bottom: 2px;
  cursor: default;

  .total-bar--title__link {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.primary};
    }
  }
`;

const TotalBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

export default class TotalBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisibleProporcaoModal: false,
    };
  }
  openProporcaoModal = () => {
    this.setState({ isVisibleProporcaoModal: true });
  };
  closeProporcaoModal = () => {
    this.setState({ isVisibleProporcaoModal: false });
  };
  render() {
    const { totais, proporcao = {}, ...props } = this.props;
    const volumeTotal = Object.keys(proporcao).reduce((acc, p) => acc + proporcao[p], 0);

    return (
      <Fragment>
        <TotalBarWrapper {...props}>
          <TotalGroup>
            <TotalItemTitle>
              <span
                title="Clique para visualizar a proporção de classes deste pedido"
                onClick={event => {
                  event.preventDefault();
                  this.openProporcaoModal();
                }}
                className="total-bar--title__link">
                Itens:
                <Icon type="info-circle-o" style={{ marginLeft: 2, fontSize: 11 }} />
              </span>
            </TotalItemTitle>
            <TotalItem>{n(totais.total_items)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Total Aut:</TotalItemTitle>
            <TotalItem>{n(totais.total_auto)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Desc.Aut:</TotalItemTitle>
            <TotalItem>{n(totais.desconto_auto_total)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Total Imob:</TotalItemTitle>
            <TotalItem>{n(totais.total_imob)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Desc.Imob:</TotalItemTitle>
            <TotalItem>{n(totais.desconto_imob_total)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Peso:</TotalItemTitle>
            <TotalItem>{n(totais.peso_bruto_total)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>% Desc.Total:</TotalItemTitle>
            <TotalItem>{p(totais.desconto_total)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Val.Merc.:</TotalItemTitle>
            <TotalItem>{n(totais.valor_total)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Base ICMS:</TotalItemTitle>
            <TotalItem>{n(totais.base_icms)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>ICMS:</TotalItemTitle>
            <TotalItem>{n(totais.valor_icms)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>IPI:</TotalItemTitle>
            <TotalItem>{n(totais.valor_ipi)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Base ST:</TotalItemTitle>
            <TotalItem>{n(totais.base_st)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>ICMS ST:</TotalItemTitle>
            <TotalItem>{n(totais.valor_icms_st)}</TotalItem>
          </TotalGroup>
          <TotalGroup>
            <TotalItemTitle>Desc.Suframa:</TotalItemTitle>
            <TotalItem>{n(totais.valor_suframa)}</TotalItem>
          </TotalGroup>

          <TotalGroup>
            <TotalItemTitle>Total NF:</TotalItemTitle>
            <TotalItem>{n(totais.valor_total_nf)}</TotalItem>
          </TotalGroup>
        </TotalBarWrapper>
        <Modal
          centered
          title="Proporção na Digitação deste Pedido"
          visible={this.state.isVisibleProporcaoModal}
          footer={null}
          onCancel={this.closeProporcaoModal}
          width={430}
          destroyOnClose={true}
          wrapClassName="proporcao-classe-modal">
          <TableProporcao
            proporcao={Object.keys(proporcao).map(p => ({
              classe: p,
              volume: proporcao[p],
              proporcao: (proporcao[p] / volumeTotal) * 100,
            }))}
          />
        </Modal>
      </Fragment>
    );
  }
}
