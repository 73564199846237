import React, { Component } from "react";
import { Input } from "antd";
import { observer } from "mobx-react";

import { n, maskedInputValue } from "@util";

@observer
export default class InputDecimal extends Component {
   static getDerivedStateFromProps(nextProps, state) {
     if ("value" in nextProps && state.value !== nextProps.value) {
       return {
         value: nextProps.value,
       };
     }
     return null;
   }
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || 0,
    };
  }
  handleChange = event => {
    event.persist();

    const { digits = 2, onChange } = this.props;
    const value = +maskedInputValue(event.target.value || "0", digits);

    this.setState({ value }, () => {
      if (!!onChange && typeof onChange === "function") {
        onChange({
          ...event,
          target: { ...event.target, value },
          value,
        });
      }
    });
  };
  render() {
    const { digits = 2, value, onChange, disabled = false, ...props } = this.props;

    return (
      <Input
        {...props}
        type="number"
        lang="en-150"
        value={n(!!this.state.value ? this.state.value : 0, digits, ".", "")}
        onChange={this.handleChange}
        disabled={disabled}
      />
    );
  }
}
