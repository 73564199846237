import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";

import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";
import styled from "styled-components";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MenuTreinamentoFornecedor extends PureComponent {
  render() {
    // eslint-disable-next-line
    const { collapsed = false, selected = [], treinamentoFornecedorData } = this.props;
    const submenuKeys = [];

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__vendedor", "sidebar-nav__diretor")}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-pendencias">
            <Link to={`/pendencias`}>
              <span>
                <Icon type="unlock" />
                <span>Pendências</span>
              </span>
            </Link>
          </MenuItem>
          <MenuItem key="nav-funcionario">
            <Link to={`/funcionario`}>
              <span>
                <Icon type="team" />
                <span>Funcionários</span>
              </span>
            </Link>
          </MenuItem>
          <MenuItem key="nav-perfil">
            <Link to={`/perfil`}>
              <span>
                <Icon type="user" />
                <span>Meus Anexos</span>
              </span>
            </Link>
          </MenuItem>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuTreinamentoFornecedor);
