import React, { Component, Fragment } from "react";
import { Table, Button, Icon } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";

import { n } from "@util";
import { WrappedValue, mapColumn } from "components/FullTable";

const cargasRowKey = item => `${item.numero_pedido}:${item.codigo_filial}:${item.codigo_cliente}:${item.loja_cliente}`;

const decorateRows = (item, index) =>
  classnames("base-table--row carga-table--row", {
    "base-table--row__odd carga-table--row__odd": index % 2 !== 0,
  });

@observer
export default class CargaItemTable extends Component {
  render() {
    const {
      items = [],
      rowKey = cargasRowKey,
      className,
      wrapperClassName,
      rowClassName = decorateRows,
      showStatus = false,
      loading = false,
      locale = {},
      bordered = true,
      size = "middle",
      onRemoveCarga,
      ...tableProps
    } = this.props;

    let columns = [
      {
        title: "Filial",
        key: "codigo_filial",
        dataIndex: "codigo_filial",
        width: "4.5%",
      },
      {
        title: "Pedido",
        key: "numero_pedido",
        dataIndex: "numero_pedido",
        width: "8%",
      },
      {
        title: "Código",
        key: "codigo_cliente",
        dataIndex: "codigo_cliente",
        render: (_, carga) => <WrappedValue value={`${carga.codigo_cliente} / ${carga.loja_cliente}`} type="text" />,
        width: "9.6%",
      },
      {
        title: "Cliente",
        key: "razao_social",
        className: "text-left",
        dataIndex: "razao_social",
        render: (text, carga) => (
          <WrappedValue
            value={
              <Fragment>
                {!!carga.shared && (
                  <Icon
                    type="share-alt"
                    title={`Pedido compartilhado por ${
                      !!carga.shared_from && !!carga.shared_from.razao_social
                        ? carga.shared_from.razao_social
                        : "outro usuário"
                    }`}
                    style={{ marginRight: 5, color: "#C00" }}
                  />
                )}
                {text}
              </Fragment>
            }
            type="text"
          />
        ),
      },
      {
        title: "Cidade",
        key: "cidade",
        dataIndex: "cidade",
        width: "10%",
      },
      {
        title: "UF",
        key: "uf",
        dataIndex: "uf",
        width: "4%",
      },
      {
        title: (
          <Fragment>
            Peso <em title="em Kilos">(KG)</em>
          </Fragment>
        ),
        key: "pesopv",
        dataIndex: "pesopv",
        render: v => <WrappedValue value={n(+v, 2)} type="text" />,
        width: "10.25%",
      },
      {
        title: "Total NF",
        key: "total_nota",
        dataIndex: "total_nota",
        render: v => <WrappedValue value={n(+v, 2)} type="text" />,
        width: "10.25%",
      },
    ];

    if (!!showStatus) {
      columns = [
        {
          title: "Status PV",
          key: "status",
          dataIndex: "status",
          width: "12%",
        },
        ...columns,
      ];
    }

    if (!!onRemoveCarga) {
      columns = [
        ...columns,
        {
          title: "Ações",
          key: "actions",
          render: (_, carga) => (
            <div className="actions">
              <Button
                icon="close"
                size="small"
                type="primary"
                onClick={() => onRemoveCarga(carga)}
                title="Remover este pedido a carga">
                Remover
              </Button>
            </div>
          ),
          width: "110px",
        },
      ];
    }

    return (
      <div className={classnames(`base-table-wrapper carga-table-wrapper`, wrapperClassName)}>
        <Table
          className={classnames(
            `base-table base-table__compact carga-table carga-table__compact`,
            { "base-table__loading carga-table__loading": !!loading },
            className
          )}
          pagination={false}
          rowKey={rowKey}
          loading={!!loading}
          bordered={bordered}
          columns={columns.map(mapColumn)}
          rowClassName={rowClassName}
          dataSource={items}
          locale={{
            filterTitle: "Filtrar",
            filterConfirm: "OK",
            filterReset: "Limpar",
            emptyText: "Sem itens disponíveis",
            ...locale,
          }}
          size={size}
          {...tableProps}
        />
      </div>
    );
  }
}
