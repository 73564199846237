import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import classnames from 'classnames';

import { MenuWrapper, StyledMenu } from 'components/Menu';

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MenuTransportador extends PureComponent {
    render() {
        const { collapsed = false, selected = [] } = this.props;
        const submenuKeys = ['subFinanceiro', 'subCarga', 'subDocumentacao'];

        return (
            <MenuWrapper className={classnames('sidebar-nav', 'sidebar-nav__transportador')}>
                <StyledMenu
                    mode="inline"
                    selectable={false}
                    defaultSelectedKeys={selected}
                    defaultOpenKeys={!collapsed ? submenuKeys : []}
                    inlineCollapsed={collapsed}
                    inlineIndent={16}>
                    <MenuItem key="nav-home">
                        <Link to="/">
                            <Icon type="home"/>
                            <span>Home</span>
                        </Link>
                    </MenuItem>
                    <SubMenu key="subFinanceiro" title={<span><Icon type="appstore-o"/><span>Serviços</span></span>}>
                        <MenuItem key="nav-faturas-manual">
                            <Link to="/transportadores/faturas">Montagem de Fatura</Link>
                        </MenuItem>
                        <MenuItem key="nav-posicao-financeira">
                            <Link to="/transportadores/posicao-financeira">Posição financeira</Link>
                        </MenuItem>
                    </SubMenu>
                    <SubMenu key="subCarga" title={<span><Icon type="scan"/><span>Carga</span></span>}>
                        <MenuItem key="nav-comp-entrega">
                            <Link to="/transportadores/comprovante-entrega">Comprovante de entrega</Link>
                        </MenuItem>
                    </SubMenu>
                    <SubMenu key="subDocumentacao"
                             title={<span><Icon type="file-text"/><span>Documentação</span></span>}>
                        <MenuItem key="nav-envio-documentacao">
                            <Link to="/transportadores/envio-documentacao">Envio de documentação</Link>
                        </MenuItem>
                    </SubMenu>
                    <MenuItem key="nav-home">
                        <Link to="/transportadores/meus-dados">
                            <Icon type="user"/>
                            <span>Meus dados</span>
                        </Link>
                    </MenuItem>
                    <MenuItem key="nav-home">
                        <Link to="/iqt">
                            <Icon type="check"/>
                            <span>IQT</span>
                        </Link>
                    </MenuItem>
                </StyledMenu>
            </MenuWrapper>
        );
    }
}

export default withRouter(MenuTransportador);
