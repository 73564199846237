import React, { Component, Fragment } from "react";
import { Button, Icon, Dropdown, Menu, Modal, Tag, Alert, Form } from "antd";
import { observer } from "mobx-react";
import _size from "lodash/size";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { SearchForm, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchApi } from "actions";
import styled from "styled-components";
import classNames from "classnames";
import Select, { Option } from "components/Select";

const FormItem = Form.Item;

const decorateRows = (item) =>
  classNames("base-table--row", {
    "base-table--row__danger": item.vencido != 0,
    "base-table--row__sucess": item.vencido == 0 && item.vencendo == 0 && item.atualizado != 0,
    "base-table--row__invalid": item.vencido == 0 && item.vencendo != 0,
  });

@observer
class EmpresaScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Empresas",
      uri: "/treinamento/empresa/",
      state: {
        filiais: {},
      },
    });

    this.state = {
      status: null
    };
  }

  tryDelete = async (id) => {
    try {
      this.props.store.isLoading = true;
      const { data: response } = await fetchApi("/treinamento/empresa/deletar/" + id);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Empresa #${id} excluida com sucesso`
        });
        this.ds.fetch();
      }
    } catch (error) {
      Modal.error({
        title: "Atenção",
        content: error
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleDelete = (empresaId) => {
    Modal.confirm({
      title: "Atenção",
      content: `Você realmente deseja excluir a empresa #${empresaId}`,
      okType: 'danger',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        this.tryDelete(empresaId)
      }
    })
  }

  enviarEmail = async (item) => {
    try {
      this.props.store.isLoading = true;
      const { data: response } = await fetchApi("/treinamento/pendencia/email/" + item.cnpj);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Email enviado com sucesso!`
        });
      }
    } catch (error) {
      Modal.error({
        title: "Atenção",
        content: error
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          situacao: "select",
        }}
        defaultFilters={this.props.match.params.situacao !== 'empresa' && { situacao: this.props.match.params.situacao }}>
        {({ form }) => (
          <Fragment>
            <FormItem>
              {form.getFieldDecorator("situacao")(
                <Select style={{ width: '150px' }} placeholder="Visualização por">
                  <Option value="vencendo">Vencendo</Option>
                  <Option value="vencido">Vencido</Option>
                  <Option value="atualizado">Atualizado</Option>
                </Select>
              )}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };

  render() {
    const { history, match } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "codigo",
        key: "codigo",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "CNPJ",
        dataIndex: "cnpj",
        exportOnly: true,
      },
      {
        title: "Razão Social",
        dataIndex: "razao_social",
        key: "razao_social",
        sorter: true,
        width: "30%",
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por razao_social" {...props} />,
        filterParser: filter => ({ ...filter, field: "razao_social", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (razaoSocial, item) => {
          return (
            <WrappedValue value={<><Tag color={item.existe_erp == 'NAO' ? "orange" : "blue"}>{item.cnpj}</Tag>{razaoSocial}</>} />
          )
        }
      },
      {
        title: "Nome Fantasia",
        dataIndex: "nome_fantasia",
        key: "nome_fantasia",
        sorter: true,
        width: "15%",
        filteredValue: this.ds.filterValue("nome_fantasia"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome fantasia" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome_fantasia", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (nomeFantasia) => {
          return (
            <WrappedValue value={nomeFantasia} />
          )
        }
      },
      {
        title: "Localidade",
        dataIndex: "",
        key: "localidade",
        width: "13%",
        export: false,
        render: (_, item) => {
          return (
            <div>{item.cidade} / {item.uf}</div>
          )
        }
      },
      {
        title: "Cidade",
        dataIndex: "cidade",
        exportOnly: true,
      },
      {
        title: "UF",
        dataIndex: "uf",
        exportOnly: true,
      },
      {
        title: "Tel.",
        key: "telefone",
        dataIndex: "telefone",
        width: '10%',
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email_contato",
        render: (email) => {
          return (
            <WrappedValue value={email} />
          )
        }
      },
      {
        title: "",
        key: "actionbar",
        width: "12%",
        export: false,
        render: (_, item) => {
          const menu =
            <Menu>
              <Menu.Item key="menu-delete" onClick={() => this.handleDelete(item.codigo)}>
                <Icon type="delete" />
                Deletar empresa
              </Menu.Item>
              <Menu.Item key="menu-padrao" onClick={() => history.push(`/empresa/padrao/${item.codigo}`)}>
                <Icon type="copy" />
                Treinamentos Padrão
              </Menu.Item>
              <Menu.Item key="menu-treinamentos"
                onClick={() => history.push(`/empresa/atrelados/${item.codigo}`)}>
                <Icon type="check" />
                Treinamentos da Empresa
              </Menu.Item>
              <Menu.Item key="menu-anexos"
                onClick={() => history.push(`/empresa/anexos/${item.codigo}`)}>
                <Icon type="paper-clip" />
                Anexar à Empresa
              </Menu.Item>
              <Menu.Item key="menu-funcionario" onClick={() => history.push(`/funcionario/${item.codigo}`)}>
                <Icon type="form" />
                Funcionários
              </Menu.Item>
              <Menu.Item key="menu-email" onClick={() => this.enviarEmail(item)}>
                <Icon type="mail" />
                Enviar E-mail
              </Menu.Item>
            </Menu>
          return (
            <Dropdown.Button
              onClick={() => history.push(`/empresa/${item.codigo}`)}
              title="Alterar esta Empresa"
              size="small"
              type="primary"
              overlay={menu}>
              <Icon type="edit" />
              Alterar
            </Dropdown.Button>
          )
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
          <Button onClick={() => history.push(`/empresa/add`)} type="primary" icon="plus">Nova Empresa</Button>
        </PageTitle>
        {!this.ds.isLoading &&
          <Alert
            style={{ fontSize: 16, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15 }}
            message={`CNPJs não encontrados na base do sistema ERP da Anjo são exibidas nesta cor!`}
            type="warning"
            showIcon
          />
        }
        <FullTable
          rowKey={item => (`${item.codigo}:${item.razao_social}`)}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          rowClassName={decorateRows}
          errorMessage="Não foi possível obter as empresas no momento"
          beforeComponent={this.renderSearchForm}
        />
      </div>
    );
  }
}

export default withStore(EmpresaScreen);
