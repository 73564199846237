import React from "react";
import classNames from "classnames";
import { Input, Icon, Button } from "antd";

export default class InputSearch extends React.Component {
  static defaultProps = {
    inputPrefixCls: "ant-input",
    prefixCls: "ant-input-search",
    enterButton: false,
  };
  input;
  onSearch = event => {
    event.preventDefault();
    event.stopPropagation();

    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(this.input.input.value, event);
    }
  };
  onPressEnter = event => {
    event.preventDefault();
    event.stopPropagation();

    const { onSearch, onPressEnter } = this.props;
    if (onPressEnter) {
      onPressEnter(event);
    } else if (onSearch) {
      onSearch(this.input.input.value, event);
    }
  };
  focus() {
    this.input.focus();
  }
  blur() {
    this.input.blur();
  }
  saveInput = node => {
    this.input = node;
  };
  getButtonOrIcon() {
    const { enterButton, prefixCls, size, disabled = false } = this.props;
    const enterButtonAsElement = enterButton;
    let node;
    if (!enterButton) {
      node = <Icon className={`${prefixCls}-icon`} type="search" key="searchIcon" />;
    } else if (enterButtonAsElement.type === Button || enterButtonAsElement.type === "button") {
      node = React.cloneElement(
        enterButtonAsElement,
        enterButtonAsElement.type === Button
          ? {
              className: `${prefixCls}-button`,
              size,
            }
          : {}
      );
    } else {
      node = (
        <Button className={`${prefixCls}-button`} type="primary" size={size} disabled={disabled} key="enterButton">
          {enterButton === true ? <Icon type="search" /> : enterButton}
        </Button>
      );
    }
    return React.cloneElement(node, {
      onMouseDown: event => {
        if (!disabled) this.onSearch(event);
      },
    });
  }

  render() {
    const {
      className,
      prefixCls,
      inputPrefixCls,
      size,
      suffix,
      enterButton,
      onSearch,
      onPressEnter,
      ...others
    } = this.props;

    const buttonOrIcon = this.getButtonOrIcon();
    let searchSuffix = suffix ? [suffix, buttonOrIcon] : buttonOrIcon;
    if (Array.isArray(searchSuffix)) {
      searchSuffix = searchSuffix.map((item, index) => {
        if (!React.isValidElement(item) || item.key) {
          return item;
        }
        return React.cloneElement(item, { key: index });
      });
    }
    const inputClassName = classNames(prefixCls, className, {
      [`${prefixCls}-enter-button`]: !!enterButton,
      [`${prefixCls}-${size}`]: !!size,
    });
    return (
      <Input
        onPressEnter={this.onPressEnter}
        size={size}
        className={inputClassName}
        prefixCls={inputPrefixCls}
        suffix={searchSuffix}
        ref={this.saveInput}
        {...others}
      />
    );
  }
}
