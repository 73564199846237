import { message } from "antd";
import { action, computed, observable } from "mobx";
import moment from "moment";
import { generateRandomNumber } from "@util";


export class PromotoriaStore {
    @observable Promotoria = {
        zy7_filial: null,
        zy7_codsol: null,
        zy7_codcli: null,
        zy7_lojcli: null,
        zy7_client: null,
        zy7_propri: null,
        zy7_emailp: null,
        zy7_respon: null,
        zy7_telres: null,
        zy7_emailr: null,
        zy7_codrep: null,
        zy7_nomrep: null,
        zy7_gerent: null,
        zy7_nomger: null,
        zy7_perimo: null,
        zy7_peraut: null,
        zy7_maqtin: null,
        zy7_qtdmaq: null,
        zy7_maquin: null,
        zy7_dtiniv: null,
        zy7_dtfimv: null,
        zy7_motivo: null,
        d_e_l_e_t_: null,
        r_e_c_n_o_: null,
        r_e_c_d_e_: null,
        zy7_status: null,
        zy7_partec: null,
        zy7_parcom: null,
        zy7_anexos: null,
        zy7_conprd: null,
        zy7_relvis: null,
        zy7_etapas: null,
        zy7_proaux: null,
        zy7_promot: null,
        zy7_desloc: null,
        zy7_obsrep: null,
        zy7_criado: null,
        zy7_codpro: null,
        zy7_qrcode: null,
        zy7_ppromo: null,
        zy7_ppromg: null,
        zy7_melhor: null,
        zy7_cancel: null
    };
    @observable Atividades = [];

    @observable Produtos = [];

    @observable Lancamentos = [];

    @observable Anexos = [];

    @observable PromotoresAux = [];

    @observable HistorioReprova = [];

    @observable promotores = [];

    @observable stepAtual = 0;
    @observable isLoading = false;
    @observable isVisibleModalProduto = false;
    @observable store;
    @observable promotoresAuxiliares = [];
    @observable inforAdicionais = {
        melhorarPromotoria: "",
        melhorarComercial: "",
        parecerGeral: "",
    }

    @observable atividadesRemovidas = [];
    @observable produtosRemovidos = [];

    @observable anexoFilesFotosEVideos = [];
    @observable anexoFilesLista = [];

    @observable quadranteCliente = null;
    @observable PromotoresAuxRemovidos: Array = [];

    constructor(store) {
        if (!!store) {
            this.store = store;
        }
    }

    @computed
    get isDisabled() {
        let disabled = false;

        if (Number(this.Promotoria.zy7_etapas) >= 7) {
            disabled = true;
        }

        if (this.store.isPromotor && Number(this.Promotoria.zy7_etapas) < 5) {
            disabled = true;
        }

        if (this.Promotoria.zy7_criado === "S" && Number(this.Promotoria.zy7_etapas) < 3 && (this.store.isVendedor || this.store.isOperador)) {
            disabled = true;
        }

        return disabled;
    }

    @computed
    get createdByGerente() {
        if (this.Promotoria.zy7_criado === "S" && this.store.isPromotor) {
            return false;
        }

        if (this.Promotoria.zy7_criado === "S" && this.store.isSupervisor) {
            return false;
        }


        if (this.Promotoria.zy7_criado === "S" && !this.store.isGerente) {
            return true;
        }

        return false;
    }

    @computed
    get isShowStepsValid() {
        if ((this.store.isVendedor || this.store.isOperador) && (this.Promotoria.zy7_status === '2' || this.Promotoria.zy7_etapas === "7")) {
            return true;
        }

        return false;
    }

    @computed
    get isShowStepsValidGerenteValidacao() {
        if (Number(this.Promotoria.zy7_etapas) <= 3 && this.store.isGerente) {
            return true;
        }

        return false;
    }

    @computed
    get isShowStepsPromotorStepLancamentos() {
        if (Number(this.Promotoria.zy7_etapas) === 5 && this.store.isPromotor) {
            return true;
        }

        return false;
    }

    @computed
    get isDisabledButtomExcluir(){
        if(Number(this.Promotoria.zy7_etapas > 2 && (this.store.isVendedor || this.store.isOperador))){
            return true;
        }

        if(this.store.isPromotor) return true;
        
        return false;
    }

    @computed
    get isDisabledButtonNext() {
        if (this.stepAtual >= 5) {
            return true;
        }

        if (this.isShowStepsPromotorStepLancamentos && this.stepAtual === 4) {
            return true;
        }

        if (!this.isShowStepsValid && (this.store.isVendedor || this.store.isOperador) && this.stepAtual === 2) {
            return true;
        }

        if (this.store.isGerente && this.stepAtual === 3 && Number(this.Promotoria.zy7_etapas) <= 3) {
            return true;
        }

        // if (this.stepAtual === 3 && Number(this.Promotoria.zy7_etapas) <= 3 && this.Promotoria.zy7_criado === "S" && !this.store.isSupervisor) return true;

        return false;
    }

    @computed
    get isFinishPromotoria() {
        if (this.Promotoria.zy7_etapas === "7") {
            return true;
        }

        if(this.Promotoria.zy7_status === "5"){
            return true;
        }

        if(this.Promotoria.zy7_status === "0"){
            return true
        }

        return false;
    }

    @computed
    get isDisabledButtonInLancamentos() {
        if ((this.Promotoria.zy7_etapas === "5" || this.Promotoria.zy7_etapas === "6")) {
            return true;
        }

        return false;
    }

    @computed
    get isDisabledButtonReproved() {
        if (Number(this.Promotoria.zy7_etapas) < 3) return true;

        // if (this.store.isPromotor && Number(this.Promotoria.zy7_etapas) === 5) return true;

        return false;
    }

    @computed
    get isDisabledButtonAddPosLancamento() {
        if (Number(this.Promotoria.zy7_etapas) > 5) return true;

        return false;
    }

    @computed
    get isDisabledAntesLancamento() {
        if (Number(this.Promotoria.zy7_etapas) <= 5 && !this.store.isPromotor) return true;

        return false;
    }

    @computed
    get isDisabledButtonsForVendendor() {
        if ((this.store.isVendedor || this.store.isOperador) && Number(this.Promotoria.zy7_etapas) >= 3) return true;

        return false;
    }

    @computed
    get isDisabledButtoNextStatus() {
        if ((this.store.isVendedor || this.store.isOperador) && Number(this.Promotoria.zy7_etapas) > 2) {
            return true;
        }

        if ((this.store.isGerente || this.store.isDiretorTipo) && Number(this.Promotoria.zy7_etapas) > 3) {
            return true;
        }

        if (this.store.isPromotor && Number(this.Promotoria.zy7_etapas) !== 5) {
            return true;
        }

        // if (this.store.isSupervisor && Number(this.Promotoria.zy7_etapas) === 5) {
        //     return true;
        // }

        return false;
    }

    @computed
    get hasAtividadesAdd(){
        if(this.Atividades.length >= 1) return true;
        
        return false;
    }

    @computed
    get isDisabledPromotorNotResponsible(){
        if(this.store.isSupervisor){
            return false;
        }

        if(this.store.loginName !== this.Promotoria.zy7_codpro && Number(this.Promotoria.zy7_etapas) === 5) return true;

        return false;
    }

    @action
    parse(data) {
        this.Lancamentos = data.lancamentos;
        this.Produtos = data.produtos;
        this.Anexos = data.anexos;
        this.Promotoria = data.data.Promotoria;
        this.PromotoresAux = data.promotores.filter(item => item.zyd_tipo === "2");
        this.HistorioReprova = data.historicoReprova;
        const promotor = data.promotores.find(item => item.zyd_tipo === "1");
        this.Promotoria.zy7_codpro = promotor.login;
        this.Promotoria.zy7_promot = promotor.nome
        this.promotores = data.promotores;
    }

    @action
    nextStep() {
        if (this.stepAtual === 6) {
            return;
        }

        if (this.stepAtual === 2 && !this.isShowStepsValid && (this.store.isVendedor || this.store.isOperador)) {
            return;
        }

        if (this.stepAtual === 3 && Number(this.Promotoria.zy7_etapas) <= 3 && this.Promotoria.zy7_criado === "S" && !this.store.isSupervisor) return;

        this.stepAtual = this.stepAtual + 1;
    }

    @action
    previousStep() {
        if (this.stepAtual === 0) {
            return;
        }

        this.stepAtual = this.stepAtual - 1;
    }

    @action
    adicionarProduto(value) {
        this.Produtos.push(value);
    }

    @action
    removerProduto(produto) {
        this.Produtos = this.Produtos.filter(item => item.zy9_codprd !== produto.zy9_codprd);

        if (!!produto.r_e_c_n_o_) {
            this.produtosRemovidos.push(produto);
        }
    }

    @action
    salvarStepAtividades(value) {
        const lancado = value?.ZY8_TPATIV === "P" ? "N" : "S"
        
        if(isNaN(value.ZY8_QTDPIN) && value?.ZY8_TPATIV === "P"){
            throw new Error('Quantidade inválida de participantes');
        }
        
        const payload = {
            zy8_descri: value?.ZY8_DESCRI,
            zy8_dtvisi: value?.ZY8_DTVISI,
            zy8_filial: value?.ZY8_FILIAL,
            zy8_local: value?.ZY8_LOCAL,
            zy8_protoc: value?.ZY8_PROTOC,
            zy8_qtdpes: value?.ZY8_QTDPES,
            zy8_qtdpin: value?.ZY8_QTDPIN,
            zy8_respon: value?.ZY8_RESPON,
            zy8_sacres: value?.ZY8_SACRES,
            zy8_codsol: this.Promotoria?.zy7_codsol,
            zy8_tpativ: value?.ZY8_TPATIV,
            zy8_lancad: lancado,
            numeroAleatorio: generateRandomNumber()
        }

        this.Atividades.push(payload);
    }

    @action
    handleRemoveAtividade(value) {
        if (!!value.r_e_c_n_o_) {
            const atividadesFiltradas = this.Atividades.filter(item => item.r_e_c_n_o_ !== value.r_e_c_n_o_);
            this.Atividades = atividadesFiltradas;

            //Isso valida que existe no banco, caso houver recno será enviado para o back para remover pelo recno.
            this.atividadesRemovidas.push(value);
        }else{
            const atividadesFiltradas = this.Atividades.filter(item => item.numeroAleatorio !== value.numeroAleatorio);
            this.Atividades = atividadesFiltradas;
        }
    }

    @action
    clearStepAtividades() {
        this.Atividades = [];
    }

    @action
    handlePromotor(value) {
        this.Promotoria.zy7_promot = value.nome;
        this.Promotoria.zy7_codpro = value.login;
    }

    @action
    ajustarInputs(value, param) {
        switch (param) {
            case "zy7_propri":
                this.Promotoria.zy7_propri = value;
                break;
            case "zy7_emailr":
                this.Promotoria.zy7_emailr = value;
                break;
            case "zy7_respon":
                this.Promotoria.zy7_respon = value;
                break;
            case "zy7_telres":
                this.Promotoria.zy7_telres = value;
                break;
            case "zy7_emailp":
                this.Promotoria.zy7_emailp = value;
                break
            case "zy7_dtiniv":
                this.Promotoria.zy7_dtiniv = value;
                break;
            case "zy7_dtfimv":
                this.Promotoria.zy7_dtfimv = value;
                break;
            default:
                break;
        }
    }

    @action
    adicionarPromotorAuxiliar(promotor) {
        const promotorAlreadyExists = this.PromotoresAux.some(item => item.login === promotor.login);

        if (promotorAlreadyExists) return message.warning('Promotor já adicionado');

        this.PromotoresAux.push(promotor);
    }

    @action
    removePromotorAux(value) {
        this.PromotoresAuxRemovidos.push(value);
        return this.PromotoresAux = this.PromotoresAux.filter(promotor => promotor.login !== value.login);
    }

    @action
    handleInputDeslocamento(value, id) {
        const index = this.PromotoresAux.findIndex(promotor => promotor.login === id);

        if (index !== -1) {
            // Usando o spread operator para criar um novo promotor com a atualização
            const updatedPromotor = {
                ...this.PromotoresAux[index],
                deslocamento: value,
            };

            // Substituir o promotor antigo pelo atualizado na mesma posição do array
            this.PromotoresAux[index] = updatedPromotor;
        }
    }

    @action
    removeAtividadePalestraAposLancamento(recno) {
        this.Atividades = this.Atividades.filter(item => item.r_e_c_n_o_ !== recno);
        return;
    }

    @action
    handleDateAtividade(data, atividade) {
        this.Atividades.forEach(item => {
            if (item.r_e_c_n_o_ === atividade.r_e_c_n_o_) {
                item.zy8_dtvisi = moment(data).format('YYYYMMDD');
            }
        });
    }
}