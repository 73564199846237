import React, { Component, Fragment } from "react";
import { Modal, Button } from "antd";
import { observer } from "mobx-react";

import "./ProporcaoInfo.less";

@observer
export default class ProporcaoInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }
  open = () => this.setState({ visible: true });
  close = () => this.setState({ visible: false });
  render() {
    const { proporcao = null, atingida = 0 } = this.props;

    if (!proporcao || proporcao.length < 1) {
      return null;
    }

    const minimo = Math.max(+proporcao[0].proporcao_cabecalho, 0);
    const apoio =
      atingida < minimo
        ? `Para prosseguir com esse pedido você precisará atingir a <strong>proporção mínima de ${minimo}%</strong>, porém a <em>proporção atual está em ${atingida}%</em>. Vendendo os itens da lista abaixo você conseguirá melhorar a proporção do seu pedido:`
        : `Você já atingiu a <strong>proporção mínima de ${minimo}%</strong> utilizando os itens abaixo:`;

    return (
      <Modal
        centered
        width={560}
        title="Proporção do Pedido"
        visible={this.state.visible}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Button type="primary" onClick={this.close}>
              Voltar ao Pedido
            </Button>
          </div>
        }
        onCancel={this.close}
        destroyOnClose={true}
        maskClosable={true}
        wrapClassName="proporcao-info-modal">
        <Fragment>
          <p dangerouslySetInnerHTML={{ __html: apoio }} />
          <table>
            <thead>
              <tr>
                <th className="text-center">Linha</th>
                <th className="text-center">Classe</th>
                <th className="text-left">Descrição</th>
              </tr>
            </thead>
            <tbody>
              {proporcao.map(p => (
                <tr key={`${p.classe_produto}:${p.linha_produto}`}>
                  <td className="text-center">{p.desc_linha}</td>
                  <td className="text-center">{p.classe_produto}</td>
                  <td className="text-left">{p.desc_classe}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      </Modal>
    );
  }
}
