import React, { Component } from "react";
import { Modal, Table } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import classnames from "classnames";
import _isEqual from "lodash/isEqual";

import { renderOrClone, compactPagination, compactFilters, compactSorter } from "./utils";

import "./ChooserTable.less";

const showTotal = (total, range) => null;
const evenAndOdd = (_, index) =>
  index % 2 !== 0 ? `chooser-table--row chooser-table--row__odd` : `chooser-table--row`;

@observer
export default class ChooserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: props.selectedRowKeys || [],
    };
  }
  componentDidMount() {
    const { fetchOnMount = true, dataSource } = this.props;
    if (!!fetchOnMount && !!dataSource) this.fetchData();
  }
  isLoading = loading => !!loading || !!this.props.dataSource.isLoading;
  updateDataSource = (pagination = null, filters = null, sorter = null) => {
    let diff = 0;

    const { dataSource, onChange, columns = [] } = this.props;
    const snapshot = dataSource.requestJSON;

    if (pagination !== null) {
      pagination = compactPagination(pagination);
      if (!_isEqual(pagination, snapshot.pagination)) {
        dataSource.pagination = pagination;
        diff++;
      }
    }
    if (filters !== null) {
      filters = compactFilters(filters);
      if (!_isEqual(filters, snapshot.filters)) {
        dataSource.filters = filters;
        diff++;
      }
    }
    if (sorter !== null) {
      sorter = compactSorter(sorter);
      if (!_isEqual(sorter, snapshot.sorter)) {
        dataSource.sorter = sorter;
        diff++;
      }
    }

    columns
      .filter(column => "filterParser" in column && typeof column.filterParser === "function")
      .forEach(column => {
        dataSource.filterParsers[column.key] = column.filterParser;
      });

    if (diff > 0) {
      if (!!onChange) {
        onChange(dataSource.requestJSON);
      } else {
        this.fetchData();
      }
    }
  };
  fetchData = async () => {
    try {
      return await this.props.dataSource.fetch();
    } catch (err) {
      const { errorMessage = "Não foi possível obter os dados relacionados neste momento" } = this.props;
      Modal.error({
        title: "Erro na requisição",
        content: errorMessage,
      });
    }
  };
  pagination = () => {
    const { dataSource, dataSourceMap = null, paginationMap = null } = this.props;
    if (dataSource.hasPagination) {
      if (!!paginationMap && typeof paginationMap === "function") {
        const items =
          !!dataSourceMap && typeof dataSourceMap === "function" ? dataSourceMap(dataSource.items) : dataSource.items;

        return paginationMap(toJS(dataSource.pagination), items);
      }

      return dataSource.pagination;
    }

    return null;
  };
  onRow = record => ({
    onClick: () => this.selectRow(record),
  });
  selectRow = record => {
    const { rowKey, disabledRowKeys = [], allowMultipleSelection = false, onSelectRow = false } = this.props;
    let selectedRowKeys = [...this.state.selectedRowKeys];

    let plainRecord = toJS(record);
    if (!plainRecord.key && !!rowKey) {
      plainRecord.key = rowKey(plainRecord);
    }

    let isDisabled = false;
    if (!!disabledRowKeys && Array.isArray(disabledRowKeys) && disabledRowKeys.indexOf(plainRecord.key) >= 0) {
      isDisabled = true;
    } else if (!!disabledRowKeys && typeof disabledRowKeys === "function" && !!disabledRowKeys(plainRecord)) {
      isDisabled = true;
    }

    if (isDisabled) {
      return false;
    }

    if (allowMultipleSelection) {
      if (selectedRowKeys.indexOf(plainRecord.key) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(plainRecord.key), 1);
      } else {
        selectedRowKeys.push(plainRecord.key);
      }
    } else {
      selectedRowKeys = [plainRecord.key];
    }
    this.setState({ selectedRowKeys }, () => {
      if (!!onSelectRow && typeof onSelectRow === "function") onSelectRow(plainRecord, selectedRowKeys);
    });
  };
  decorateRows = (record, index) => {
    const { disabledRowKeys = [], rowKey } = this.props;
    let plainRecord = toJS(record);
    if (!plainRecord.key && !!rowKey) {
      plainRecord.key = rowKey(plainRecord);
    }

    let isDisabled = false;
    if (!!disabledRowKeys && Array.isArray(disabledRowKeys) && disabledRowKeys.indexOf(plainRecord.key) >= 0) {
      isDisabled = true;
    } else if (!!disabledRowKeys && typeof disabledRowKeys === "function" && !!disabledRowKeys(plainRecord)) {
      isDisabled = true;
    }
    if (isDisabled) {
      return `chooser-table--row chooser-table--row__disabled`;
    }

    if (!!plainRecord.key && this.state.selectedRowKeys.indexOf(plainRecord.key) >= 0) {
      return `chooser-table--row chooser-table--row__selected`;
    }

    return evenAndOdd(plainRecord, index);
  };
  render() {
    const {
      columns = [],
      dataSource,
      dataSourceMap = null,
      paginationMap,
      paginationProps,
      className,
      wrapperClassName,
      locale = {},
      loading = false,
      bordered = true,
      size = "middle",
      height = 348,
      rowClassName = this.decorateRows,
      scroll = { y: height },
      selectedRowKeys,
      disabledRowKeys,
      beforeComponent,
      afterComponent,
      children,
      ...tableProps
    } = this.props;

    const ChooserTableComponent = (
      <Table
        className={classnames(`chooser-table`, { "chooser-table__loading": this.isLoading(loading) }, className)}
        pagination={dataSource.hasPagination && { size: "small", showTotal, ...this.pagination(), ...paginationProps }}
        loading={this.isLoading(loading)}
        bordered={bordered}
        rowClassName={rowClassName}
        onChange={(pg, filters, order) => {
          if (dataSource.hasPagination && !!pg && +pg.current !== +dataSource.pagination.current) {
            this.updateDataSource(pg, null, null);
          } else {
            this.updateDataSource(pg, filters || null, order || null);
          }
        }}
        columns={columns}
        dataSource={
          !!dataSourceMap && typeof dataSourceMap === "function" ? dataSourceMap(dataSource.items) : dataSource.items
        }
        locale={{
          filterTitle: "Filtrar",
          filterConfirm: "OK",
          filterReset: "Limpar",
          emptyText: "Sem dados encontrados para esta consulta ou filtro",
          ...locale,
        }}
        size={size}
        scroll={scroll}
        onRow={this.onRow}
        {...tableProps}
      />
    );

    return (
      <div className={classnames(`chooser-table-wrapper`, wrapperClassName)}>
        {!!beforeComponent && renderOrClone(beforeComponent, { dataSource, onUpdateDataSource: this.updateDataSource })}
        {dataSource.controlled && !dataSource.fetched && !this.isLoading(loading) && !!children
          ? children
          : ChooserTableComponent}
        {!!afterComponent && renderOrClone(afterComponent, { dataSource, onUpdateDataSource: this.updateDataSource })}
      </div>
    );
  }
}
