import React, { Component } from "react";
import { observer } from "mobx-react";
import _size from "lodash/size";
import { serializeObject } from "@util";
import withStore from "withStore";

@observer
class RequestForm extends Component {
  componentWillUnmount() {
    if (!!this._waitSubmit) clearTimeout(this._waitSubmit);
  }
  submitForm = () => {
    if (!!this._formRef) {
      if (!!this._waitSubmit) clearTimeout(this._waitSubmit);

      this._waitSubmit = setTimeout(() => this._formRef.submit(), 110);
    }
  };
  render() {
    const { store, children, target = "_blank", params = {}, ...otherProps } = this.props;
    return (
      <form ref={_ref => (this._formRef = _ref)} method="post" target={target} tabIndex="-1" {...otherProps}>
        {store.isLogged && <input type="hidden" name="_token" value={store.loginToken} tabIndex="-1" />}
        {!!params &&
          _size(params) > 0 &&
          serializeObject(params).map((param, i) => (
            <input type="hidden" key={`${param.field}_${i}`} name={param.field} value={param.value} tabIndex="-1" />
          ))}
        {!!children &&
          typeof children === "function" &&
          children({
            submitForm: this.submitForm,
          })}
      </form>
    );
  }
  _waitSubmit;
  _formRef;
}

export default withStore(RequestForm);
