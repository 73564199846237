import React, { Component } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";

import { flatArrayToObject , filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import SearchFormCliente from "components/clientes/SearchFormCliente";
import ResumoCliente, { Item } from "components/clientes/ResumoCliente";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

@observer
class ClientesScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Relação de Clientes",
      uri: "/r/clientes",
      state: {
        categorias: {},
        canais: {},
        perfis: [],
      },
    });

    this.state = {};
  }
  renderSearchForm = props => {
    const { store } = this.props;

    return (
      <SearchFormCliente
        showCidade={!!store.isB2B || +store.viewport.width >= 1336}
        showCanal={!store.isB2B}
        showPerfil={!store.isB2B}
        showCategoria={store.isB2B}
        {...props}
      />
    );
  };
  render() {
    const { store } = this.props;

    const extraData = {
      canais: toJS(this.ds.state.canais),
      perfis: flatArrayToObject(this.ds.state.perfis, "cod_perfil", "descricao_perfil"),
    };

    const dataParserPerfil = (cod_perfil, item, e) => (!!cod_perfil ? e.perfis[cod_perfil] || "Não definido" : "");

    const columns = [
      {
        title: <span className="th-small-label">Código</span>,
        dataLabel: "Código",
        dataIndex: "codigo",
        key: "codigo",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Cliente" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">Cliente</span>,
        dataLabel: "Cliente",
        dataIndex: "razao_social",
        key: "razao_social",
        sorter: true,
        className: "text-left",
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Razão Social" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">CNPJ</span>,
        dataLabel: "CNPJ",
        dataIndex: "cnpj",
        key: "cnpj",
        sorter: false,
        width: "11%",
        filteredValue: this.ds.filterValue("cnpj"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por CNPJ" {...props} />,
        filterParser: filter => ({ ...filter, value: `${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">I.E.</span>,
        dataLabel: "I.E.",
        dataIndex: "ie",
        key: "ie",
        sorter: false,
        render: text => <WrappedValue value={!!text && text.trim() !== "" ? text : "ISENTO"} />,
        width: "10%",
        filteredValue: this.ds.filterValue("ie"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Inscrição Estadual" {...props} />,
        filterParser: filter => ({ ...filter, value: `${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">Ult.Compra</span>,
        dataLabel: "Ult.Compra",
        dataIndex: "ultima_compra_rel",
        key: "ultima_compra_rel",
        sorter: true,
        dataType: "date",
        width: "10%",
        filteredValue: this.ds.filterValue("ultima_compra_rel"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, value: filterDate(filter.value, "") }),
      },

      {
        title: <span className="th-small-label">Telefone</span>,
        dataLabel: "Telefone",
        dataIndex: "telefone",
        key: "telefone",
        sorter: false,
        width: "10%",
      },
      {
        title: <span className="th-small-label">Endereço</span>,
        dataLabel: "Endereço",
        dataIndex: "endereco",
        key: "endereco",
        sorter: true,
        className: "text-left",
        width: "13%",
        filteredValue: this.ds.filterValue("endereco"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Endereço" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">Bairro</span>,
        dataLabel: "Bairro",
        dataIndex: "bairro",
        key: "bairro",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("bairro"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Bairro" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">Cidade</span>,
        dataLabel: "Cidade",
        dataIndex: "cidade",
        key: "cidade",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("cidade"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cidade" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">UF</span>,
        dataLabel: "UF",
        dataIndex: "uf",
        key: "uf",
        sorter: true,
        width: "7.5%",
        filteredValue: this.ds.filterValue("uf"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Estado" {...props} />,
      },
      {
        title: "Canal",
        dataIndex: "cod_canal",
        dataParser: (cod_canal, item, e) => e.canais[cod_canal] || "Não definido",
        exportOnly: true,
      },
      {
        title: "Perfil Automotivo",
        dataIndex: "cod_perfil_aut",
        dataParser: dataParserPerfil,
        exportOnly: true,
      },
      {
        title: "Perfil Forçado Auto.",
        dataIndex: "cod_perfil_aut_forcado",
        dataParser: dataParserPerfil,
        exportOnly: true,
      },
      {
        title: "Perfil Automotivo",
        dataIndex: "cod_perfil_imob",
        dataParser: dataParserPerfil,
        exportOnly: true,
      },
      {
        title: "Perfil Forçado Auto.",
        dataIndex: "cod_perfil_imob_forcado",
        dataParser: dataParserPerfil,
        exportOnly: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        exportOnly: true,
      },
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name} />
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          rowKey={item => `${item.codigo}:${item.cnpj}`}
          expandedRowRender={cliente => (
            <ResumoCliente
              className="full-table--more-info"
              cliente={cliente}
              showPerfil={cliente.unidade_negocio.toUpperCase() === "REVENDA"}
              canais={extraData.canais}
              perfis={extraData.perfis}>
              {store.isTipoVendaInterna && !!store.vendedoresRelacionados[cliente.vendedor_atendimento] && (
                <Item label="Vendedor Atendimento">{store.vendedoresRelacionados[cliente.vendedor_atendimento]}</Item>
              )}
            </ResumoCliente>
          )}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          extraData={extraData}
          errorMessage="Não foi possível obter seus clientes relacionados no momento"
        />
      </div>
    );
  }
}

export default withStore(ClientesScreen);
