import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker } from "antd";
import { observer } from "mobx-react";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm, Toolbar } from "components/FullTable";
import RadioGroup from "antd/lib/radio/group";
import RadioButton from "antd/lib/radio/radioButton";
import moment from "moment";

const FormItem = Form.Item;

@observer
class CursosTreinamentosPromotoriaReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Cursos e Treinamentos Promotoria",
      uri: "/r/cursos-treinamentos-promotoria",
      controlled: false,
    });

    this.state = {
      isMop,
      quantidade: moment().add(1, 'month').diff(moment(), 'days')
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          quantidade: "text",
        }}
        filterParser={{
          quantidade: filter => ({ ...filter, type: "text" }),
        }}
        defaultFilters={{ quantidade: moment().add(1, 'month').diff(moment(), 'days') }}>
        {({ form }) => (
          <Fragment>
                <FormItem>
                {form.getFieldDecorator("quantidade")(
                  <RadioGroup
                    onChange={({ target: { value } }) => this.setState({ quantidade: value })}
                    value={this.state.quantidade}
                    buttonStyle="solid"
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <RadioButton
                        key={`quantidade-${i + 1}`}
                        value={moment().add(i + 1, 'months').diff(moment(), 'days')}
                      >
                        {`${i + 1} mês${i + 1 > 1 ? 'es' : ''}`}
                      </RadioButton>
                    ))}
                  </RadioGroup>
                )}
                </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
            <FormItem style={{ float: "right", margin: 0 }}>
              <Toolbar.Button
                  disabled={dataSource.isLoading}
                  size="default"
                  icon="reload"
                  ghost={true}
                  onClick={() => dataSource.fetch()}>
                  Atualizar
              </Toolbar.Button>
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Promotoria",
        dataIndex: "promotoria",
        key: "promotoria",
        sorter: false,
        filteredValue: this.ds.filterValue("promotoria"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Promotoria" {...props} />,
        filterParser: filter => ({ ...filter, field: "promotoria", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Data inicial",
        dataIndex: "data_inicial",
        dataType: "date",
        key: "data_inicial",
        width: "9.08%",
      },
      {
        title: "Data final",
        dataIndex: "data_final",
        dataType: "date",
        key: "data_final",
        width: "9.08%",
      },
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Representante",
        dataIndex: "representante",
        key: "representante",
        sorter: false,
        filteredValue: this.ds.filterValue("representante"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "representante", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Qtd. pessoas",
        dataIndex: "pessoas",
        key: "pessoas",
        width: "9.08%",
      },
      {
        title: "Vlr. gasto",
        dataIndex: "valores_gastos",
        key: "valores_gastos",
        width: "9.08%",
        render: n => <WrappedValue value={n} type="money" />,
      },
      {
        title: "Linha do Curso",
        dataIndex: "linha",
        key: "linha",
        sorter: false,
        filteredValue: this.ds.filterValue("linha"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Linha do Curso" {...props} />,
        filterParser: filter => ({ ...filter, field: "linha", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Atividade",
        dataIndex: "atividade",
        key: "atividade",
        width: "9.08%",
      },
      {
        title: "Volume/Receita Antes",
        dataIndex: "volume_antes",
        key: "volume_antes",
        width: "9.08%",
      },
      {
        title: "Valor antes",
        dataIndex: "valor_antes",
        key: "valor_antes",
        width: "9.08%",
        render: n => <WrappedValue value={n} type="money" />,
      },
      {
        title: "Volume/Receita Depois",
        dataIndex: "volume_depois",
        key: "volume_depois",
        width: "9.08%",
      },
      {
        title: "Valor depois",
        dataIndex: "valor_depois",
        key: "valor_depois",
        width: "9.08%",
        render: n => <WrappedValue value={n} type="money" />,
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          actionBar={<Toolbar exportable={false} printable={false} cleanable={false}/>}
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.promotoria}`+`${item.pessoas}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter seus cursos e treinamentos no momento"
        />
      </div>
    );
  }
}

export default withStore(CursosTreinamentosPromotoriaReportScreen);
