import React, { Component, PureComponent } from "react";
import { Modal, Row, Col, Checkbox, Form, Input, Button, Spin } from "antd";
import { observer } from "mobx-react";
import MaskedInput from "antd-mask-input";
import { fetchApi, fetchPostApi } from "actions";
import { getData, uniq } from "@util";
import { REGION_STATES } from "@env";
import withStore from "withStore";
import InputDecimal from "components/InputDecimal";
import InputSearch from "components/InputSearch";
import Select, { Option } from "components/Select";
import { v } from "components/FormBuilder";
import PageHeader, { BackButton } from "components/PageHeader";
//import ChooserCnae from "components/clientes/ChooserCnae";

const WEEK_DAYS = {
  dom: "Domingo",
  seg: "Segunda",
  ter: "Terça",
  qua: "Quarta",
  qui: "Quinta",
  sex: "Sexta",
  sab: "Sábado",
};
const FormItem = Form.Item;

function checkDataLogistica(cliente, w) {
  if (!cliente || !cliente.data_logistica || cliente.data_logistica.trim() === "") {
    return true;
  }

  return cliente.data_logistica.indexOf(w) !== -1;
}

function flatPerfisToObject(arr, canal = "03", key = "cod_perfil", value = "descricao_perfil") {
  return arr.filter(p => p.canal === canal).reduce((acc, e) => ({ ...acc, [e[key]]: e[value] }), {});
}

// export class CnaeError extends Error {
//   constructor(message, code = 0) {
//     super(message);

//     this.name = "CnaeError";
//     this.code = code;
//   }
// }

@observer
class CadastroEditScreen extends Component {
  state = {
    cliente: null,
    gerente: null,
    vendedor: null,
    perfis: {},
    perfis_forcado: {},
    perfisDisabled: [],
    resetPerfisDisabled: [],
    canais: {},
    current: {},
    focused: null,
    allowed: [
      "nome_comprador",
      //"email_financeiro",
      //"email_nps",       
      //"email_comprador", 
      "email_xml",
      "email_contato",
      "telefone",
      //"cnae",
      //"desc_cnae",
      "whatsapp",
      "nome_contato",
      "volume_projetado_tinta",
      "volume_projetado_solvente",
      "endereco_cobranca",
      "cidade_cobranca",
      "uf_cobranca",
      "bairro_cobranca",
      "cep_cobranca",
      "endereco_entrega",
      "complemento_entrega",
      "cidade_entrega",
      "uf_entrega",
      "bairro_entrega",
      "cep_entrega",
      "email_laudo",
      "data_logistica",
      "reenvia_senha",
      "nome_resp_fin",
      "email_financeiro",
      "telefone_fin",
      "nome_resp_fin2",
      "email_fin2",
      "telefone_resp_fin2",
      "nome_resp_fin3",
      "email_resp_fin3",
      "tel_resp_fin3",
      //"nome_comp",
      "email_comp",
      "telefone_resp_comp",
      "nome_comp2",
      "email_comp2",
      "tel_resp",
      "nome_comp3",
      "email_comp3",
      "tel_comp3",
      "nome_proprietario",
      "email_prop",
      "whatsapp_prop",
      "resp_nf",
      //"email_nf",
      "tel_nf",
      "resp_agenda",
      "email_agenda",
      "tel_agenda",
      "nome_resp_nf",
      "email_nps1",
      "whatsapp_nps",
    ],
    clienteAtualiza: null
  };
  componentDidMount() {
    this.props.store.isLoading = true;

    if (!this.props.isAtualiza) {
      this.fetchCadastro();
    }
    else {
      this.fetchAtualizacao();
    }
  }
  mapClienteToForm() {
    const { cliente, allowed, perfis, perfisDisabled } = this.state;
    const perfisList = flatPerfisToObject(perfis, cliente.cod_canal);
    const formData = Object.keys(cliente).reduce(
      (acc, k) => {
        const canEmailLaudo = cliente.unidade_negocio.toUpperCase() !== "REVENDA" && k === "email_laudo";
        if ((canEmailLaudo || (k !== "data_logistica" && k !== "email_laudo")) && allowed.indexOf(k) !== -1) {
          acc[k] = cliente[k];
        }
        return acc;
      },
      {
        cod_perfil_aut: perfisList[cliente.cod_perfil_aut] || "",
        cod_perfil_aut_forcado: cliente.cod_perfil_aut_forcado || undefined,
        cod_perfil_imob: perfisList[cliente.cod_perfil_imob] || "",
        cod_perfil_imob_forcado: cliente.cod_perfil_imob_forcado || undefined,
      }
    );

    if (!!formData.cod_perfil_aut_forcado) {
      perfisDisabled.push("cod_perfil_aut_forcado");
    }
    if (!!formData.cod_perfil_imob_forcado) {
      perfisDisabled.push("cod_perfil_imob_forcado");
    }

    this.props.form.setFieldsValue(formData);
    this.setState({ perfisDisabled });
  }
  removeCamposVazios(objeto) {
    const entradasAtualizadas = Object.entries(objeto).map(([chave, valor]) => [chave, (valor !== undefined && valor !== null && valor !== '') ? valor : ' ']);

    const novoObjeto = Object.fromEntries(entradasAtualizadas);

    return novoObjeto;
  }
  mapClienteToFormAtualizacao() {
    const { clienteAtualiza } = this.state;

    this.props.form.setFieldsValue(clienteAtualiza);
  }
  async fetchCadastro() {
    const { store, match, history } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/clientes/cadastro/" + match.params.slug);
      if (!!response.success) {
        const { cliente, gerente, vendedor, perfis, perfis_forcado, canais, allowed } = response.data;
        this.setState({ cliente, gerente, vendedor, perfis, perfis_forcado, canais, allowed }, () =>
          this.mapClienteToForm()
        );
      } else {
        throw new Error("Não foi possível obter dados atualizados de seu cliente");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados de seu cliente",
        onOk() {
          history.replace("/vendedor/clientes")
        },
      });

      this.setState({ cliente: null });
    } finally {
      store.isLoading = false;
    }
  }
  async fetchAtualizacao() {
    const { store, isAtualiza } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/clientes/atualizacao/" + isAtualiza);
      if (response.success) {
        this.setState({ clienteAtualiza: response.data }, () => this.mapClienteToFormAtualizacao())
      } else {
        throw new Error("Não foi possível obter dados atualizados de seu cliente");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados de seu cliente",
      });

      this.setState({ cliente: null });
    } finally {
      store.isLoading = false;
    }
  }

  afterChanged = async (field, onChanged, nextFocus = null) => {
    if (this.hasChanged(field) && !!onChanged && typeof onChanged === "function") {
      await onChanged(field);
    }
    if (!!nextFocus) {
      this.requestFocus(nextFocus);
    }
  };

  hasChanged = field => {
    const { current } = this.state;
    const value = this.props.form.getFieldValue(field);

    return !(field in current) || `${current[field]}` !== `${value}`;
  };

  requestFocus = field => {
    if (!!this._inputs[field]) {
      this._requestFocus = setTimeout(() => {
        try {
          this._inputs[field].focus();
        } catch (ex) { }
      }, 1000 / 60);
    }
  };

  saveCurrentValue = field => event => {
    const { current } = this.state;
    const value = typeof event === "string" ? event : event.target.value;
    this.setState({ focused: field, current: { ...current, [field]: value } });
  };

  // loadCnae = async () => {
  //   try {
  //     await this.props.form.loadCnae();
  //   } catch (err) {
  //     throw new CnaeError(`Atividade-CNAE inválido!`, 10);
  //   }
  // };

  saveInput = field => _ref => {
    this._inputs[field] = _ref;
  };

  getInputProps = (field, customProps = {}) => {
    const { data } = this.props;
    const { onChange = null, ...extraProps } = customProps;

    return {
      ref: this.saveInput(field),
      name: field,
      value: data[field],
      onFocus: this.saveCurrentValue(field),
      onChange: this.updateItemValue(field, onChange),
      ...extraProps,
    };
  };

  // openChooser = (modal = "cnae", onChoose = null) => {
  //   const _onChoose = async (...args) => {
  //     if (!!onChoose && typeof onChoose === "function") {
  //       await onChoose(...args);
  //     }
  //   };

  //   switch (modal) {
  //     case "cnae":
  //       this._choosers.cnae.open(Cnae => {
  //         if (!!Cnae) {
  //           _onChoose(Cnae);
  //         }
  //       });
  //       break;
  //     case "condicao":
  //       this._choosers.condicao.open(condicao => {
  //         if (!!condicao) {
  //           _onChoose(condicao);
  //         }
  //       });
  //       break;  
  //     default:
  //       console.warn("Chooser modal not found");
  //       break;
  //   }
  // };

  async saveCadastro(formData) {
    const { store, match, history } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchPostApi("/clientes/cadastro/" + match.params.slug, formData);
      if (!!response.success) {
        Modal.success({
          title: "Cadastro atualizado com sucesso",
          content: "Os dados de seu cliente foram atualizados com sucesso",
          onOk() {
            history.replace("/vendedor/clientes");
          },
          keyboard: false,
        });

        store.ping();
        store.isLoading = false;
      } else {
        throw new Error("Não foi possível atualizar estes dados neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Somente usuários do tipo CLIENTE podem atualizar dados cadastrais",
        5: "Não foi possível atualizar estes dados neste momento. Tente novamente mais tarde",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  }

  async saveAtualizacaoCadastro(formData) {
    const { store, match, history, isAtualiza, closeAtualiza } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchPostApi("/clientes/atualizacao/" + isAtualiza, this.removeCamposVazios(formData));
      if (!!response.success) {
        Modal.success({
          title: "Cadastro atualizado com sucesso",
          content: "Os dados de seu cliente foram atualizados com sucesso",
          keyboard: false,
        });

        closeAtualiza();
        store.isLoading = false;
      } else {
        throw new Error("Não foi possível atualizar estes dados neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Somente usuários do tipo CLIENTE podem atualizar dados cadastrais",
        5: "Não foi possível atualizar estes dados neste momento. Tente novamente mais tarde",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  }

  resetPerfilForcado = linha => {
    const field = `cod_perfil_${linha}`;
    const field_forcado = `${field}_forcado`;
    this.setState(
      {
        resetPerfisDisabled: [...this.state.resetPerfisDisabled, field_forcado],
      },
      () => this.props.form.setFieldsValue({ [field_forcado]: this.state.cliente[field] })
    );
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveCadastro({ ...this.state.cliente, ...formData });
      }
    });
  };
  handleAtualizacao = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveAtualizacaoCadastro({ ...formData });
      }
    });
  };
  handleDataLogistica = event => {
    const { target } = event;
    const checked = !!target.checked;
    const { cliente } = this.state;

    let data_logistica =
      !!cliente && !!cliente.data_logistica ? cliente.data_logistica.split(";") : Object.keys(WEEK_DAYS);
    if (!checked && data_logistica.indexOf(target.value) !== -1) {
      data_logistica.splice(data_logistica.indexOf(target.value), 1);
    } else if (checked && data_logistica.indexOf(target.value) === -1) {
      data_logistica.push(target.value);
    }
    data_logistica = uniq(data_logistica);

    this.setState({ cliente: { ...cliente, data_logistica: data_logistica.join(";") } });
  };
  handleCancel = () => {
    const { renewFocus } = this.props;
    renewFocus();
  }
  render() {
    const { chooser, isAtualiza } = this.props;
    const { cliente, clienteAtualiza, perfis_forcado = {} } = this.state;
    const { isLoading } = this.props.store;
    const { getFieldDecorator } = this.props.form;

    const inputProps = {
      readOnly: isLoading,
    };

    const isB2B = !!cliente && cliente.unidade_negocio.toUpperCase() !== "REVENDA";
    const perfisList = !!cliente ? flatPerfisToObject(perfis_forcado, cliente.cod_canal) : {};

    const optionsUF = Object.keys(REGION_STATES).map(uf => (
      <Option key={uf} value={uf}>
        {REGION_STATES[uf]}
      </Option>
    ));

    const optionsPerfis = Object.keys(perfisList).map(cod_perfil => (
      <Option key={cod_perfil} value={cod_perfil}>
        {perfisList[cod_perfil]}
      </Option>
    ));
    return (
      <div className="cadastro-cliente-screen">
        {!isAtualiza ?
          <>
            <PageHeader
              style={{ marginLeft: '-24px', marginRight: '-24px' }}
              title={`Alterando Cadastro (${!!cliente && !!cliente.codigo ? `${cliente.codigo}/${cliente.loja}` : "CARREGANDO"
                })${!!cliente && cliente.status === "INATIVO" ? " - INATIVO" : ""}`}
            />
            <p>
              Altere os campos abaixo para atualizar o cadastro de seu cliente{" "}
              <strong>
                {!!cliente && !!cliente.razao_social ? `${cliente.razao_social} (CNPJ: ${cliente.cnpj})` : "CARREGANDO"}
              </strong>
              :
            </p>
          </>
          :
          <PageHeader
            style={{ marginLeft: '-24px', marginRight: '-24px' }}
            title={`Alterando Cadastro`}
          />
        }
        {!isAtualiza ?
          <Spin spinning={!cliente && !!isLoading}>
            <Form
              layout="vertical"
              hideRequiredMark={false}
              onSubmit={this.handleSubmit}
              className="cadastro-cliente--form">
              <Row gutter={12}>
                {/* <Col span={12}>
                  <FormItem label="Atividade-CNAE">
                    {getFieldDecorator("cnae", { rules: [v.required] })(
                    <InputSearch
                    {...inputProps}
                      maxLength={10} 
                      onBlur={() => this.afterChanged("cnae", this.loadCnae)}
                      onSearch={value => //chooser("cnaes", () => this.requestFocus("desc_cnae"))}
                      
                      this.openChooser("cnae", Cnae => {this.afterChanged("cnae", () => this.loadCnae(), "desc_cnae");
                      })
                    }
                    onPressEnter={event => {
                      event.preventDefault();
                      if (event.target.value.trim() === "") {
                        this.openChooser("cnae", () =>
                          this.afterChanged("cnae", () => this.loadCliente(), "desc_cnae")
                        );
                      } else {
                        this.afterChanged("cnae", () => this.loadCliente(), "desc_cnae" );
                      }
                    }}
                    />
                    )}
                  </FormItem>
                </Col> */}
                {/* <Col span={12}>
                  <FormItem label="Descrição CNAE">
                    {getFieldDecorator("desc_cnae" )(<Input {...inputProps} disabled={true} readOnly={true} maxLength={100} />)}
                  </FormItem>
                </Col> */}
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem label="Telefone Principal">
                    {getFieldDecorator("telefone", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Número WhatsApp">
                    {getFieldDecorator("whatsapp", {})(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem label="Nome para Contato">
                    {getFieldDecorator("nome_contato", { rules: [v.required] })(<Input {...inputProps} maxLength={60} />)}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12}>
                {!!isB2B && (
                  <Col span={12}>
                    <FormItem label="E-mail Laudo">
                      {/* {getFieldDecorator("email_laudo", { rules: [v.emailComp] })(<Input {...inputProps} maxLength={50} />)} */}
                      {getFieldDecorator("email_laudo")(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                )}
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem label="E-mail para Contato">
                    {/* {getFieldDecorator("email_contato", { rules: [v.emailComp, v.required] })(<Input {...inputProps} maxLength={250} />)} */}
                    {getFieldDecorator("email_contato", { rules: [v.required] })(<Input {...inputProps} maxLength={200} />)}
                  </FormItem>
                </Col>
                {!!cliente && (
                  <Col span={12}>
                    <Checkbox
                      onChange={event =>
                        this.setState({
                          cliente: { ...cliente, reenvia_senha: !!event.target.checked ? "S" : "N" },
                        })
                      }
                      checked={!!cliente.reenvia_senha && cliente.reenvia_senha[0] === "S"}
                      value="S">
                      Reenviar o email de acesso para o cliente (Enviado para o email do Contato).
                    </Checkbox>
                  </Col>
                )}
              </Row>
              {!!cliente && !isB2B && (
                <div style={{ marginTop: 26 }}>
                  <h3 className="form-title-split">Perfil do Cliente</h3>
                  <Row gutter={12}>
                    <Col span={10}>
                      <FormItem label="Perfil Automotivo">
                        {getFieldDecorator("cod_perfil_aut")(<Input {...inputProps} readOnly={true} />)}
                      </FormItem>
                    </Col>
                    <Col span={10}>
                      <FormItem label="Perfil Imobiliário">
                        {getFieldDecorator("cod_perfil_imob")(<Input {...inputProps} readOnly={true} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  {!!cliente.can_change_perfil && optionsPerfis.length > 0 && (
                    <Row gutter={12}>
                      <Col span={10}>
                        <FormItem label="Perfil Forçado Aut.">
                          {getFieldDecorator("cod_perfil_aut_forcado")(
                            <Select
                              {...inputProps}
                              disabled={this.state.perfisDisabled.indexOf("cod_perfil_aut_forcado") !== -1}
                              defaultActiveFirstOption={false}
                              placeholder="Nenhum perfil forçado">
                              {optionsPerfis}
                            </Select>
                          )}
                          {this.state.perfisDisabled.indexOf("cod_perfil_aut_forcado") !== -1 && (
                            <Button
                              disabled={this.state.resetPerfisDisabled.indexOf("cod_perfil_aut_forcado") !== -1}
                              onClick={event => this.resetPerfilForcado("aut")}
                              style={{ marginTop: 9 }}
                              loading={isLoading}
                              type="danger"
                              size="small"
                              ghost={true}>
                              Desfazer perfil forçado
                            </Button>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={10}>
                        <FormItem label="Perfil Forçado Imob.">
                          {getFieldDecorator("cod_perfil_imob_forcado")(
                            <Select
                              {...inputProps}
                              disabled={this.state.perfisDisabled.indexOf("cod_perfil_imob_forcado") !== -1}
                              defaultActiveFirstOption={false}
                              placeholder="Nenhum perfil forçado">
                              {optionsPerfis}
                            </Select>
                          )}
                          {this.state.perfisDisabled.indexOf("cod_perfil_imob_forcado") !== -1 && (
                            <Button
                              disabled={this.state.resetPerfisDisabled.indexOf("cod_perfil_imob_forcado") !== -1}
                              onClick={event => this.resetPerfilForcado("imob")}
                              style={{ marginTop: 9 }}
                              loading={isLoading}
                              type="danger"
                              size="small"
                              ghost={true}>
                              Desfazer perfil forçado
                            </Button>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
              {!!cliente && isB2B && (
                <div>
                  <h3 className="form-title-split">Potencial de compra mensal (Kg)</h3>
                  <Row gutter={12}>
                    <Col span={12}>
                      <FormItem label="Tinta e complemento">
                        {getFieldDecorator("volume_projetado_tinta", { rules: [v.required] })(<InputDecimal digits={0} {...inputProps} />)}
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label="Solvente">
                        {getFieldDecorator("volume_projetado_solvente", { rules: [v.required] })(<InputDecimal digits={0} {...inputProps} />)}
                      </FormItem>
                    </Col>
                  </Row>
                </div>
              )}
              <div>
                <h3 className="form-title-split">Endereço para Cobrança</h3>
                <Row gutter={12}>
                  <Col span={24}>
                    <FormItem label="Endereço">
                      {getFieldDecorator("endereco_cobranca", { rules: [v.required] })(<Input {...inputProps} maxLength={40} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={6}>
                    <FormItem label="Bairro">
                      {getFieldDecorator("bairro_cobranca", { rules: [v.required] })(<Input {...inputProps} maxLength={60} />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Cidade">
                      {getFieldDecorator("cidade_cobranca", { rules: [v.required] })(<Input {...inputProps} maxLength={25} />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Estado">
                      {getFieldDecorator("uf_cobranca", { rules: [v.required] })(
                        <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Selecione um Estado">
                          {optionsUF}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="CEP">
                      {getFieldDecorator("cep_cobranca", { rules: [v.required] })(
                        <Input {...inputProps} maxLength={8} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>

              <div>
                <h3 className="form-title-split">Endereço para Entrega</h3>
                <Row gutter={12}>
                  <Col span={16}>
                    <FormItem label="Endereço">
                      {getFieldDecorator("endereco_entrega", { rules: [v.required] })(<Input {...inputProps} maxLength={40} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Complemento (opcional)">
                      {getFieldDecorator("complemento_entrega")(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={6}>
                    <FormItem label="Bairro">
                      {getFieldDecorator("bairro_entrega", { rules: [v.required] })(<Input {...inputProps} maxLength={60} />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Cidade">
                      {getFieldDecorator("cidade_entrega", { rules: [v.required] })(<Input {...inputProps} maxLength={25} />)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Estado">
                      {getFieldDecorator("uf_entrega", { rules: [v.required] })(
                        <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Selecione um Estado">
                          {optionsUF}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="CEP">
                      {getFieldDecorator("cep_entrega", { rules: [v.required] })(<Input {...inputProps} maxLength={8} />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div>
                <h3 className="form-title-split">NPS/Contatos</h3>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Responsável Financeiro">
                      {getFieldDecorator("nome_resp_fin", { rules: [v.required] })(<Input {...inputProps} maxLength={30} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Financeiro">
                      {getFieldDecorator("email_financeiro", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone Financeiro">
                      {getFieldDecorator("telefone_fin", { rules: [v.required] })(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Responsável Financeiro 2">
                      {getFieldDecorator("nome_resp_fin2", {})(<Input {...inputProps} maxLength={30} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Financeiro 2">
                      {getFieldDecorator("email_fin2", {})(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone Financeiro 2">
                      {getFieldDecorator("telefone_resp_fin2", {})(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Responsável Financeiro 3">
                      {getFieldDecorator("nome_resp_fin3", {})(<Input {...inputProps} maxLength={30} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Financeiro 3">
                      {getFieldDecorator("email_resp_fin3", {})(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone Financeiro 3">
                      {getFieldDecorator("tel_resp_fin3", {})(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Comprador">
                      {getFieldDecorator("nome_comprador", { rules: [v.required] })(<Input {...inputProps} maxLength={60} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Comprador">
                      {getFieldDecorator("email_comp", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone Comprador">
                      {getFieldDecorator("telefone_resp_comp", { rules: [v.required] })(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Comprador 2">
                      {getFieldDecorator("nome_comp2", {})(<Input {...inputProps} maxLength={30} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Comprador 2">
                      {getFieldDecorator("email_comp2", {})(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone Comprador 2">
                      {getFieldDecorator("tel_resp", {})(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Comprador 3">
                      {getFieldDecorator("nome_comp3", {})(<Input {...inputProps} maxLength={30} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Comprador 3">
                      {getFieldDecorator("email_comp3", {})(<Input {...inputProps} maxLength={200} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone Comprador 3. ">
                      {getFieldDecorator("tel_comp3", {})(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>

              <Row gutter={12}>
                <Col span={10}>
                  <FormItem label="Proprietário">
                    {getFieldDecorator("nome_proprietario", { rules: [v.required] })(<Input {...inputProps} maxLength={60} />)}
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem label="E-mail Proprietário">
                    {getFieldDecorator("email_prop", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={60} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="WhatsApp Proprietário">
                    {getFieldDecorator("whatsapp_prop", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={10}>
                  <FormItem label="Responsável Nota Fiscal">
                    {getFieldDecorator("resp_nf", { rules: [v.required] })(<Input {...inputProps} maxLength={30} />)}
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem label="E-mail Nota Fiscal">
                    {getFieldDecorator("email_xml", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={200} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="Telefone Nota Fiscal">
                    {getFieldDecorator("tel_nf", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={10}>
                  <FormItem label="Responsável Agendamento">
                    {getFieldDecorator("resp_agenda", { rules: [v.required] })(<Input {...inputProps} maxLength={30} />)}
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem label="E-mail Agendamento">
                    {getFieldDecorator("email_agenda", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={200} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="Telefone Agendamento ">
                    {getFieldDecorator("tel_agenda", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={10}>
                  <FormItem label="Responsável NPS">
                    {getFieldDecorator("nome_resp_nf", { rules: [v.required] })(<Input {...inputProps} maxLength={30} />)}
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem label="E-mail NPS">
                    {getFieldDecorator("email_nps1", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={200} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="WhatsApp NPS">
                    {getFieldDecorator("whatsapp_nps", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <div style={{ marginBottom: 16 }}>
                <h3 className="form-title-split">Data Logística</h3>
                <p>Sugira abaixo os melhores dias preferenciais para que a Anjo priorize as entregas deste cliente:</p>
                <Row gutter={12}>
                  {Object.keys(WEEK_DAYS).map(w => {
                    return (
                      <Col key={w} md={{ span: 24 }} lg={{ span: WEEK_DAYS[w].length <= 6 ? 3 : 4 }}>
                        <Checkbox onChange={this.handleDataLogistica} checked={checkDataLogistica(cliente, w)} value={w}>
                          {WEEK_DAYS[w]}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <Button
                style={{ marginTop: 9 }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="cadastro-cliente--button">
                Alterar cadastro
              </Button>
            </Form>
          </Spin>
          :
          <Spin spinning={!cliente && !!isLoading}>
            <Form
              layout="vertical"
              hideRequiredMark={false}
              onSubmit={this.handleAtualizacao}
              className="cadastro-cliente--form">
              <div>
                <Row>
                  <Col span={10}>
                    <FormItem label="Nome Fantasia (Não é Razão Social)">
                      {getFieldDecorator("nome_fantasia", { rules: [v.required, v.nomeFantasia] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                </Row>
                <div>
                  <h3 className="form-title-split">NPS/Contatos</h3>
                  <Row gutter={12}>
                    <Col span={10}>
                      <FormItem label="Nome Completo NPS">
                        {getFieldDecorator("nome_nps", { rules: [v.required, v.nome] })(<Input {...inputProps} maxLength={50} />)}
                      </FormItem>
                    </Col>
                    <Col span={10}>
                      <FormItem label="E-mail NPS">
                        {getFieldDecorator("email_nps", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="WhatsApp NPS">
                        {getFieldDecorator("whats_nps", { rules: [v.required] })(
                          <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </div>
                <h3>Financeiro</h3>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Financeiro">
                      {getFieldDecorator("nome_fin", { rules: [v.required, v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Financeiro">
                      {getFieldDecorator("email_fin", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Celular/fone Financeiro">
                      {getFieldDecorator("telefone_fin", { rules: [v.required] })(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Financeiro 2">
                      {getFieldDecorator("nome_fin2", { rules: [v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Financeiro 2">
                      {getFieldDecorator("email_fin2", {})(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Financeiro 3">
                      {getFieldDecorator("nome_fin3", { rules: [v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Financeiro 3">
                      {getFieldDecorator("email_fin3", {})(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div>
                <h3 className="form-title-split">Comprador</h3>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Comprador">
                      {getFieldDecorator("nome_comp", { rules: [v.required, v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Comprador">
                      {getFieldDecorator("email_comp", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Celular/fone Comprador">
                      {getFieldDecorator("telefone_comp", { rules: [v.required] })(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Comprador 2">
                      {getFieldDecorator("nome_comp2", { rules: [v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Comprador 2">
                      {getFieldDecorator("email_comp2", { rules: [v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Comprador 3">
                      {getFieldDecorator("nome_comp3", { rules: [v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Comprador 3">
                      {getFieldDecorator("email_comp3", { rules: [v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div>
                <h3 className="form-title-split">Proprietário</h3>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Nome Completo Proprietário">
                      {getFieldDecorator("nome_prop", { rules: [v.required, v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Proprietário">
                      {getFieldDecorator("email_prop", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="WhatsApp Proprietário">
                      {getFieldDecorator("whatsapp_prop", { rules: [v.required] })(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div>
                <h3 className="form-title-split">NFe</h3>
                <Row gutter={12}>
                  <Col span={10}>
                    <FormItem label="Responsável Nota Fiscal">
                      {getFieldDecorator("resp_nf", { rules: [v.nome] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem label="E-mail Nota Fiscal">
                      {getFieldDecorator("email_nf", { rules: [v.required, v.emailComp] })(<Input {...inputProps} maxLength={50} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Celular/fone Nota Fiscal">
                      {getFieldDecorator("tel_nf")(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>

              <Row type="flex" justify="end" gutter={12}>
                <Col span={3}>
                  <Button
                    block
                    onClick={this.handleCancel}>
                    Cancelar
                  </Button>
                </Col>
                <Col span={4} >
                  <Button
                    block
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    className="cadastro-cliente--button">
                    Atualizar cadastro
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        }
      </div>
    );
  }
  _choosers = {};
}

export default Form.create()(withStore(CadastroEditScreen));
