import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Icon, Input, List, Row, Modal, message, Alert, Divider } from 'antd';
import { Observer } from 'mobx-react';
import withStore from 'withStore';
import ChooserPromotor from './ChooserPromotor';
import AtualizarPromotorModal from './AtualizarPromotorModal';
import { fetchPostApi } from 'actions';
import moment from 'moment';

const LabelGridText = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #363636;
`;

@Observer
class StepValidacaoSupervisor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStatusModalPromotor: false,
      visible: false,
    }

    this.removePromotorAux = this.removePromotorAux.bind(this);
    this.handlePromotor = this.handlePromotor.bind(this);
    this.handlePromotorAux = this.handlePromotorAux.bind(this);
    this.handleInputDeslocamento = this.handleInputDeslocamento.bind(this);
    this.handleModalData = this.handleModalData.bind(this);
  }

  handleOpenPromotores() {
    this.setState({ isStatusModalPromotor: true });
  }

  handleClosePromotores() {
    this.setState({ isStatusModalPromotor: false });
  }

  handlePromotor(value) {
    this.props.promotoria.Promotoria.zy7_promot = value.nome;
    this.props.promotoria.Promotoria.zy7_codpro = value.login;
    this.forceUpdate();
    this.handleModalData()
  }

  atualizarPromotorAux(data){
    this.props.promotoria.adicionarPromotorAuxiliar(data);
    this.forceUpdate();
  }

  handlePromotorAux(value) {
    if (value.login === this.props.promotoria.Promotoria.zy7_codpro) return Modal.warning({
      title: 'Promotor Auxiliar',
      content: `O promotor selecionado já está como principal.`
    });

    const dados = {
      login: value.login,
      nome: String(value.nome).substring(0, 30),
    }
    
    this.verificarDatasPromotor(dados);
  }

  verificarDatasPromotor = async (dados) => {
    try {
      this.props.promotoria.isLoading = true;
      const formData = {
        promotor: dados.login,
        data_inicio: moment(this.props.promotoria.Promotoria.zy7_dtiniv, 'DD-MM-YYYY').format('YYYYMMDD'),
        data_fim: moment(this.props.promotoria.Promotoria.zy7_dtfimv, 'DD-MM-YYYY').format('YYYYMMDD')
      }

      const { data } = await fetchPostApi(`/promotoria/verificar-datas-promotor`, formData);

      if(data.isContais){
        Modal.confirm({
            title: 'AVISO',
            content: 'A data escolhida já está reservada no calendário do promotor auxiliar selecionado.',
            onOk: () => {
              this.atualizarPromotorAux(dados)
            },
            onCancel: () => {},
            okText: 'De acordo'
        })
      }else{
        this.atualizarPromotorAux(dados);
      }

      return
    } catch (error) {
    } finally {
        this.props.promotoria.isLoading = false;
    }
  }

  removePromotorAux(item) {
    this.props.promotoria.removePromotorAux(item);
    this.forceUpdate();
  }

  handleInputDeslocamento(value, id) {
    this.props.promotoria.handleInputDeslocamento(value, id);
    this.forceUpdate();
  }

  openChooser = (modal = "cliente", onChoose = null) => {
    const _onChoose = async (...args) => {
      if (!!onChoose && typeof onChoose === "function") {
        await onChoose(...args);
      }
    };

    switch (modal) {
      case "promotor":
        this._choosers.promotor.open(_onChoose);
        break;
      case "promotoresAux":
        this._choosers.promotoresAux.open(_onChoose);
        break;
      default:
        console.warn("Chooser modal not found");
        break;
    }
  };

  handleModalData() {
    if (!this.state.visible) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
    }
  }

  render() {
    const { isStatusModalPromotor } = this.state;
    return (
      <div>
        <Form
          autoComplete="off"
          layout="vertical"
          onSubmit={this.handleSubmit}
          style={{ marginTop: '30px' }}
        >
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item label="Selecione promotor" hasFeedback>
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  disabled={this.props.promotoria.isFinishPromotoria || !this.props.store.isSupervisor || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtoNextStatus}
                  onClick={event => {
                    event.preventDefault();
                    this.openChooser("promotor", this.handlePromotor);
                  }}
                >
                  Selecionar
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Código Promotor" hasFeedback>
                <Input value={this.props.promotoria.Promotoria.zy7_codpro} disabled maxLength="20" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Promotor Selecionado" hasFeedback>
                <Input value={this.props.promotoria.Promotoria.zy7_promot} disabled maxLength="20" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Alterar data" >
                <Button style={{ width: '100%' }} disabled={this.props.promotoria.isFinishPromotoria || !this.props.store.isSupervisor || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtoNextStatus} type="primary" onClick={this.handleModalData}>Alterar</Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item label="Promotores Aux" hasFeedback>
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  disabled={this.props.promotoria.isFinishPromotoria || !this.props.store.isSupervisor || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtoNextStatus}
                  onClick={event => {
                    event.preventDefault();
                    this.openChooser("promotoresAux", this.handlePromotorAux);
                  }}
                >
                  Selecionar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <List
          header={<div>Promotores auxiliares</div>}
          bordered
          column
          dataSource={this.props.promotoria.PromotoresAux}
          itemLayout='horizontal'
          renderItem={item => (
            <Row gutter={6} style={{ marginTop: 10, marginBottom: 10, marginLeft: 5, alignItems: 'center', justifyContent: 'center' }}>
              {!!item && (
                <Col span={1}>
                  <Button
                    type="danger"
                    ghost
                    disabled={this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledPromotorNotResponsible}
                    onClick={() => this.removePromotorAux(item)}
                    style={{
                      width: "calc(5% - 4px)",
                      minWidth: 20,
                      height: 20,
                      padding: "3px 8px",
                      display: 'flex', // Tornar o container um flex container
                      justifyContent: 'center', // Centralizar horizontalmente
                      alignItems: 'center' // Centralizar verticalmente
                    }}
                  >
                    <Icon type="close" />
                  </Button>
                </Col>
              )}
              <Col span={4}>
                <LabelGridText>Código: {item.login}</LabelGridText>
              </Col>
              <Col span={8}>
                <LabelGridText>Nome: {item.nome}</LabelGridText>
              </Col>
              <Col span={6}>
                <Input
                  placeholder='Informe o deslocamento'
                  disabled={this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledPromotorNotResponsible}
                  value={item.deslocamento}
                  onChange={e => this.handleInputDeslocamento(e.target.value, item.login)}
                />
              </Col>
            </Row>

          )}
        />
        <ChooserPromotor ref={_ref => (this._choosers["promotor"] = _ref)} />
        <ChooserPromotor ref={_ref => (this._choosers["promotoresAux"] = _ref)} />
        {!!this.state.visible && <AtualizarPromotorModal promotoria={this.props.promotoria} isVisible={this.state.visible} onClose={this.handleModalData} />}
      </div>
    );
  }
  _choosers = {}
}

export default withStore(StepValidacaoSupervisor);
