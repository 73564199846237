import React, { Component, useState, useEffect } from "react";
import { Col, Form, Spin, message } from "antd";
import { observer } from "mobx-react";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter } from "components/FullTable";
import styled from "styled-components";
import { fetchApi } from "actions";

const CardMonth = styled.div`
  background-color: #3573B9;
  width: 80px;
  min-width: 80px;
  height: 40px;
  border-radius: 6px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RenderCellExpand = ({ login }) => {
  const [loading, setLoading] = useState(true);
  const [listOcisiodade, setListOcisiodade] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/r/ociosidade/${login}`);
        const { data } = response;
        setListOcisiodade(data.data);
      } catch (error) {
        message.error('Erro ao buscar informações ao promotor selecionado');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [login]);

  return (
    <Col span={24} style={{ display: 'flex' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <Spin tip="Carregando..." spinning={loading} />
        </div>
      ) : (
        listOcisiodade.map(item => (
          <CardMonth key={`${item.mes}-${item.ano}`}>
            <Col><strong style={{ color: '#fff' }}>{item.mes} - {item.ano}</strong></Col>
            <Col><strong style={{ color: '#fff' }}>{item.dias_restantes} dia(s)</strong></Col>
          </CardMonth>
        ))
      )}
    </Col>
  );
};

@observer
class OciosidadeReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Ociosidade",
      uri: "/r/ociosidade",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }

  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "login",
        key: "login",
        sorter: false,
        filteredValue: this.ds.filterValue("login"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código" {...props} />,
        filterParser: filter => ({ ...filter, field: "login", value: `%${filter.value}`, op: "LIKE" }),
        width: "5%",
      },
      {
        title: "Promotor",
        dataIndex: "nome",
        key: "nome",
        sorter: false,
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}`, op: "LIKE" }),
        width: "94%",
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          loading={this.props.store.isLoading}
          rowKey={item => `${item.login}`}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas ociosidades no momento"
          expandedRowRender={({ login }) => !!login && login && <RenderCellExpand login={login} />}
        />
      </div>
    );
  }
}

export default withStore(OciosidadeReportScreen);