/**
 * @flow
 */

import { observable, computed, toJS } from "mobx";
import _size from "lodash/size";
import _isObject from "lodash/isObject";
import { parsePagination, parseFilter } from "./utils";
import { getApiUrl } from "@env";
import { fetchApi, fetchPostApi } from "actions";

import exportable from "stores/ExportableStore";

export default class TableDataSource {
  static restoreLocation: string = "/";
  static restoreAction: string = "PUSH";

  @observable items: Array = [];
  @observable.shallow selectedRowKeys: Array = [];
  @observable.shallow state: Object = {};
  @observable pagination: Object = {};
  @observable filters: Object = {};
  @observable filterParsers: Object = {};
  @observable sorter: Object = {};
  @observable fetched: boolean = false;
  @observable isLoading: boolean = false;
  @observable controlled: boolean = false;
  @observable name: string = null;
  @observable uri: string = null;
  @observable.shallow postData: Object = {};
  @observable defaultFilter: Object = {};
  @observable uniqueKeyName: string = null;

  constructor(uri, state: Object = null, filterParsers: Object = null, controlled: boolean = false, defaultFilter: Object = null) {
    if (typeof uri === "string") {
      this.uri = uri;
      if (!!state) this.state = state;
      if (!!filterParsers) this.filterParsers = filterParsers;
      if (!!defaultFilter) this.defaultFilter = defaultFilter;
      this.controlled = controlled;
    } else if (_isObject(uri) && "uri" in uri) {
      Object.keys(uri).forEach(prop => {
        this[prop] = uri[prop];
      });
    }
  }

  @computed
  get hasPagination() {
    return !!this.pagination && "total" in this.pagination && +this.pagination.total > 0;
  }

  @computed
  get hasSorter() {
    return !!this.sorter && "field" in this.sorter;
  }

  @computed
  get hasFilter() {
    return !!this.filters && _size(this.filters) > 0;
  }

  @computed
  get hasSelected() {
    return !!this.selectedRowKeys && this.selectedRowKeys.length > 0;
  }

  @computed
  get hasDefaultFilter() {
    return !!this.defaultFilter;
  }

  @computed
  get dataFilter() {
    if (this.hasFilter) {
      return {
        filter: Object.keys(this.filters).reduce((acc, k) => {
          const value = toJS(this.filters[k]);
          if (value !== null) {
            let filter = parseFilter(k.replace(/\$[0-9]+$/gi, ""), Array.isArray(value) ? value.join("|") : value);
            if (k in this.filterParsers && typeof this.filterParsers[k] === "function") {
              filter = this.filterParsers[k](filter);
            }

            return acc.concat(Array.isArray(filter) ? filter : [filter]);
          }

          return acc;
        }, []),
      };
    }

    return {};
  }

  @computed
  get requestJSON() {
    return {
      uri: toJS(this.uri),
      pagination: toJS(this.pagination),
      filters: toJS(this.filters),
      sorter: toJS(this.sorter),
    };
  }

  createSnapshot() {
    const snapData = {
      ...this.requestJSON,
      items: toJS(this.items),
      state: toJS(this.state),
    };

    const snapKey = `location:${TableDataSource.restoreLocation}`;

    if (!!window && "sessionStorage" in window) {
      window.sessionStorage.setItem(snapKey, JSON.stringify(snapData));
    }
  }

  async restoreSnapshot(forceRefreshItems = false) {
    const snapKey = `location:${TableDataSource.restoreLocation}`;

    if (!!window && "sessionStorage" in window) {
      const snap = window.sessionStorage.getItem(snapKey);
      if (!!snap) {
        const snapData = JSON.parse(snap);
        if ("uri" in snapData) {
          // e os filtros?
          this.selectedRowKeys = [];
          this.sorter = snapData.sorter;
          this.filters = snapData.filters;
          this.pagination = snapData.pagination;
          this.state = { ...this.state, ...snapData.state };

          if (!forceRefreshItems && snapData.items.length > 0) {
            this.items = snapData.items;
            this.isLoading = false;
            this.fetched = true;
          } else {
            window.sessionStorage.removeItem(snapKey);

            return await this.fetch(true);
          }

          // try to restore the scroll position...
          requestAnimationFrame(() => document.body.dispatchEvent(new Event("restoreScroll")));

          return {};
        }
      }
    }

    return false;
  }

  export(...args) {
    exportable.export(...args);
  }

  filterValue(key, defaultValue = null) {
    if (key in this.filters && !!this.filters[key]) {
      return Array.isArray(this.filters[key]) ? this.filters[key][0] : this.filters[key];
    }

    return defaultValue;
  }

  actionUri(queryParams = {}) {
    const params = {};
    if (this.hasPagination) {
      params.page = this.pagination.current;
    }
    if (this.hasSorter) {
      params.order = `${this.sorter.field}:${!!this.sorter.order && this.sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }

    return !!this.uri ? getApiUrl(this.uri, Object.assign(params, queryParams)) : "#empty-action";
  }

  reset() {
    this.items = [];
    this.pagination = {};
    this.filters = {};
    this.sorter = {};
    this.isLoading = false;
    this.fetched = false;
  }

  async fetch(restorable = false) {
    if (!this.uri) {
      throw new Error("URL de consumo não definida");
    }

    this.isLoading = true;

    const params = {};
    if (this.hasPagination) {
      params.page = this.pagination.current;
    }
    if (this.hasSorter) {
      params.order = `${this.sorter.field}:${!!this.sorter.order && this.sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }

    if (this.controlled && !this.hasFilter) {
      this.reset();
      return {};
    }

    const { data: response } =
      this.hasFilter || this.hasDefaultFilter || _size(this.postData) > 0
        ? await fetchPostApi(this.uri, { ...this.dataFilter, ...this.defaultFilter, ...this.postData }, params)
        : await fetchApi(this.uri, params);

    if (!!response.success) {
      const { items, count = 0, limit = 1, page = 1, pageCount = 1, ...restData } = response.data;

      this.items = items;
      this.selectedRowKeys = [];
      this.pagination = parsePagination({ count, limit, page, pageCount });
      this.state = { ...this.state, ...restData };
      this.isLoading = false;
      this.fetched = true;

      if (!!restorable) {
        this.createSnapshot();
      }

      return restData;
    } else {
      this.isLoading = false;

      throw new Error("Não foi possível obter os dados relacionados neste momento");
    }
  }
}