import React, { Component } from "react";
import { Modal } from "antd";
import CadastroEditScreen from "./CadastroEditScreen";

export default class CadastroEditModalScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      codigo_cliente: null
    }
  }

  open = (codigo_cliente) => {
    this.setState({ visible: true, codigo_cliente });
  };

  close = (refresh = false) => {
    const { renewFocus } = this.props;
    this.setState({ visible: false, codigo_cliente: null });

    if (refresh) {
      renewFocus();
    }
  };

  render() {
    const { visible, codigo_cliente } = this.state;
    return (
      <>
        {visible && codigo_cliente &&
          < Modal
            cancelButtonDisabled
            okButtonDisabled
            width={1100}
            visible={visible && codigo_cliente}
            closable={false}
            footer={null}
          >
            <CadastroEditScreen
              closeAtualiza={this.close}
              renewFocus={() => this.close(true)}
              isAtualiza={codigo_cliente}
            />
          </Modal>
        }
      </>
    )
  }
}
