import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { CartesianGrid, ComposedChart, Bar, XAxis, YAxis, Tooltip, Legend, Label, Line, Cell } from "recharts";
import _capitalize from "lodash/capitalize";
import styled from "styled-components";
import _isEqual from "lodash/isEqual";
import moment from "moment";

import { dateFormat, n } from "@util";
import { fetchTrimestre } from "actions/dashboard";
import { PageTitle, PageTitleExtra } from "./Styled";
import "./StyledPanel.css";

const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CustomizedLabel = ({ x = 0, y = 0, value, unit }) => (
  <text
    x={x}
    y={y - 5}
    fontSize={11}
    fontWeight={600}
    fill="#666">
    {unit === "%" ? n(value) + " " + unit : unit + " " + n(value)}
  </text>
);

const CustomizedPart = ({ x = 0, y = 0, value }) => (
  <text
    x={x - 23}
    y={y - 8}
    fontSize={10}
    fontWeight={700}
    fill="white">
    {n(value * 100) + "%"}
  </text>
);

const getIntroOfPage = (label) => {
  if (label === "1º") {
    return "1º Trimestre";
  }
  if (label === "2º") {
    return "2º Trimestre";
  }
  else if (label === "3º") {
    return "3º Trimestre";
  }
  else if (label === "4º") {
    return "4º Trimestre";
  }
  else {
    return fgetMonth(label);
  }
  return "";
};

const fgetMonth = (mes) => {
  const agora = moment();
  let mesdesc = "";

  agora.month((+mes) - 1);
  mesdesc = agora.format("MMMM");

  return mesdesc.charAt(0).toUpperCase() + mesdesc.slice(1);
};


const CustomTooltip = ({ active, payload, label, unit }: any) => {
  if (active && payload && payload.length) {

    const faturado = payload[0].value;
    const carteira = payload[1].value;
    const objetivo = payload[2].value;
    const participacao = payload[3].value * 100;
    const fundo = participacao >= 100 ? "green" : "red";

    return (
      <div className={`custom-tooltip ${fundo}`}>
        <div className="tooltip-children">
          <div>{`${getIntroOfPage(label)} (valores em ${unit})`}</div>
          <div>{`Faturado : ${n(faturado, 2)}`}</div>
          <div>{`Carteira : ${n(carteira, 2)}`}</div>
          <div>{`Objetivo : ${n(objetivo, 2)}`}</div>
          <div>{`Participação* : ${n(participacao, 2)} %`}</div>
          <div className="desc">*Participação sobre o valor faturado.</div>
        </div>
      </div>
    );
  }

  return null;
};

const MixChart = ({ keyLabel = "mes", unit = "R$", title = "", titleX = "", data = [], isB2B }) => (
  <Spin spinning={!!data && data.length <= 0}>
    {!!data && data.length > 0 ?
      < ComposedChart
        width={480}
        height={280}
        data={data}
        margin={{
          top: 40,
          right: 55,
          left: 55,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={keyLabel}>
          <Label value={titleX} offset={-10} angle={0} position="left" />
        </XAxis>
        <YAxis
          tickFormatter={tick => {
            return n(tick);
          }}
        >
          <Label value={title} align="left" offset={-30} angle={0} position="insideTopLeft" />
        </YAxis>
        {/* <Tooltip formatter={
          (value, label) => label === "participacao" ? n(value * 100, 2) + ' %' : `${unit} ` + n(value, 2)
        } /> */}
        <Tooltip content={<CustomTooltip unit={unit} />} cursor={{ fill: "transparent" }} />
        <Legend />
        <Bar dataKey="faturado" stackId="a" fill="#02aed9" label="Faturado" />
        <Bar dataKey="carteira" stackId="a" fill="#34e5d5" angle={90} label={<CustomizedLabel unit={unit} x={20} />} />
        <Line type="monotone" name="Meta" dataKey="meta" stroke="#ff7300" />
        <Line type="monotone" dataKey="participacao" Label="Participação" label={<CustomizedPart unit={'%'} x={0} y={0} />} />

      </ComposedChart>
      :
      <div style={{
        width: 480,
        height: 280,
        top: 40,
        right: 55,
        left: 55,
        bottom: 20,
      }} />
    }
  </Spin >
);

export default class TrimestrePanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
      VOL: null,
      FAT: null,
      DETVOL: null,
      DETFAT: null,
    };
  }
  componentDidMount() {
    this.fetchData("VOL");
    this.fetchData("FAT");
    this.fetchData("DETVOL");
    this.fetchData("DETFAT");
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.setState({
        VOL: null,
        FAT: null,
        DETVOL: null,
        DETFAT: null,
      }, () => {
        this.fetchData("VOL");
        this.fetchData("FAT");
        this.fetchData("DETVOL");
        this.fetchData("DETFAT");
      })
    }
  }
  fetchData = async (tipo) => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchTrimestre(!!this.props.isTipoVendaInterna ? this.props.relacionadoData.codigo : null, tipo);
      if (response.success) {
        const { data } = response;
        this.setState({ [tipo]: data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, VOL, FAT, DETVOL, DETFAT } = this.state;

    return (
      <div style={{ minHeight: 380 }}>
        <PageTitle title="KPI - Trimestre" meta={false}>
          <PageTitleExtra>
            {!VOL ? "Carregando..." : `${dateFormat(VOL.data_inicial)} até ${dateFormat(VOL.data_final)}`}
          </PageTitleExtra>
        </PageTitle>
        <DashboardBody>
          <Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
              <MixChart title="Faturamento" keyLabel="trimestre" unit="R$" titleX="Trimestre" data={FAT?.items} isB2B={this.props.isB2B} />
              <MixChart title="Faturamento" keyLabel="mes" unit="R$" titleX="Mes" data={DETFAT?.items} isB2B={this.props.isB2B} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
              <MixChart title="Volume" keyLabel="trimestre" unit="Kg" titleX="Trimestre" data={VOL?.items} isB2B={this.props.isB2B} />
              <MixChart title="Volume" keyLabel="mes" unit="Kg" titleX="Mes" data={DETVOL?.items} isB2B={this.props.isB2B} />
            </div>
            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
                  {!!data.items.trimestre_cli && data.items.trimestre_cli.length > 0 &&
                    <MixChart title="Grupo positivado" keyLabel="trimestre" unit="Qtd" titleX="Trimestre" data={data.items.trimestre_cli} isB2B={this.props.isB2B} />
                  }
                  {!!data.items.trimestre_det_cli && data.items.trimestre_det_cli.length > 0 &&
                    <MixChart title="Grupo positivado" keyLabel="mes" unit="Qtd" titleX="Mes" data={data.items.trimestre_det_cli} isB2B={this.props.isB2B} />
                  }
                </div> */}
          </Fragment>
        </DashboardBody>
      </div>
    );
  }
}
