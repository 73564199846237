/** 
 * @flow
 */

import { action, observable, computed, toJS } from "mobx";
import moment from "moment";
import _isEqual from "lodash/isEqual";

import { n, moneyFixed } from "@util";
import { PedidoError } from "./Pedido";

// 01 = AUTOMOTIVA
// 02 = IMOBILIARIA

export const PRECO_POSICIONADO = "PRECO_POSICIONADO";
export const DESCONTO = "DESCONTO";

export const EMPTY_POLICY = {
  desc_perfil: 0.0,
  desc_logis: 0.0,
  desc_extra: 0.0,
  desc_retir: 0.0,
  desc_geren: 0.0,
  desc_finan: 0.0,
  desc_mix: 0.0,
};

export const MIN_LITROS = 0.0001;

export default class Item {
  static today: string = null;
  static status_pedido: string = "2";
  static unidade_negocio: string = "REVENDA";

  @observable mode: string = "add";
  @observable item_produto: string = null;
  @observable codigo_produto: string = null;
  @observable descricao: string = null;
  @observable preco_tabela: number = 0.0;
  @observable tipo_produto: string = "AMBOS";
  @observable tipo_sku: string = "PA";
  @observable classe_produto: string = null;
  @observable segum: string = null;
  @observable codigo_tabela: string = null;
  @observable ufcli: string = "EX";
  @observable tipo_desconto_suframa: string = "NENHUM";
  @observable uf_filial: string = null;
  @observable cod_canal: string = null;
  @observable tipocli: string = "S";
  @observable neg_anterior: string = "N";
  @observable bonificado: string = "N";
  @observable verba_codigo: string = "";
  @observable origem: string = null;
  @observable importado_zona_franca: string = "NAO";
  @observable fator_conversao: number = 0.0;
  @observable venda_litragem_minima: number = 0.0;
  @observable quantidade_minima: number = 0.0;
  @observable peso_bruto: number = 0.0;
  @observable quantidade_por_fardo = null;
  @observable aliquota_ipi = null;
  @observable icms_operacao = null;
  @observable icms_interno = null;
  @observable codigo_linha: string = null;
  @observable ultimo_preco = null;
  @observable data_ultimo_preco: string = null;
  @observable ped_compra: string = null;
  @observable item_pc: string = null;
  @observable grupo_cor: string = null;
  @observable quantidade: number = 0;
  @observable conversao: number = 0;
  @observable densidade: number = 0;
  @observable quantidadeMaxBonif: number = 0;
  @observable mva: number = 0;
  @observable desc_locked: boolean = false;
  @observable desc_camp: number = 0.0;
  @observable desc_finan: number = 0.0;
  @observable desc_geren: number = 0.0;
  @observable desc_kit: number = 0.0;
  @observable desc_logis: number = 0.0;
  @observable desc_mix: number = 0.0;
  @observable desc_taxa: number = 0.0;
  @observable desc_taxa_prev: number = 0.0;
  @observable desc_perfil: number = 0.0;
  @observable desconto_extra_disp: number = 0.0;
  @observable desconto_volume: number = 0.0;
  @observable cashback: number = 0;
  @observable dias_cashback: number = 0;
  @observable somaclasse_cashback: string = " ";
  @observable valor_cashback: number = 0;
  @observable desc_extra: number = 0.0;
  @observable desc_rebat: number = 0.0;
  @observable desc_retir: number = 0.0;
  @observable preco_vendido: number = 0.0;
  //@observable pode_desc_gerencial: string = "NAO";
  @observable perc_desc_gerencial: number = 0.0;
  @observable diferenca_tabela: string = "NAO";
  @observable venda_permitida: string = "SIM";
  @observable tintometrico: string = "SIM";
  @observable policia_federal: string = "NAO";
  @observable libera_pf: string = "NAO";
  @observable libera_pf_on: string = "GLOBAL";
  @observable status: string = "ATIVO";
  @observable campanha: Object = null;
  @observable campKit: Object = null;
  @observable policy: Object = null;
  @observable bicomponente: Array = [];
  @observable config_cashback: Array = [];
  @observable rastro_residuo_item: string = "";
  @observable venda_liberada: string = "SIM";
  @observable possui_cashback: string = "NAO";
  @observable bloqueado_plataforma: string = "NENHUM";
  @observable deduz_st: string = "NAO";
  @observable quantidade_max_liberado_kit: number = 0;
  @observable isValidTicket: boolean = false;
  @observable observacao_item: string = null;

  constructor(data = null, policy = null, calculaPreco = true, oldPrice = {}) {
    this.resetPolicy();

    if (!!data) {
      this.parse(data, policy, calculaPreco, oldPrice);
    }
  }

  @action
  applyPolicy(p, calculaPreco = true) {
    if (
      !!this.codigo_produto &&
      !!this.codigo_linha &&
      this.tipoDescontoCampanha !== PRECO_POSICIONADO &&
      this.neg_anterior === "N"
    ) {
      const currentPolicy = toJS(this.policy);
      const desc_extra = Math.max(+this.desconto_extra_disp, 0);
      const isFuncionario = this.cod_canal === "10"

      if (this.isLinhaAuto) {
        const freshPolicy = {
          desc_perfil: this.isSolvente
            ? Math.max(+p.desc_solve_auto, 0)
            : this.isTintaComp
              ? Math.max(+p.desc_tinta_auto, 0)
              : this.policy.desc_perfil,
          desc_logis: !isFuncionario ? +p.desc_logis_auto : 0,
          desc_extra: !isFuncionario ? (+p.desc_extra_auto > 0 && desc_extra > 0 ? desc_extra : 0) : 0,
          desc_retir: !isFuncionario ? +p.desc_retir_auto : 0,
          desc_geren: !isFuncionario ? +p.desc_geren_auto : 0,
          desc_finan: !isFuncionario ? +p.desc_finan_auto : 0,
          desc_mix: !isFuncionario ? +p.desc_mix_auto : 0,
        };

        if (_isEqual(currentPolicy, freshPolicy)) {
          return;
        }

        this.policy = observable.object({ ...currentPolicy, ...freshPolicy });

        if (!this.desc_locked) {
          this.desc_logis = !isFuncionario ? +p.desc_logis_auto : 0;
          this.desc_extra = !isFuncionario ? (+p.desc_extra_auto > 0 && desc_extra > 0 ? desc_extra : 0) : 0;
          this.desc_retir = !isFuncionario ? +p.desc_retir_auto : 0;
          this.desc_finan = !isFuncionario ? +p.desc_finan_auto : 0;
          this.desc_mix = !isFuncionario ? +p.desc_mix_auto : 0;
        }
      } else {
        const freshPolicy = {
          desc_perfil: this.isSolvente
            ? Math.max(+p.desc_solve_imob, 0)
            : this.isTintaComp
              ? Math.max(+p.desc_tinta_imob, 0)
              : this.policy.desc_perfil,
          desc_logis: !isFuncionario ? +p.desc_logis_imob : 0,
          desc_extra: !isFuncionario ? (+p.desc_extra_imob > 0 && desc_extra > 0 ? desc_extra : 0) : 0,
          desc_retir: !isFuncionario ? +p.desc_retir_imob : 0,
          desc_geren: !isFuncionario ? +p.desc_geren_imob : 0,
          desc_finan: !isFuncionario ? +p.desc_finan_imob : 0,
          desc_mix: !isFuncionario ? +p.desc_mix_imob : 0,
        };

        if (_isEqual(currentPolicy, freshPolicy)) {
          return;
        }

        this.policy = observable.object({ ...currentPolicy, ...freshPolicy });

        if (!this.desc_locked) {
          this.desc_logis = !isFuncionario ? +p.desc_logis_imob : 0;
          this.desc_extra = !isFuncionario ? (+p.desc_extra_imob > 0 && desc_extra > 0 ? desc_extra : 0) : 0;
          this.desc_retir = !isFuncionario ? +p.desc_retir_imob : 0;
          this.desc_finan = !isFuncionario ? +p.desc_finan_imob : 0;
          this.desc_mix = !isFuncionario ? +p.desc_mix_imob : 0;
        }
      }

      if (!!calculaPreco) this.calculaPreco();
      return true;
    }
    return false;
  }

  @action
  applyTaxa(t, calculaPreco = true) {
    if (+t === +this.desc_taxa) {
      return;
    }

    if (!!this.codigo_produto && !!this.codigo_linha && this.neg_anterior === "N") {
      const testComum = !this.hasCampKit && !this.hasCampanha;

      const testCampKit =
        !!this.hasCampKit &&
        ((+t < 0 && this.campKit.calc_acresc_condpag === "SIM") ||
          (+t > 0 && this.campKit.calc_descre_condpag === "SIM"));

      const testCamp1 = !!this.hasCampanha && this.tipoDescontoCampanha !== PRECO_POSICIONADO;

      const testCamp2 =
        !!this.hasCampanha &&
        this.tipoDescontoCampanha === PRECO_POSICIONADO &&
        ((+t < 0 && this.campanha.clc_acresc_condpg === "SIM") ||
          (+t > 0 && this.campanha.clc_decresc_condpg === "SIM"));

      const testCamp = testCamp1 || testCamp2;

      if (testComum || testCampKit || testCamp) {
        this.desc_taxa = +t;
      } else {
        this.desc_taxa = 0;
      }

      if (!!calculaPreco) this.calculaPreco();

      this.desc_taxa_prev = +this.desc_taxa;

      return true;
    }
    return false;
  }

  @action
  parse(data, policy = null, calculaPreco = true, oldPrice = {}) {
    const hasCampanha = "Campanha" in data && !!data.Campanha && "codigo_campanha" in data.Campanha;
    const hasCampKit = "CampKit" in data && !!data.CampKit && "codigo_kit" in data.CampKit;

    if (!!data.Produto.item_produto) {
      this.item_produto = data.Produto.item_produto;
      this.mode = "edit";
    }
    this.uf_filial = !!data.Operacao && "uf_filial" in data.Operacao ? data.Operacao.uf_filial : null;
    this.codigo_produto = data.Produto.codigo || data.Produto.codigo_produto;
    this.descricao = data.Produto.descricao;
    this.preco_tabela = moneyFixed(Math.max(+data.Produto.preco_tabela, 0));
    this.tipo_produto = data.Produto.tipo_produto || "AMBOS";
    this.tipo_sku = data.Produto.tipo_sku || "PA";
    this.classe_produto = data.Produto.classe_produto;
    this.segum = data.Produto.segum;
    this.ufcli = !!data.Cliente && "uf" in data.Cliente ? data.Cliente.uf.toUpperCase() : "EX";
    this.tipo_desconto_suframa = !!data.Cliente && "tipo_desconto_suframa" in data.Cliente ? data.Cliente.tipo_desconto_suframa : "NENHUM";
    this.tipocli = !!data.Cliente && "tipo_cliente" in data.Cliente ? data.Cliente.tipo_cliente : null;
    this.cod_canal = !!data.Cliente && "cod_canal" in data.Cliente ? data.Cliente.cod_canal : null;
    this.deduz_st = !!data.Cliente && "deduz_st" in data.Cliente ? data.Cliente.deduz_st : "NAO";
    // this.deduz_st = "NAO"
    this.origem = data.Produto.origem;
    this.ncm = data.Produto.ncm;
    this.importado_zona_franca = !!data.Produto && "importado_zona_franca" in data.Produto ? data.Produto.importado_zona_franca : "NAO";
    this.fator_conversao = moneyFixed(Math.max(+data.Produto.fator_conversao, 0), 3);
    this.venda_litragem_minima = moneyFixed(Math.max(+data.Produto.venda_litragem_minima, 0), 3);
    this.peso_bruto = moneyFixed(Math.max(+data.Produto.peso_bruto, 0), 4);
    this.quantidade_por_fardo = data.Produto.quantidade_por_fardo;
    this.aliquota_ipi = data.Produto.aliquota_ipi;
    this.icms_operacao = !!data.Operacao && data.Operacao.icms_operacao ? +data.Operacao.icms_operacao : 0;
    this.icms_interno = !!data.Operacao && data.Operacao.icms_interno ? +data.Operacao.icms_interno : 0;
    this.codigo_tabela = !!data.ITTAB ? data.ITTAB.codigo_tabela : null;
    this.neg_anterior = data.Produto.neg_anterior || "N";
    this.bonificado = data.Produto.bonificado || "N";
    this.verba_codigo = data.Produto.verba_codigo || "";
    this.codigo_linha = data.Produto.codigo_linha || "01";
    this.ultimo_preco = data.Produto.ultimo_preco;
    this.data_ultimo_preco = data.Produto.data_ultimo_preco;
    this.ped_compra = data.Produto.num_pedido_compra || "";
    this.item_pc = data.Produto.it_pedido_compra || "";
    this.grupo_cor = data.Produto.grupo_cor || "";
    this.quantidade =
      "quantidade_vendida" in data.Produto && +data.Produto.quantidade_vendida > 0
        ? +data.Produto.quantidade_vendida
        : 0;
    this.conversao = 0;
    this.densidade = +data.Produto.densidade > 0 ? +data.Produto.densidade : 0.0;
    this.mva = Math.max(+data.Produto.mva, 0);
    this.preco_vendido =
      "preco_vendido" in data.Produto && +data.Produto.preco_vendido > 0 ? +data.Produto.preco_vendido : 0.0;
    this.perc_desc_gerencial = data.Produto.perc_desc_gerencial || 0.0;
    this.venda_permitida = data.Produto.venda_permitida || "SIM";
    this.tintometrico = data.Produto.tintometrico || "SIM";
    this.policia_federal = data.Produto.policia_federal || "NAO";
    this.libera_pf = data.Cliente.libera_pf || "NAO";
    this.libera_pf_on = data.Cliente.libera_pf_on || "GLOBAL";
    this.status = data.Produto.status || "ATIVO";
    this.venda_liberada = data.Produto.venda_liberada || "SIM";
    this.possui_cashback = data.Produto.possui_cashback || "NAO";
    this.bloqueado_plataforma = data.Produto.bloqueado_plataforma || "NENHUM";
    this.observacao_item = data.Produto.observacao_item || "";
    this.isValidTicket = data.Produto.isValidTicket || false;
    this.quantidade_max_liberado_kit = data.Produto.quantidade_max_ticket || 0;

    if ("desconto_extra_disp" in data.Produto && !isNaN(+data.Produto.desconto_extra_disp)) {
      this.desconto_extra_disp = Math.max(+data.Produto.desconto_extra_disp, 0);
    }

    if ("desconto_volume" in data.Produto && !isNaN(+data.Produto.desconto_volume)) {
      this.desconto_volume = Math.max(+data.Produto.desconto_volume, 0);
    }

    if ("diferenca_tabela" in data.Produto) {
      this.diferenca_tabela = data.Produto.diferenca_tabela.toUpperCase();
    }

    if (!!policy) {
      this.applyPolicy(policy, false);
    }

    if ("desconto_perfil" in data.Produto) {
      this.desc_perfil = +data.Produto.desconto_perfil;
    } else {
      this.desc_perfil = Math.max(this.desconto_base, 0.0);
    }

    if ("desconto_gerente" in data.Produto) {
      this.desc_geren = +data.Produto.desconto_gerente;
    } else {
      this.desc_geren =
        !hasCampKit && +this.perc_desc_gerencial > 0 //!hasCampKit && +this.policy.desc_geren > 0 && this.perc_desc_gerencial > 0
          ? Math.max(+this.perc_desc_gerencial, 0) //Math.max(+this.policy.desc_geren, 0)
          : 0;
    }

    if ("desconto_logistico" in data.Produto) {
      this.desc_logis = +data.Produto.desconto_logistico;
    }

    if ("desconto_cliente" in data.Produto) {
      this.desc_retir = +data.Produto.desconto_cliente;
    }

    if ("desconto_rebate" in data.Produto) {
      this.desc_rebat = +data.Produto.desconto_rebate;
    }

    if ("desconto_mix" in data.Produto) {
      this.desc_mix = +data.Produto.desconto_mix;
    }

    if ("desconto_finan" in data.Produto) {
      this.desc_finan = +data.Produto.desconto_finan;
    }

    if ("desconto_taxa" in data.Produto) {
      this.desc_taxa = +data.Produto.desconto_taxa;
    }

    if ("rastro_residuo_item" in data.Produto) {
      this.rastro_residuo_item = data.Produto.rastro_residuo_item;
    }

    if ("desconto_extra" in data.Produto && !isNaN(+data.Produto.desconto_extra)) {
      this.desc_extra = Math.max(+data.Produto.desconto_extra, 0);
    }

    if ("Bicomponente" in data && Array.isArray(data.Bicomponente) && data.Bicomponente.length > 0) {
      this.bicomponente = data.Bicomponente;
    }

    if ("Config_cashback" in data && data.Produto.possui_cashback === "SIM" && Array.isArray(data.Config_cashback) && data.Config_cashback.length > 0) {
      this.config_cashback = data.Config_cashback;
    }

    if (hasCampanha) {
      this.campanha = observable.object({
        codigo_campanha: data.Campanha.codigo_campanha,
        tipo_campanha: data.Campanha.tipo_campanha,
        tipo_desconto: data.Campanha.tipo_desconto || DESCONTO,
        desconto: this.mode === "edit" || (this.neg_anterior === 'S' && this.mode === "add") ? Math.max(+data.Campanha.desconto, 0) : 0,
        base_campanha: Math.max(+data.Campanha.base_campanha, 0),
        preco_posicionado: Math.max(+data.Campanha.preco_posicionado, 0),
        permite_subir_preco: data.Campanha.permite_subir_preco.toUpperCase() || "NAO",
        safra: !!data.Campanha.safra && data.Campanha.safra[0] === "S" ? "SIM" : "NAO",
        data_final: !!data.Campanha.data_final ? data.Campanha.data_final.split(" ")[0] : "",
        clc_acresc_condpg: data.Campanha.clc_acresc_condpg || "NAO",
        clc_decresc_condpg: data.Campanha.clc_decresc_condpg || "NAO",
      });

      /* Campanha de PRECO_POSICIONADO não têm descontos em % */
      if (this.tipoDescontoCampanha === PRECO_POSICIONADO) {
        this.resetPolicy();
        // this.preco_vendido = moneyFixed(+this.campanha.preco_posicionado, 3);
      } else {
        this.desc_camp = +this.campanha.desconto;
        this.desc_kit = 0;
      }
      if (!!calculaPreco) this.calculaPreco();
    } else if (hasCampKit) {
      this.campKit = observable.object({
        codigo_kit: data.CampKit.codigo_kit,
        desconto_kit: Math.max(+data.CampKit.desconto_kit, 0),
        preco_kit: Math.max(+data.CampKit.preco_kit, 0),
        dt_kit_final: !!data.CampKit.dt_kit_final ? data.CampKit.dt_kit_final.split(" ")[0] : "",
        qtd_min: Math.max(+data.CampKit.qtd_min, 0),
        unmin: Math.max(+data.CampKit.unmin, 0),
        adicional_qtd_minima: Math.max(+data.CampKit.adicional_qtd_minima, 0),
        produto_fora_kit: data.CampKit.produto_fora_kit.toUpperCase() || "NAO",
        proporcao: Math.max(+data.CampKit.proporcao, 0),
        qtd_min_proporcao: Math.max(+data.CampKit.qtd_min_proporcao, 0),
        local_vld_proporcao: data.CampKit.local_vld_proporcao.toUpperCase() || "KIT",
        calc_acresc_condpag: data.CampKit.calc_acresc_condpag || "NAO",
        calc_descre_condpag: data.CampKit.calc_descre_condpag || "NAO",
      });

      this.quantidade_minima = this.campKit.qtd_min;

      if (Object.keys(oldPrice).length > 0) {
        this.campKit.preco_kit = Math.max(+oldPrice.preco_vendido, 0)
      }

      /* Camp. KIT de PRECO_POSICIONADO não têm descontos em % */
      if (this.tipoDescontoCampanha === PRECO_POSICIONADO) {
        this.resetPolicy();
        this.preco_vendido = moneyFixed(+this.campKit.preco_kit, 3);
      } else {
        this.desc_kit = +this.campKit.desconto_kit;
        this.desc_camp = 0;
      }
      if (!!calculaPreco) this.calculaPreco();
    } else {
      this.campanha = null;
      this.campKit = null;
      this.desc_camp = 0;
      this.desc_kit = 0;

      if (this.isBrinde) {
        this.preco_vendido = moneyFixed(+this.preco_tabela, 3);
      } else if (!!calculaPreco) {
        this.calculaPreco();
      }
    }
  }

  @action
  resetPolicy() {
    this.desc_camp = 0.0;
    this.desc_finan = 0.0;
    this.desc_geren = 0.0;
    this.desc_kit = 0.0;
    this.desc_logis = 0.0;
    this.desc_mix = 0.0;
    this.desc_perfil = 0.0;
    this.desc_extra = 0.0;
    this.desc_retir = 0.0;
    this.policy = observable.object({ ...EMPTY_POLICY });
  }

  @action
  reset() {
    this.mode = "add";
    this.item_produto = null;
    this.codigo_produto = null;
    this.descricao = null;
    this.preco_tabela = 0.0;
    this.tipo_produto = "AMBOS";
    this.tipo_sku = "PA";
    this.classe_produto = null;
    this.segum = null;
    this.codigo_tabela = null;
    this.ufcli = "EX";
    this.tipo_desconto_suframa = 'NENHUM';
    this.uf_filial = null;
    this.cod_canal = null;
    this.tipocli = "S";
    this.origem = null;
    this.importado_zona_franca = null;
    this.fator_conversao = 0.0;
    this.venda_litragem_minima = 0.0;
    this.peso_bruto = 0.0;
    this.quantidade_por_fardo = null;
    this.aliquota_ipi = null;
    this.icms_operacao = null;
    this.icms_interno = null;
    this.codigo_linha = null;
    this.ultimo_preco = null;
    this.data_ultimo_preco = null;
    this.ped_compra = null;
    this.item_pc = null;
    this.grupo_cor = null;
    this.quantidade = 0;
    this.conversao = 0;
    this.desconto_volume = 0;
    this.cashback = 0;
    this.dias_cashback = 0;
    this.somaclasse_cashback = " ";
    this.valor_cashback = 0;
    this.densidade = 0;
    this.quantidadeMaxBonif = 0;
    this.preco_vendido = 0.0;
    this.mva = 0;
    this.neg_anterior = "N";
    this.bonificado = "N";
    this.verba_codigo = null;
    //this.pode_desc_gerencial = "NAO";
    this.perc_desc_gerencial = 0;
    this.diferenca_tabela = "NAO";
    this.venda_permitida = "SIM";
    this.tintometrico = "SIM";
    this.policia_federal = "NAO";
    this.libera_pf = "NAO";
    this.libera_pf_on = "GLOBAL";
    this.status = "ATIVO";
    this.campanha = null;
    this.bicomponente = [];
    this.config_cashback = [];
    this.rastro_residuo_item = "";
    this.venda_liberada = "SIM";
    this.possui_cashback = 'NAO';
    this.bloqueado_plataforma = "NENHUM";

    this.resetPolicy();
  }

  @action
  checkQuantidade() {
    const __errors = [];

    this.quantidade = this.__quantidade;

    // Desabilita arredondamento e qtd. min. em pedidos status = Amostra ou se o perfil do cliente for FUNCIONARIO
    if ((Item.status_pedido === "5" && this.tipo_sku === "PA") || (this.cod_canal === "10")) {
      return;
    }
    
    if (!!this.isValidTicket) {
      if (this.quantidade > this.quantidade_max_liberado_kit) {
        this.quantidade = this.quantidade_max_liberado_kit;
        throw new PedidoError("A quantidade não pode ser maior do que a liberada pelo kit");
      }

      return;
    }

    if (+this.quantidade > 0) {
      const quantidade_por_fardo = +this.quantidade_por_fardo; //Math.max(+this.quantidade_por_fardo, 1);
      const quantidade_minima = 0.01; //Math.max(+this.quantidade_minima, 1);

      if (+this.quantidade < +this.quantidade_minima) {
        const fardos = Math.max(Math.ceil(quantidade_minima / quantidade_por_fardo), 1);
        const quantidade_precisa = fardos * quantidade_por_fardo;

        if (quantidade_precisa !== +this.quantidade) {
          this.quantidade = quantidade_precisa;
          __errors.push("Item abaixo da quantidade mínima estipulada (" + quantidade_minima + " latas).");
        }
      }

      const fardos = Math.max(Math.ceil(+this.quantidade / quantidade_por_fardo), 1);
      const quantidade_precisa = fardos * quantidade_por_fardo;
      if (quantidade_precisa !== +this.quantidade) {
        this.quantidade = quantidade_precisa;
        __errors.push("Não pode ser vendido fardo quebrado");
      }
    }

    if (__errors.length > 0) {
      throw new PedidoError(__errors.join(" / "));
    }
  }

  @action
  checkDesconto() {
    const fdesconto = parseFloat(this.desc_perfil || 0);
    const desc_perfil = Math.max(!isNaN(fdesconto) ? fdesconto : 0, 0);

    if (Item.status_pedido !== "S" && desc_perfil > this.desconto_base) {
      this.desc_perfil = moneyFixed(+this.desconto_base, 3);

      // diferença mínima = não da alerta só arruma...
      const diff = desc_perfil - this.desconto_base;
      if (diff > 0.009) {
        throw new PedidoError(`Desconto não permitido para o perfil!`);
      }
    } else {
      this.desc_perfil = moneyFixed(Math.min(+desc_perfil, 100), 3);
    }
  }

  @action
  checkPreco() {
    const preco_vendido = Math.max(+this.preco_vendido, 0);
    if (preco_vendido < +this.preco_minimo) {
      this.preco_vendido = moneyFixed(+this.preco_minimo, 3);
      throw new PedidoError(
        this.tipoDescontoCampanha === PRECO_POSICIONADO
          ? `Produto com preço posicionado a R$ ${n(
            +this.campanha.preco_posicionado,
            3
          )}. Permitido vender por esse valor mínimo ou valor maior!`
          : `Valor abaixo do mínimo permitido!`
      );
    }
  }

  calculaPrecoVendido() {
    if (!!this.codigo_produto && !!this.preco_tabela && !this.isBrinde && this.neg_anterior !== "S") {
      if (this.desconto_volume > 0) {
        const desc_volume = +this.desconto_volume / 100;
        const desc_valor = desc_volume * +this.preco_tabela;

        return moneyFixed(Math.max(+this.preco_tabela - desc_valor, 0), 3);
      }
      if (!!this.hasCampanha && this.tipoDescontoCampanha === PRECO_POSICIONADO) {
        if (this.campanha.permite_subir_preco === "NAO") {
          return moneyFixed(+this.campanha.preco_posicionado, 3);
        }

        let preco_vendido_base = +this.preco_vendido > 0 ? +this.preco_vendido : +this.campanha.preco_posicionado;
        if (+this.desc_taxa_prev !== 0) {
          preco_vendido_base = (preco_vendido_base * 100) / (100 - +this.desc_taxa_prev);
        }

        return moneyFixed(Math.max(+preco_vendido_base, 0), 3);
      }
      if (!!this.hasCampKit && this.tipoDescontoCampanha === PRECO_POSICIONADO) {
        if (+this.preco_vendido > this.campKit.preco_kit) {
          return moneyFixed(+this.preco_vendido, 3);
        } else {
          return moneyFixed(+this.campKit.preco_kit, 3);
        }
      }
      if (this.tipoDescontoCampanha !== PRECO_POSICIONADO) {
        const desc_perfil = +this.desc_perfil / 100;
        const desc_acmldo = +this.desconto_acumulado / 100;
        const desc_valor = (desc_perfil + desc_acmldo) * +this.preco_tabela;

        return moneyFixed(Math.max(+this.preco_tabela - desc_valor, 0), 3);
      }
    }

    return Math.max(+this.preco_vendido, 0);
  }

  @action
  calculaPreco(extra = null) {
    this.preco_vendido = this.calculaPrecoVendido();

    if (+this.desc_taxa !== 0 && !this.isBrinde) {
      const desc_taxa = this.preco_vendido * (+this.desc_taxa / 100);
      this.preco_vendido = moneyFixed(this.preco_vendido - desc_taxa, 3);
    }
  }

  @action
  calculaDesconto() {
    if (
      !!this.codigo_produto &&
      !!this.preco_tabela &&
      !this.isBrinde &&
      this.tipoDescontoCampanha !== PRECO_POSICIONADO
    ) {
      const desc_acmldo = +this.desconto_acumulado / 100;
      const desc_acmldo_valor = desc_acmldo * +this.preco_tabela;
      const desc_bruto_valor = +this.preco_tabela - (+this.preco_vendido + desc_acmldo_valor);
      this.desc_perfil = moneyFixed((desc_bruto_valor / +this.preco_tabela) * 100, 3);
    }
  }

  tagCampanha() {
    if (this.hasCampanha) {
      if (this.tipoDescontoCampanha === PRECO_POSICIONADO) {
        return !!this.campanha.safra && this.campanha.safra === "SIM"
          ? `Camp. Anjo Safra`
          : `Camp. de Preço Posicionado`;
      }
      return !!this.campanha.safra && this.campanha.safra === "SIM"
        ? `Camp. Anjo Safra c/ Desconto (${this.campanha.desconto}%)`
        : this.desc_camp === 0 ? `Camp. de Preço c/ Desconto desmarcada` : `Camp. de Preço c/ Desconto (${this.campanha.desconto}%)`;
    }

    return null;
  }

  shortTagCampanha() {
    if (this.hasCampanha) {
      const tag = [];
      if (this.tipoDescontoCampanha === PRECO_POSICIONADO) {
        if (!!this.campanha.safra && this.campanha.safra === "SIM") {
          tag.push(`Anjo Safra`);
        } else {
          tag.push(`Camp. de Preço`);
        }
      } else {
        if (this.campanha.desconto > 0) {
          tag.push(!!this.campanha.safra && this.campanha.safra === "SIM" ? `Anjo Safra` : `Camp. Desconto`);
          tag.push(`(${this.campanha.desconto}%)`);
        }
      }
      if (this.campanhaErrors.length > 0 && this.campanhaErrors.indexOf("data_final") !== -1) {
        tag.push(`[expirada]`);
      }
      return tag.join(" ");
    }

    return null;
  }

  @computed
  get isValid() {
    return (
      !!this.codigo_produto &&
      +this.quantidade > 0 &&
      +this.valor_total > 0 &&
      this.descontoErrors.length === 0 &&
      this.campanhaErrors.length === 0 &&
      this.campKitErrors.length === 0 &&
      !this.precoTabelaChanged
    );
  }

  @computed
  get isLinhaAuto() {
    return (
      !!this.codigo_linha && (this.codigo_linha === "01" || this.codigo_linha === "04" || this.codigo_linha === "05")
    );
  }

  @computed
  get vlrItemCashback() {
    return (
      !!this.cashback && this.cashback > 0 ? (this.cashback / 100) * ((this.preco_vendido) * this.quantidade) : 0
    );
  }

  get vlrItemCashback_Cimp() {
    return (
      !!this.cashback && this.cashback > 0 ? (this.cashback / 100) * ((this.preco_vendido - (this.valor_icms / this.quantidade) - ((this.valor_piscof / this.quantidade))) * this.quantidade) : 0
    );
  }

  @computed
  get isLinhaImob() {
    return !!this.codigo_linha && this.codigo_linha === "02";
  }

  @computed
  get isSolvente() {
    if (!!this.classe_produto) {
      const p = this.classe_produto.substr(0, 2);
      return p === "01";
    }
    return false;
  }

  @computed
  get isTintaComp() {
    if (!!this.classe_produto) {
      const p = this.classe_produto.substr(0, 2);
      return p === "02" || p === "03";
    }
    return false;
  }

  @computed
  get isBrinde() {
    return !!this.codigo_produto && this.tipo_sku === "BR";
  }

  @computed
  get isVendaPermitida() {
    return !!this.codigo_produto && this.venda_liberada === "SIM";
  }

  @computed
  get isRastroResiduo() {
    return !!this.rastro_residuo_item && this.rastro_residuo_item !== "";
  }

  @computed
  get isPlataformaBloqueada() {
    //return !!(this.plataforma_vendedor === this.bloqueado_plataforma);
    return this.bloqueado_plataforma === "NEXXUS";
  }

  @computed
  get isVendaCopoPermitida() {
    return (!!this.codigo_produto && this.codigo_produto === "ME0002" && +this.quantidade <= 49) || (!!this.codigo_produto && this.codigo_produto !== "ME0002");
  }

  @computed
  get isNegociacaoAnterior() {
    return !!this.codigo_produto && this.neg_anterior === "S";
  }

  @computed
  get hasCampanha() {
    return !!this.campanha && "codigo_campanha" in this.campanha;
  }

  @computed
  get hasCampKit() {
    return !!this.campKit && "codigo_kit" in this.campKit && !this.isNegociacaoAnterior;
  }

  @computed
  get hasCampSafra() {
    return this.hasCampanha && !!this.campanha.safra && this.campanha.safra === "SIM";
  }

  @computed
  get tipoCampanha() {
    return this.hasCampanha ? (this.campanha.tipo_campanha || "DIRETA").toUpperCase() : null;
  }

  @computed
  get isCashback() {
    return !!this.config_cashback && this.config_cashback.length > 0;
  }

  /* SERVE PARA KIT TAMBÉM */
  @computed
  get tipoDescontoCampanha() {
    if (this.hasCampKit) {
      return +this.campKit.desconto_kit > 0 ? DESCONTO : PRECO_POSICIONADO;
    }

    return this.hasCampanha ? this.campanha.tipo_desconto.toUpperCase().trim() : null;
  }

  @computed
  get precoTabelaChanged() {
    if (this.neg_anterior === "S") {
      return false;
    }

    if (!!this.codigo_produto && !!this.codigo_linha && this.diferenca_tabela === "SIM") {
      return (!this.hasCampanha && !this.hasCampKit) || this.tipoDescontoCampanha === DESCONTO;
    }

    return false;
  }

  @computed
  get descontoErrors() {
    if (this.neg_anterior === "S") {
      return [];
    }

    const invalid = [];
    if (
      !!this.policy &&
      !!this.codigo_produto &&
      !!this.codigo_linha &&
      this.tipoDescontoCampanha !== PRECO_POSICIONADO
    ) {
      Object.keys(EMPTY_POLICY).forEach(k => {
        const v = Math.max(+this[k], 0);
        if (k === "desc_extra") {
          const extra = Math.max(+this.desconto_extra_disp, 0);
          if (v > extra) {
            invalid.push(k);
          }
        } else if (Item.status_pedido === "S" && k === "desc_perfil") {
          if (v < 0 || v > 100) {
            invalid.push(k);
          }
        } else if (k !== "desc_geren") {
          if (v > this.policy[k]) {
            invalid.push(k);
          }
        }
      });
    }
    /* 
    if (+this.desc_geren > 0 && this.perc_desc_gerencial === 0) {
      invalid.push("desc_geren");
    }
    */
    return invalid;
  }

  @computed
  get campanhaErrors() {
    if (this.neg_anterior === "S") {
      return [];
    }

    const invalid = [];
    if (this.hasCampanha) {
      if (!!this.campanha.data_final) {
        const today = !!Item.today ? moment(Item.today, "YYYY-MM-DD") : moment();
        const data_final = moment(this.campanha.data_final, "YYYY-MM-DD");
        if (!today.isSameOrBefore(data_final)) {
          invalid.push("data_final");
        }
      }
      if (this.tipoDescontoCampanha !== PRECO_POSICIONADO && +this.desc_camp > +this.campanha.desconto) {
        invalid.push("desc_camp");
      }
      /* if (
        this.tipoDescontoCampanha === PRECO_POSICIONADO &&
        this.campanha.permite_subir_preco === "NAO" &&
        +this.preco_vendido !== +this.campanha.preco_posicionado
      ) {
        invalid.push("preco_posicionado");
      } */
    }
    return invalid;
  }

  @computed
  get campKitErrors() {
    if (this.neg_anterior === "S") {
      return [];
    }

    const invalid = [];
    if (this.hasCampKit) {
      if (!!this.campKit.dt_kit_final) {
        const today = !!Item.today ? moment(Item.today, "YYYY-MM-DD") : moment();
        const data_final = moment(this.campKit.dt_kit_final, "YYYY-MM-DD");
        if (!today.isSameOrBefore(data_final)) {
          invalid.push("data_final");
        }
      }
      if (this.tipoDescontoCampanha !== PRECO_POSICIONADO && +this.desc_kit > +this.campKit.desconto_kit) {
        invalid.push("desc_kit");
      }
      /* if (this.tipoDescontoCampanha === PRECO_POSICIONADO && +this.preco_vendido !== +this.campKit.preco_kit) {
        invalid.push("preco_posicionado");
      } */
    }
    return invalid;
  }

  @computed
  get canChangeQuantidade() {
    return this.tintometrico === "SIM" && this.venda_liberada === "SIM";
  }

  @computed
  get canChangeDesconto() {
    if (this.tipoDescontoCampanha === PRECO_POSICIONADO) {
      return false;
    }
    if (this.isBrinde) {
      return false;
    }
    return true;
  }

  @computed
  get canChangeDescCamp() {
    if (this.hasCampanha) {
      if (this.tipoDescontoCampanha === DESCONTO && this.campanha.base_campanha > 0) {
        return true;
      }
    }
    return false;
  }

  @computed
  get canChangePreco() {
    if (this.hasCampanha) {
      if (
        this.tipoDescontoCampanha === PRECO_POSICIONADO &&
        this.campanha.permite_subir_preco.toUpperCase() !== "SIM"
      ) {
        return false;
      }
    }
    if (this.hasCampKit) {
      return this.tipoDescontoCampanha !== PRECO_POSICIONADO;
    }
    if (this.isBrinde) {
      return false;
    }
    return true;
  }

  @computed
  get preco_minimo() {
    if (
      this.hasCampanha &&
      this.tipoDescontoCampanha === PRECO_POSICIONADO &&
      this.campanha.permite_subir_preco === "SIM"
    ) {
      return +this.campanha.preco_posicionado;
    }

    return 0.0;
  }

  @computed
  get __quantidade() {
    const value = parseFloat(this.quantidade || 0);
    return Math.max(!isNaN(value) ? value : 0, 0);
  }

  get __conversao() {
    const value = parseFloat(this.conversao || 0);
    return Math.max(!isNaN(value) ? value : 0, 0);
  }

  get __quantidadeMaxBonif() {
    const value = parseFloat(this.quantidadeMaxBonif || 0);
    return Math.max(!isNaN(value) ? value : 0, 0);
  }

  @computed
  get desconto_base() {
    if (!!this.codigo_produto && !!this.codigo_linha && !!this.classe_produto) {
      return Math.max(+this.policy.desc_perfil, 0);
    }

    return 0;
  }

  @computed
  get desconto_acumulado() {
    if (!!this.codigo_produto && !!this.codigo_linha) {
      let desconto_acumulado = [
        "desc_extra",
        "desc_camp",
        "desc_finan",
        "desc_geren",
        "desc_kit",
        "desc_logis",
        "desc_mix",
        "desc_rebat",
        "desc_retir",
      ].reduce((acc, k) => {
        return acc + +this[k];
      }, 0);

      return desconto_acumulado;
    }
    return 0;
  }

  @computed
  get valor_total_bruto() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const valor_total_bruto = Math.max(+this.preco_tabela, 0) * +this.__quantidade;
      return moneyFixed(valor_total_bruto);
    }
    return 0;
  }

  @computed
  get valor_total_sem_taxa() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const valor_total = Math.max(+this.calculaPrecoVendido(), 0) * +this.__quantidade;
      return moneyFixed(valor_total);
    }
    return 0;
  }

  @computed
  get valor_total() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const valor_total = Math.max(+this.preco_vendido, 0) * +this.__quantidade;
      return moneyFixed(valor_total);
    }
    return 0;
  }

  @computed
  get peso_bruto_total() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const peso_bruto_total = Math.max(+this.peso_bruto, 0) * +this.__quantidade;
      return moneyFixed(peso_bruto_total);
    }
    return 0;
  }

  @computed
  get valor_ipi() {
    if (!!this.codigo_produto && Item.status_pedido !== "6" && +this.__quantidade > 0 && this.tipo_desconto_suframa !== "ICMS/IPI/PIS/COFINS" && this.tipo_desconto_suframa !== "ICMS/IPI") {
      const valor_total = Math.max(+this.preco_vendido, 0) * +this.__quantidade;
      const valor_ipi = valor_total * (Math.max(+this.aliquota_ipi, 0) / 100);
      return moneyFixed(valor_ipi);
    }
    return 0;
  }

  @computed
  get base_icms() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const frete = 0;
      const lIncide = !(this.ncm === "27101919" && this.uf_filial !== this.ufcli);
      let valor_icms = 0;

      const valor_total = lIncide ? Math.max(+this.preco_vendido, 0) * +this.__quantidade : 0;
      let base_icms = valor_total + frete;
      // consumidor final
      if (!!this.tipocli && this.tipocli.toUpperCase() === "F") {
        base_icms = base_icms + this.valor_ipi;
      }
      return moneyFixed(base_icms);
    }
    return 0;
  }

  /* Tive que criar essa base de icms somente para o ST */
  @computed
  get base_icms_pst() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const frete = 0;
      let valor_icms = 0;

      const valor_total = Math.max(+this.preco_vendido, 0) * +this.__quantidade;
      let base_icms = valor_total + frete;
      // consumidor final
      if (!!this.tipocli && this.tipocli.toUpperCase() === "F") {
        base_icms = base_icms + this.valor_ipi;
      }
      return moneyFixed(base_icms);
    }
    return 0;
  }


  @computed
  get valor_piscof() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const nAliq = 9.25;
      let valor_piscof = 0;

      valor_piscof = Math.max(+this.base_icms, 0) * (+nAliq / 100);

      return valor_piscof;
    }
    return 0;
  }

  @computed
  get valor_suframa() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      let valor_suframa = 0;
      if (this.tipo_desconto_suframa === "ICMS/IPI/PIS/COFINS" && this.importado_zona_franca !== 'SIM') {
        valor_suframa = this.valor_icms + this.valor_piscof;
        //valor_suframa = this.valor_piscof; 
      }
      else if (this.tipo_desconto_suframa === "ICMS/IPI/PIS/COFINS" && this.importado_zona_franca === 'SIM') {
        valor_suframa = this.valor_piscof;
      }
      else if (this.tipo_desconto_suframa === "ICMS/IPI" && this.importado_zona_franca !== 'SIM') {
        valor_suframa = this.valor_icms;
      }
      else if (this.tipo_desconto_suframa === "ICMS" && this.importado_zona_franca !== 'SIM') {
        valor_suframa = this.valor_icms;
      }
      else {
        valor_suframa = 0;
      }
      return valor_suframa;
    }
    return 0;
  }

  @computed
  get valor_icms() {
    if (!!this.codigo_produto && !!this.uf_filial && !!this.bonificado && Item.status_pedido !== "6") {
      //aqui eu nao calculo o valor do icms para itens bonificados com cashback quando o faturamento ocorre em SC...
      let valor_icms = 0;
      if (this.tipo_desconto_suframa === "ICMS/IPI/PIS/COFINS" && this.importado_zona_franca !== 'SIM') {
        valor_icms = (this.bonificado === "S" && this.uf_filial === "SC") ? 0 : (+(this.base_icms - this.valor_piscof) * (Math.max(+this.icms_operacao, 0) / 100));
      }
      else {
        valor_icms = (this.bonificado === "S" && this.uf_filial === "SC") ? 0 : (+this.base_icms * (Math.max(+this.icms_operacao, 0) / 100));
      }
      return moneyFixed(valor_icms);
    }
    return 0;
  }

  @computed
  get base_st() {
    const lIncide = (this.ncm === "27101919" || this.ufcli != 'SC');
    if (!!this.codigo_produto && +this.mva > 0 && this.tipocli === "S" && lIncide && this.deduz_st !== "SIM") {
      //const a = +this.base_icms + +this.valor_ipi;
      const a = (Math.max(+this.preco_vendido, 0) * +this.__quantidade) + +this.valor_ipi;
      const base_st = a * (Math.max(+this.mva, 0) / 100) + a;
      return moneyFixed(base_st);
    }
    return 0;
  }

  @computed
  get valor_icms_st() {
    if (!!this.codigo_produto && +this.base_st > 0 && Item.status_pedido !== "6") {
      const valor_st = +this.base_st * (Math.max(+this.icms_interno, 0) / 100);
      const valor_icms_st = Math.max(valor_st - +this.valor_icms, 0);
      return moneyFixed(valor_icms_st);
    }
    return 0;
  }

  @computed
  get valor_total_nf() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const valor_total_nf = (+this.valor_total + +this.valor_ipi + +this.valor_icms_st) - +this.valor_suframa;
      return moneyFixed(valor_total_nf);
    }
    return 0;
  }

  @computed
  get litragem() {
    if (!!this.codigo_produto && +this.__quantidade > 0) {
      const valor_total = Math.max(+this.fator_conversao, MIN_LITROS) / (this.segum === "KG" ? this.densidade : 1) * +this.__quantidade;
      return moneyFixed(valor_total, 4);
    }
    return 0;
  }

  @computed
  get litro2kg() {
    if (!!this.codigo_produto && +this.__conversao > 0) {
      const valor_kg = +this.densidade * +this.__quantidade;
      return moneyFixed(valor_kg, 2);
    }
    return 0;
  }

  @computed
  get asJSON() {
    const item = toJS(this);
    const totais = {
      peso_bruto_total: this.peso_bruto_total,
      valor_total_bruto: this.valor_total_bruto,
      valor_total: this.valor_total,
      valor_ipi: this.valor_ipi,
      base_icms: this.base_icms,
      valor_icms: this.valor_icms,
      base_st: this.base_st,
      valor_icms_st: this.valor_icms_st,
      valor_total_nf: this.valor_total_nf,
      valor_suframa: this.valor_suframa,
      litragem: this.litragem,
    };
    return {
      ...item,
      totais,
    };
  }

  static fromJSON(itemJS, policyUpdated) {
    const { campanha = null, campKit = null, policy, totais, ...item } = itemJS;

    const newItem = new Item();
    for (let j in item) {
      newItem[j] = item[j];
    }

    newItem.desc_taxa = 0.0;
    newItem.desc_locked = false;

    if (!!policyUpdated) {
      newItem.applyPolicy(policyUpdated, false);
    }

    if (!!campanha && "codigo_campanha" in campanha) {
      newItem.campanha = observable.object({ ...campanha });

      if (newItem.tipoDescontoCampanha === PRECO_POSICIONADO) {
        newItem.resetPolicy();
        if (!!newItem.campanha.safra && newItem.campanha.safra[0] === "S") {
          newItem.campanha.safra = "SIM";
        } else {
          newItem.campanha.safra = "NAO";
        }
      } else {
        newItem.desc_camp = +newItem.campanha.desconto;
        newItem.desc_kit = 0.0;
      }
      newItem.calculaPreco();
    } else if (!!campKit && "codigo_kit" in campKit) {
      newItem.campKit = observable.object({ ...campKit });
      newItem.quantidade_minima = newItem.campKit.qtd_min || 0;

      if (newItem.tipoDescontoCampanha === PRECO_POSICIONADO) {
        newItem.resetPolicy();
        newItem.preco_vendido = moneyFixed(+newItem.campKit.preco_kit, 3);
      } else {
        newItem.desc_kit = +newItem.campKit.desconto_kit;
        newItem.desc_camp = 0.0;
      }
      newItem.calculaPreco();
    } else {
      newItem.campanha = null;
      newItem.campKit = null;
      newItem.desc_camp = 0.0;
      newItem.desc_kit = 0.0;

      if (!newItem.isBrinde) newItem.calculaPreco();
    }

    newItem.desc_taxa_prev = 0.0;

    return newItem;
  }
}
