import React, { PureComponent } from "react";
import { Form, Input, Select, Radio, Checkbox, DatePicker, Button, Upload, Icon } from "antd";
import MaskedInput from "antd-mask-input";
import classnames from "classnames";
import _isObject from "lodash/isObject";
import _size from "lodash/size";

import { fz, flatObjectToOptions, validateCNPJ, validateCPF } from "@util";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

export const PREFIX_NONE = "e";
export const PREFIX_NUMBER = "n";
export const PREFIX_LETTER = "l";

export const v = {
  required: { required: true, message: "Campo Obrigatório" },
  email: { type: "email", message: "Insira um e-mail válido" },
  emailComp: {
    validator: (rule, value, callback) => {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:#!\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!!value) {
        value = value.split(/[;,]/g);
        if (value.length > 0) {
          for (var k = 0; k < value.length; k++) {
            if (!re.test(String(value[k]).trim().toLowerCase())) {
              callback("Um ou mais e-mails inválidos nesse campo");
              return;
            }
          }
        }
      }

      callback();
    },
  },
  nome: {
    validator: (rule, value, callback) => {
      // eslint-disable-next-line
      const re = /^[a-záàâãéèêíìïóôõöúçñ ]+$/i;

      if (!!value) {
        value = value.split(/[;,]/g);
        if (value.length > 0) {
          for (var k = 0; k < value.length; k++) {
            if (!re.test(String(value[k]).trim().toLowerCase())) {
              callback("Nome inválido");
              return;
            }
          }
        }
      }

      callback();
    },
  },
  nomeFantasia: {
    validator: (rule, value, callback) => {
      // eslint-disable-next-line
      const re = /^[a-záàâãéèêíìïóôõöúçñ&'_\+\- ]+$/i;

      if (!!value) {
        value = value.split(/[;,]/g);
        if (value.length > 0) {
          for (var k = 0; k < value.length; k++) {
            if (!re.test(String(value[k]).trim().toLowerCase())) {
              callback("Nome inválido");
              return;
            }
          }
        }
      }

      callback();
    },
  },
  integer: {
    validator: (rule, value, callback) => {
      if (!value || value.toString().trim().length === 0 || (!isNaN(+value) && Number.isInteger(+value))) {
        callback();
        return;
      }

      callback("Este campo aceita apenas números, sem formatação");
    },
  },
  cnpj: {
    validator: (rule, value, callback) => {
      if (!!value && value.toString().trim().length >= 14 && !validateCNPJ(value)) {
        callback("Insira um CNPJ válido");
        return;
      }

      callback();
    },
  },
  cpf: {
    validator: (rule, value, callback) => {
      if (!!value && !validateCPF(value)) {
        callback("Insira um CPF válido");
        return;
      }

      callback();
    },
  },
};

export function parseLabel(k, label, prefix = "n") {
  const labelPrefix =
    !!prefix && prefix !== PREFIX_NONE
      ? `${prefix === PREFIX_LETTER ? String.fromCharCode(97 + (+k - 1)) : fz(k)}) `
      : "";
  return labelPrefix + label;
}

export function flatLabels(formLayout = [], prefix = "n", parentLabel = null) {
  return formLayout.reduce((acc, item, k) => {
    if (!!item.type && item.type === "group") {
      return { ...acc, ...flatLabels(item.items || [], item.prefix || prefix, item.flatHeader || item.header || null) };
    }

    const { field = null, label = null } = item;
    const itemLabel = {
      [field]: (!!parentLabel ? `${parentLabel}\r\n` : "") + parseLabel(k + 1, label, prefix),
    };
    return { ...acc, ...itemLabel };
  }, {});
}

export default class FormBuilder extends PureComponent {
  static defaultProps = {
    prefix: false,
    inputProps: {},
    onUpload: () => { },
    parseOptions: k => ({}),
    fileList: [],
    isLoading: false,
    gutter: 12,
  };
  removeFile = file => this.props.onUpload("remove", file);
  beforeUpload = file => {
    this.props.onUpload("append", file);
    return false;
  };
  renderInput(item) {
    const { inputProps, parseOptions, fileList, isLoading } = this.props;
    const { field, options = null, rules, ...fieldProps } = item;

    if (!!options) {
      const pso = _isObject(options) ? options : parseOptions(options);
      if (!pso || _size(pso) < 1) {
        return <Input {...inputProps} {...fieldProps} type="text" />;
      }

      const selectProps = { ...inputProps, defaultActiveFirstOption: !fieldProps.placeholder, ...fieldProps };

      if (!!fieldProps.type && fieldProps.type === "radio") {
        return (
          <RadioGroup {...selectProps}>
            {Object.keys(pso).map(k => (
              <Radio key={`${field}_option_${k}`} value={k}>
                {pso[k]}
              </Radio>
            ))}
          </RadioGroup>
        );
      }

      if (!!fieldProps.type && fieldProps.type === "checkbox") {
        return <CheckboxGroup {...selectProps} options={flatObjectToOptions(pso)} />;
      }

      return (
        <Select {...selectProps}>
          {Object.keys(pso).map(k => (
            <Option key={`${field}_option_${k}`} value={k}>
              {pso[k]}
            </Option>
          ))}
        </Select>
      );
    }

    if (!!fieldProps.type && fieldProps.type === "file") {
      return (
        <Upload
          {...fieldProps}
          action={null}
          onRemove={this.removeFile}
          beforeUpload={this.beforeUpload}
          fileList={fileList}>
          <Button loading={isLoading}>
            <Icon type="upload" /> Selecione o seu(s) arquivo(s)
          </Button>
        </Upload>
      );
    }

    if (!!fieldProps.type && fieldProps.type.indexOf("date") !== -1) {
      return <DatePicker format="DD/MM/YYYY" {...inputProps} {...fieldProps} />;
    }

    if (!!fieldProps.rows) {
      return <Input.TextArea {...inputProps} {...fieldProps} />;
    }

    if (!!fieldProps.mask) {
      return <MaskedInput {...inputProps} {...fieldProps} />;
    }

    return <Input {...inputProps} {...fieldProps} />;
  }
  render() {
    const { formLayout, prefix, header = null, inline = false, className, style, ...formProps } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div
        className={classnames(
          "form-builder--group",
          {
            "form-builder--group__inline": !!inline,
          },
          className
        )}
        style={style}>
        {!!header && <h4 className="form-builder--title">{header}</h4>}
        <div className="form-builder--fields">
          {formLayout.map((item, k) => {
            const { field, label = null, extra = null, rules = [], type = "text", ...otherProps } = item;

            if (type === "group") {
              return (
                <FormBuilder
                  key={`group_${k}`}
                  {...formProps}
                  {...otherProps}
                  prefix={otherProps.prefix || prefix}
                  formLayout={otherProps.items || []}
                  header={otherProps.header || header}
                />
              );
            }

            return (
              <FormItem
                key={`${field}_${k}`}
                label={!!label ? parseLabel(k + 1, label, otherProps.prefix || prefix) : ""}
                extra={extra}>
                {getFieldDecorator(field, { rules })(this.renderInput(item))}
              </FormItem>
            );
          })}
        </div>
      </div>
    );
  }
}
