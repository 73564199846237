import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { PieChart, Pie, Cell } from "recharts";
import _capitalize from "lodash/capitalize";
import styled from "styled-components";
import _isEqual from "lodash/isEqual";

import { dateFormat, n } from "@util";
import { fetchMix } from "actions/dashboard";
import { PageTitle, PageTitleExtra, ChartGroup, ChartGroupTitle } from "./Styled";

const COLORS = ["#02aed9", "#446fb8", "#34e5d5"];
const RADIAN = Math.PI / 180;

const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, volume, symbol, index, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) + 27;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text x={x} y={y} fontWeight={900} fontSize={14} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" fill="#666">
        {`${(percent * 100).toFixed(0)}% ${(_capitalize(name))}`}
      </text>
      <text
        x={x}
        y={y}
        dy={15}
        fontSize={15}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fill="#969696">
        {`${(n(volume))} ${(symbol)}`}
      </text>
    </g>
  );
};

const renderCustomizedLitLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, volume, symbol, index, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) + 27;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <g>
      <text x={x} y={y -10} fontWeight={900} fontSize={10} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" fill="#666">
        {`${(percent * 100).toFixed(0)}% ${(_capitalize(name))}`}
      </text>
      <text
        x={x}
        y={y-10}
        dy={11}
        fontSize={10}
        fontWeight={900}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fill="#969696">
       {`${(n(volume))} ${(symbol)}`}
      </text>
    </g>
  );
};

const MixChart = ({ data = [], children, isB2B, ...props }) => (
  <PieChart width={700} height={450} style={{ margin: "0 auto" }}>
    <Pie
      cx={340}
      cy={210}
      innerRadius={100}
      outerRadius={170}
      data={data}
      dataKey="percentual_mix"
      nameKey="desc_classe"
      label={renderCustomizedLabel}
      {...props}>
      {data.map((entry, index) => (
        <Cell key={entry.desc_classe} volume={entry.realizado} symbol={isB2B ? "Kg" : "Litros"} fill={COLORS[index % COLORS.length]} />
      ))}
      {children}
    </Pie>
  </PieChart>
);

const AuxChart = ({ label, data = [], isB2B,  children, ...props }) => (
  <ChartGroup>
    <ChartGroupTitle>{label}</ChartGroupTitle>
    <PieChart width={355} height={230}>
      <Pie
        cx={170}
        cy={103}
        innerRadius={33}
        outerRadius={63}
        data={data}
        dataKey="percentual_mix"
        nameKey="desc_classe"
        label={renderCustomizedLitLabel}
        {...props}>
        {data.map((entry, index) => (
          <Cell key={entry.desc_classe} volume={entry.realizado} symbol={isB2B ? "Kg" : "Litros"} fill={COLORS[index % COLORS.length]} />
        ))}
        {children}
      </Pie>
    </PieChart>
  </ChartGroup>
);

export default class MixPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchMix(!!this.props.isTipoVendaInterna ? this.props.relacionadoData.codigo : null);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data } = this.state;
    return (
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 380 }}>
          <PageTitle title="MIX" meta={false}>
            <PageTitleExtra>
              {!data ? "Carregando..." : `${dateFormat(data.data_inicial)} até ${dateFormat(data.data_final)}`}
            </PageTitleExtra>
          </PageTitle>
          {!!data && (
            <DashboardBody>
              {!!data.items.mix && data.items.mix.length > 0 ? (
                <Fragment>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <MixChart data={data.items.mix} isB2B={this.props.isB2B} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                      paddingBottom: 20,
                    }}>
                    {!!data.items.imob && data.items.imob.length > 0 && (
                      <AuxChart label="Imobiliária" data={data.items.imob} isB2B={this.props.isB2B} />
                    )}
                    {!!data.items.auto && data.items.auto.length > 0 && (
                      <AuxChart label="Automotiva" data={data.items.auto} isB2B={this.props.isB2B} />
                    )}
                    {!!data.items.carb && data.items.carb.length > 0 && (
                      <AuxChart label="Carbon" data={data.items.carb} isB2B={this.props.isB2B} />
                    )}
                  </div>
                </Fragment>
              ) : (
                <p>
                  <strong>Dados insuficientes para montar o seu gráfico de MIX.</strong>
                </p>
              )}
            </DashboardBody>
          )}
        </div>
      </Spin>
    );
  }
}
