import React, { Component } from "react";
import { observer } from "mobx-react";

import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

import "./ChooserGetMany.less";
import { Button } from "antd";

const rowKey = item => `${item.vendedor_atendimento}`;

const rowClassName = (item, selectedRepresentantes) => {
  const className = [];
  if (selectedRepresentantes.some(vendedor => vendedor.vendedor_atendimento === item.vendedor_atendimento)) {
    className.push("selected-table");
  }
  return className.join(" ");
};

@observer
export default class ChooserGetManySellers extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/maquinas-tintometricas/buscar-representantes");

    this.state = {
      visible: false,
      onChoose: null,
      selectedRepresentantes: []
    };
  }

  onSelectRow = (selectedRepresentante) => {
    this.setState((prevState) => {
      const { selectedRepresentantes } = prevState;
      const alreadySelected = selectedRepresentantes.some(vendedor => vendedor.vendedor_atendimento === selectedRepresentante.vendedor_atendimento);

      if (alreadySelected) {
        // Se o vendedore já está selecionado, removê-lo
        return {
          selectedRepresentantes: selectedRepresentantes.filter(vendedor => vendedor.vendedor_atendimento !== selectedRepresentante.vendedor_atendimento),
        };
      } else {
        // Caso contrário, adicionar o cliente ao acumulador
        return {
          selectedRepresentantes: [...selectedRepresentantes, selectedRepresentante],
        };
      }
    });
  };

  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };

  close = () => {
    this.setState({ visible: false, selectedRepresentantes: [] });
  };

  onSelected = () => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(this.state.selectedRepresentantes);
    }
    this.close();
  }

  onClear = () => {
    this.setState({ selectedRepresentantes: [] });
  }

  renderFooter = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Button onClick={this.close} disabled={this.ds.isLoading}>
          Fechar
        </Button>
        <Button onClick={this.onClear} type="dashed" disabled={this.ds.isLoading}>
          Limpar selecionados
        </Button>
        <Button
          type="primary"
          loading={this.ds.isLoading}
          disabled={!this.state.selectedRepresentantes.length}
          onClick={this.onSelected}>
          Adicionar
        </Button>
      </div>
    );
  };

  render() {
    const columns = [
      {
        title: "Cód Vend.",
        key: "vendedor_atendimento",
        width: "12%",
        className: "text-left",
      },
      {
        title: "Nm. Vend.",
        key: "nome_vend_atendimento",
        width: "88%",
        className: "text-left",
      },
    ];

    return (
      <ChooserModal
        footer={this.renderFooter()}
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Selecione os representantes"
        errorMessage="Não foi possível obter seus representantes no momento"
        searchPlaceholder="Busque pelo nome ou código do representante"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        rowClassName={item => rowClassName(item, this.state.selectedRepresentantes)}
        {...this.props}
      />
    );
  }
}
