import React, { Component, PureComponent } from "react";
import { Modal, Row, Col, Form, Icon, Input, Upload, Button, Spin, Checkbox, DatePicker } from "antd";
import { observer } from "mobx-react";

import { fetchApi, fetchPostApi } from "actions";
import { getData } from "@util";
import withStore from "withStore";

import { v } from "components/FormBuilder";
import PageHeader, { BackButton } from "components/PageHeader";
import ConsultaCNPJModal from "components/clientes/ConsultaCNPJModal";
import moment from "moment";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

@observer
class TreinamentoEditorScreen extends Component {
  async componentDidMount() {
    if (this.props.match.params.edit !== 'add') {
      await this.fetchBase();
    }
  }
  async fetchBase() {
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/treinamento/edit/" + this.props.match.params.edit);
      if (!!response.success) {
        const { nome, sigla, vigencia, necessita_anexo, interno, obs, bloqueia_entrada } = response.data;
        this.props.form.setFieldsValue({
          nome,
          sigla,
          vigencia: vigencia == 'S',
          necessita_anexo: necessita_anexo == 'S',
          interno: interno == 'S',
          bloqueia_entrada: bloqueia_entrada == 'S',
          obs,
        });
      } else {
        throw new Error("Não foi possível obter dados atualizados para este formulário");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados para este formulário",
        onOk: () => this.props.history.replace("/treinamento"),
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }
  async saveTreinamento(data) {
    const { store, history, match } = this.props;

    const errorMessages = {
      10: "Não foi possível registrar seu treinamento neste momento. Tente novamente mais tarde",
    };

    store.isLoading = true;

    const checks = [
      'necessita_anexo',
      'vigencia',
      'interno',
      'bloqueia_entrada',
    ]

    for (let i = 0; i < checks.length; i++) {
      const check = checks[i];

      if (data[check]) {
        data[check] = 'S'
      } else {
        data[check] = 'N';
      }
    }

    try {
      if (match.params.edit !== 'add') {
        data = {
          ...data,
          codigo: match.params.edit
        }
      }
      const { data: response } = await fetchPostApi("/treinamento/" + (match.params.edit === 'add' ? "save" : "update"), data);
      if (!!response.success) {
        Modal.success({
          title: "Treinamento registrado com sucesso",
          onOk() {
            history.replace("/treinamento");
          },
          keyboard: false,
        });

        store.isLoading = false;
      } else {
        throw new Error(errorMessages[10]);
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || (typeof err === "string" ? err : errorMessages[10]),
      });

      store.isLoading = false;
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveTreinamento(Object.keys(formData).reduce((acc, k) => ({ ...acc, [k]: formData[k] || "" }), {}));
      }
    });
  };
  render() {
    const { isLoading } = this.props.store;
    const { getFieldDecorator } = this.props.form;
    const { match } = this.props;

    const inputProps = {
      readOnly: isLoading,
      autoComplete: "off",
    };

    const isAdd = match.params.edit === 'add';

    return (
      <div className="cadastro-cliente-screen">
        <PageHeader title={isAdd ? "Novo Treinamento" : `Alterar treinamento #${match.params.edit}`} headerLeft={<BackButton onClick={() => this.props.history.goBack()} />} />
        <Spin spinning={!!isLoading}>
          <Form
            autoComplete="off"
            layout="vertical"
            hideRequiredMark={false}
            onSubmit={this.handleSubmit}
            className="cadastro-cliente--form">
            <div>
              <Row gutter={12}>
                <Col span={9}>
                  <FormItem label="Nome">
                    {getFieldDecorator("nome", { rules: [v.required] })(
                      <Input maxLength={100} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="Sigla">
                    {getFieldDecorator("sigla", { rules: [v.required] })(<Input maxLength={11} {...inputProps} />)}
                  </FormItem>
                </Col>
                <Col span={2}>
                  <FormItem label="Interno?">
                    {getFieldDecorator("interno", { valuePropName: "checked" })(
                      <Checkbox {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={2}>
                  <FormItem label="Vigência?">
                    {getFieldDecorator("vigencia", { valuePropName: "checked" })(
                      <Checkbox {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem label="Necessita Anexo?">
                    {getFieldDecorator("necessita_anexo", { valuePropName: "checked" })(
                      <Checkbox {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem label="Bloqueia Entrada?">
                    {getFieldDecorator("bloqueia_entrada", { valuePropName: "checked", initialValue: true })(
                      <Checkbox {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <FormItem label="Observação">
                    {getFieldDecorator("obs")(<TextArea {...inputProps} rows={2} />)}
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 6, marginBottom: 16 }}>
              <Button
                style={{ marginTop: 9 }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="cadastro-cliente--button">
                {isAdd ? 'Enviar' : 'Alterar'} cadastro
              </Button>
            </div>
          </Form>
        </Spin>
        <ConsultaCNPJModal
          ref={ref => (this._consultaCNPJ = ref)}
          isLoading={isLoading}
          onConsulta={this.requestConsultaCNPJ}
        />
      </div>
    );
  }
  _consultaCNPJ;
  _ie;
}

export default Form.create()(withStore(TreinamentoEditorScreen));
