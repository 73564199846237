import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Modal } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import RequestForm from "components/RequestForm";
import AppLayout from "layouts/AppLayout";
import PedidosPeriodoScreen from "screens/common/PedidosPeriodoScreen";
import NotasFiscaisScreen from "screens/common/NotasFiscaisScreen";
import NpsScreen from "screens/common/NpsScreen";
import MaquinasReportScreen from "screens/common/MaquinasReportScreen";
import HistoricoMaquinasReportScreen from "screens/common/HistoricoMaquinasReportScreen";
import AnaliseVolumeReportScreen from "screens/common/AnaliseVolumeReportScreen";
import CursosTreinamentosPromotoriaReportScreen from "screens/common/CursosTreinamentosPromotoriaReportScreen";
import OciosidadeReportScreen from "screens/common/OciosidadeReportScreen";
import VolumePerfilScreen from "screens/common/VolumePerfilScreen";
import RankVolumeScreen from "screens/vendedor/relatorios/RankVolumeScreen";

import BoletosScreen from "screens/common/BoletosScreen";
import ResumoCashbackScreen from "screens/vendedor/relatorios/ResumoCashbackScreen";
import ListaPintores from "screens/common/Promotoria/ListaPintores";
import ProdutosTrabalhadoPromotoria from "screens/common/ProdutosTrabalhadoPromotoria";
import Promotor from "./Promotor";
import Aprovador from "./Aprovador";
import Treinamento from "./Treinamento";
import TreinamentoFornecedor from "./TreinamentoFornecedor";
import ImportacaoScreen from "screens/common/MaquinasTintometricas/ImportacaoScreen";
import SAC from "./SAC";

const Cliente = React.lazy(() => import(/* webpackChunkName: "cliente" */ "./Cliente"));
const Vendedor = React.lazy(() => import(/* webpackChunkName: "vendedor" */ "./Vendedor"));
const Diretor = React.lazy(() => import(/* webpackChunkName: "vendedor" */ "./Diretor"));
const Transportador = React.lazy(() => import(/* webpackChunkName: "vendedor" */ "./Transportador"))

const LoadingView = () => (
  <p>
    <strong>Carregando módulo...</strong>
  </p>
);

@observer
class AppRouter extends Component {
  userMenuAction = event => {
    const { store, history } = this.props;
    const { key } = event;
    if (key.indexOf("changepwd") !== -1) {
      if (store.isCliente) {
        history.push("/cliente/change_password");
      }
      else if (store.isDiretor) {
        history.push("/diretor/change_password");
      }
      else if (store.isTransportador) {
        history.push("/transportador/change_password");
      }
      else if (!store.isDiretor && !store.isOperadorAdmin) {
        history.push("/vendedor/change_password");
      }
      else if (store.isOperador || store.isOperadorAdmin) {
        history.push("/operadores/change_password");
      }

      return;
    }
    if (key.indexOf("changeop") !== -1 && store.isOperadorAdmin) {
      const currentUrl = history.location.pathname;
      const warningUrls = ["/pedidos/add", "/pedidos/edit"];
      const hasWarning = !!warningUrls.find(path => currentUrl.indexOf(path) !== -1);
      const onOk = () => history.push("/operadores");

      if (hasWarning) {
        Modal.confirm({
          title: "Pedido em Andamento",
          content:
            "Você está adicionando ou editando um pedido neste momento. Ao mudar seu operador de trabalho seu pedido atual será perdido.",
          cancelText: "Cancelar",
          okText: "Confirmar",
          onOk,
        });
      } else {
        onOk();
      }

      return;
    }
    if (key.indexOf("logout") !== -1) {
      Modal.confirm({
        title: "Você confirma a saída do Portal ANJO NEXXUS?",
        content:
          "Clicando no botão abaixo você será deslogado do Portal ANJO NEXXUS, no próximo acesso seu login e senha serão solicitados novamente",
        cancelText: "Cancelar",
        okText: "Confirmar / Sair",
        onOk: () => {
          store.logout();
          history.replace("/auth/login");
        },
      });
      return;
    }
  };
  render() {
    const { store, match } = this.props;
    return (
      <AppLayout
        collapsed={store.sidebarCollapsed}
        hideMenuButton={store.hideMenuButton}
        onCollapse={store.toggleSidebar}
        tipo={store.isLogged && store.loginData.tipo}
        isB2B={store.isB2B}
        habilitaPedido={store.habilitaPedido}
        isTipoVendedor={store.isTipoVendedor}
        isTipoVendaInterna={store.isTipoVendaInterna}
        loginName={store.loginName}
        tipoDiretor={store.tipoDiretor}
        Diretor_unidade_negocio={store.Diretor_unidade_negocio}
        resetTabs={store.resetTabs.bind(store)}
        isOperadorAdmin={store.isOperadorAdmin}
        isNexxusPro={store.isNexxusPro}
        onUserMenu={this.userMenuAction}
        isGerente={store.isGerente}
        isPromotor={store.isPromotor}
        isAprovador={store.isAprovador}
        treinamentoFornecedorData={store.treinamentoFornecedorData}
        location={store.location}
        tipoSupervisor={store.tipoSupervisor}
      >
        <Route path="/r/nps" component={NpsScreen} />
        <Route path="/r/maquinas" component={MaquinasReportScreen} />
        <Route path="/r/historico-maquinas" component={HistoricoMaquinasReportScreen} />
        <Route path="/r/cursos-treinamentos-promotoria" component={CursosTreinamentosPromotoriaReportScreen} />
        <Route path="/r/produtos-trabalhados-promotoria" component={ProdutosTrabalhadoPromotoria} />
        <Route path="/r/analise-volume" component={AnaliseVolumeReportScreen} />
        <Route path="/r/ociosidade" component={OciosidadeReportScreen} />
        <Route path="/r/resumo_cashback" component={ResumoCashbackScreen} />
        <Route path="/r/volume_perfil" component={VolumePerfilScreen} />
        <Route path="/r/ranking" component={RankVolumeScreen} />
        <Route path="/r/volume_perfil-mop" component={VolumePerfilScreen} />
        <Route path="/notas-fiscais" component={NotasFiscaisScreen} />
        <Route path="/notas-fiscais-mop" component={NotasFiscaisScreen} />
        <Route path="/boletos/:cliente" component={BoletosScreen} />
        <Route exact path="/boletos" component={BoletosScreen} />
        <Route path="/r/pedidos" component={PedidosPeriodoScreen} />
        <Route path="/r/listar-pintores/:qrcode" component={ListaPintores} />
        <Route exact path={'/maquinas-tintometricas/importacao'} component={ImportacaoScreen} />
        <React.Suspense fallback={<LoadingView />}>
          {store.isCliente ? (
            <Cliente match={match} />
          ) : store.isDiretor || store.isGerente ? (
            <Diretor match={match} />
          ) : store.isTransportador ? (
            <Transportador match={match} />
          ) : store.isPromotor ? (
            <Promotor match={match} />
          ) : store.isAprovador ? (
            <Aprovador match={match} />
          ) : store.isTreinamento ? (
            <Treinamento match={match} />
          ) : store.isTreinamentoFornecedor ? (
            <TreinamentoFornecedor match={match} />
          ) : store.isSAC ? (
            <SAC match={match} />
          ) : (
            <Vendedor match={match} />
          )}
        </React.Suspense>
        <RequestForm id="printForm" action={store.printFormAction} />
      </AppLayout>
    );
  }
}

export default withStore(AppRouter);
