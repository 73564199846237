/**
 * @flow
 */
import { action, observable, computed, toJS } from "mobx";
import { itemSeq, lastSeq } from "@util";

import Item from "./Item";

export class OrcamentoError extends Error {
  constructor(message, code = 0) {
    super(message);

    this.name = "OrcamentoError";
    this.code = code;
  }
}

function validateItemForOrc(orcamento, item) {
  const testExiste =
    orcamento.hasItems && !!orcamento.items.find(citem => citem.produto_orcamento === item.produto_orcamento);

  if (testExiste) {
    throw new OrcamentoError(
      `O produto ${item.produto_orcamento} já está adicionado na lista de itens. Favor remover o item antes de tentar adicioná-lo novamente.`
    );
  }

  return true;
}

export default class Orcamento {
  @observable isLoading: boolean = false;

  @observable codigo_orcamento: string = null;
  @observable codigo_orcamento_original: string = null;

  @observable items: Array = [];
  @observable pendingItems: Array = [];
  @observable removedItems: Array = [];

  @observable
  data: Object = {
    data_orcamento: null,
    filial_orcamento: null,
    nome_filial: "",
    cliente_orcamento: null,
    loja_orcamento: null,
    obs_orcamento: "",
    entrega_orcamento: "",
    volume_orcamento: 0.0,
    peso_orcamento: 0.0,
    baseicm_orcamento: 0.0,
    icms_orcamento: 0.0,
    basest_orcamento: 0.0,
    icmsst_orcamento: 0.0,
    ipi_orcamento: 0.0,
    total_orcamento: 0.0,
    condpag_orcamento: "",
    status_orcamento: "01",
    desstatus_orcamento: "Em digitação",
  };

  constructor({ codigo_cliente = null, loja_cliente = null }) {
    if (!!codigo_cliente) this.data.cliente_orcamento = codigo_cliente;
    if (!!loja_cliente) this.data.loja_orcamento = loja_cliente;
  }

  @action
  parse({ orcamento, items = [] }, copy = false) {
    if (!!orcamento) {
      if (!copy) {
        this.codigo_orcamento = orcamento.codigo_orcamento;
        this.data.obs_orcamento = orcamento.obs_orcamento || "";
        this.data.entrega_orcamento = orcamento.entrega_orcamento || "";

      } else {
        this.codigo_orcamento_original = orcamento.codigo_orcamento;
      }

      if (!!orcamento.filial_orcamento) this.data.filial_orcamento = orcamento.filial_orcamento;
      this.data.nome_filial = orcamento.nome_filial || "";
      if (!!orcamento.cliente_orcamento) this.data.cliente_orcamento = orcamento.cliente_orcamento;
      if (!!orcamento.loja_orcamento) this.data.loja_orcamento = orcamento.loja_orcamento;
      this.data.volume_orcamento = +(orcamento.volume_orcamento || 0.0);
      this.data.peso_orcamento = +(orcamento.peso_orcamento || 0.0);
      this.data.baseicm_orcamento = +(orcamento.baseicm_orcamento || 0.0);
      this.data.icms_orcamento = +(orcamento.icms_orcamento || 0.0);
      this.data.basest_orcamento = +(orcamento.basest_orcamento || 0.0);
      this.data.icmsst_orcamento = +(orcamento.icmsst_orcamento || 0.0);
      this.data.ipi_orcamento = +(orcamento.ipi_orcamento || 0.0);
      this.data.total_orcamento = +(orcamento.total_orcamento || 0.0);
      if (!!orcamento.condpag_orcamento) this.data.condpag_orcamento = orcamento.condpag_orcamento;
      if (!!orcamento.status_orcamento) this.data.status_orcamento = orcamento.status_orcamento;
      if (!!orcamento.desstatus_orcamento) this.data.desstatus_orcamento = orcamento.desstatus_orcamento;
      if (!!orcamento.data_orcamento) this.data.data_orcamento = orcamento.data_orcamento;
    }
    if (!!items && items.length > 0) {
      items.forEach(itemData => {
        this.items.push(new Item({ ...itemData, codigo_orcamento: this.codigo_orcamento }));
      });
    }
  }

  @computed
  get hasItems() {
    return this.items.length > 0;
  }

  @computed
  get isEdit() {
    return !!this.codigo_orcamento && this.hasItems;
  }

  @computed
  get isItemsValid() {
    if (this.hasItems) {
      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].isValid) return false;
      }
      return true;
    }
    return false;
  }

  lastItemOrcamento() {
    if (this.items.length > 0 || this.removedItems.length > 0) {
      return +lastSeq(
        [].concat(
          this.items.map(k => +k.item_orcamento),
          this.removedItems.map(k => +k.item_orcamento)
        )
      );
    }
    return 0;
  }

  @action
  addItem(item: Item) {
    if (validateItemForOrc(this, item)) {
      item.item_orcamento = +itemSeq(this.lastItemOrcamento());
      this.items.push(item);

      return true;
    }
    return false;
  }

  @action
  updateItem(item: Item) {
    if (this.hasItems && item.isValid) {
      const idx = this.items.findIndex(citem => citem.item_orcamento === item.item_orcamento);
      if (idx >= 0) {
        this.items[idx] = item;
      } else {
        this.addItem(item);
      }
    }
  }

  @action
  removeItem(item: Item) {
    if (this.hasItems && !!item.item_orcamento) {
      const idx = this.items.findIndex(citem => citem.item_orcamento === item.item_orcamento);
      if (idx >= 0) {
        this.items.splice(idx, 1);

        /* SE ESTIVER REMOVENDO UM ITEM JÁ EXISTENTE NO BANCO EM UMA EDIÇÃO DE PEDIDO, REGISTRAR PARA POSTERIOR UPDATE EXCLUSIVO */
        if (this.isEdit && item.mode === "edit") {
          item.mode = "delete";
          this.removedItems.push(item);
        }
      }
    }
  }

  exportData() {
    const data = toJS(this.data);
    const items = this.items.map(item => item.asJSON);
    const removed = this.removedItems.map(ritem => ritem.asJSON);

    return {
      Orcamento: {
        codigo_orcamento: toJS(this.codigo_orcamento),
        ...data,
      },
      OrcamentoItem: [...removed, ...items],
    };
  }

  @computed
  get asJSON() {
    const data = toJS(this.data);
    const items = this.items.map(item => item.asJSON);
    const removed = this.removedItems.map(ritem => ritem.asJSON);

    return {
      data: {
        codigo_orcamento: toJS(this.codigo_orcamento),
        ...data,
      },
      items,
      removed,
    };
  }
}
