import React from "react";
import { Redirect, Route } from "react-router-dom";

import LoginLayout from "layouts/LoginLayout";
import LoginScreen from "screens/common/LoginScreen";
import PasswordForgotScreen from "screens/common/PasswordForgotScreen";
import FornecedorAcessoScreen from "screens/common/FornecedorAcessoScreen";

const AuthRouter = ({ match }) => (
  <LoginLayout>
    <Route exact path={`${match.url}/login`} component={LoginScreen} />
    <Route exact path={`${match.url}/password-forgot`} component={PasswordForgotScreen} />
    <Route exact path={`${match.url}/fornecedor/:hash`} component={FornecedorAcessoScreen} />
    <Route
      exact
      path={match.url}
      render={props => (
        <Redirect
          to={{
            pathname: `${match.url}/login`,
            state: { from: props.location },
          }}
        />
      )}
    />
  </LoginLayout>
);

export default AuthRouter;
