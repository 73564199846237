import React, { Component, Fragment } from "react";
import { Modal, Alert, Form, DatePicker, Dropdown, Menu, Button, Icon } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";

import { getData, filterDate } from "@util";
import { fetchApi, fetchPostApi } from "actions";
import FullTable, { SearchForm, Toolbar, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import StatusCircle from "components/pedidos/StatusCircle";
import SharePedidoModal from "components/pedidos/SharePedidoModal";

const FormItem = Form.Item;

const rowKey = item => `${item.Pedido.numero_pedido}:${item.Pedido.codigo_filial}`;

const rowClassName = (item, index) => {
  if (!!item.Pedido.codigo_orcamento) {
    return `full-table--row full-table--row__success-light`;
  }

  return index % 2 !== 0 ? `full-table--row full-table--row__odd` : `full-table--row`;
};

function SimulacoesCounter({ possiveis = 0, realizadas = 0, restantes = 0, ...props }) {
  return (
    <Alert
      message={`Simulações permitidas: ${possiveis} | Simulações feitas: ${realizadas} | Simulações restantes: ${restantes}`}
      type={restantes === 0 ? "error" : restantes < (possiveis / 2) ? "warning" : "success"}
      showIcon
      {...props}
    />
  );
}

const StyledIcon = styled.span`
  display: inline-block;
  color: #fff;
  background-color: ${props => props.bgColor || "#1890ff"};
  font-size: 13px;
  font-weight: bold;
  padding: 1px 3px;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    color: ${props => props.bgColor || "#1890ff"};
  }
`;

export const IconConstrutora = props => (
  <StyledIcon {...props}>
    <Icon type="home" title="Construtora" />
  </StyledIcon>
);

export const IconOrcamento = props => (
  <StyledIcon {...props}>
    <Icon type="user" title="Orçamento Solicitado pelo Cliente" />
  </StyledIcon>
);

export const IconSimulacao = props => (
  <StyledIcon {...props}>
    <Icon type="line-chart" title="Simulação" />
  </StyledIcon>
);

const SharedButton = ({ codigo, name, ...props }) => (
  <StyledIcon
    as="div"
    style={{
      fontSize: 12,
      padding: "1px 4px",
      cursor: "pointer",
    }}
    {...props}>
    <Icon type="share-alt" title={`Pedido compartilhado por ${name}`} />
  </StyledIcon>
);

const IconShared = ({ codigo, colegas, ...props }) => {
  const colega = colegas.find(c => c.codigo === codigo);

  return (
    <Icon
      type="share-alt"
      title={`Pedido compartilhado com ${!!colega ? colega.razao_social : "outro usuário"}`}
      style={{ marginRight: 5, color: "#C00" }}
      {...props}
    />
  );
};

@observer
export default class TabSimulacoes extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      uri: "/pedidos/simulacao",
      state: {
        vendedores: [],
        operadores: [],
      },
    });

    this.state = {};
  }

  componentDidMount() {
    this.handleExtraContente(false);
  }

  handleExtraContente(isDelSimu) {
    const { store, history } = this.props;
    const isOperadorAdmin = store.isOperadorAdmin && store.testLoginOriginal(store.loginData.login);
    const habilitaPedido = store.habilitaPedido;
    let qtdrestantes = store.isOperador ? +store.operadorData.qtdsimu_rest_politica : +store.vendedorData.qtdsimu_rest_politica;
    const onAdd = () => history.push(`/simulacoes/add`);

    if (isDelSimu) {
      qtdrestantes++;
    }

    this.props.appendTabBarExtraContent(
      <Fragment>
        {!habilitaPedido && (
          <Button
            type="danger"
            icon="close"
            style={{ display: "block", marginTop: -1 }}>
            Simulação desabilitada temporariamente !
          </Button>
        )}
        {habilitaPedido && (
          <Button
            type="primary"
            icon="plus"
            id="btn_nova_simulacao"
            disabled={isOperadorAdmin || !habilitaPedido || qtdrestantes <= 0}
            onClick={onAdd}
            style={{ display: "block", marginTop: -1 }}>
            Nova Simulação
          </Button>
        )}
      </Fragment>
    );
  }

  clickActions(pedido, event = {}) {
    const { history, store } = this.props;
    const { key = "menu-edit" } = event;

    const isOperadorAdmin = store.isOperadorAdmin && store.testLoginOriginal(store.loginData.login);

    switch (key) {
      case "menu-print":
        store.requestPrintForm(`/pedidos/pedido/${pedido.codigo_hash}`);
        break;
      case "menu-print-sdesc":
        store.requestPrintForm(`/pedidos/pedido/${pedido.codigo_hash_sdesc}`);
        break;
      case "menu-enviar":
        Modal.confirm({
          title: "Você confirma esta operação?",
          content: "Clicando no botão abaixo você irá enviar esta simulação para aprovação de seu gerente/diretor.",
          cancelText: "Cancelar",
          okText: "Confirmar",
          onOk: () => this.enviarSimulacao(pedido.codigo_hash),
        });
        break;
      case "menu-pedido":
        Modal.confirm({
          title: "Você confirma esta operação?",
          content: "Clicando no botão abaixo você irá transformar esse orçamento em pedido.",
          cancelText: "Cancelar",
          okText: "Confirmar",
          onOk: () => this.transformarPedido(pedido.codigo_hash),
        });
        break;
      case "menu-delete":
        Modal.confirm({
          title: "Você confirma esta operação?",
          content: "Clicando no botão abaixo você irá excluir esta simulação.",
          cancelText: "Cancelar",
          okText: "Excluir Simulação",
          onOk: () => this.deletePedido(pedido.codigo_hash),
        });
        break;
      case "menu-copy":
        history.push(`/simulacoes/add/${pedido.numero_pedido}-${pedido.codigo_filial}`);
        break;
      default:
        if (!!pedido.locked || isOperadorAdmin) {
          Modal.warning({
            title: "Pedido bloqueado para edição",
            content: "Orçamentos cadastrados há mais de 30 dias não podem ser alterados",
          });
          return;
        }
        history.push(`/pedidos/edit/${pedido.numero_pedido}-${pedido.codigo_filial}`);
        break;
    }
  }
  enviarSimulacao = async codigo_hash => {
    const { store } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/pedidos/enviar_simulacao/" + codigo_hash);
      if (!!response.success) {
        Modal.success({
          title: "Simulação enviada com sucesso",
          content: "Sua simulação foi enviada para a análise de seu gerente",
        });

        store.ping();
        store.isLoading = false;

        this.ds.fetch();
      } else {
        throw new Error("Não foi possível envia sua simulação neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Apenas vendedores ou operadores podem solicitar esta ação",
        5: "Não foi possível envia sua simulação neste momento. Tente novamente mais tarde",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  };
  transformarPedido = async codigo_hash => {
    const { store } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/pedidos/transformar_pedido/" + codigo_hash);
      if (!!response.success) {
        Modal.success({
          title: "Orçamento transformado com sucesso",
          content: "Seu orçamento foi transformado em pedido",
        });

        store.ping();
        store.isLoading = false;

        this.ds.fetch();
      } else {
        throw new Error("Não foi possível transformar seu orçamento neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Apenas vendedores podem solicitar esta ação",
        5: "Não foi possível transformar seu orçamento neste momento. Tente novamente mais tarde",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  };
  deletePedido = async codigo_hash => {
    const { store } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/pedidos/deletar/" + codigo_hash);
      if (!!response.success) {
        Modal.success({
          title: "Simulação excluída com sucesso",
          content: "Sua simulação foi excluída do sistema",
        });

        store.ping();
        store.isLoading = false;
        this.ds.fetch();
        this.handleExtraContente(true);

      } else {
        throw new Error("Não foi possível excluir sua simulação neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Apenas vendedores podem solicitar exclusão de simulações",
        5: "Não foi possível excluir sua simulação neste momento. Tente novamente mais tarde",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  };

  compartilharPedido = async (codigo_hash, compartilhado, tipo) => {
    const { store } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchPostApi("/pedidos/compartilhar_pedido/" + codigo_hash, {
        compartilhado,
        tipo,
      });
      if (!!response.success) {
        Modal.success({
          title: !!compartilhado ? "Pedido compartilhado com sucesso" : "Pedido retornado com sucesso",
          content: !!compartilhado
            ? "Seu pedido foi compartilhado com outro usuário"
            : "Este pedido foi retornado ao seu estado original",
        });

        store.ping();
        store.isLoading = false;

        this.ds.fetch();
      } else {
        throw new Error("Não foi possível compartilhado seu pedido neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Apenas vendedores e operadores podem solicitar esta ação",
        5: "Não foi possível compartilhar/retornar seu pedido neste momento. Tente novamente mais tarde",
        10: "Pedido indisponível, em processo de atualização ou já inserido em uma Carga ANJO SAFRA / KIT, não será mais possível alterá-lo",
        15: "Pedido não relacionado a você neste momento, por favor atualize esta página",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  };
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <Fragment>
        {!this.ds.hasFilter && (
          <SimulacoesCounter
            style={{ fontSize: 16, letterSpacing: -0.5, fontWeight: "500", marginBottom: 21 }}
            possiveis={this.props.store.isOperador ? +this.props.store.operadorData.qtdsimu_politica : +this.props.store.vendedorData.qtdsimu_politica}
            realizadas={this.props.store.isOperador ? (+this.props.store.operadorData.qtdsimu_politica) - (+this.props.store.operadorData.qtdsimu_rest_politica) : (+this.props.store.vendedorData.qtdsimu_politica) - (+this.props.store.vendedorData.qtdsimu_rest_politica)}
            restantes={this.props.store.isOperador ? +this.props.store.operadorData.qtdsimu_rest_politica : +this.props.store.vendedorData.qtdsimu_rest_politica}
          />
        )}
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          onUpdateDataSource={onUpdateDataSource}
          filterFields={{
            data_emissao$0: "date",
            data_emissao$1: "date",
          }}
          filterParser={{
            data_emissao$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
            data_emissao$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
          }}
          style={{ marginBottom: 12 }}>
          {({ form }) => (
            <Fragment>
              <FormItem style={{ float: "right", margin: 0 }}>
                <Toolbar.Button
                  disabled={dataSource.isLoading}
                  size="default"
                  icon="reload"
                  ghost={true}
                  onClick={() => dataSource.fetch()}>
                  Atualizar
                </Toolbar.Button>
              </FormItem>
              <FormItem label="Emissão da Simulação">
                {form.getFieldDecorator("data_emissao$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
              </FormItem>
              <FormItem>
                {form.getFieldDecorator("data_emissao$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
              </FormItem>
              <FormItem>
                <Button type="danger" htmlType="submit" icon="search" />
              </FormItem>
              {!!dataSource.hasFilter && (
                <FormItem>
                  <Toolbar.Button
                    size="default"
                    icon="delete"
                    ghost={true}
                    onClick={() => onUpdateDataSource(null, {}, null)}>
                    Limpar Filtros
                  </Toolbar.Button>
                </FormItem>
              )}
            </Fragment>
          )}
        </SearchForm>
      </Fragment>
    );
  };
  renderActionMenu = (text, item) => {
    const pedido = toJS(item.Pedido);
    const { store } = this.props;
    const habilitaPedido = store.habilitaPedido;
    const qtdrestantes = store.isOperador ? +store.operadorData.qtdsimu_rest_politica : +store.vendedorData.qtdsimu_rest_politica;

    const isVendedor = item.Pedido.etapa_simulacao === 'VENDEDOR' && item.Pedido.tipo_pedido === "SIMULAÇÃO";

    const menu = (
      <Menu onClick={event => this.clickActions(pedido, event)}>
        <Menu.Item key="menu-edit" disabled={!!pedido.locked}>
          <Icon type="edit" />
          Alterar Simulação
        </Menu.Item>
        {item.Pedido.tipo_pedido === "SIMULAÇÃO" && (
          <Menu.Item key="menu-enviar">
            <Icon type="right-circle" />
            Enviar ao Gerente
          </Menu.Item>
        )}
        {this.props.store.isB2B && (
          <Menu.Item key="menu-pedido" disabled={!!pedido.locked}>
            <Icon type="login" />
            Transformar em Pedido
          </Menu.Item>
        )}
        <Menu.Item key="menu-print">
          <Icon type="printer" />
          Imprimir Simulação
        </Menu.Item>
        <Menu.Item key="menu-print-sdesc">
          <Icon type="printer" />
          Imprimir sem desconto
        </Menu.Item>
        <Menu.Item key="menu-copy" disabled={qtdrestantes <= 0}>
          <Icon type="copy" />
          Copiar Simulação
        </Menu.Item>
        <Menu.Item key="menu-delete">
          <Icon type="delete" />
          Excluir Simulação
        </Menu.Item>
      </Menu>
    )

    const menuVendedor = (
      <Menu onClick={event => this.clickActions(pedido, event)}>
        <Menu.Item key="menu-print">
          <Icon type="printer" />
          Imprimir Simulação
        </Menu.Item>
        <Menu.Item key="menu-print-sdesc">
          <Icon type="printer" />
          Imprimir sem desconto
        </Menu.Item>
        <Menu.Item key="menu-copy" disabled={qtdrestantes <= 0}>
          <Icon type="copy" />
          Copiar Simulação
        </Menu.Item>
      </Menu>
    )

    return (
      <div className="actions">
        {!isVendedor ? (
          <Dropdown.Button
            onClick={this.clickActions.bind(this, pedido, { key: "menu-print" })}
            title="Pedidos fora da etapa de vendedor  não podem sofrer alterações"
            size="small"
            type="primary"
            overlay={menuVendedor}>
            <Icon type="printer" style={{ color: "#fff" }} />
            Imprimir
          </Dropdown.Button>
        ) : (
          <Dropdown.Button
            disabled={!!item.Pedido.shared || !habilitaPedido}
            onClick={this.clickActions.bind(this, pedido)}
            title="Alterar este Pedido"
            size="small"
            type="primary"
            overlay={menu}>
            <Icon type="edit" style={{ color: !!item.Pedido.shared ? "#CCC" : "#fff" }} />
            Alterar
          </Dropdown.Button>
        )}
      </div>
    );
  };

  render() {
    const { store } = this.props;

    const columns = [
      {
        title: null,
        key: "term",
        sorter: false,
        render: (_, item) =>
          !item.Pedido.shared ? (
            <StatusCircle danger={!!item.Pedido.analise_pedido && item.Pedido.analise_pedido !== "NAO"} />
          ) : null,
        className: "text-center pedido-status-column",
        width: "30px",
      },
      {
        title: "Emissão",
        dataIndex: "Pedido.data_emissao",
        dataType: "date",
        key: "data_emissao",
        sorter: true,
        width: "9.75%",
        filteredValue: this.ds.filterValue("data_emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, type: "str_date", value: filterDate(filter.value) }),
      },
      {
        title: "Filial",
        dataIndex: "Filial.nome_filial",
        key: "nome_filial",
        sorter: true,
        render: (_, item) => (
          <WrappedValue value={`${item.Pedido.codigo_filial} - ${item.Filial.nome_filial}`} type="text" />
        ),
        width: "11%",
        filteredValue: this.ds.filterValue("nome_filial"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Filial" {...props} />,
        filterParser: filter => ({ ...filter, field: "Filial.nome_filial", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Número",
        dataIndex: "Pedido.numero_pedido",
        key: "numero_pedido",
        sorter: true,
        width: "9.25%",
        filteredValue: this.ds.filterValue("numero_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Número" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.numero_pedido", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Código",
        dataIndex: "Pedido.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (_, item) => (
          <WrappedValue value={`${item.Pedido.codigo_cliente} / ${item.Pedido.loja_cliente}`} type="text" />
        ),
        width: "9.25%",
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.codigo_cliente", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Cliente",
        dataIndex: "Cliente.razao_social",
        key: "razao_social",
        sorter: true,
        render: (text, item) => (
          <WrappedValue
            value={
              <Fragment>
                {!item.Pedido.shared && (item.Pedido.vendedor_compartilhado || item.Pedido.operador_compartilhado) && (
                  <IconShared
                    codigo={item.Pedido.vendedor_compartilhado || item.Pedido.operador_compartilhado}
                    colegas={[...this.ds.state.vendedores, ...this.ds.state.operadores]}
                  />
                )}
                {item.Cliente.construtora === "S" && <IconConstrutora style={{ marginRight: 5 }} />}
                {!!item.Pedido.codigo_orcamento && <IconOrcamento bgColor="#33AF19" style={{ marginRight: 5 }} />}
                {item.Pedido.tipo_pedido === "SIMULAÇÃO" && (
                  <IconSimulacao bgColor="#e3681f" style={{ marginRight: 5 }} />
                )}
                {text}
                {store.isTipoVendaInterna && !!store.vendedoresRelacionados[item.Pedido.codigo_vendedor] && (
                  <div>
                    <strong>Vendedor:</strong> {store.vendedoresRelacionados[item.Pedido.codigo_vendedor]}
                  </div>
                )}
              </Fragment>
            }
            title={text}
            type="text"
            style={{ fontSize: 11 }}
          />
        ),
        className: "text-left",
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Cliente.razao_social", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Valor NF",
        dataIndex: "Pedido.total_nota",
        dataType: "money",
        key: "total_nota",
        sorter: true,
        width: "9%",
      },
      {
        title: "Etapa Sim.",
        dataIndex: "Pedido.etapa_simulacao",
        key: "etapa_simulacao",
        sorter: true,
        width: "8.75%",
      },
      {
        title: "Situ. Sim.",
        dataIndex: "Pedido.situacao_simulacao",
        key: "situacao_simulacao",
        sorter: true,
        width: "10.25%",
      },
      {
        title: "Ações",
        key: "actions",
        width: 134,
        render: this.renderActionMenu,
      },
    ];

    const isLoading = !!this.props.store.isLoading || !!this.ds.isLoading;

    return (
      <Fragment>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          actionBar={"none"}
          rowKey={rowKey}
          rowClassName={rowClassName}
          loading={isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas simulações no momento"
        />
        <SharePedidoModal
          ref={_ref => (this._sharePedido = _ref)}
          isLoading={isLoading}
          tipo={this.props.store.loginData.tipo}
          vendedores={this.ds.state.vendedores}
          operadores={this.ds.state.operadores}
        />
      </Fragment>
    );
  }
  _sharePedido;
}
