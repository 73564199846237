import React, { Component } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, Toolbar, TextFilter, WrappedValue } from "components/FullTable";

@observer
class TabelaPrecoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Tabelas de Preço",
      uri: "/tabela_preco",
    });

    this.state = {};
  }
  render() {
    const { history, store } = this.props;

    let columns = [
      {
        title: "Data",
        dataIndex: "Tabela.data_ult_atualizacao",
        dataType: "date",
        key: "data_ult_atualizacao",
        sorter: true,
        width: "10.25%",
      },
      {
        title: "Código",
        dataIndex: "Tabela.codigo_tabela",
        key: "codigo_tabela",
        sorter: true,
        width: "11%",
        filteredValue: this.ds.filterValue("codigo_tabela"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Título",
        dataIndex: "Tabela.titulo_tabela",
        key: "titulo_tabela",
        sorter: true,
        filteredValue: this.ds.filterValue("titulo_tabela"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Título" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Filial",
        dataIndex: "Tabela.codigo_filial",
        key: "codigo_filial",
        sorter: false,
        width: "8%",
        filteredValue: this.ds.filterValue("codigo_filial"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Filial" {...props} />,
      },
    ];

    if (!store.isB2B) {
      columns = columns.concat([
        {
          title: "Modalidade",
          dataIndex: "Tabela.modalidade_frete",
          key: "modalidade_frete",
          sorter: true,
          width: "16%",
          filteredValue: this.ds.filterValue("modalidade_frete"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Modalidade" {...props} />,
          filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
        },          
        {
          title: "Ações",
          key: "actions",
          width: 120,
          render: (text, item) => (
            <div className="actions">
              <Button
                onClick={() => store.requestPrintForm(`/tabela_preco/revenda/${item.Tabela.codigo_tabela}`)}
                icon="printer"
                size="small"
                type="primary">
                Imprimir
              </Button>
            </div>
          ),
        },
      ]);
    } else {
      if (store.isTipoVendaInterna) {
        columns = columns.concat([
          {
            title: "Vendedor",
            dataIndex: "Vendedor.razao_social",
            key: "Vendedor.razao_social",
            sorter: true,
            width: "12%",
            filteredValue: this.ds.filterValue("Vendedor.razao_social"),
            filterDropdown: props => <TextFilter placeholder="Filtrar por Vendedor" {...props} />,
            filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
          },
        ]);
      }

      columns = columns.concat([
        {
          title: "Cliente",
          dataIndex: "Tabela.codigo_cliente",
          key: "codigo_cliente",
          sorter: true,
          width: "11%",
          filteredValue: this.ds.filterValue("codigo_cliente"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Cód. Cliente" {...props} />,
          filterParser: filter => ({ ...filter, value: `%${filter.value}`, op: "LIKE" }),
        },
        {
          title: "Nome",
          dataIndex: "Cliente.razao_social",
          key: "razao_social",
          sorter: true,
          render: text => <WrappedValue value={text} type="text" style={{ fontSize: 11 }} />,
          width: "auto",
          filteredValue: this.ds.filterValue("razao_social"),
          filterDropdown: props => <TextFilter placeholder="Filtrar por Nome Cliente" {...props} />,
          filterParser: filter => ({
            ...filter,
            field: "Cliente.razao_social",
            value: `%${filter.value}%`,
            op: "LIKE",
          }),
        },
        {
          title: "Ações",
          key: "actions",
          width: 215,
          render: (text, item) => (
            <div className="actions">
              <Button
                onClick={() => history.push(`/tabela-preco/b2b/${item.Tabela.codigo_tabela}`)}
                icon="bars"
                size="small"
                type="danger">
                Produtos
              </Button>
              <Button
                onClick={() => store.requestPrintForm(`/tabela_preco/b2b/${item.Tabela.codigo_tabela}`)}
                icon="printer"
                size="small"
                type="primary">
                Imprimir
              </Button>
            </div>
          ),
        },
      ]);
    }

    return (
      <div className="tabelas-screen">
        <PageTitle title="Tabelas de Preço">
          {!!store.isB2B &&   (
            <Button
              title="Download de Todas as Tabelas de Preço"
              type="danger"
              icon="cloud-download-o"
              onClick={event => {
                event.preventDefault();
                window.open(
                  `https://intranet.anjo.com.br/docs/tabela_b2b.php?v=${btoa(store.loginData.login)}&t=${btoa(store.loginData.tipo)}`,
                  "_blank"
                );
              }}>
              Download de Todas as Tabelas de Preço
            </Button>
          )}
        </PageTitle>
        <FullTable
          className={!!store.isB2B && "full-table__compact"}
          actionBar={<Toolbar exportable={true} printable={false} />}
          rowKey={item => `${item.Tabela.codigo_tabela}:${item.Tabela.codigo_filial}`}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          restorable={true}
          errorMessage="Não foi possível obter suas tabelas no momento"
        />
      </div>
    );
  }
}

export default withStore(TabelaPrecoScreen);
