import React, { Component } from "react";
import { Col, Modal, Row } from "antd";
import { observer } from "mobx-react";

import "./ChooserModal.less";



@observer
export default class HistoricoReprovacaoModal extends Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => {
        this.props.handleClose();
    }

    render() {
        const { visible, title, width, reprovacoes } = this.props;
        return (
            <Modal
                centered
                title={title}
                visible={visible}
                onCancel={this.handleClose}
                width={800}
                okButtonProps={{ hidden: true }}
                cancelText="Fechar"
                destroyOnClose={true}
                maskClosable={false}
                wrapClassName="chooser-modal">
                {reprovacoes.map(item => (
                    <Row key={item.r_e_c_n_o_} gutter={8}>
                        <Col span={10}><h5>Responsável: {item.zyf_nome}</h5></Col>
                        <Col span={14}><h5>Observação: {item.zyf_motivo}</h5></Col>
                    </Row>
                )
                )}
                {!reprovacoes.length && <strong>Não há reprovações</strong>}
            </Modal>
        );
    }
}