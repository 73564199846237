import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import { mapColumn } from "components/FullTable";
import { Table, Button } from "antd";
import { humanFileSize } from "@util";

export default class AnexoPedido extends PureComponent {
  render() {
    const {
      className,
      anexos,
      isLoading,
      ...restProps
    } = this.props;

    const columns = [
      {
        title: "Arquivo",
        key: "nome_arquivo",
        width: '60%',
      },
      {
        title: "Tamanho",
        key: "tamanho_arquivo",
        width: '20%',
        render: (text, record) => (
          humanFileSize(text)
        )
      },
      {
        title: "Ações",
        key: "path_arquivo",
        width: '20%',
        render: (text, record) => (
          <div className="actions">
            <Button
              icon="printer"
              href={`${record.path_arquivo}`}
              target="_blank"
              size="small"
              type="primary">
              Visualizar
            </Button>
          </div>
        ),
      }
    ];
    return (
      <div className={classnames("resumo-cliente", className)} {...restProps}>
        <Table
          pagination={false}
          loading={!!isLoading}
          columns={columns.map(mapColumn)}
          dataSource={anexos}
          size={200}
        />
      </div>
    );
  }
}
