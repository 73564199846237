import React, { Component, Fragment } from "react";
import { Alert, Row, Col, Form, Input, Button, Icon } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";

import { getData, n } from "@util";

import InputSearch from "components/InputSearch";
import Select, { Option } from "components/Select";
import PedidoResumo from "components/pedidos/PedidoResumo";
import VerbaBonResumo from "components/pedidos/VerbaBonResumo";

const FormItem = Form.Item;

const formItemLayout = {};

const PedidoSubTitle = styled.h4`
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin-bottom: 16px;
`;

const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;

  > strong {
    display: block;
  }
`;

const Item = ({ label = null, children, ...props }) => (
  <ItemWrapper {...props}>
    {!!label && <strong>{`${label}:`}</strong>}
    {children}
  </ItemWrapper>
);

@observer
export default class StepPagto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: {},
      focused: null,
    };
  }
  componentDidMount() {
    const { pedido } = this.props;

    if (pedido.condicao_sol?.codigo_condicao === pedido.condicao_tc?.codigo_condicao && pedido.data.codigo_condicao === null && pedido.isB2B) {
      pedido.data.codigo_condicao = pedido.condicao_sol?.codigo_condicao;
      this.loadCondicao();
    }
  }
  componentWillUnmount() {
    if (!!this._requestFocus) clearTimeout(this._requestFocus);
  }
  saveInput = field => _ref => {
    this._inputs[field] = _ref;
  };
  requestFocus = field => {
    if (!!this._inputs[field]) {
      this._requestFocus = setTimeout(() => {
        try {
          this._inputs[field].focus();
        } catch (ex) { }
      }, 1000 / 60);
    }
  };
  saveCurrentValue = field => event => {
    const { current } = this.state;
    const value = typeof event === "string" ? event : event.target.value;
    this.setState({ focused: field, current: { ...current, [field]: value } });
  };
  updateItemValue = (field, onChange = null, tr = v => v) => event => {
    const { data } = this.props.pedido;
    data[field] = tr(typeof event === "string" ? event : event.target.value);
    if (field == "cupom" && data[field].length < 6) {
      data.cupom_valido = false;
    }
    else if (field == "pedido_originalb2b" && data[field].length < 6) {
      data.pedido_originalb2b_valido = false;
    }
    if (!!onChange && typeof onChange === "function") {
      onChange(field, toJS(data[field]));
    }
  };
  hasChanged = field => {
    const { current } = this.state;
    const { data } = this.props.pedido;

    return !current[field] || `${current[field]}` !== `${toJS(data[field])}`;
  };
  afterChanged = async (field, onChanged, nextFocus = null) => {
    if (this.hasChanged(field) && !!onChanged && typeof onChanged === "function") {
      await onChanged(field);
    }
    if (!!nextFocus) {
      this.requestFocus(nextFocus);
    }
  };
  afterChangedOriginal = async (field, onChanged, nextFocus = null) => {
    if (!!onChanged && typeof onChanged === "function") {
      await onChanged(field);
    }
    if (!!nextFocus) {
      this.requestFocus(nextFocus);
    }
  };
  loadCondicao = async () => {
    try {
      await this.props.pedido.loadCondicao();
    } catch (err) {
      this.props.onPedidoError(`Condição de pagto inválida ou não permitida para este pedido!`);
    }
  };
  loadCupom = async () => {
    const { pedido, onPedidoError } = this.props;
    try {
      if (!pedido.isLoading) {
        await pedido.loadCupom();
        this.setState({ current: { ...this.state.current, cupom: pedido.data.cupom } });
      }
    } catch (err) {
      this.props.onPedidoError(err.code == 0 ? err.message : `Cupom não encontrado!`);
    }
  };

  loadCashback = async () => {
    const { pedido, onPedidoError } = this.props;
    try {
      if (!pedido.isLoading) {
        await pedido.loadCashback();
        this.setState({ current: { ...this.state.current, resgate_cashback: pedido.data.resgate_cashback } });
      }
    } catch (err) {
      this.props.onPedidoError(err.code == 0 ? err.message : `Cliente sem saldo de cashback!`);
    }
  };

  revogaCashback = async () => {
    const { pedido, onPedidoError } = this.props;
    try {
      if (!pedido.isLoading) {
        await pedido.revogaCashback();
        this.setState({ current: { ...this.state.current, resgate_cashback: pedido.data.resgate_cashback } });
      }
    } catch (err) {
      this.props.onPedidoError(err.code == 0 ? err.message : `Cashback não pode ser cancelado!`);
    }
  };

  loadPedidoOriginal = async () => {
    const { pedido, onPedidoError } = this.props;
    try {
      if (!pedido.isLoading) {
        await pedido.loadPedidoOriginal();
        this.setState({ current: { ...this.state.current, pedido_originalb2b: pedido.data.pedido_originalb2b } });
      }
    } catch (err) {
      this.props.onPedidoError(err.code == 0 ? err.message : `Pedido original não encontrado!`);
    }
  };
  getInputProps = (field, customProps = {}) => {
    const { data } = this.props.pedido;
    const { onChange = null, ...extraProps } = customProps;

    return {
      ref: this.saveInput(field),
      name: field,
      value: data[field],
      onFocus: this.saveCurrentValue(field),
      onChange: this.updateItemValue(field, onChange),
      ...extraProps,
    };
  };
  renderAlerts({ pedido, data, pagtoStatus, valorTotalNf }) {
    const _alerts = [];
    const total_bonificado = pedido.total_bonificado;
    //utilizo o tratamento abaixo para diminuir do total utilizado o valor bonificado no pedido atual para nao considerar duplicacaoes em valor bonificado...
    //const verba_aux = pedido.data.verba_utilizado > 0 ? pedido.data.verba_utilizado - total_bonificado : pedido.data.verba_utilizado;
    //-------------------------------------------------------------------------------------------------------------------------------------------------------
    const verba_utilizado = pedido.data.verba_utilizado; //verba_aux < 0 ? 0 : verba_aux;
    const saldo_bonificado = pedido.data.verba_valor - (verba_utilizado + total_bonificado);

    if (pagtoStatus && pedido.hasBrinde && pedido.data.filial_faturamento !== "01") {
      _alerts.push(
        <Alert
          key="a0"
          message={
            <Fragment>
              <strong>Atenção:</strong> Não é possível adicionar itens do tipo brinde em pedidos com faturamento em
              filiais que não sejam a Matriz (01)
            </Fragment>
          }
          type="error"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }
    if (pedido.isB2B && pedido.isComplementar && pedido.peso_bruto_total > 200) {
      _alerts.push(
        <Alert
          message={
            <Fragment>
              Esse pedido se trata de um pedido complementar e não pode ser superior a 200Kg.
            </Fragment>
          }
          type="error"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }

    if (!!pedido.valida_minimo && pagtoStatus && +pedido.precoMinimo > 0 && valorTotalNf < +pedido.precoMinimo && !pedido.data.isValidKit) {
      _alerts.push(
        <Alert
          key="a1"
          message={
            <Fragment>
              <strong>Atenção:</strong> O pedido não ultrapassou o valor mínimo de{" "}
              <strong>R$ {n(pedido.precoMinimo)}</strong>
            </Fragment>
          }
          type="error"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }
    if (!!pedido.data.verba_codigo && pedido.isPedidoNormal && saldo_bonificado < 0) {
      _alerts.push(
        <Alert
          key="a1"
          message={
            <Fragment>
              <strong>Atenção:</strong> Verba insuficiente para os itens bonificados {" "}
            </Fragment>
          }
          type="error"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }

    if (
      !!pedido.valida_minimo &&
      pagtoStatus &&
      !!data.codigo_condicao &&
      +pedido.data.qtd_parcelas > 1 &&
      +pedido.valor_parcela > 0 &&
      +pedido.valor_parcela < +pedido.parcela_minima
    ) {
      _alerts.push(
        <Alert
          key="a2"
          message={
            <Fragment>
              <strong>Atenção:</strong> O valor de parcela em relação ao total de mercadorias não atingiu o mínimo de{" "}
              <strong>R$ {n(+pedido.parcela_minima)}</strong>
            </Fragment>
          }
          type="warning"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }
    if (data.tipo_frete === "F" && (!data.codigo_transp || !data.nome_transp)) {
      _alerts.push(
        <Alert
          key="a3"
          message={
            !!pedido.valida_minimo &&
              !pedido.permite_fob &&
              valorTotalNf >= +pedido.precoMinimo &&
              valorTotalNf < 1500 ? (
              <Fragment>
                <strong>Atenção:</strong> Para pedidos com o valor total abaixo de <strong>R$ 1.500,00</strong> é
                obrigatório informar uma transportadora <strong>FOB de despacho</strong>.
              </Fragment>
            ) : (
              <Fragment>
                <strong>Atenção:</strong> Neste pedido é obrigatório informar uma transportadora de{" "}
                <strong>despacho</strong>.
              </Fragment>
            )
          }
          type="error"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }
    if (data.tipo_frete !== "F" && data.redesp_tipo === "F" && (!data.redesp_transp || !data.redesp_nome_transp)) {
      _alerts.push(
        <Alert
          key="a4"
          message={
            <Fragment>
              <strong>Atenção:</strong> Neste pedido é obrigatório informar uma transportadora de{" "}
              <strong>redespacho</strong>.
            </Fragment>
          }
          type="error"
          showIcon
          style={{ margin: "0 0 16px" }}
        />
      );
    }
    return _alerts;
  }
  render() {
    const { chooser, options, pedido } = this.props;
    const { data } = pedido;
    const isLoading = pedido.isLoading || this.props.store.isLoading;

    const hasCondicao = !!data.codigo_condicao;
    const pagtoStatus = data.status_pedido === "1" || data.status_pedido === "9" || data.status_pedido === "A";
    const valorTotalNf = Math.max(+pedido.valor_total_nf, 0);
    const valorAcresDecresc = Math.abs(pedido.valor_total_sem_taxa - pedido.valor_total);
    const isDiretorInUse = pedido.isDiretorInUse;
    const inputProps = {
      readOnly: isLoading,
      disabled: !pedido.hasCliente,
    };

    return (
      <div className="pedido-step">
        {this.renderAlerts({ pedido, data, pagtoStatus, valorTotalNf })}
        <Form noValidate layout="vertical">
          <Row gutter={12}>
            <Col span={8}>
              <FormItem {...formItemLayout} required={true} label="Forma Pagto">
                <Select
                  {...inputProps}
                  ref={this.saveInput("forma_pagto")}
                  value={data.forma_pagto}
                  onSelect={value => {
                    data.forma_pagto = value;
                    this.requestFocus("codigo_condicao");
                  }}>
                  {Object.keys(options.formaPagto).map(k => (
                    <Option key={k} value={k}>
                      {options.formaPagto[k]}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={4}>
              <FormItem {...formItemLayout} required={true} label="Condição de Pagto">
                <InputSearch
                  {...inputProps}
                  disabled={inputProps.disabled || !pedido.codigo_condicao_enabled}
                  ref={this.saveInput("codigo_condicao")}
                  value={data.codigo_condicao}
                  onFocus={this.saveCurrentValue("codigo_condicao")}
                  onChange={this.updateItemValue("codigo_condicao")}
                  onBlur={() => this.afterChanged("codigo_condicao", this.loadCondicao)}
                  onSearch={value => chooser("condicao", () => this._inputs.codigo_condicao.blur())}
                  onPressEnter={event => {
                    if (event.target.value.trim() === "") {
                      chooser("condicao", () => this._inputs.codigo_condicao.blur());
                    } else {
                      this._inputs.codigo_condicao.blur();
                    }
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="Descrição Condição">
                <Input {...inputProps} value={data.nome_condicao} readOnly={true} disabled={!hasCondicao} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            {(!pedido.isB2B && ((data.status_pedido === "6" && pedido.hasBrinde) || pedido.isAmostra)) && (
              <Col span={8}>
                <FormItem {...formItemLayout} required={isDiretorInUse} label="Cupom">
                  <Input
                    {...this.getInputProps("cupom", inputProps)}
                    //{...inputProps}
                    disabled={inputProps.disabled || !isDiretorInUse}
                    ref={this.saveInput("cupom")}
                    value={data.cupom}
                    onFocus={this.saveCurrentValue("cupom")}
                    onChange={this.updateItemValue("cupom")}
                    onBlur={isDiretorInUse ? () => this.afterChanged("cupom", this.loadCupom) : null}
                    onPressEnter={event => {
                      if (event.target.value.trim() === "") {
                        chooser("cupom", () => this._inputs.cupom.blur());
                      } else {
                        this._inputs.cupom.blur();
                      }
                    }}
                    maxLength="6"
                  />
                </FormItem>
              </Col>
            )}
            {(!pedido.isB2B && !!data.cupom_valido && ((data.status_pedido === "6" && pedido.hasBrinde) || pedido.isAmostra)) && (
              <Fragment>
                <Col span={3} style={{ padding: 12 }}>
                  <Item label="Valor Cupom">{`R$ ` + n(data.cupom_valor, 2)}</Item>
                </Col>
                <Col span={4} style={{ padding: 12 }}>
                  <Item label="Valor Pedido">{`R$ ` + n(valorTotalNf, 2)}</Item>
                </Col>
                <Col span={4} style={{ padding: 12 }}>
                  <Item label="Valor já Utilizado">{`R$ ` + n(data.cupom_utilizado, 2)}</Item>
                </Col>
                <Col span={4} style={{ padding: 12 }}>
                  <Item label="Saldo">
                    <Button
                      disabled={false}
                      title="saldo"
                      size="large"
                      type="default"
                      style={(data.cupom_valor - data.cupom_utilizado - valorTotalNf) <= 0
                        ? { fontSize: 12, height: 23, letterSpacing: -0.5, background: "#AC1A22", borderColor: "white", color: "white" }
                        : { fontSize: 12, height: 23, letterSpacing: -0.5, background: "green", borderColor: "white", color: "white" }
                      }>
                      R$ {n(data.cupom_valor - data.cupom_utilizado - valorTotalNf, 2)}
                    </Button>
                  </Item>
                </Col>
              </Fragment>
            )}
          </Row>

          {!pedido.isB2B && !!pedido.isPedidoNormal && (
            <Row gutter={24}>
              <Col span={8}>
                <FormItem {...formItemLayout} label="Resgatar Cashback" style={{ margin: 8 }}>
                  <Button
                    size="large"
                    type={data.valor_resgate_cashback > 0 ? "danger" : "default"}
                    style={{ width: "100%" }}
                    onClick={() => data.valor_resgate_cashback > 0 ? this.revogaCashback() : this.loadCashback()}>
                    {data.valor_resgate_cashback > 0 ? 'Clique aqui para cancelar o Cashback' : 'Clique aqui para aplicar o Cashback'}
                  </Button>
                </FormItem>
              </Col>
              {data.valor_resgate_cashback > 0 && (
                <Fragment>
                  <Col span={4}>
                    <FormItem {...formItemLayout} label="Saldo Cashback Cliente" style={{ margin: 8 }}>
                      <Button
                        type="ghost"
                        size="large"
                        style={{ width: "100%", padding: 5 }}
                      >
                        R$ {n(data.saldo_resgate_cashback, 2)}
                      </Button>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem {...formItemLayout} label="% Máximo de resgate" style={{ margin: 8 }}>
                      <Button
                        type="default"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        {n(data.percentual_resgate_cashback, 2)} %
                      </Button>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem {...formItemLayout} label="Desconto na Nota Fiscal" style={{ margin: 8 }}>
                      <Button
                        type="primary"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        <Icon type="swap" style={{ marginRight: 5, fontWeight: "bold" }} />
                        R$ {n(data.valor_resgate_cashback, 2)}
                      </Button>
                    </FormItem>
                  </Col>
                </Fragment>
              )}
            </Row>
          )}
          {pedido.isB2B && data.status_pedido === "C" && (
            <Row gutter={12}>
              <Col span={6}>
                <FormItem {...formItemLayout} required={isDiretorInUse} label="Pedido Original">
                  <Input
                    {...this.getInputProps("pedido_originalb2b", inputProps)}
                    //{...inputProps}
                    disabled={inputProps.disabled}
                    ref={this.saveInput("pedido_originalb2b")}
                    value={data.pedido_originalb2b}
                    onFocus={this.saveCurrentValue("pedido_originalb2b")}
                    onChange={this.updateItemValue("pedido_originalb2b")}
                    onBlur={() => this.afterChangedOriginal("pedido_originalb2b", this.loadPedidoOriginal)}
                    onPressEnter={event => {
                      this._inputs.pedido_originalb2b.blur();
                    }}
                    maxLength="6"
                  />
                </FormItem>
              </Col>
              {data.pedido_originalb2b_valido && pedido.peso_bruto_total <= 150 && (
                <Col span={15}>
                  <Alert
                    message={
                      <Fragment>
                        <strong>Sucesso !</strong> Pedido validado ! O peso do pedido Original somado a este pedido é de
                        <strong>{" "}
                          {n(pedido.peso_bruto_total + data.pedido_originalb2b_peso)}
                        </strong>{" "}
                        Kg.
                      </Fragment>
                    }
                    type="success"
                    showIcon
                    style={{ margin: "15px 3px 0" }}
                  />
                </Col>
              )}
            </Row>
          )}
          {!!hasCondicao && pedido.condicaoTaxa !== 0 && valorAcresDecresc !== 0 && (
            <Row gutter={12}>
              <Col span={24}>
                {pedido.condicaoTaxa < 0 ? (
                  <Alert
                    message={
                      <Fragment>
                        <strong>Atenção:</strong> Prazo médio selecionado maior que o acordado para o cliente, será
                        considerado um acréscimo de{" "}
                        <strong>
                          {n(Math.abs(pedido.condicaoTaxa))}% (R$ {n(valorAcresDecresc)})
                        </strong>{" "}
                        em seu pedido. Favor rever os itens do pedido para visualizar o acréscimo e passar a informação
                        ao cliente.
                      </Fragment>
                    }
                    type="warning"
                    showIcon
                  />
                ) : (
                  <Alert
                    message={
                      <Fragment>
                        Prazo médio selecionado menor que o acordado para o cliente, será considerado um desconto de{" "}
                        <strong>
                          {n(Math.abs(pedido.condicaoTaxa))}% (R$ {n(valorAcresDecresc)})
                        </strong>{" "}
                        em seu pedido. Favor rever os itens do pedido para visualizar o desconto e passar a informação
                        ao cliente.
                      </Fragment>
                    }
                    type="success"
                    showIcon
                  />
                )}
              </Col>
            </Row>
          )}
          {(!!pedido.data.verba_codigo) && pedido.isPedidoNormal && (
            <Row gutter={12} style={{ marginTop: 12 }}>
              <Col span={24}>
                <PedidoSubTitle>Resumo Verba Bonificação</PedidoSubTitle>
                <VerbaBonResumo pedido={pedido} options={options} />
              </Col>
            </Row>
          )}
          <Row gutter={12} style={{ marginTop: 12 }}>
            <Col span={24}>
              <PedidoSubTitle>Resumo do Pedido</PedidoSubTitle>
              <PedidoResumo pedido={pedido} options={options} />
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
  _inputs = {};
}
