import { fetchApi, fetchPostApi } from "actions";

export const fetchBase = async (tipo = "kit", codigo_carga = null, ...args) => {
  const { data: response } = await fetchApi(
    `/pedidos/cargas/base/${tipo}${!!codigo_carga ? "/" + codigo_carga : ""}`,
    ...args
  );
  return response;
};

export const fetchCapacidade = async (carga, ...args) => {
  const { tipo = "kit", ...formData } = carga;
  const { data: response } = await fetchPostApi(`/pedidos/cargas/capacidade/${tipo}`, formData, ...args);
  return response;
};

export const fetchEdit = async (codigo, ...args) => {
  const { data: response } = await fetchApi(`/pedidos/cargas/edit/${codigo}`, ...args);
  return response;
};

export const saveCarga = async (tipo = "kit", formData, ...args) => {
  const { data: response } = await fetchPostApi(`/pedidos/cargas/save/${tipo}`, formData, ...args);
  return response;
};
