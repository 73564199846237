export const genRanHex = size => '#'+[...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');


export function stringToColor(str) {
    // Função de hash simples para converter uma string em um número
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 4) - hash);
    }

    // Converter o número em componentes de cor
    let r = (hash & 0xFF0000) >> 16; // Componente de vermelho
    let g = (hash & 0x00FF00) >> 8;  // Componente de verde
    let b = hash & 0x0000FF;         // Componente de azul

    // Retorna a cor no formato hexadecimal
    return `rgb(${r}, ${g}, ${b})`;
}