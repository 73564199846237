import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { lighten } from "polished";
import _isEqual from "lodash/isEqual";

import { fetchTickets } from "actions/dashboard";
import { PageTitle } from "./Styled";

const DashboardTicketsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0 30px;
`;

const TicketWrapper = styled.div`
  text-align: center;
  background-color: ${lighten(0.05, "#312675")};
  -webkit-box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.2);
  min-width: 186px;
  color: #fff;
  border-radius: 8px;
  padding: 10px 12px;
  cursor: default;

  > h4 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: #fff;
    margin: 0;
  }

  & + & {
    margin-left: 3.25%;
  }
`;

const TicketValue = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  margin: 6px 0;

  > small {
    font-size: 16px;
    vertical-align: top;
  }
`;

const Ticket = ({ title, label, value, ...props }) => (
  <TicketWrapper>
    <h4 title={title || label}>{label}</h4>
    <TicketValue title={title || label}>{value}</TicketValue>
  </TicketWrapper>
);

export default class TicketsPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchTickets(!!this.props.isTipoVendaInterna ? this.props.relacionadoData.codigo : null);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data } = this.state;

    return (
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 160 }}>
          <PageTitle title="Clientes" meta={false} />
          {isLoading ? (
            <p>
              <strong>Carregando...</strong>
            </p>
          ) : !!data && !!data.tickets ? (
            <DashboardTicketsWrapper>
              <Ticket title="Total de Clientes Cadastrados" label="Cadastrados" value={data.tickets.clientes_total} />
              <Ticket
                title="Clientes Ativos nos últimos 6 meses"
                label={
                  <Fragment>
                    Ativos <em>(Ult. 6 meses)</em>
                  </Fragment>
                }
                value={data.tickets.clientes_ativos_ult_6meses}
              />
              <Ticket title="Clientes Atendidos" label="Atendidos" value={data.tickets.clientes_atendidos} />
              <Ticket
                label="Prazo Médio"
                value={
                  <Fragment>
                    {data.tickets.prazo_medio} <small>dias</small>
                  </Fragment>
                }
              />
            </DashboardTicketsWrapper>
          ) : (
            <p>
              <strong>Dados insuficientes para mostrar todas as suas informações.</strong>
            </p>
          )}
        </div>
      </Spin>
    );
  }
}
