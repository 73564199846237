import React, { Component } from "react";
import { observer } from "mobx-react";
import { Tabs } from "antd";

import withStore from "withStore";

import TabPedidos from "./TabPedidos";
import TabSimulacoes from "./TabSimulacoes";
import TabMonitorOff from "./TabMonitorOff";
import TabResiduo from "./TabResiduo";
import TabCargas from "./TabCargas";

import "./PedidosScreen.less";

const TabPane = Tabs.TabPane;

@observer
class PedidosScreen extends Component {
  constructor(props) {
    super(props);

    const { store } = props;

    this.pedidosTabs = [
      { title: "Orçamento", key: "tab-orcamento", component: TabPedidos },
      { title: "Monitor Off-line", key: "tab-monit-off", component: TabMonitorOff },
      { title: "Resíduo", key: "tab-residuo", component: TabResiduo, disabled: false /*!store.isB2B*/, },
      {
        title: "Simulações",
        key: "tab-simulacao",
        component: TabSimulacoes,
        disabled: store.isB2B,
      },
      {
        title: "Anjo Safra",
        key: "tab-safra",
        component: props => <TabCargas {...props} tipo="safra" />,
        disabled: store.isB2B,
      },
      {
        title: "Campanha Kit",
        key: "tab-camp-kit",
        component: props => <TabCargas {...props} tipo="kit" />,
        disabled: store.isB2B,
      },
    ];

    this.state = {
      activeKey: store.selectedTab("/pedidos", "tab-orcamento"),
      extraContent: {},
    };
  }
  onChange = activeKey => {
    this.setState({ activeKey });
  };
  appendTabBarExtraContent(key, component) {
    this.state.extraContent[key] = null;
    this.setState({ extraContent: { ...this.state.extraContent, [key]: component } }, () => this.forceUpdate());
  }

  renderTabBarExtraContent() {
    const { activeKey, extraContent } = this.state;

    if (!!extraContent[activeKey]) {
      const Element = extraContent[activeKey];
      if (React.isValidElement(Element)) {
        return React.cloneElement(Element);
      }
    }

    return null;
  }

  render() {
    const { store, history, match } = this.props;

    return (
      <div className="pedidos-screen">
        <Tabs
          type="card"
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          tabBarExtraContent={this.renderTabBarExtraContent()}
          style={{ overflow: "inherit" }}>
          {this.pedidosTabs
            .filter(tab => !tab.disabled)
            .map(tab => (
              <TabPane tab={tab.title} key={tab.key}>
                {React.createElement(tab.component, {
                  appendTabBarExtraContent: this.appendTabBarExtraContent.bind(this, tab.key),
                  history,
                  match,
                  store,
                })}
              </TabPane>
            ))}
        </Tabs>
      </div>
    );
  }
}

export default withStore(PedidosScreen);
