import React, { Component } from 'react';
import { Button, Col, Icon, List, Row, message } from 'antd';
import { Observer } from 'mobx-react';
import ChooserClasseAuto from "components/sda/ChooserClasseAuto";
import ChooserClasseImob from "components/sda/ChooserClasseImob";
import ChooserProduto from "components/solicitacaoDesconto/ChooserProduto";
import styled from 'styled-components';

const LabelGridText = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #363636;
`;

@Observer
class StepProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStatusModalProduto: false,
      isLoading: false
    }

    this.handleRemoveProduto = this.handleRemoveProduto.bind(this);
  }

  adicionarProdutos = (value) => {
    if (this.props.promotoria.Produtos.some((produto) => produto.zy9_codprd === value.codigo)) {
      message.warning('Produto já adicionado!');
      return;
    }

    const produto = {
      zy9_codprd: value.codigo,
      zy9_codsol: this.props.promotoria?.Promotoria?.zy7_codsol,
      zy9_descpr: value.classe ?? value.descricao,
      zy9_tipo: value.classe ? "C" : "P",
      zy9_linha: value.codigo_linha
    }

    this.props.promotoria.adicionarProduto(produto);
    this.forceUpdate();
  }

  handleRemoveProduto(produto) {
    this.props.promotoria.removerProduto(produto);
    this.forceUpdate();
  }

  handleOpenModalProduto = () => {
    this.setState({ isStatusModalProduto: true });
  }

  handleCloseModalProduto = () => {
    this.setState({ isStatusModalProduto: false });
  }

  openChooser = (modal = "cliente", onChoose = null) => {
    const _onChoose = async (...args) => {
      if (!!onChoose && typeof onChoose === "function") {
        await onChoose(...args);
      }
    };

    switch (modal) {
      case "classeAuto":
        this._choosers.classeAuto.open(_onChoose);
        break;
      case "classeImob":
        this._choosers.classeImob.open(_onChoose);
        break;
      case "produto":
        this._choosers.produto.open(_onChoose);
        break;
      default:
        console.warn("Chooser modal not found");
        break;
    }
  };

  render() {
    const { isLoading } = this.state

    return (
      <div>
        <Row>
          <h4>Adicionar mais produtos</h4>
          <Col span={16} style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Button.Group size="small" style={{ marginBottom: 16 }}>
              <Button
                onClick={event => {
                  event.preventDefault();
                  this.openChooser("produto", this.adicionarProdutos);
                }}
                type="danger"
                icon="plus"
                disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
                style={{ padding: "3px 7px", height: 30, margin: 2 }}>
                Adicionar Produto
              </Button>
              <Button
                onClick={event => {
                  event.preventDefault();
                  this.openChooser("classeAuto", this.adicionarProdutos);
                }}
                type="danger"
                icon="plus"
                disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
                style={{ padding: "3px 7px", height: 30, margin: 2 }}>
                Adicionar Classe Automotiva
              </Button>
              <Button
                onClick={event => {
                  event.preventDefault();
                  this.openChooser("classeImob", this.adicionarProdutos);
                }}
                disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
                type="danger"
                icon="plus"
                style={{ padding: "3px 7px", height: 30, margin: 2 }}>
                Adicionar Classe Imobiliária
              </Button>
            </Button.Group>
          </Col>
        </Row>
        <List
          header={<div>Produtos</div>}
          bordered
          dataSource={this.props.promotoria.Produtos}
          renderItem={item => (
            <List.Item>
              <Col span={1}>
                <Button
                  type="danger"
                  ghost
                  disabled={this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
                  onClick={() => this.handleRemoveProduto(item)}
                  style={{
                    width: "calc(5% - 4px)",
                    minWidth: 20,
                    height: 20,
                    padding: "3px 8px",
                    display: 'flex', // Tornar o container um flex container
                    justifyContent: 'center', // Centralizar horizontalmente
                    alignItems: 'center' // Centralizar verticalmente
                  }}
                >
                  <Icon type="close" />
                </Button>
              </Col>
              <Col span={4}>
                <LabelGridText>Código: {item.zy9_codprd}</LabelGridText>
              </Col>
              <Col span={4}>
                <LabelGridText>Código Solicitação: {item.zy9_codsol}</LabelGridText>
              </Col>
              <Col span={4}>
                <LabelGridText>Descrição: {item.zy9_descpr}</LabelGridText>
              </Col>
              <Col span={4}>
                <LabelGridText>Linha: {item.zy9_linha === "01" ? "AUTOMOTIVA" : item.zy9_linha === "02" ? "IMOBILIÁRIA" : "PRODUTO COM CLASSE"}</LabelGridText>
              </Col>
            </List.Item>
          )}
        />
        <ChooserClasseAuto ref={_ref => (this._choosers["classeAuto"] = _ref)} loading={isLoading} cliente={this.props?.promotoria?.Promotoria?.zy7_codcli + '-' + this.props?.promotoria?.Promotoria?.zy7_lojcli} />
        <ChooserClasseImob ref={_ref => (this._choosers["classeImob"] = _ref)} loading={isLoading} cliente={this.props?.promotoria?.Promotoria?.zy7_codcli + '-' + this.props?.promotoria?.Promotoria?.zy7_lojcli} />
        <ChooserProduto ref={_ref => (this._choosers["produto"] = _ref)} loading={isLoading} cliente={this.props?.promotoria?.Promotoria?.zy7_codcli + '-' + this.props?.promotoria?.Promotoria?.zy7_lojcli} />
      </div>
    );
  }
  _choosers = {}
}

export default StepProdutos;
