import React, { Component, PureComponent } from "react";
import { observer } from "mobx-react";
import withStore from "withStore";
import { fetchPostApi } from "actions";
import { Button, Icon, Modal, Progress, Upload } from "antd";
import styled from "styled-components";
import PageTitle from "components/PageTitle";
import * as XLSX from 'xlsx';

const FileItem = styled.div`
  text-align: center;
  padding-top: 1em;
  padding-bottom: 2.5em;
`;

class FileInput extends PureComponent {
  render() {
    const { isLoading = false, disabled = false, ...props } = this.props;

    return (
      <Upload disabled={disabled} beforeUpload={file => false} multiple={false} action={null} {...props}>
        <Button loading={isLoading}>
          {!disabled && <Icon type="upload" />} Selecionar arquivo (.csv)
        </Button>
      </Upload>
    );
  }
}

@observer
class ImportacaoScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      progress: 0,
      totalRegistros: 0,
      registrosProcessados: 0
    };
  }

  importarDados = async (payload) => {
    try {
      this.setState(prevState => ({
        registrosProcessados: prevState.registrosProcessados + 1,
        progress: parseFloat(((prevState.registrosProcessados + 1) / prevState.totalRegistros * 100).toFixed(2))
      }));

      await fetchPostApi(`/maquinas-tintometricas/importar-dados`, payload);
    } catch (error) {
      console.error("Erro ao importar dados:", error);
    }
  }

  changeFile = async ({ file }) => {
    const allowedExtensions = [".csv", ".xlsx", ".xls"];
    const fileExtension = file.name.split('.').pop();

    if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
      return Modal.error({
        title: "Importação",
        content: "Arquivo não permitido, esses são os arquivos permitidos: csv, xlsx e xls"
      });
    }

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0].trim();
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      this.setState({
        totalRegistros: parsedData.length,
        registrosProcessados: 0,
        isLoading: true
      });

      // Loop for-await
      for await (const item of parsedData) {
        await this.importarDados(item);
      }

      this.setState({ isLoading: false });
      
      Modal.success({
        title: "Importação",
        content: "Importado com sucesso!"
      })
    };
  }

  render() {
    return (
      <div className="importacao">
        <PageTitle title="Importação de dados tintométrico" />
        <Progress percent={this.state.progress} size="small" status="active" />
        <FileItem>
          <FileInput
            disabled={this.state.isLoading}
            isLoading={this.state.isLoading}
            showUploadList={false}
            onChange={this.changeFile}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </FileItem>
      </div>
    );
  }
}

export default withStore(ImportacaoScreen);
