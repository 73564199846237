import React, { Component } from "react";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import styled from "styled-components";

import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.codigo}:${item.classe}`;

const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataTag = styled.div`
  display: inline-block;
  background-color: #c00;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

@observer
export default class ChooserClasse extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/sda/classesImob//000000-00");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        const { cliente } = this.props;
        this.ds.uri = "/sda/classesImob/" + cliente;
      },
      { delay: 60 }
    );
  }

  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Código",
        key: "codigo",
        width: "13.5%",
      },
      {
        title: "Classe",
        key: "classe",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {item.permite_gerencial === 'NAO' && <DataTag>Solicitação bloqueada</DataTag>}
          </DescricaoCell>
        ),    
      },
      {
        title: "Permite Gerencial",
        key: "permite_gerencial",
        width: "18%",
      },

    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Encontre uma classe de produto Imobiliário"
        errorMessage="Não foi possível obter as classes de produtos no momento"
        searchPlaceholder="Busque pelo nome ou código da classe"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        disabledRowKeys={(item) => {
          if(item.permite_gerencial === 'NAO'){
            return true;
          }
        }}
        {...this.props}
      />
    );
  }
}
