/**
 * @flow
 */

import { action, observable, computed, toJS } from "mobx";

import { getData } from "@util";

import { PedidoError } from "./Pedido";

const sortCargas = (ca, cb) => {
  const nda = +ca.data_entrega.split("-").join("");
  const ndb = +cb.data_entrega.split("-").join("");

  if (nda < ndb) {
    return -1;
  }

  if (nda > ndb) {
    return 1;
  }

  return 0;
};

export class CargaItem {
  @observable numero_pedido: string = null;
  @observable codigo_kit: string = null;
  @observable codigo_filial: string = "01";
  @observable codigo_cliente: string = null;
  @observable loja_cliente: string = null;
  @observable codigo_vendedor: string = null;
  @observable codigo_operador: string = null;
  @observable razao_social: string = null;
  @observable cidade: string = null;
  @observable uf: string = null;
  @observable tipo_caminhao: string = "TRUCK";
  @observable modalidade_frete: string = "RODOVIARIO";
  @observable pesopv: number = 0.0;
  @observable total_nota: number = 0.0;
  @observable vendedor_compartilhado: string = null;
  @observable operador_compartilhado: string = null;
  @observable shared: boolean = false;
  @observable shared_from: Object = null;
  @observable status: string = null;

  constructor(item) {
    if (!!item) {
      this.parse(item);
    }
  }

  parse(carga) {
    this.numero_pedido = getData(carga, "Pedido.numero_pedido");
    this.codigo_kit = getData(carga, "Pedido.codigo_kit");
    this.codigo_filial = getData(carga, "Pedido.codigo_filial") || "01";
    this.codigo_cliente = getData(carga, "Pedido.codigo_cliente");
    this.loja_cliente = getData(carga, "Pedido.loja_cliente");
    this.codigo_vendedor = getData(carga, "Pedido.codigo_vendedor");
    this.codigo_operador = getData(carga, "Pedido.codigo_operador");
    this.razao_social = getData(carga, "Cliente.razao_social");
    this.cidade = getData(carga, "Cliente.cidade");
    this.uf = getData(carga, "Cliente.uf");
    this.tipo_caminhao = getData(carga, "Pedido.tipo_caminhao") || "TRUCK";
    this.modalidade_logistica = getData(carga, "Pedido.modalidade_frete") || "RODOVIARIO";
    this.pesopv = +getData(carga, "Pedido.pesopv") || 0;
    this.total_nota = +getData(carga, "Pedido.total_nota") || 0;
    this.vendedor_compartilhado = getData(carga, "Pedido.vendedor_compartilhado");
    this.operador_compartilhado = getData(carga, "Pedido.operador_compartilhado");
    this.shared = !!getData(carga, "Pedido.shared");
    this.shared_from = getData(carga, "Pedido.shared_from");
    this.status = getData(carga, "CargaItem.status");
  }

  @computed
  get asPedido() {
    const { razao_social, cidade, uf, status, ...Pedido } = toJS(this);
    return { Pedido, Cliente: { razao_social, cidade, uf }, CargaItem: { status } };
  }

  @computed
  get asJSON() {
    return toJS(this);
  }
}

export class Carga {
  @observable sequencia_carga: number = 0;
  @observable data_entrega: string = null;
  @observable codigo_kit: string = null;
  @observable codigo_filial: string = null;

  @observable items: Array<CargaItem> = [];

  // eslint-disable-next-line
  @observable programacao: ProgramacaoCarga = null;

  constructor(
    programacao,
    { items = null, data_entrega = null, sequencia_carga = null, codigo_kit = null, codigo_filial = null }
  ) {
    this.programacao = programacao;
    if (!!codigo_kit) this.codigo_kit = codigo_kit;
    if (!!codigo_filial) this.codigo_filial = codigo_filial;
    if (!!data_entrega) this.data_entrega = data_entrega;

    if (!!items && items.length > 0) {
      this.parse(items);
    }

    if (sequencia_carga !== null) {
      this.sequencia_carga = +sequencia_carga;
    } else {
      this.sequencia_carga = this.programacao.nextSequenciaCarga();
    }
  }

  parse(items = []) {
    for (let j = 0, jc = items.length; j < jc; j++) {
      const item = new CargaItem(items[j]);

      if (!this.codigo_filial) {
        this.codigo_filial = item.codigo_filial;
      }

      if (!this.codigo_kit) {
        this.codigo_kit = item.codigo_kit;
      }

      this.items.push(item);
    }
  }

  @computed
  get uniqueClientes() {
    if (this.items.length > 0) {
      return Object.keys(
        this.items.reduce((acc, item) => {
          const key = `${item.codigo_cliente}:${item.loja_cliente}`;
          return { ...acc, [key]: key };
        }, {})
      );
    }
    return [];
  }

  @computed
  get isPesoValid() {
    if (this.items.length > 0 && !!this.tipo_caminhao && !!this.programacao.tipos[this.tipo_caminhao]) {
      const min = Math.max(+this.programacao.tipos[this.tipo_caminhao].min, 0);
      const max = Math.max(+this.programacao.tipos[this.tipo_caminhao].max, 1);

      return this.total_peso >= min && this.total_peso <= max;
    }
    return true;
  }

  @computed
  get isMaxClientesValid() {
    if (this.items.length > 0) {
      const clientesMax = Math.max(+this.programacao.validacao_clientes.maximo_clientes_carga, 1);
      const clientesCount = this.uniqueClientes.length;

      return clientesCount <= clientesMax;
    }
    return false;
  }

  @computed
  get isValid() {
    if (this.items.length > 0) {
      const codigo = this.programacao.codigo_vendedor || this.programacao.codigo_operador;
      return (
        !!this.data_entrega &&
        this.items.findIndex(item => item.codigo_vendedor === codigo || item.codigo_operador === codigo) !== -1
      );
    }
    return false;
  }

  @computed
  get tipo_caminhao() {
    if (this.items.length > 0) {
      return this.items[0].tipo_caminhao || "TRUCK";
    }
    return null;
  }

  @computed
  get modalidade_frete() {
    if (this.items.length > 0) {
      return this.items[0].modalidade_frete || "RODOVIARIO";
    }
    return null;
  }

  @computed
  get total_peso() {
    if (this.items.length > 0) {
      return this.items.reduce((m, carga) => m + carga.pesopv, 0);
    }
    return 0;
  }

  @computed
  get total_nota() {
    if (this.items.length > 0) {
      return this.items.reduce((m, carga) => m + carga.total_nota, 0);
    }
    return 0;
  }

  @action
  addItem(item: CargaItem) {
    if (!!this.tipo_caminhao && this.tipo_caminhao !== item.tipo_caminhao) {
      throw new PedidoError(`Não é possível montar carga com tipos de caminhão diferente!`);
    }

    if (!!this.modalidade_frete && this.modalidade_frete !== item.modalidade_frete) {
      throw new PedidoError(`Não é possível montar carga com modalidades de frete diferente!`);
    }

    if (!!this.codigo_filial && this.codigo_filial !== item.codigo_filial) {
      throw new PedidoError(`Código de Filial diferente do padrão da Carga!`);
    }

    if (!this.codigo_filial) {
      this.codigo_filial = item.codigo_filial;
    }

    if (!this.codigo_kit) {
      this.codigo_kit = item.codigo_kit;
    }

    this.items.push(item);
  }

  removeItem(item: CargaItem) {
    this.items = this.items.filter(
      aitem => !(aitem.numero_pedido === item.numero_pedido && aitem.codigo_filial === item.codigo_filial)
    );

    if (this.items.length === 0 && this.programacao.cargas.length === 0) {
      this.codigo_filial = 0;
      this.codigo_kit = 0;
    }
  }

  @computed
  get asJSON() {
    const { programacao, items, ...carga } = this;

    return {
      ...toJS(carga),
      items: items.map(item =>
        Object.assign(item.asJSON, toJS({ sequencia_carga: carga.sequencia_carga, data_entrega: carga.data_entrega }))
      ),
    };
  }
}

export default class ProgramacaoCarga {
  today: string = null;
  @observable isLoading: boolean = false;

  @observable tipo: string = "kit";
  @observable codigo_carga: string = null;
  @observable status_carga: string = null;
  @observable data_inclusao: string = null;
  @observable codigo_operador: string = null;
  @observable codigo_vendedor: string = null;

  @observable cargas: Array<Carga> = [];
  @observable.shallow pedidos: Array = [];

  tipos: Object = null;
  validacao_clientes: Object = null;

  constructor({ tipo = "kit", codigo_operador = null, codigo_vendedor = null, data = null, cargas = [] }) {
    this.tipo = tipo.toLowerCase();

    if (!!codigo_operador) {
      this.codigo_operador = codigo_operador;
    } else {
      this.codigo_vendedor = codigo_vendedor;
    }

    if (!!data) {
      this.parse(data, cargas);
    }
  }

  @action
  parse(data, cargas = []) {
    if (!!data.tipo_carga) {
      this.tipo = data.tipo_carga.toUpperCase() === "SAFRA" ? "safra" : "kit";
    }
    this.codigo_carga = data.codigo_carga;
    this.status_carga = data.status_carga;
    this.data_inclusao = data.data_inclusao;
    if (!!data.codigo_vendedor) this.codigo_vendedor = data.codigo_vendedor;
    if (!!data.codigo_operador) this.codigo_operador = data.codigo_operador;

    const groupedCargas = cargas.reduce((acc, item) => {
      const key = `S${item.CargaItem.sequencia_carga}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push({ ...item });
      return acc;
    }, {});

    this.cargas = Object.keys(groupedCargas).map(key => {
      return new Carga(this, {
        items: groupedCargas[key],
        data_entrega: (groupedCargas[key][0].CargaItem.data_entrega || "").split(" ")[0],
        sequencia_carga: +groupedCargas[key][0].CargaItem.sequencia_carga,
      });
    });
  }

  @action
  reset() {
    this.tipo = "kit";
    this.codigo_carga = null;
    this.status_carga = null;
    this.data_inclusao = null;
    this.codigo_operador = null;
    this.codigo_vendedor = null;
    this.cargas = [];
  }

  nextSequenciaCarga() {
    if (this.cargas.length > 0) {
      return this.cargas.reduce((m, carga) => Math.max(+carga.sequencia_carga, m), 0) + 1;
    }
    return 0;
  }

  codigoKit() {
    if (this.cargas.length > 0) {
      return toJS(this.cargas[0].codigo_kit);
    }
    return null;
  }

  codigoFilial() {
    if (this.cargas.length > 0) {
      return toJS(this.cargas[0].codigo_filial);
    }
    return null;
  }

  @computed
  get sortedCargas() {
    if (this.cargas.length > 0) {
      return this.cargas.slice().sort(sortCargas);
    }
    return [];
  }

  @computed
  get total_peso() {
    if (this.cargas.length > 0) {
      return this.cargas.reduce((m, carga) => m + carga.total_peso, 0);
    }
    return 0;
  }

  @computed
  get total_nota() {
    if (this.cargas.length > 0) {
      return this.cargas.reduce((m, carga) => m + carga.total_nota, 0);
    }
    return 0;
  }

  @computed
  get pedidosPendentes() {
    if (this.pedidos.length > 0) {
      const cargasPedidos = this.cargas.reduce((acc, carga) => {
        const pedidos = carga.items.map(item => `${item.numero_pedido}:${item.codigo_filial}`);
        return acc.concat(pedidos);
      }, []);

      return this.pedidos.filter(
        pedido =>
          !cargasPedidos.find(carga => carga === `${pedido.Pedido.numero_pedido}:${pedido.Pedido.codigo_filial}`)
      );
    }
    return [];
  }

  @computed
  get isValid() {
    return this.cargas.filter(carga => !!carga.isValid).length > 0;
  }

  @computed
  get isEdit() {
    return !!this.codigo_carga && !!this.status_carga && !!this.data_inclusao;
  }

  exportData() {
    const { tipo = "kit", codigo_carga, codigo_vendedor, codigo_operador, cargas = [] } = this;

    const Programacao = toJS({
      codigo_carga,
      codigo_filial: this.codigoFilial(),
      codigo_kit: this.codigoKit(),
      codigo_vendedor,
      codigo_operador,
    });

    const Carga = cargas.reduce((acc, carga) => acc.concat(carga.asJSON.items), []);

    return {
      tipo,
      Programacao,
      Carga,
    };
  }

  @computed
  get asJSON() {
    const { cargas, ...programacao } = this;

    return {
      ...toJS(programacao),
      cargas: cargas.map(carga => carga.asJSON),
    };
  }
}
