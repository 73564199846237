import React, { Component, Fragment } from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Spin, message } from "antd";
import { observer } from "mobx-react";
import withStore from "withStore";
import moment from "moment";
import PageHeader, { BackButton } from "components/PageHeader";
import Select, { Option } from "components/Select";
import { fetchPostApi, fetchApi } from "actions";
import InputSearch from "components/InputSearch";
import ChooserCliente from "./components/ChooserCliente";
import ChooserPromotor from "./components/ChooserPromotor";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

@observer
class SolicitacaoPromotoria extends Component {
    constructor(props) {
        super(props);
        this.disabledDate = this.disabledDate.bind(this);
        this.handlePromotor = this.handlePromotor.bind(this);
        this.handleSelectedCliente = this.handleSelectedCliente.bind(this);

        this.state = {
            current: {
            },
            isLoading: false,
            isStatusModalCliente: false,
            datasOcupadasPromotor: [],
            infoCliente: {
                emailProprietario: "",
                nomeProprietario: ""
            },
            aquarela: {
                type: 'aquarela',
                quantidade: 0,
                contem: "N"
            },
            hyperion: {
                type: 'hyperion',
                quantidade: 0,
                contem: 'N'
            },
            opcoes: [
                {
                    value: "S",
                    label: "Sim"
                },
                {
                    value: "N",
                    label: "Não"
                }
            ]
        };
    }

    buscarDatasSelecionadas = async () => {
        const { getFieldValue } = this.props.form;
        this.setState({ isLoading: true });

        const codPromotor = getFieldValue('ZY7_CODPRO');

        if (!codPromotor) return;

        try {
            const { data } = await fetchApi(`/promotoria/datas-selecionadas/${codPromotor}`);
            this.setState({ datasOcupadasPromotor: data });
        } catch (error) {
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleUpdateInfoCliente = async () => {
        if (!this.props.form.getFieldValue('ZY7_EMAILP') || !this.props.form.getFieldValue('ZY7_PROPRI')) {
            return;
        }

        if (String(this.state.infoCliente.emailProprietario).toUpperCase() !== String(this.props.form.getFieldValue('ZY7_EMAILP')).toUpperCase() || String(this.state.infoCliente.nomeProprietario).toUpperCase() !== String(this.props.form.getFieldValue('ZY7_PROPRI')).toUpperCase()) {
            Modal.confirm({
                title: "Atualizar informações",
                content: "Ao aperta sim você irá atualizar as informações nome proprietário e email proprietário do cliente selecionado",
                onOk: () => this.updateInfoCliente(),
                cancelText: "Cancelar",
                okText: "Confirmar",
            })
        }
    }

    updateInfoCliente = async () => {
        try {
            this.setState({ isLoading: true });

            const formData = {
                emailProprietario: this.props.form.getFieldValue('ZY7_EMAILP'),
                nomeProprietario: this.props.form.getFieldValue('ZY7_PROPRI'),
                codigoCliente: this.props.form.getFieldValue('ZY7_CODCLI')
            }

            await fetchPostApi('/promotoria/atualizar-dados-cliente', formData);

            this.setState((prevState) => ({
                infoCliente: {
                    ...prevState.infoCliente,
                    emailProprietario: this.props.form.getFieldValue('ZY7_EMAILP'),
                    nomeProprietario: this.props.form.getFieldValue('ZY7_PROPRI')
                }
            }));

            Modal.success({
                title: "Atualizar informações",
                content: "Informações alteradas com sucesso do cliente!",
                okText: "Confirmar",
            });
        } catch (error) {

        } finally {
            this.setState({ isLoading: false });
        }
    }


    handleOpenModalCliente = () => {
        this.setState({ isStatusModalCliente: true });
    }

    handleCloseModalCliente = () => {
        this.setState({ isStatusModalCliente: false });
    }

    disabledDate = current => {
        const { getFieldValue } = this.props.form;
        const dataInicio = getFieldValue('ZY7_DTINIV'); // Data inicial selecionada no campo do formulário
        const { datasOcupadasPromotor } = this.state;
        const dataFormat = 'YYYYMMDD';

        if (!current) {
            return false; // Retorna false se current não estiver definido
        }

        // Desabilita datas antes da data inicial selecionada
        if (dataInicio && current.isBefore(dataInicio, 'day')) {
            return true;
        }

        // Desabilita datas dentro do intervalo ocupado pelo promotor
        const isDisabledFromDatabase = datasOcupadasPromotor.some(registro => {
            const dataInicioRegistro = moment(registro.zyd_dtiniv, dataFormat);
            const dataFimRegistro = moment(registro.zyd_dtfimv, dataFormat);

            // Verifica se current é uma instância válida de moment antes de usar isBetween
            return moment.isMoment(current) && current.isBetween(dataInicioRegistro, dataFimRegistro, 'day', '[]'); // '[]' inclui os limites
        });

        return isDisabledFromDatabase;
    };


    handleSelectClient = async (value) => {
        this.setState({ isLoading: true });
        this.props.form.setFieldsValue({
            ZY7_CLIENT: "",
            ZY7_CODCLI: "",
            ZY7_LOJCLI: "",
            ZY7_FILIAL: "",
            ZY7_PERIMO: "",
            ZY7_PERAUT: "",
            ZY7_EMAILP: "",
            ZY7_PROPRI: "",
            ZY7_CODREP: "",
            ZY7_NOMREP: "",
            ZY7_GERENT: "",
            ZY7_NOMGER: "",
            ZY7_RESPON: "",
            ZY7_EMAILR: "",
            ZY7_TELRES: ""
        });

        if (String(value.cod_perfil_imob_forcado).trim()) {
            const { data } = await fetchApi(`/promotoria/buscar-perfil/${value.cod_perfil_imob_forcado}`);

            this.props.form.setFieldsValue({
                pefil_imobiliario: data.zaw_descri
            });
        } else {
            if (String(value.cod_perfil_imob).trim()) {
                const { data } = await fetchApi(`/promotoria/buscar-perfil/${value.cod_perfil_imob}`);
                this.props.form.setFieldsValue({
                    pefil_imobiliario: data.zaw_descri
                });
            }
        }

        if (String(value.cod_perfil_aut_forcado)) {
            const { data } = await fetchApi(`/promotoria/buscar-perfil/${value.cod_perfil_aut_forcado}`);
            this.props.form.setFieldsValue({
                pefil_automotivo: data.zaw_descri
            })
        } else {
            if (String(value.cod_perfil_aut)) {
                const { data } = await fetchApi(`/promotoria/buscar-perfil/${value.cod_perfil_aut}`);
                this.props.form.setFieldsValue({
                    pefil_automotivo: data.zaw_descri
                })
            }
        }

        this.props.form.setFieldsValue({
            ZY7_CLIENT: value.razao_social,
            ZY7_CODCLI: value.codigo,
            ZY7_LOJCLI: value.loja,
            ZY7_FILIAL: value.filial,
            ZY7_PERIMO: String(value.cod_perfil_imob_forcado).trim() ? value.cod_perfil_imob_forcado : value.cod_perfil_imob,
            ZY7_PERAUT: String(value.cod_perfil_aut_forcado).trim() ? value.cod_perfil_aut_forcado : value.cod_perfil_aut,
            ZY7_EMAILP: value.email_prop,
            ZY7_PROPRI: value.nome_proprietario,
            ZY7_CODREP: value.vendedor_atendimento,
            ZY7_NOMREP: value.vendendor,
            ZY7_GERENT: value.codigo_gerente,
            ZY7_NOMGER: value.nome_gerente
        });

        this.setState((prevState) => ({
            infoCliente: {
                ...prevState.infoCliente,
                emailProprietario: value.email_prop,
                nomeProprietario: value.nome_proprietario
            }
        }));
        this.setState({ isLoading: false });
    }

    enviarDados = async (value) => {
        const supervisor = this.props?.store?.isSupervisor;
        const maquinas = [];

        if (value.ZY7_MAQTIN === "S" && this.state.aquarela.quantidade === 0 && value.ZY7_MAQTIN === "S" && this.state.hyperion.quantidade === 0) {
            Modal.warning({
                title: 'Cliente possui máquinas',
                content: 'Você selecinou que o cliente possui máquinas, por favor informar pelo menos uma das máquinas'
            });
            return;
        }

        maquinas.push(this.state.aquarela);
        maquinas.push(this.state.hyperion);

        const data = {
            ...value,
            maquinas: JSON.stringify(maquinas)
        }

        try {
            this.setState({ isLoading: true });
            await fetchPostApi('/promotoria/cadastro', data);
            message.success('Promotoria cadastrada com sucesso!');
            this.props.form.resetFields();


            this.setState({
                aquarela: {
                    type: 'aquarela',
                    quantidade: 0,
                    contem: "N"
                },
                hyperion: {
                    type: 'hyperion',
                    quantidade: 0,
                    contem: 'N'
                },
            });

            this.props.history.goBack();
        } catch (error) {
            message.error("Ocorreu um erro para gerar a promotoria")
        } finally {
            this.setState({ isLoading: false });

            if (!supervisor) {
                await this.buscarDatasSelecionadas();
            }
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                await this.enviarDados(values);
            }
        });
    };

    handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9]+$/;

        if (!regex.test(keyValue)) {
            event.preventDefault();
        }
    };

    openChooser = (modal = "cliente", onChoose = null) => {
        const _onChoose = async (...args) => {
            if (!!onChoose && typeof onChoose === "function") {
                await onChoose(...args);
            }
        };

        switch (modal) {
            case "promotor":
                this._choosers.promotor.open(_onChoose);
                break;
            default:
                console.warn("Chooser modal not found");
                break;
        }
    };

    handlePromotor(value) {
        const { isSupervisor } = this.props.store;

        this.props.form.setFieldsValue({
            ZY7_PROMOT: value.nome,
            ZY7_CODPRO: value.login,
            ZY7_DTFIMV: null,
            ZY7_DTINIV: null
        });

        if (!isSupervisor) {
            this.buscarDatasSelecionadas();
        }

        this.forceUpdate();
    }

    handleSelectedCliente = async () => {
        try {
            this.setState({ isLoading: true });
            const cod_cliente = this.props.form.getFieldValue('ZY7_CODCLI');
            const { data } = await fetchApi(`/promotoria/buscar-cliente-informado/${cod_cliente}`);

            if (!data) {
                this.handleOpenModalCliente();
                Modal.info({
                    title: 'Buscar cliente',
                    content: 'Não possível localizar o cliente informado, selecione manualmente'
                });
                return;
            }

            if (String(data.cod_perfil_imob_forcado).trim()) {
                const response = await fetchApi(`/promotoria/buscar-perfil/${data.cod_perfil_imob_forcado}`);
                this.props.form.setFieldsValue({
                    pefil_imobiliario: response.data.zaw_descri
                });
            } else {
                if (String(data.cod_perfil_imob).trim()) {
                    const response = await fetchApi(`/promotoria/buscar-perfil/${data.cod_perfil_imob}`);
                    this.props.form.setFieldsValue({
                        pefil_imobiliario: response.data.zaw_descri
                    });
                }
            }

            if (String(data.cod_perfil_aut_forcado)) {
                const reponse = await fetchApi(`/promotoria/buscar-perfil/${data.cod_perfil_aut_forcado}`);
                this.props.form.setFieldsValue({
                    pefil_automotivo: reponse.data.zaw_descri
                })
            } else {
                if (String(data.cod_perfil_aut)) {
                    const response = await fetchApi(`/promotoria/buscar-perfil/${data.cod_perfil_aut}`);
                    this.props.form.setFieldsValue({
                        pefil_automotivo: response.data.zaw_descri
                    })
                }
            }

            this.props.form.setFieldsValue({
                ZY7_CLIENT: data.razao_social,
                ZY7_CODCLI: data.codigo,
                ZY7_LOJCLI: data.loja,
                ZY7_FILIAL: data.filial,
                ZY7_PERIMO: String(data.cod_perfil_imob_forcado).trim() ? data.cod_perfil_imob_forcado : data.cod_perfil_imob,
                ZY7_PERAUT: String(data.cod_perfil_aut_forcado).trim() ? data.cod_perfil_aut_forcado : data.cod_perfil_aut,
                ZY7_EMAILP: data.email_prop,
                ZY7_PROPRI: data.nome_proprietario,
                ZY7_CODREP: data.vendedor_atendimento,
                ZY7_NOMREP: data.vendendor,
                ZY7_GERENT: data.codigo_gerente,
                ZY7_NOMGER: data.nome_gerente
            });
        } catch (error) {
            Modal.error({
                title: 'Buscar cliente',
                content: 'Não foi possível buscar o cliente informado'
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleValidateDate = async (date) => {
        if (!this.props.form.getFieldValue('ZY7_PROMOT')) {
            return;
        }

        if (!this.props.form.getFieldValue('ZY7_DTINIV')) {
            return;
        }

        try {
            this.setState({ isLoading: true });
            const formData = {
                promotor: this.props.form.getFieldValue('ZY7_CODPRO'),
                data_inicio: moment(this.props.form.getFieldValue('ZY7_DTINIV')).format('YYYYMMDD'),
                data_fim: moment(date).format('YYYYMMDD')
            }

            const { data } = await fetchPostApi(`/promotoria/verificar-datas-promotor`, formData);

            if (data.isContais) {
                Modal.confirm({
                    title: 'AVISO',
                    content: 'A data escolhida já está reservada no calendário do promotor selecionado.',
                    onOk: () => { },
                    onCancel: () => {
                        this.props.form.setFieldsValue({ 'ZY7_DTINIV': null, 'ZY7_DTFIMV': null });
                    },
                    okText: 'De acordo'
                })
            }

        } catch (error) {

        }finally{
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { isLoading, isStatusModalCliente } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <PageHeader
                    title="Cadastro de promotoria"
                    headerLeft={<BackButton onClick={this.props.history.goBack} />}
                />
                <Spin spinning={isLoading} size="large">
                    <Form
                        autoComplete="off"
                        layout="vertical"
                        onSubmit={this.handleSubmit}
                        style={{ marginTop: '30px' }}
                    >
                        <Row gutter={8}>
                            <h3><strong>Cliente</strong></h3>
                            <Col span={4}>
                                <Form.Item label="Cliente">
                                    <InputSearch
                                        maxLength="14"
                                        value={this.props.form.getFieldValue('ZY7_CODCLI')}
                                        onChange={e => this.props.form.setFieldsValue({ 'ZY7_CODCLI': e.target.value })}
                                        onSearch={() => this.handleOpenModalCliente()}
                                        onPressEnter={event => {
                                            if (event.target.value.trim() !== "") {
                                                this.handleSelectedCliente();
                                            }

                                            if (event.target.value.trim() === "") {
                                                this.props.form.setFieldsValue({ 'ZY7_CODCLI': null });
                                                this.handleOpenModalCliente();
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ display: 'none' }}>
                                <Form.Item label="Código cliente" hasFeedback>
                                    {getFieldDecorator('ZY7_CODCLI', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                    })(<Input disabled maxLength="6" />)}
                                </Form.Item>
                            </Col>
                            <Col span={13}>
                                <Form.Item label="Nome cliente" hasFeedback>
                                    {getFieldDecorator('ZY7_CLIENT', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item label="Loja" hasFeedback>
                                    {getFieldDecorator('ZY7_LOJCLI', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                    })(<Input disabled maxLength="2" />)}
                                </Form.Item>
                            </Col>
                            <Col span={2} style={{ display: 'none' }}>
                                <Form.Item label="Filial" hasFeedback>
                                    {getFieldDecorator('ZY7_FILIAL')(<Input disabled maxLength="2" />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Nome do proprietário" hasFeedback>
                                    {getFieldDecorator('ZY7_PROPRI', {
                                        rules: [
                                            { required: true, message: 'Campo é obrigatório' },
                                            {
                                                pattern: /^[a-zA-Z\s]+$/,
                                                message: "Campo só aceita letras"
                                            }
                                        ],
                                    })(<Input maxLength="50" onBlur={this.handleUpdateInfoCliente} />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Email do proprietário" hasFeedback>
                                    {getFieldDecorator('ZY7_EMAILP', {
                                        rules: [{ required: false, message: 'Informe o campo obrigatório' }, {
                                            pattern: emailRegex,
                                            message: 'Por favor, insira um email válido!',
                                        }],
                                    })(<Input maxLength="100" type="email" onBlur={this.handleUpdateInfoCliente} />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Responsável do acompanhamento" hasFeedback>
                                    {getFieldDecorator('ZY7_RESPON', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                    })(<Input maxLength="50" />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Telefone do responsável " hasFeedback>
                                    {getFieldDecorator('ZY7_TELRES', {
                                        rules: [
                                            { required: true, message: 'Informe o campo obrigatório' },
                                            { min: 11, message: 'Informe um tamanho válido de número' },
                                            { max: 11, message: 'Informe um tamanho válido de número' },
                                            { pattern: /^(?!.*(\d)\1{4})[0-9]+$/, message: 'Limite de 4 números consecutivos iguais excedido.' }
                                        ],
                                    })(<Input maxLength={11} onKeyPress={this.handleKeyPress} />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Email do responsável " hasFeedback>
                                    {getFieldDecorator('ZY7_EMAILR', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }, {
                                            pattern: emailRegex,
                                            message: 'Por favor, insira um email válido!',
                                        }],
                                    })(<Input maxLength="100" type="email" />)}
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{ display: 'none' }}>
                                <Form.Item label="Identificador representante" hasFeedback>
                                    {getFieldDecorator('ZY7_CODREP', {
                                        rules: [{ required: false, message: 'Informe o campo obrigatório' }],
                                    })(<Input maxLength="6" disabled />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Nome representante" hasFeedback>
                                    {getFieldDecorator('ZY7_NOMREP', {
                                        rules: [{ required: false, message: 'Informe o campo obrigatório' }],
                                    })(<Input maxLength="50" disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{ marginTop: 20 }}>
                            <h3><strong>Gerente</strong></h3>
                            <Col span={2} style={{ display: 'none' }}>
                                <Form.Item label="Identificador" hasFeedback>
                                    {getFieldDecorator('ZY7_GERENT')(<Input disabled maxLength="6" />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Nome" hasFeedback>
                                    {getFieldDecorator('ZY7_NOMGER')(<Input disabled maxLength="50" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{ marginTop: 20 }}>
                            <Col span={10}>
                                <Row gutter={8}>
                                    <h3><strong>Perfil do cliente</strong></h3>
                                    <Col span={10}>
                                        <Form.Item label="Imobiliário" hasFeedback style={{ display: 'none' }}>
                                            {getFieldDecorator('ZY7_PERIMO')(<Input disabled maxLength="20" />)}
                                        </Form.Item>
                                        <Form.Item label="Imobiliário" hasFeedback>
                                            {getFieldDecorator('pefil_imobiliario')(<Input disabled maxLength="20" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item label="Automotivo" hasFeedback style={{ display: 'none' }}>
                                            {getFieldDecorator('ZY7_PERAUT')(<Input disabled maxLength="20" />)}
                                        </Form.Item>
                                        <Form.Item label="Automotivo" hasFeedback>
                                            {getFieldDecorator('pefil_automotivo')(<Input disabled maxLength="20" />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{ marginTop: 20 }}>
                            <Col span={14}>
                                <Row gutter={8}>
                                    <h3><strong>Máquinas</strong></h3>
                                    <Col span={6}>
                                        <Form.Item label="Possui máquinas" hasFeedback>
                                            {getFieldDecorator('ZY7_MAQTIN', {
                                                rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                            })(
                                                <Select>
                                                    <Option value="S">Sim</Option>
                                                    <Option value="N">Não</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {this.props?.form?.getFieldValue('ZY7_MAQTIN') === 'S' &&
                                        <Fragment>
                                            <Col span={6} style={{ marginRight: 10 }}>
                                                <Form.Item label="Aquarela">
                                                    <Select
                                                        value={this.state.aquarela.contem}
                                                        onChange={(value) => {
                                                            if (value === "N") {
                                                                this.setState(prevState => ({
                                                                    aquarela: { ...prevState.aquarela, contem: value, quantidade: 0 }
                                                                }));
                                                                return;
                                                            }

                                                            this.setState(prevState => ({
                                                                aquarela: { ...prevState.aquarela, contem: value }
                                                            }));
                                                        }}
                                                    >
                                                        {this.state.opcoes.map(item => (
                                                            <Option value={item.value} key={item.value}>{item.label}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label="Quantidade">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        value={this.state.aquarela.quantidade}
                                                        disabled={this.state.aquarela.contem !== "S"}
                                                        onChange={(event) => {
                                                            this.setState(prevState => ({
                                                                aquarela: { ...prevState.aquarela, quantidade: event.target.value }
                                                            }));
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} style={{ marginLeft: 10 }}>
                                                <Form.Item label="Hyperion">
                                                    <Select
                                                        value={this.state.hyperion.contem}
                                                        onChange={(value) => {
                                                            if (value === "N") {
                                                                this.setState(prevState => ({
                                                                    hyperion: { ...prevState.hyperion, contem: value, quantidade: 0 }
                                                                }));
                                                                return;
                                                            }

                                                            this.setState(prevState => ({
                                                                hyperion: { ...prevState.hyperion, contem: value }
                                                            }));
                                                        }}
                                                    >
                                                        {this.state.opcoes.map(item => (
                                                            <Option value={item.value} key={item.value}>{item.label}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label="Quantidade">
                                                    <Input
                                                        type="number"
                                                        min={0}
                                                        value={this.state.hyperion.quantidade}
                                                        disabled={this.state.hyperion.contem !== "S"}
                                                        onChange={(event) => {
                                                            this.setState(prevState => ({
                                                                hyperion: { ...prevState.hyperion, quantidade: event.target.value }
                                                            }));
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Fragment>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{ marginTop: 20 }}>
                            <Col span={4}>
                                <Form.Item label="Selecione promotor">
                                    <Button
                                        style={{ width: '100%' }}
                                        type='danger'
                                        ghost
                                        onClick={event => {
                                            event.preventDefault();
                                            this.openChooser("promotor", this.handlePromotor);
                                        }}
                                    >
                                        Selecionar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={2} style={{ display: 'none' }}>
                                <Form.Item label="Código promotor" hasFeedback>
                                    {getFieldDecorator('ZY7_CODPRO')(<Input disabled maxLength="6" />)}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Promotor" hasFeedback>
                                    {getFieldDecorator('ZY7_PROMOT')(<Input disabled maxLength="50" />)}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Data inicial da visita" hasFeedback>
                                    {getFieldDecorator('ZY7_DTINIV', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                    })(<DatePicker format="DD/MM/YYYY" disabledDate={this.disabledDate} />)}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Data final da visita" hasFeedback>
                                    {getFieldDecorator('ZY7_DTFIMV', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                    })(
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            disabled={!this.props.form.getFieldValue('ZY7_DTINIV')}
                                            disabledDate={this.disabledDate}
                                            onChange={this.handleValidateDate}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col span={12}>
                                <Form.Item label="Motivo da Promotoria" hasFeedback>
                                    {getFieldDecorator('ZY7_MOTIVO', {
                                        rules: [{ required: true, message: 'Informe o campo obrigatório' }]
                                    })(<Input.TextArea />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button htmlType="submit" type="primary">Cadastrar promotoria</Button>
                    </Form>
                </Spin>
                <ChooserPromotor ref={_ref => (this._choosers["promotor"] = _ref)} />
                <ChooserCliente onSelectCliente={this.handleSelectClient} visible={isStatusModalCliente} onClose={this.handleCloseModalCliente} clientId={this.props.form.getFieldValue('ZY7_CODCLI')} />
            </div>
        );
    }
    _choosers = {}
}

export default withStore(Form.create({ name: 'promotoria' })(SolicitacaoPromotoria));
