import React, { PureComponent } from "react";
import { Input } from "antd";

export default class TextFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterText: !!props.selectedKeys && props.selectedKeys.length > 0 ? props.selectedKeys[0] : "",
    };
  }
  requestFocus = () => !!this._searchInputRef && this._searchInputRef.focus();
  setFilterText = filterText => this.setState({ filterText });
  handleChange = event => this.setFilterText(!!event.target.value ? event.target.value : "");
  handleSearch = () => {
    const { setSelectedKeys, confirm } = this.props;
    setSelectedKeys(!!this.state.filterText ? [this.state.filterText] : []);
    setTimeout(() => confirm(), 1000 / 60);
  };
  render() {
    const { placeholder = "Buscar" } = this.props;

    return (
      <div className="filter-dropdown text-filter-dropdown">
        <Input.Search
          ref={ref => (this._searchInputRef = ref)}
          placeholder={placeholder}
          value={this.state.filterText}
          onChange={this.handleChange}
          onSearch={this.handleSearch}
          enterButton={true}
          style={{ width: 290 }}
        />
      </div>
    );
  }
  _searchInputRef;
}
