import React, { Component } from "react";
import { Button, Modal, Row, Col, Avatar, Spin, Menu, Dropdown, Icon } from "antd";
import { observer } from "mobx-react";
import _size from "lodash/size";

import withStore from "withStore";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchApi, fetchPostApi } from "actions";
import PageHeader, { BackButton } from "components/PageHeader";
import styled from "styled-components";
import classnames from "classnames";

const Title = styled.div`
  font-size: 20px; 
  font-weight: 600; 
  line-height: 1.4; 
  color: rgba(0, 0, 0, .85);
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, .45)
`;

@observer
class TreinamentoPadraoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Treinamentos Padrão",
      uri: "/treinamento/",
      defaultFilter: {
      },
    });

    this.state = {
      selected: null,
      nome: 'Carregando nome...',
      empresa: 'Carregando empresa...',
      download_uri: ''
    };
  }

  componentDidMount() {
    this.fetchMonitor();
  }

  fetchMonitor = async () => {
    const { match } = this.props
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi(`/treinamento/empresa/monitor/${match.params.empresa}`);
      this.handleMonitorData(response.data);
    } catch (error) {

    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleMonitorData = (data) => {
    const mapped = data.map((item) => {
      return item.id_treino;
    })

    this.setState({ selected: mapped });
  }

  // fetchFuncionario = async () => {
  //   const { match } = this.props
  //   this.props.store.isLoading = true;

  //   try {
  //     const { data: response } = await fetchApi(`/treinamento/funcionario/busca_func/${match.params.empresa || this.props.store.treinamentoFornecedorData?.codigo}/${match.params.funcionario}`);
  //     this.setState({ nome: response.data.nome, empresa: response.data.razao_social, download_uri: response.data.download_uri })
  //   } catch (error) {

  //   } finally {
  //     this.props.store.isLoading = false;
  //   }
  // }

  handleSave = async () => {
    this.props.store.isLoading = true;
    try {
      const { match, history } = this.props;
      const { selected } = this.state;

      const { data: response } = await fetchPostApi(`/treinamento/empresa/padrao`, { selecionados: selected, empresa: match.params.empresa });
      if (!!response.success) {
        Modal.success({
          title: "Salvo com sucesso!",
          content: "Treinamentos atualizados com sucesso!",
          onOk: () => history.goBack()
        });
      }
    } catch (error) {
      Modal.error({
        title: "Erro!",
        content: "Erro ao salvar treinamentos a empresa!"
      })
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleClick = () => {
    this.handleSave();
  }

  render() {
    const { history, match } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "codigo",
        key: "codigo",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        width: "20%",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Sigla",
        dataIndex: "sigla",
        key: "sigla",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("sigla"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por sigla" {...props} />,
        filterParser: filter => ({ ...filter, field: "sigla", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (_, item) => {
          return (
            <div>{item.sigla}</div>
          )
        }
      },
      {
        title: "Necessita anexo?",
        dataIndex: "necessita_anexo",
        key: "necessita_anexo",
        width: "12%",
        render: (necessita_anexo) => {
          return (
            <div>{necessita_anexo === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Vigência?",
        key: "vigencia",
        dataIndex: "vigencia",
        width: '10%',
        render: (vigencia) => {
          return (
            <div>{vigencia === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Observação",
        key: "observacao",
        dataIndex: "obs",
        render: (obs) => {
          return (
            <WrappedValue value={obs} />
          )
        }
      }
    ];

    const rowSelection = {
      selectedRowKeys: this.state.selected,
      onChange: (selectedRowKeys) => {
        this.setState({ selected: selectedRowKeys });
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="report-screen">
        <PageHeader title={this.ds.name}
          headerLeft={<BackButton onClick={() => history.goBack()} />}
          headerRight={
            <Button loading={this.props.store.isLoading || this.ds.isLoading} onClick={this.handleClick} type="primary" icon="plus">
              Salvar
            </Button>
          }>
        </PageHeader>
        <FullTable
          actionBar="none"
          rowSelection={rowSelection}
          rowKey={item => (`${item.codigo}`)}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter os treinamentos no momento"
        />
      </div >
    );
  }
}

export default withStore(TreinamentoPadraoScreen);
