import React, { Component } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";

import { TableDataSource } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.codigo_transportadora}:${item.cnpj}`;

@observer
export default class ChooserTransp extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/transportadoras/0000/" + (props.tipo || "desp"));

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        this.ds.uri =
          "/pedidos/transportadoras/" + this.props.pedido.codigo_vendedor + "/" + (this.props.tipo || "desp");
      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Código",
        key: "codigo_transportadora",
        width: "15%",
      },
      {
        title: "Transportadora",
        key: "nome_transportadora",
        className: "text-left",
      },
      {
        title: "Cidade/UF",
        key: "cidadeuf",
        width: "18%",
        render: (_, item) => <div>{item.municipio} / {item.est}</div>
      },
      {
        title: "CNPJ",
        key: "cnpj",
        width: "18%",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Encontre uma Transportadora"
        errorMessage="Não foi possível obter suas transportadoras no momento"
        searchPlaceholder="Busque pelo código de transportadora"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        {...this.props}
      />
    );
  }
}
