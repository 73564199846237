import { fetchApi, fetchApiWithCache, fetchPostApi } from "actions";

export const fetchBase = async (un = null, ...args) => {
  const { data: response } = await fetchApiWithCache(`/pedidos/base${!!un ? "/" + un : ""}`, ...args);
  return response;
};

export const fetchCliente = async (cliente, ...args) => {
  const { codigo_cliente, loja_cliente = "01", filial_faturamento = "01" } = cliente;
  const { data: response } = await fetchApi(
    `/pedidos/cliente/${codigo_cliente}-${loja_cliente}-${filial_faturamento}`,
    ...args
  );
  return response;
};

export const fetchAlvara = async (alvara, ...args) => {
  const { codigo_cliente, loja_cliente = "01", codigo_alvara } = alvara;
  const { data: response } = await fetchApiWithCache(
    `/pedidos/alvaras/${codigo_cliente}-${loja_cliente}/${codigo_alvara}`,
    ...args
  );
  return response;
};

export const fetchCupom = async (dadosCupom, ...args) => {
  const { codigo_cliente, loja_cliente = "01", cupom, status_pedido } = dadosCupom;
  const { data: response } = await fetchApi(
    `/pedidos/cupons/${status_pedido}-${codigo_cliente}-${loja_cliente}/${cupom}`,
    ...args
  );
  return response;
};

export const fetchCashback = async (dadosCashback, ...args) => {
  const { codigo_cliente, loja_cliente = "01" } = dadosCashback;
  const { data: response } = await fetchApi(
    `/pedidos/resgatecashback/${codigo_cliente}-${loja_cliente}`,
    ...args
  );
  return response;
};

export const fetchRevogaCashback = async (dadosCashback, ...args) => {
  const { codigo_cliente, loja_cliente = "01", filial_faturamento, numero_pedido } = dadosCashback;
  const { data: response } = await fetchApi(
    `/pedidos/revogacashback/${codigo_cliente}-${loja_cliente}-${filial_faturamento}-${numero_pedido}`,
    ...args
  );
  return response;
};
export const fetchPedidoOriginal = async (dadosPedidoOriginal, ...args) => {
  const { codigo_cliente, loja_cliente = "01", pedido_originalb2b, status_pedido } = dadosPedidoOriginal;
  const { data: response } = await fetchApi(
    `/pedidos/original/${status_pedido}-${codigo_cliente}-${loja_cliente}/${pedido_originalb2b}`,
    ...args
  );
  return response;
};

export const fetchTransp = async (transp, ...args) => {
  const { codigo_transp, codigo_vendedor, tipo = "desp" } = transp;
  const { data: response } = await fetchApiWithCache(
    `/pedidos/transportadoras/${codigo_vendedor}/${tipo}/${codigo_transp}`,
    ...args
  );
  return response;
};

export const fetchCondicao = async (condicao, ...args) => {
  const { codigo_cliente, loja_cliente = "01", pmp = 1, status_pedido = "2", codigo_condicao, forma_pagto } = condicao;
  const { data: response } = await fetchApi(
    `/pedidos/condicoes/${status_pedido}/${pmp}/${codigo_condicao}/${codigo_cliente}-${loja_cliente}/${forma_pagto}`,
    ...args
  );
  return response;
};

export const fetchStatus = async (status, ...args) => {
  const { codigo_cliente, loja_cliente = "01", status_pedido, tipo_transp = "C" } = status;
  const { data: response } = await fetchApi(
    `/pedidos/valida_status/${codigo_cliente}-${loja_cliente}/${status_pedido}/${tipo_transp}`,
    ...args
  );
  return response;
};

export const fetchProduto = async (produto, ...args) => {
  const {
    codigo_cliente,
    codigo_produto,
    loja_cliente = "01",
    filial_faturamento = "01",
    tipo_frete = "C",
    status = "2",
    unidade_negocio = "REVENDA",
    tipo_transp = null,
    op_logistica = "F",
    cliente_retira = "NAO",
  } = produto;

  const params = [`${codigo_cliente}-${loja_cliente}-${filial_faturamento}`, tipo_frete, codigo_produto, op_logistica, cliente_retira, status];
  if (!!tipo_transp) {
    params.push(tipo_transp);
  }

  const { data: response } = await fetchApi(
    `/pedidos/${unidade_negocio === "REVENDA" ? "produto" : "produto_b2b"}/${params.join("/")}`,
    ...args
  );
  return response;
};

export const fetchKits = async (kit, ...args) => {
  const { codigo_kit = null, codigo_cliente, loja_cliente = "01", filial_faturamento = "01", tipo_transp = "C", op_logistica = "F", cliente_retira = 'NAO', perfil_imob, perfil_auto } = kit;
  const { data: response } = await fetchApi(
    `/pedidos/kits/${codigo_cliente}-${loja_cliente}-${filial_faturamento}/${op_logistica}/${cliente_retira}/${perfil_imob}/${perfil_auto}/${tipo_transp}${!!codigo_kit ? "/" + codigo_kit : ""
    }`,
    ...args
  );
  return response;
};

export const fetchKit = async (kit, ...args) => {
  const { codigo_kit, codigo_cliente, loja_cliente = "01", filial_faturamento = "01", tipo_transp = "C", op_logistica = "F", cliente_retira = 'NAO', codprod = false } = kit;
  const { data: response } = await fetchApi(
    `/pedidos/kit/${codigo_kit}/${codigo_cliente}-${loja_cliente}-${filial_faturamento}/${op_logistica}/${cliente_retira}/${tipo_transp}${!!codprod ? `/${codprod}` : ''}`,
    ...args
  );
  return response;
};

export const fetchEdit = async (codigo, residuo = false, un = "REVENDA", ...args) => {
  const { data: response } = await fetchApi(
    `/pedidos/${un === "REVENDA" ? "edit" : "edit_b2b"}/${codigo}${!!residuo ? "/residuo" : ""}`,
    ...args
  );
  return response;
};

export const savePedido = async (formData, ...args) => {
  const { data: response } = await fetchPostApi("/pedidos/save", formData, ...args);
  return response;
};
