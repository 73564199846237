import React, { PureComponent } from "react";
import { autorun, toJS } from "mobx";
import { Form } from "antd";
import classnames from "classnames";
import moment from "moment";
import _size from "lodash/size";
import { filterDate } from "@util";

class SearchForm extends PureComponent {
  componentDidMount() {
    const { dataSource, filterParser = {}, defaultFilters } = this.props;

    if (typeof filterParser === "object") {
      Object.keys(filterParser).forEach(key => {
        dataSource.filterParsers[key] = filterParser[key];
      });
    }

    if (defaultFilters) {
      dataSource.filters = defaultFilters;
    }

    this._keepMapping = autorun(() => this.mapFiltersToFields(toJS(dataSource.filters)));
  }
  componentWillUnmount() {
    if (!!this._keepMapping) this._keepMapping();
  }
  mapFiltersToFields(filters) {
    const { filterFields = {}, form } = this.props;
    const m = _size(filterFields) > 0 ? Object.keys(filterFields) : Object.keys(filters);
    if (m.length > 0) {
      form.setFieldsValue(
        m.reduce((acc, k) => {
          let value = filters[k] || "";
          if (Array.isArray(value) && value.length > 0) {
            value = value[0];
          }
          if (!!filterFields[k]) {
            if (`${filterFields[k]}`.indexOf("date") !== -1) {
              try {
                value = !!value ? moment(typeof value === "string" ? filterDate(value) : value) : null;
              } catch (err) { }
            } else if (filterFields[k] === "checkbox" && value === "") {
              value = false;
            } else if ((filterFields[k] === "options" || filterFields[k] === "select") && value === "") {
              value = undefined;
            }
          }

          return { ...acc, [k]: value };
        }, {})
      );
    }
  }
  defaultFilters = (values) => {
    this.props.form.setFieldsValue(values);
  }
  cleanFilters = () => {
    this.props.form.resetFields();
    this.props.onUpdateDataSource(null, {}, null);
  };
  submitForm = event => {
    if (!!event) event.preventDefault();

    const { dataSource, onUpdateDataSource, filterFields = {}, form } = this.props;

    form.validateFields((err, fieldsValue) => {
      if (!err) {
        let mappedFilters = Object.keys(fieldsValue).reduce((acc, k) => {
          acc[k] = fieldsValue[k];

          if (acc[k] !== null && typeof acc[k] === "object") {
            if (moment.isMoment(acc[k])) {
              acc[k] = acc[k].format(
                !!filterFields[k] && filterFields[k] === "datetime" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
              );
            } else {
              acc[k] = JSON.stringify(acc[k]);
            }
          }

          return acc;
        }, {});

        if (dataSource.hasFilter) {
          const currentFilters = toJS(dataSource.filters);
          mappedFilters = !!mappedFilters ? { ...currentFilters, ...mappedFilters } : currentFilters;
        }

        onUpdateDataSource(null, mappedFilters, null);
      }
    });
  };
  render() {
    const {
      className,
      dataSource,
      onUpdateDataSource,
      filterParser,
      filterFields,
      form,
      extraData,
      ...otherProps
    } = this.props;

    return (
      <Form
        noValidate
        className={classnames("full-table--search-form", className)}
        onSubmit={this.submitForm}
        {...otherProps}>
        {this.props.children({
          form,
          submitForm: this.submitForm,
          cleanFilters: this.cleanFilters,
        })}
      </Form>
    );
  }
}

export default Form.create()(SearchForm);
