import React, { Component } from "react";
import { Table, Modal } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";

import { WrappedValue, mapColumn } from "components/FullTable";
import CargaStatusTag from "./CargaStatusTag";

import "./CargaAprovDetails.less";

const rowKey = item => `${item.codigo_carga}:${item.codigo_cliente}:${item.codigo_loja}`;

const decorateRows = (item, index) =>
  classnames("base-table--row", {
    "base-table--row__odd": index % 2 !== 0,
  });

@observer
export default class CargaAprovDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aprovs: [],
      visible: false,
    };
  }
  open = (aprovs = []) => {
    this.setState({ aprovs, visible: true });
  };
  close = () => {
    this.setState({ visible: false, aprovs: [] });
  };
  render() {
    const { title = "Aprovações da Safra", width = 820, isLoading = false } = this.props;

    const columns = [
      {
        title: "Status",
        dataIndex: "status_safra",
        key: "status_safra",
        render: text => <CargaStatusTag status={text} />,
        width: "13%",
      },
      {
        title: "Código",
        dataIndex: "codigo_cliente",
        key: "codigo_cliente",
        width: "12%",
      },
      {
        title: "Loja",
        dataIndex: "codigo_loja",
        key: "codigo_loja",
        width: "8%",
      },
      {
        title: "Cliente",
        dataIndex: "razao_social",
        key: "razao_social",
      },
      {
        title: "Data Lib/Rej",
        dataIndex: "data_status",
        key: "data_status",
        render: text => <WrappedValue value={text} type="datetime" />,
        width: "19.5%",
      },
    ];

    return (
      <Modal
        centered
        title={title}
        footer={null}
        visible={this.state.visible}
        onCancel={this.close}
        width={width}
        destroyOnClose={true}
        wrapClassName="carga-aprov-details-modal">
        <Table
          className={classnames(`base-table base-table__compact`, { "base-table__loading": !!isLoading })}
          pagination={false}
          rowKey={rowKey}
          loading={!!isLoading}
          bordered={true}
          columns={columns.map(mapColumn)}
          rowClassName={decorateRows}
          dataSource={this.state.aprovs}
          locale={{
            filterTitle: "Filtrar",
            filterConfirm: "OK",
            filterReset: "Limpar",
            emptyText: "Sem itens de aprovação até o momento",
          }}
          size="middle"
        />
      </Modal>
    );
  }
}
