import React, { Component, Fragment, useState } from "react";
import { Button, List, Spin, Icon, Tag, Switch } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";

import { dateFormat, humanFileSize } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import { TableDataSource } from "components/FullTable";

const rowKey = (item, i) => `${i}`;
const showTotal = (total, range) => (total > 1 ? `${range[0]}-${range[1]} de ${total} itens` : `${total} item`);

const ItemWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 10px 13px;

  > .ant-tag {
    font-size: 11px;
    cursor: default;
    padding: 0 5px;
    line-height: 18px;
    height: 20px;
  }
`;
const ItemTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 5px 0 7px;
  color: #393939;
`;
const ItemDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 16px;
`;
const ItemArquivosWrapper = styled.div`
  background-color: #f9f9f9;
  padding: 9px 13px;
  margin: 0 -13px 16px;
  border-radius: 10px;

  ul {
    margin: 0;
  }
  ul > li {
    line-height: 21px;
  }
`;
const ItemArquivosTitle = styled.h4`
  font-size: 14px;
  font-weight: bold;
  margin: 1px 0 4px;
  color: #696969;
  cursor: default;
`;
const ItemArquivosLink = styled.a`
  margin-left: 3px;
  > strong {
    margin: 0 4px;
  }
  > em {
    font-size: 10px;
    margin-left: 2px;
    letter-spacing: -0.5px;
  }
`;

function ItemArquivosItem({ file, size, href, ...props }) {
  const filename = file.split("/");

  return (
    <li {...props}>
      <ItemArquivosLink
        href={href}
        title="Clique para baixar este arquivo"
        target="_blank"
        rel="noopener noreferrer"
        type="application/octet-stream"
        download={filename[filename.length - 1]}>
        <Icon type="file" />
        <strong>{filename[filename.length - 1]}</strong>
        <em>({humanFileSize(size)})</em>
      </ItemArquivosLink>
    </li>
  );
}

function ListItem({ item, downloadUri, ...props }) {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <List.Item style={{ padding: 0 }} {...props}>
      <ItemWrapper>
        <Tag color="blue">{dateFormat(item.Arquivo.data_veiculacao)}</Tag>
        <ItemTitle>{item.Arquivo.titulo}</ItemTitle>
        {!isOpened && (
          <Button onClick={() => setIsOpened(true)} type="primary" size="small" icon="plus-circle">
            {!!item.Arquivo.arquivos && item.Arquivo.arquivos.length > 0 ? `Ver arquivos` : `Ver mais detalhes`}
          </Button>
        )}
        {!!isOpened && (
          <Fragment>
            {!!item.Arquivo.descricao && (
              <ItemDescription
                dangerouslySetInnerHTML={{ __html: item.Arquivo.descricao.split("\n").join("<br />") }}
              />
            )}
            {!!item.Arquivo.arquivos && item.Arquivo.arquivos.length > 0 && (
              <ItemArquivosWrapper>
                <ItemArquivosTitle>Arquivos</ItemArquivosTitle>
                <ul>
                  {item.Arquivo.arquivos.map(arq => (
                    <ItemArquivosItem
                      key={arq.file}
                      file={arq.file}
                      size={arq.size}
                      href={`${downloadUri}${arq.file}`}
                    />
                  ))}
                </ul>
              </ItemArquivosWrapper>
            )}
            <Button ghost={true} onClick={() => setIsOpened(false)} type="primary" size="small" icon="minus-circle">
              Ver menos
            </Button>
          </Fragment>
        )}
      </ItemWrapper>
    </List.Item>
  );
}

const meses = {
  "12": "Dezembro",
  "11": "Novembro",
  "10": "Outubro",
  "09": "Setembro",
  "08": "Agosto",
  "07": "Julho",
  "06": "Junho",
  "05": "Maio",
  "04": "Abril",
  "03": "Março",
  "02": "Fevereiro",
  "01": "Janeiro"
};

function ListItemDiretor({ item, downloadUri, index, ...props }) {
  const [isOpened, setIsOpened] = useState(false);

  if (item.length > 0) {
    let titulo = '';
    let isTitulo = false;

    if (item[0].monthyear) {
      const month = item[0].monthyear.slice(0, 2);
      const year = item[0].monthyear.slice(2);

      titulo = 'Campanhas de ' + meses[month] + " / " + year;
      isTitulo = false;
    } else if (item[0].titulo) {
      titulo = item[0].titulo;
      isTitulo = true;
    }

    return (
      <List.Item style={{ padding: 0 }} {...props}>
        <ItemWrapper>
          <ItemTitle>{titulo}</ItemTitle>
          {!isOpened && (
            <Button onClick={() => setIsOpened(true)} type="primary" size="small" icon="plus-circle">
              Ver arquivos
            </Button>
          )}
          {!!isOpened && (
            <Fragment>
              <ItemArquivosWrapper>
                <ItemArquivosTitle>Arquivos</ItemArquivosTitle>
                <ul>
                  {isTitulo && item[0].descricao && (
                    <ItemDescription
                      dangerouslySetInnerHTML={{ __html: item[0].descricao.split("\n").join("<br />") }}
                    />
                  )}
                  {item.map(a =>
                    a?.arquivos?.map((arq) => (
                      <ItemArquivosItem
                        key={arq.file}
                        file={arq.file}
                        size={arq.size}
                        href={`${downloadUri}${arq.file}`}
                      />
                    ))
                  )}
                </ul>
              </ItemArquivosWrapper>
              <Button ghost={true} onClick={() => setIsOpened(false)} type="primary" size="small" icon="minus-circle">
                Ver menos
              </Button>
            </Fragment>
          )}
        </ItemWrapper>
      </List.Item>
    );
  } else {
    return (<></>)
  }
}

@observer
class ArquivosScreen extends Component {
  constructor(props) {
    super(props);

    const isComercial = props.match.url.indexOf("-comercial") !== -1;

    this.ds = new TableDataSource({
      name: isComercial ? "Arquivos Comerciais" : "Campanhas",
      uri: isComercial ? "/arquivos/vendedor" : "/arquivos/cliente",
      state: {
        download_uri: null,
      },
    });

    this.state = {
      legacy: false
    };
  }
  componentDidMount() {
    this.ds.fetch();
  }
  renderItem = item => <ListItem item={item} downloadUri={this.ds.state.download_uri} />;
  renderItemDiretor = (item, index) => <ListItemDiretor item={item} index={index} downloadUri={this.ds.state.download_uri} />;
  updatePagination = (page = 1) => {
    this.ds.pagination.current = +page;
    this.ds.fetch();
  };
  changeToLegacy = (checked) => {
    this.setState({ legacy: checked });

    if (checked) {
      this.ds.uri = "/arquivos/vendedor/1"
    } else {
      this.ds.uri = "/arquivos/vendedor/0"
    }

    this.ds.fetch();
  }
  render() {
    const isLoading = this.props.store.isLoading || this.ds.isLoading;

    return (
      <div className="arquivos-screen">
        <PageTitle title={this.ds.name} />
        <Spin spinning={!!isLoading}>
          {this.props.store.isDiretor &&
            <>
              <div>Modo legado?</div>
              <Switch checked={this.state.legacy} onChange={this.changeToLegacy} />
            </>
          }
          {!!isLoading ? (
            <div style={{ minHeight: 420 }} />
          ) : (
            <>
              <List
                itemLayout="vertical"
                loading={isLoading}
                locale={{
                  emptyText: "Sem itens cadastrados para sua visualização",
                }}
                rowKey={rowKey}
                pagination={{
                  size: "small",
                  showTotal,
                  onChange: this.updatePagination,
                  ...this.ds.pagination,
                }}
                dataSource={this.ds.items}
                renderItem={this.props.store.isDiretor && !this.state.legacy ? this.renderItemDiretor : this.renderItem}
              />
            </>
          )}
        </Spin>
      </div>
    );
  }
}

export default withStore(ArquivosScreen);
