import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";

const FormItem = Form.Item;

@observer
class NpsScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Respostas Nps",
      uri: "/r/nps",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          data$0: "date",
          data$1: "date",
        }}
        filterParser={{
          data$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          data$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Data de Resposta">
              {form.getFieldDecorator("data$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Data Resposta",
        dataIndex: "Nps.data",
        dataType: "date",
        key: "Nps.data",
        sorter: true,
        width: "13%",
        filteredValue: this.ds.filterValue("data"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data da Resposta (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Nps.data",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Código",
        dataIndex: "Nps.cliente",
        key: "Nps.cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.25%",
      },
      {
        title: "Loja",
        dataIndex: "Nps.loja",
        width: "6%",
      },
      {
        title: "Cliente",
        dataIndex: "Nps.razao_social",
        className: "text-left",
        key: "Nps.razao_social",
        sorter: true,
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Nps.razao_social", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Comentário",
        dataIndex: "Nps.comentario",
        key: "Nps.comentario",
      },
      {
        title: "Nota",
        dataIndex: "Nps.nota",
        dataType: "number",
        key: "Nps.nota",
        sorter: true,
        width: "12.5%",
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Nps.id}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas respostas nps no momento"
        />
      </div>
    );
  }
}

export default withStore(NpsScreen);
