import "moment/locale/pt-br";

import React from "react";
import ReactDOM from "react-dom";
import UIStore from "./stores/UIStore";
import ExportableStore from "./stores/ExportableStore";
import App from "./App";

const render = Component => {
  ReactDOM.render(<Component store={UIStore} exportable={ExportableStore} />, document.getElementById("root"));
};

render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./App", () => render(require("./App").default));
}
