import React, { Component, Fragment } from "react";
import { Form } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import _size from "lodash/size";
import styled from "styled-components";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import TicketsPanel from "components/dashboard/TicketsPanel";
import SemaforoPanel from "components/dashboard/SemaforoPanel";
import MetaPanel from "components/dashboard/MetaPanel";
import MetaVolPanel from "components/dashboard/MetaVolPanel";
import MetaPanelLinha from "components/dashboard/MetaPanelLinha";
import IndicadoresPanel from "components/dashboard/IndicadoresPanel";
import MixPanel from "components/dashboard/MixPanel";
import TrimestrePanel from "components/dashboard/TrimestrePanel";

import Select from "components/Select";

const FormItem = Form.Item;

const FormVendedores = styled(Form)`
  &.ant-form {
    margin-bottom: 16px;

    .ant-select,
    .ant-cascader-picker {
      min-width: 210px;
      width: auto;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    font-weight: 600;
    line-height: 16px;
  }
`;

@observer
class HomeScreen extends Component {
  renderDashboard() {
    const { store } = this.props;
    const relacionadoData = toJS(store.relacionadoData);
    const codigo = store.vendedorData.micro_regiao;

    return (
      <Fragment>
        {store.isTipoVendaInterna && _size(relacionadoData) > 0 && (
          <FormVendedores>
            <FormItem label="Vendedor Selecionado">
              <Select
                options={store.vendedoresRelacionados}
                value={relacionadoData.codigo}
                onChange={option => store.setRelacionadoData(option)}
                defaultActiveFirstOption={false}
                placeholder="Selecione um Vendedor"
              />
            </FormItem>
          </FormVendedores>
        )}
        <TicketsPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} />
        {!store.isB2B && !store.isTipoVendaInterna && ( //colocado no ar apenas da revenda prque a b2b ainda não montou os indicadores...
          <SemaforoPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} isOperador={false} codigo={codigo} />
        )}
        <MetaVolPanel isTipoVendaInterna={store.isTipoVendaInterna} tipo="Objetivo Volume" relacionadoData={relacionadoData} />
        <MetaPanelLinha isTipoVendaInterna={store.isTipoVendaInterna} tipo="Objetivo Volume" relacionadoData={relacionadoData} />
        <MetaPanel isTipoVendaInterna={store.isTipoVendaInterna} tipo="Objetivo Faturamento" relacionadoData={relacionadoData} />
        <MetaPanelLinha isTipoVendaInterna={store.isTipoVendaInterna} tipo="Objetivo Faturamento" relacionadoData={relacionadoData} />
        {store.isB2B && (
          <TrimestrePanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} isB2B={store.isB2B} />
        )}
        <IndicadoresPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} />
        <MixPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} isB2B={store.isB2B} />
      </Fragment>
    );
  }

  renderDashboardMop() {
    const { store } = this.props;
    const relacionadoData = toJS(store.relacionadoData);
    const codigo = store.operadorData.codigo_operador;

    return (
      <Fragment>
        <TicketsPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} />
        <SemaforoPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} isOperador={true} codigo={codigo} />
        <MetaVolPanel isTipoVendaInterna={store.isTipoVendaInterna} tipo="Objetivo Volume" relacionadoData={relacionadoData} />
        <MetaPanel isTipoVendaInterna={store.isTipoVendaInterna} tipo="Objetivo Faturamento" relacionadoData={relacionadoData} />
        <IndicadoresPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} />
        <MixPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} isB2B={store.isB2B} />
      </Fragment>
    );
  }

  render() {
    const { store } = this.props;

    return (
      <div className="home-screen home-screen__vendedor">
        {store.isVendedor ?
          this.renderDashboard() :
          store.isOperador ?
            this.renderDashboardMop() :
            <PageTitle title="Bem-vindo ao ANJO NEXXUS" meta={false} />}
      </div>
    );
  }
}

export default withStore(HomeScreen);
