import React, { Component } from "react";
import { observer } from "mobx-react";

import ProdutoTable from "components/pedidos/ProdutoTable";

@observer
export default class StepProduto extends Component {
  componentDidMount() {
    this.props.resetItems();
  }
  render() {
    const { pedido, onEditItem, onEditBonifItem, onRemoveItem } = this.props;
    const items = pedido.items.map(item => item.asJSON);

    return (
      <div className="pedido-step">
        <ProdutoTable pedido={pedido} items={items} onEdit={onEditItem} onRemove={onRemoveItem} onEditBonif={onEditBonifItem} />
      </div>
    );
  }
}
