import React, { Component, Fragment } from "react";
import { Modal, Spin, Button, Icon } from "antd";
import { Helmet } from "react-helmet";
import { observable } from "mobx";
import { observer } from "mobx-react";

import withStore from "withStore";
import { dateFormat, getData, n } from "@util";
import { fetchBase, fetchEdit, fetchCapacidade, saveCarga } from "actions/carga";
import { ProgramacaoCarga, PedidoError } from "stores/PedidoStore";

import PageHeader, { BackButton } from "components/PageHeader";
import CargaModal from "components/pedidos/cargas/CargaModal";
import CargaTable from "components/pedidos/cargas/CargaTable";

import "./CargaEditorScreen.less";

const LABELS_TMPL = {
  kit: {
    titleAdd: "Nova Programação de KIT",
    titleEdit: "Editando Programação de KIT",
    titleConfirm: "Programação de KIT",
    btnSaveAdd: "Incluir Programação de KIT",
    btnSaveEdit: "Alterar Programação de KIT",
    btnNewCarga: "Nova Carga de KIT",
  },
  safra: {
    titleAdd: "Nova Programação de Safra",
    titleEdit: "Editando Programação de Safra",
    titleConfirm: "Programação de Safra",
    btnSaveAdd: "Incluir Safra",
    btnSaveEdit: "Alterar Safra",
    btnNewCarga: "Nova Carga Safra",
  },
};

function onCargaError(errorMessage, onOk) {
  Modal.error({
    title: "Atenção",
    content: errorMessage || "Não foi possível continuar sua carga",
    onOk,
  });
  return false;
}

async function checkCapacidade(tipo, codigo_carga, carga) {
  try {
    const checkData = await fetchCapacidade({
      tipo,
      codigo_carga,
      filial: carga.codigo_filial,
      data_faturamento: carga.data_entrega,
      codigo_kit: carga.codigo_kit,
      volume_carga: carga.total_peso,
      cargas: carga.items.map(item => item.asPedido),
    });
    if (!checkData.success) {
      throw new PedidoError("Data de Faturamento indisponível para seleção, por favor escolha outra data");
    }
  } catch (err) {
    const errorCode = !!err.response ? getData(err, "response.data.code", 15) : 15;
    const errorMessages = {
      1: "Operação indisponível para seu tipo de login",
      5: "Por favor informe cargas com um KIT válido",
      10: "A data de faturamento informada está fora da vigência permitida para esta Campanha",
      15: "Data de Faturamento indisponível para seleção, por favor escolha outra data",
      20: "O volume total configurado excede a capacidade disponível para a data de faturamento selecionada",
      25: "Não é possível programar a data de faturamento para um final desse semana ou feriados",
      26: "Não é possível programar a data de faturamento para o mesmo dia de hoje",
      30: "Uma das cidades utilizadas em sua carga não é permitida para seu atendimento",
    };

    let em = errorMessages[errorCode] || errorMessages[15];
    if (+errorCode === 30) {
      const { data } = err.response.data;
      em = `A cidade ${data.cidade}/${data.estado} não faz parte da sua região de atendimento, por favor revise sua carga`;
    }

    throw new PedidoError(`Carga Fat. ${dateFormat(carga.data_entrega)}: ${em}`);
  }

  return true;
}

@observer
class CargaEditorScreen extends Component {
  constructor(props) {
    super(props);

    const { isVendedor = true, vendedorData = {}, operadorData = {} } = props.store;
    const tipo = !!props.match.params.tipo && props.match.params.tipo === "safra" ? "safra" : "kit";

    this.programacao = new ProgramacaoCarga(
      !!isVendedor
        ? { tipo, codigo_vendedor: vendedorData.codigo }
        : {
            tipo,
            codigo_operador: operadorData.codigo_operador || operadorData.codigo || null,
          }
    );

    this.state = {
      started: false,
    };
  }
  async componentDidMount() {
    const { store, match } = this.props;

    store.trackTab("/pedidos", this.programacao.tipo === "kit" ? "tab-camp-kit" : "tab-safra");

    await this.fetchBase();

    if (!!match.params && !!match.params.slug) {
      await this.fetchEdit(match.params.slug);
    }
  }
  async fetchBase() {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      const response = await fetchBase(this.programacao.tipo);
      if (!!response.success) {
        const { pedidos = [], tipos, validacao_clientes, today = null } = response.data;

        this.programacao.today = today;
        this.programacao.tipos = tipos;
        this.programacao.validacao_clientes = validacao_clientes;
        this.programacao.pedidos = observable(pedidos);

        this.setState({ started: true });
      } else {
        throw new PedidoError("Não foi possível iniciar uma nova carga no momento");
      }
    } catch (err) {
      onCargaError("Não foi possível iniciar uma nova carga no momento", () =>
        history.replace("/pedidos/#" + (this.programacao.tipo === "kit" ? "tab-camp-kit" : "tab-safra"))
      );
    } finally {
      store.isLoading = false;
    }
  }
  async fetchEdit(codigo) {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      const response = await fetchEdit(codigo);
      if (!!response.success) {
        const { carga, pedidos = [] } = response.data;
        this.programacao.parse(carga, pedidos);
      } else {
        throw new PedidoError("Não foi editar visualizar esta carga no momento");
      }
    } catch (err) {
      onCargaError("Não foi possível editar esta carga no momento", () =>
        history.replace("/pedidos/#" + (this.programacao.tipo === "kit" ? "tab-camp-kit" : "tab-safra"))
      );
    } finally {
      store.isLoading = false;
    }
  }
  stepBack = () => {
    if (this.programacao.cargas.length > 0) {
      Modal.confirm({
        title: "Você deseja sair da programação?",
        content: "Ao sair deste tela todos os dados informados serão descartados.",
        onOk: () =>
          this.props.history.replace("/pedidos/#" + (this.programacao.tipo === "kit" ? "tab-camp-kit" : "tab-safra")),
      });
    } else {
      this.props.history.goBack();
    }
  };
  novaCarga = () => {
    if (!!this._cargaModal) {
      this._cargaModal.open();
    }
  };
  saveCarga = async (carga, pedidoSoltos = []) => {
    if (!!this.programacao.tipos[carga.tipo_caminhao] && !carga.isPesoValid) {
      const min = +this.programacao.tipos[carga.tipo_caminhao].min;
      const max = +this.programacao.tipos[carga.tipo_caminhao].max;

      throw new PedidoError(`Peso mínimo/máximo permitido para ${carga.tipo_caminhao.toUpperCase()} (${min}/${max})!`);
    }
    //CONTAINER NAO VALIDA MAXIMO DE ENTREGAS A PEDIDO DO KAMILA DO COMERCIAL NO CHAMADO 00023803
    if (!carga.isMaxClientesValid  && carga.tipo_caminhao.toUpperCase() !== "CONTAINER") {
      throw new PedidoError(`Maximo de clientes por carga atingido!`);
    }

    if (this.programacao.cargas.length > 0) {
      const otherCargas = this.programacao.cargas.filter(citem => citem.sequencia_carga !== carga.sequencia_carga);
      if (otherCargas.length > 0) {
        const cargaInSameMonth = otherCargas.filter(citem => {
          const aDate = carga.data_entrega.split("-");
          const bDate = citem.data_entrega.split("-");
          return aDate[0] === bDate[0] && aDate[1] === bDate[1];
        });
        if (cargaInSameMonth.length > 0) {
          throw new PedidoError(
            "Não é permitido programar mais de uma carga para o mesmo mês, por favor escolha outra data"
          );
        }
      }
    }

    await checkCapacidade(this.programacao.tipo.toLowerCase(), this.programacao.codigo_carga || "", carga);

    const idx = this.programacao.cargas.findIndex(citem => citem.sequencia_carga === carga.sequencia_carga);
    if (idx >= 0) {
      this.programacao.cargas[idx] = carga;
    } else {
      this.programacao.cargas.push(carga);
    }

    if (!!pedidoSoltos && pedidoSoltos.length > 0) {
      for (let i = 0; i < pedidoSoltos.length; i++) {
        this.programacao.pedidos.push(pedidoSoltos[i]);
      }
    }

    return true;
  };
  editCarga = carga => {
    const { codigo_filial = "01", data_entrega, sequencia_carga, codigo_kit } = carga.asJSON;

    const cargaCopy = {
      codigo_filial,
      data_entrega,
      sequencia_carga,
      codigo_kit,
      items: carga.items.map(item => item.asPedido),
    };

    if (!!this._cargaModal) {
      this._cargaModal.open(cargaCopy, "edit");
    }
  };
  removeCarga = carga => {
    const onOk = () => {
      this.programacao.cargas = this.programacao.cargas.filter(
        c => !(c.sequencia_carga === carga.sequencia_carga && c.data_entrega === carga.data_entrega)
      );
    };
    Modal.confirm({
      title: "Você deseja remover esta carga da programação?",
      onOk,
    });
  };
  saveProgramacao = () => {
    const frequenciaCliente = this.programacao.cargas.reduce((acc, carga) => {
      for (let i = 0, ic = carga.uniqueClientes.length; i < ic; i++) {
        const cliente = carga.uniqueClientes[i];
        if (!acc[cliente]) {
          acc[cliente] = 0;
        }
        acc[cliente]++;
      }
      return acc;
    }, {});

    const clientes = Object.keys(frequenciaCliente);
    const minimo_clientes = Math.max(+this.programacao.validacao_clientes.minimo_clientes_programacao, 1);
    for (let j = 0, jc = clientes.length; j < jc; j++) {
      if (frequenciaCliente[clientes[j]] < minimo_clientes) {
        onCargaError(
          `Número mínimo de programações por cliente não atingido. Programe pelo menos ${minimo_clientes} cargas por cliente para poder prosseguir.`
        );
        return;
      }
    }

    const onOk = async () => {
      this.programacao.isLoading = true;

      try {
        for (const carga of this.programacao.cargas) {
          await checkCapacidade(this.programacao.tipo.toLowerCase(), this.programacao.codigo_carga || "", carga);
        }

        const response = await saveCarga(this.programacao.tipo, this.programacao.exportData());
        if (!!response.success) {
          const { history } = this.props;

          Modal.success({
            title: `Programação de Carga (${this.programacao.tipo.toUpperCase()}) enviada com sucesso!`,
            content: "Por favor aguarde alguns instantes até o processamento de status.",
            onOk: () =>
              history.replace("/pedidos/#" + (this.programacao.tipo === "kit" ? "tab-camp-kit" : "tab-safra")),
            autoFocusButton: "ok",
            keyboard: false,
          });
        } else {
          throw new PedidoError(
            "Não foi possível salvar sua programação de carga. Por favor tente novamente mais tarde."
          );
        }
      } catch (err) {
        const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
        if (+errorCode === 10) {
          const { data } = err.response.data;
          onCargaError(
            `O pedido compartilhado ${data.numero_pedido} / ${data.codigo_filial} não está mais disponível para uso. Remova de sua programação ou entre em contato com o vendedor que havia lhe cedido este pedido.`
          );
        } else {
          onCargaError(
            err.name === "PedidoError" && !!err.message
              ? err.message
              : "Não foi possível salvar sua programação de carga. Por favor tente novamente mais tarde."
          );
        }
      } finally {
        if (!!this.programacao) this.programacao.isLoading = false;
      }

      return true;
    };

    Modal.confirm({
      title: `Você confirma o envio desta ${LABELS_TMPL[this.programacao.tipo].titleConfirm}?`,
      onOk,
    });
  };
  render() {
    const { store } = this.props;
    const { started } = this.state;
    const labels = LABELS_TMPL[this.programacao.tipo];
    const title = !this.programacao.isEdit ? labels.titleAdd : labels.titleEdit;
    const isLoading = !started || store.isLoading || this.programacao.isLoading;

    return (
      <Fragment>
        <div className="carga-editor-screen">
          {!isLoading && (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
          <Spin spinning={isLoading}>
            {started ? (
              <Fragment>
                <PageHeader
                  title={title}
                  headerLeft={<BackButton onClick={this.stepBack} />}
                  headerRight={
                    <Fragment>
                      <Button icon="plus" type="danger" onClick={this.novaCarga} style={{ marginRight: 9 }}>
                        {labels.btnNewCarga}
                      </Button>
                      <Button disabled={!this.programacao.isValid} type="primary" onClick={this.saveProgramacao}>
                        <Icon type="file-add" /> {!this.programacao.isEdit ? labels.btnSaveAdd : labels.btnSaveEdit}
                      </Button>
                    </Fragment>
                  }
                  inside={true}
                />
                <div className="carga-editor--content">
                  <CargaTable
                    isLoading={this.props.store.isLoading}
                    programacao={this.programacao}
                    cargas={this.programacao.cargas.map(carga => carga.asJSON)}
                    onEdit={this.editCarga}
                    onRemove={this.removeCarga}
                    locale={{
                      emptyText: (
                        <Fragment>
                          Sem cargas programadas até o momento. Clique em <strong>"+ {labels.btnNewCarga}"</strong> para
                          adicionar uma.
                        </Fragment>
                      ),
                    }}
                  />
                </div>
                {!!this.programacao && (
                  <div className="carga-editor--footer">
                    <div className="footer-label">
                      <strong>Total NF (R$):</strong> {n(this.programacao.total_nota)}
                    </div>
                    <div className="footer-label">
                      <strong>Peso Total (KG):</strong> {n(this.programacao.total_peso)}
                    </div>
                  </div>
                )}
              </Fragment>
            ) : (
              <div style={{ minHeight: 480 }} />
            )}
          </Spin>
        </div>
        <CargaModal
          ref={_ref => (this._cargaModal = _ref)}
          isLoading={this.props.store.isLoading}
          programacao={this.programacao}
          onCargaSave={this.saveCarga}
          onCargaError={onCargaError}
        />
      </Fragment>
    );
  }
  _cargaModal;
}

export default withStore(CargaEditorScreen);
