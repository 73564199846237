import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker, Modal } from "antd";
import { observer } from "mobx-react";

import { filterDate, getData } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue, SearchForm } from "components/FullTable";
import { fetchNfMail } from "actions/relatorios";


const FormItem = Form.Item;

@observer
class NotasFiscaisScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Notas Fiscais",
      uri: !isMop ? "/notas_fiscais" : "/notas_fiscais/mop",
      state: {
        uri_xml: null,
        uri_nfe: null,
      },
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  
  handleEmail = (hash) => {
    this.fetchNfMail(hash);
  }
  
  async fetchNfMail(hash) {
    
    const { history, store } = this.props;
    store.isLoading = true;
    
    try {
      const response = await fetchNfMail(hash);
      
      if (!!response.success) {
        const { options } = response.data;
        Modal.success({
          title: "E-mail enviado com sucesso!",
          content: "O e-mail com a NF será enviada para o cliente/representante dentro de alguns minutos.",
          keyboard: false,
        });

      } else {
        throw new Error("Não foi possível enviar e-mail da nota neste momento");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;
      const errorMessages = {
        1: "Não foi possível enviar e-mail da nota neste momento",
        10: "E-mail não encontrado no sistema ou indisponível no momento",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || errorMessages[1],
      });
    } finally {
      store.isLoading = false;
    }

  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          emissao$0: "date",
          emissao$1: "date",
        }}
        filterParser={{
          emissao$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          emissao$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem label="Filtrar por Emissão">
              {form.getFieldDecorator("emissao$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("emissao$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const { store } = this.props;
    
    const isB2B = store.isB2B;

    const columns = [
      {
        title: "Emissão",
        dataIndex: "NotaFiscal.emissao",
        dataType: "date",
        key: "emissao",
        sorter: true,
        width: "10.5%",
        filteredValue: this.ds.filterValue("emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "NotaFiscal.emissao",
          type: "str_date",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "NF / Série",
        dataIndex: "NotaFiscal.numero_nf",
        key: "numero_nf",
        sorter: true,
        render: (text, record) => (
          <WrappedValue value={`${record.NotaFiscal.numero_nf} / ${record.NotaFiscal.serie_nf}`} />
        ),
        width: "13%",
        filteredValue: this.ds.filterValue("numero_nf"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nº NF" {...props} />,
        filterParser: filter => ({ ...filter, field: "NotaFiscal.numero_nf", value: `%${filter.value}`, op: "LIKE" }),
        export: {
          label: "NF",
        },
      },
      {
        title: "Série",
        dataIndex: "NotaFiscal.serie_nf",
        exportOnly: true,
      },
      {
        title: "Código",
        dataIndex: "NotaFiscal.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (text, item) => (
          <WrappedValue value={`${item.NotaFiscal.codigo_cliente} / ${item.NotaFiscal.loja_cliente}`} type="text" />
        ),
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo_cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.25%",
      },
      {
        title: "Loja",
        dataIndex: "NotaFiscal.loja_cliente",
        exportOnly: true,
      },
      {
        title: "Cliente",
        dataIndex: "Cliente.razao_social",
        className: "text-left",
        key: "razao_social",
        sorter: true,
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "NotaFiscal.razao_social", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Valor",
        dataIndex: "NotaFiscal.valor_bruto",
        // dataType: "money",
        key: "valor_bruto",
        render: text => <WrappedValue value={text} type="money" />,
        sorter: true,
        width: "12.5%",
      },
      {
        title: "Ações",
        key: "actions",
        width: isB2B ? 340 : 250,
        render: (text, record) => (
          <div className="actions">
            <Button
              icon="mail"
              size="small"
              onClick={event => {
                event.preventDefault();
                this.handleEmail(record.NotaFiscal.chave_nf);
              }}
              type="primary">
              E-mail
            </Button>

            <Button
              icon="printer"
              href={`${this.ds.state.uri_nfe}${record.NotaFiscal.chave_nf}`}
              target="_blank"
              size="small"
              type="primary">
              NF-e
            </Button>
            <Button
              icon="download"
              href={`${this.ds.state.uri_xml}${record.NotaFiscal.chave_nf}`}
              size="small"
              type="danger">
              XML
            </Button>
            { isB2B && (
            <Button
              icon="printer"
              href={`${this.ds.state.uri_laudo}${record.NotaFiscal.arquivo_laudo}`}
              size="small"
              target="_blank"
              disabled={!record.NotaFiscal.arquivo_laudo}
              type="danger">
              Laudo
            </Button>
            )}
          </div>
        ),
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
          {this.props.store.isOperador && (
            <Button
              title="Modo de listagem de NF-e do operador"
              type="primary"
              icon="filter"
              ghost={!!this.state.isMop}
              onClick={() => this.props.history.push(!this.state.isMop ? `/notas-fiscais-mop` : `/notas-fiscais`)}>
              {this.state.isMop ? `Apenas minhas NF-e` : `Todos os vendedores`}
            </Button>
          )}
        </PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          rowKey={item => `${item.NotaFiscal.numero_nf}:${item.NotaFiscal.serie_nf}:${item.NotaFiscal.loja_cliente}`}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas notas fiscais no momento"
        />
      </div>
    );
  }
}

export default withStore(NotasFiscaisScreen);
