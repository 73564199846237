import React, { Component, Fragment } from "react";
import { Modal, Button, Spin, Icon, Row, Col, Checkbox, message, Form } from "antd";
import moment from "moment";
import styled from "styled-components";
import Select from "components/Select";
import TextArea from "antd/lib/input/TextArea";
import { aprovarOuReprovar } from "actions/memorando";

const antIcon = <Icon type="loading" style={{ fontSize: 42 }} spin />;

const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;

  > strong {
    display: block;
  }
`;

const MemoDetalhesWrapper = styled.div`
  .ant-row {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const Tag = styled.div`
  display: inline-block;
  background-color: ${props => props.situacao === "Aprovado" ? '#33af1a' : (props.situacao === "Reprovado" ? '#ac1a22' : '#4a87c9')};
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

const Item = ({ label = null, children, InnerHTML = {}, ...props }) => (
  <ItemWrapper {...props} >
    {!!label && <strong>{`${label}:`}</strong>}
    {!!InnerHTML?.__html ?
      <div dangerouslySetInnerHTML={InnerHTML}></div>
      : children}
  </ItemWrapper>
);


class ModalMemorando extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: "Memorando",
      visibleAprovacao: false,
      isAprovando: false,
      obs: "",
      data: null,
      loading: false
    };
  }

  open = (customTitle, data) => {
    this.setState({ data }, () => this.setState({ visible: true, title: customTitle }))
  }

  close = () => {
    this.setState({ visible: false, obs: null });
  }

  approvalModal = (is) => {
    this.setState({ visibleAprovacao: true, isAprovando: is });
  }

  setApproval = async () => {
    try {
      this.setState({ loading: true })
      const data = {
        obs: this.state.obs,
        chave: this.state.data?.Processos.chave,
        status: this.state.isAprovando ? "L" : "R"
      }
      const response = await aprovarOuReprovar(data);

      if (response.success) {
        message.success(`Memorando ${this.state.isAprovando ? "aprovado" : "reprovado"} com sucesso!`);
        this.setState({ visibleAprovacao: false, visible: false, obs: "" }, () => this.props.onClose && this.props.onClose())
      }
    } catch (error) {
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const { visible, title, data } = this.state;

    return (
      <Fragment>
        <Modal
          width={700}
          title={
            <Row gutter={12}>
              <Col span={16} style={{ textAlign: 'center' }}>{title}</Col>
              <Col span={7} style={{ textAlign: 'center' }}>
                <Col span={12}>
                  <Button key="reprovar"
                    type="danger"
                    disabled={data?.Processos.status !== "Pendente" || data?.Processos.isaprovador === 'N'}
                    onClick={() => this.approvalModal(false)}
                    style={{ marginRight: 10 }}
                  >
                    Reprovar
                  </Button>
                </Col>
                <Col span={12}>
                  <Button key="aprovar"
                    type="primary"
                    disabled={data?.Processos.status !== "Pendente" || data?.Processos.isaprovador === 'N'}
                    onClick={() => this.approvalModal(true)}
                  >
                    Aprovar
                  </Button>
                </Col>
              </Col>
            </Row>
          }
          visible={visible}
          onCancel={this.close}
          footer={[
            <Button key="back" onClick={this.close}>
              Voltar
            </Button>
          ]}
        >
          <MemoDetalhesWrapper>
            <Row gutter={12}>
              <Col span={24}>
                <Item label="Aprovador">
                  {data?.Processos.nome_aprovador}
                </Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <Item label="De">
                  {data?.Processos.nome_solicitante}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Setor">
                  {data?.Processos.setor_origem}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Para">
                  {data?.Processos.nome_destinatario}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Setor">
                  {data?.Processos.setor_destino}
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Item label="Assunto">
                  {data?.Processos.motivo}
                </Item>
              </Col>
            </Row>
            {data?.Processos.centro_custo &&
              <Item label="Centro de custo #1">
                <Row gutter={12}>
                  <Col span={8}>
                    {data?.Processos.centro_custo}
                  </Col>
                  <Col span={16}>
                    Padrão
                  </Col>
                </Row>
              </Item>
            }
            {data?.Processos.centro_custo2 &&
              <Item label="Centro de custo #2">
                <Row gutter={12}>
                  <Col span={8}>
                    {data?.Processos.centro_custo2}
                  </Col>
                  <Col span={16}>
                    Padrão
                  </Col>
                </Row>
              </Item>
            }
            {data?.Processos.centro_custo3 &&
              <Item label="Centro de custo #3">
                <Row gutter={12}>
                  <Col span={8}>
                    {data?.Processos.centro_custo3}
                  </Col>
                  <Col span={16}>
                    Padrão
                  </Col>
                </Row>
              </Item>
            }
            {data?.Processos.centro_custo4 &&
              <Item label="Centro de custo #4">
                <Row gutter={12}>
                  <Col span={8}>
                    {data?.Processos.centro_custo4}
                  </Col>
                  <Col span={16}>
                    Padrão
                  </Col>
                </Row>
              </Item>
            }
            {data?.Processos.centro_custo5 &&
              <Item label="Centro de custo #5">
                <Row gutter={12}>
                  <Col span={8}>
                    {data?.Processos.centro_custo5}
                  </Col>
                  <Col span={16}>
                    Padrão
                  </Col>
                </Row>
              </Item>
            }
            <Row>
              <Col span={18}>
                <Item label="Memorando Informativo?">
                  <Checkbox checked={data?.Processos.informativo === 'SIM'} style={{ cursor: 'default' }}>Sim</Checkbox>
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Depósito">
                  <Select
                    name="Depósito"
                    value={data?.Processos.deposito === 'SIM' ? "Sim" : "Não"}
                    options={["Sim", "Não"]}
                    open={false}
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Item label="Participantes">
                {data?.Participante?.map((value) => (
                  <div>{value.nome_colaborador}</div>
                ))}
              </Item>
            </Row>
            <Row>
              <Item label="Aprovadores">
                {data?.Colaborador?.map((value) => (
                  <Row>
                    <Item><span style={{ fontWeight: 500 }}>Nome: </span>{value.nome_colaborador}</Item>
                    <Item><span style={{ fontWeight: 500 }}>Data: </span>{value.data_hora_interacao}</Item>
                    {value.tipo_interacao === "Aprovado" ?
                      <Fragment>
                        <Item><Tag situacao={value.tipo_interacao}>Aprovado</Tag></Item>
                        <Item><span style={{ fontWeight: 500 }}>Ressalva aprovação: </span>{value.motivo_rejeicao}</Item>
                      </Fragment>
                      :
                      (value.tipo_interacao === "Reprovado" ?
                        <Fragment>
                          <Item><Tag situacao={value.tipo_interacao}>Reprovado</Tag></Item>
                          <Item><span style={{ fontWeight: 500 }}>Motivo rejeição: </span>{value.motivo_rejeicao}</Item>
                        </Fragment>
                        :
                        <Item><Tag situacao={value.tipo_interacao}>Pendente</Tag></Item>)
                    }
                  </Row>
                ))}
              </Item>
            </Row>
            <Row>
              <Item label="Descrição"
                InnerHTML={{ __html: data?.Processos.descricao?.split("\n").join("<br />") }}>
              </Item>
            </Row>
          </MemoDetalhesWrapper>
        </Modal>
        <Modal
          title={<div>{this.state.isAprovando ? "Aprovação" : "Reprovação"}</div>}
          visible={this.state.visibleAprovacao}
          onCancel={() => this.setState({ visibleAprovacao: false, obs: "" })}
          footer={[
            <Button onClick={() => this.setState({ visibleAprovacao: false, obs: "" })}
              loading={this.state.loading}>
              Voltar
            </Button>,
            <Button type="primary"
              onClick={() => this.setApproval()}
              loading={this.state.loading}
              disabled={!this.state.isAprovando ? (!this.state.obs || this.state.obs.length <= 6) : false}>
              {this.state.isAprovando ? "Aprovar" : "Reprovar"}
            </Button>
          ]}>
          <Form.Item label="Motivo:" required={!this.state.isAprovando}>
            <TextArea rows={4}
              placeholder={`Insira o motivo da ${this.state.isAprovando ? "aprovação" : "reprovação"} aqui` + (!this.state.isAprovando ? " (mínimo de 6 caracteres)" : "")}
              onChange={({ target: { value } }) => this.setState({ obs: value })}
              value={this.state.obs} />
          </Form.Item>
        </Modal>
      </Fragment>
    );
  }
}

export default ModalMemorando;
