import React, { Component, Fragment } from "react";
import { Modal, Alert, Form, DatePicker, Checkbox, Button, Icon } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import FullTable, { SearchForm, Toolbar, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import StatusCircle from "components/pedidos/StatusCircle";
import { IconConstrutora } from "./TabPedidos";

const FormItem = Form.Item;

const rowKey = item => `${item.Pedido.numero_pedido}:${item.Pedido.codigo_filial}`;

@observer
export default class TabResiduo extends Component {
  constructor(props) {
    super(props);
    
    this.ds = new TableDataSource("/pedidos/residuo");

    this.state = {};
  }
  clickActions(pedido, event = {}) {
    const { history, store } = this.props;
    const { key = "menu-edit" } = event;

    const isOperadorAdmin = store.isOperadorAdmin && store.testLoginOriginal(store.loginData.login);

    switch (key) {
      default:
        if (isOperadorAdmin) {
          Modal.warning({
            title: "Pedido bloqueado para edição",
            content: "Super administradores não podem editar um pedido sem selecionar um operador",
          });
          return;
        }
        history.push(`/pedidos/residuo/edit/${pedido.numero_pedido}-${pedido.codigo_filial}`);
        break;
    }
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    return (
      <Fragment>
        <Alert
          message={
            <Fragment>
              Listagem de pedidos com resíduo nos últimos <em>30 dias</em>.
            </Fragment>
          }
          type="info"
          showIcon
          style={{ margin: "-1px 0 14px" }}
        />
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          onUpdateDataSource={onUpdateDataSource}
          filterFields={{
            data_emissao$0: "date",
            data_emissao$1: "date",
            total_residuo: "checkbox",
          }}
          filterParser={{
            data_emissao$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
            data_emissao$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
            total_residuo: filter => ({ ...filter, field: "Pedido.total_residuo", value: 1000, op: ">=" }),
          }}
          style={{ marginBottom: 12 }}>
          {({ form, submitForm }) => (
            <Fragment>
              <FormItem style={{ float: "right", margin: 0 }}>
                <Toolbar.Button
                  disabled={dataSource.isLoading}
                  size="default"
                  icon="reload"
                  ghost={true}
                  onClick={() => dataSource.fetch()}>
                  Atualizar
                </Toolbar.Button>
              </FormItem>
              <FormItem label="Emissão do Pedido">
                {form.getFieldDecorator("data_emissao$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
              </FormItem>
              <FormItem>
                {form.getFieldDecorator("data_emissao$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
              </FormItem>
              <FormItem>
                <Button type="danger" htmlType="submit" icon="search" />
              </FormItem>
              {!!dataSource.hasFilter && (
                <FormItem>
                  <Toolbar.Button
                    size="default"
                    icon="delete"
                    ghost={true}
                    onClick={() => onUpdateDataSource(null, {}, null)}>
                    Limpar Filtros
                  </Toolbar.Button>
                </FormItem>
              )}
              <FormItem>
                {form.getFieldDecorator("total_residuo", { valuePropName: "checked" })(
                  <Checkbox onClick={() => setTimeout(submitForm, 60)}>Apenas pedidos acima de R$ 1.000,00</Checkbox>
                )}
              </FormItem>
            </Fragment>
          )}
        </SearchForm>
      </Fragment>
    );
  };
  renderActionMenu = (text, item) => {
    const pedido = toJS(item.Pedido);
    const { store } = this.props;

    return (
      <div className="actions">
        <Button onClick={this.clickActions.bind(this, pedido)} title="Alterar Resíduo" disabled={!store.isB2B} size="small" type="primary">
          <Icon type="edit" style={{ color: "#fff" }} />
          Alterar
        </Button>
      </div>
    );
  };
  render() {
    const { store } = this.props;

    const columns = [
      {
        title: null,
        key: "term",
        sorter: false,
        render: (_, item) => (
          <StatusCircle danger={!!item.Pedido.analise_pedido && item.Pedido.analise_pedido !== "NAO"} />
        ),
        className: "text-center pedido-status-column",
        width: "28px",
      },
      {
        title: "Emissão",
        dataIndex: "Pedido.data_emissao",
        dataType: "date",
        key: "data_emissao",
        sorter: true,
        width: "9.75%",
        filteredValue: this.ds.filterValue("data_emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, type: "date", value: filterDate(filter.value) }),
      },
      {
        title: "Filial",
        dataIndex: "Filial.nome_filial",
        key: "nome_filial",
        sorter: true,
        render: (_, item) => (
          <WrappedValue value={`${item.Pedido.codigo_filial} - ${item.Filial.nome_filial}`} type="text" />
        ),
        width: "11%",
        filteredValue: this.ds.filterValue("nome_filial"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Filial" {...props} />,
        filterParser: filter => ({ ...filter, field: "Filial.nome_filial", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Número",
        dataIndex: "Pedido.numero_pedido",
        key: "numero_pedido",
        sorter: true,
        width: "9.5%",
        filteredValue: this.ds.filterValue("numero_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Número" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.numero_pedido", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Tipo",
        dataIndex: "Pedido.tipo_pedido",
        key: "tipo_pedido",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("tipo_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Tipo" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.tipo_pedido", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Código",
        dataIndex: "Pedido.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (_, item) => (
          <WrappedValue value={`${item.Pedido.codigo_cliente} / ${item.Pedido.loja_cliente}`} type="text" />
        ),
        width: "9.75%",
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Pedido.codigo_cliente", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Cliente",
        dataIndex: "Cliente.razao_social",
        key: "razao_social",
        sorter: true,
        render: (text, item) => (
          <WrappedValue
            value={
              <Fragment>
                {item.Cliente.construtora === "S" && <IconConstrutora style={{ marginRight: 5 }} />}
                {text}
                {store.isTipoVendaInterna && !!store.vendedoresRelacionados[item.Pedido.codigo_vendedor] && (
                  <div>
                    <strong>Vendedor:</strong> {store.vendedoresRelacionados[item.Pedido.codigo_vendedor]}
                  </div>
                )}
              </Fragment>
            }
            title={text}
            type="text"
            style={{ fontSize: 11 }}
          />
        ),
        className: "text-left",
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Cliente.razao_social", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Valor NF",
        dataIndex: "Pedido.total_residuo",
        dataType: "money",
        key: "total_residuo",
        sorter: true,
        width: "9%",
      },
      {
        title: "Tipo Ped.",
        dataIndex: "Pedido.tipo_ped",
        key: "tipo_ped",
        sorter: true,
        width: "9%",
      },
      {
        title: "Ações",
        key: "actions",
        width: 102,
        render: this.renderActionMenu,
      },
    ];

    return (
      <FullTable
        className="full-table__compact"
        beforeComponent={this.renderSearchForm}
        actionBar={"none"}
        rowKey={rowKey}
        loading={this.props.store.isLoading}
        columns={columns}
        dataSource={this.ds}
        errorMessage="Não foi possível obter seus pedidos no momento"
      />
    );
  }
}
