import React, { Component, Fragment } from "react";
import { Row, Col, Button } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";

import { dateFormat, n } from "@util";

function diasFormatter(value) {
  value = Math.floor(+value);
  return `${value} dia${value > 1 ? "s" : ""}`;
}

function condicaoExists(condicao) {
  return !!condicao && "codigo_condicao" in condicao && !!condicao.codigo_condicao;
}

const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;

  > strong {
    display: block;
  }
`;

const PedidoResumoWrapper = styled.div`
  .ant-row {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const Item = ({ label = null, children, ...props }) => (
  <ItemWrapper {...props}>
    {!!label && <strong>{`${label}:`}</strong>}
    {children}
  </ItemWrapper>
);

@observer
export default class PedidoResumo extends Component {
  render() {
    const { pedido, options, ...props } = this.props;
    const { data } = pedido;
    const { prev_cashback } = pedido;
    return (
      <PedidoResumoWrapper {...props}>
        {!!pedido.numero_pedido && (
          <Row gutter={12}>
            <Col span={24}>
              <Item label="Pedido Nº">{pedido.numero_pedido}</Item>
            </Col>
          </Row>
        )}
        <Row gutter={12}>
          <Col span={4}>
            <Item label="Filial Faturamento">{options.filiais[data.filial_faturamento] || "Sem Filial"}</Item>
          </Col>
          <Col span={14}>
            <Item label="Cliente">{`${data.codigo_cliente}/${data.loja_cliente} - ${data.razao_social}`}</Item>
          </Col>
          {!!data.codigo_alvara && (
            <Col span={6}>
              <Item label="Alvará">{data.codigo_alvara || "Sem Alvará"}</Item>
            </Col>
          )}
        </Row>
        {prev_cashback > 0 && (
        <Row gutter={12}>
            <Col span={6}>
              <Item label="Previsão Cashback">R$ {n(prev_cashback, 2)}</Item>
            </Col>
        </Row>
        )}
        <Row gutter={12}>
          <Col span={4}>
            <Item label="Prazo Médio Solvente">{diasFormatter(data.prazo_medio_solvente)}</Item>
          </Col>
          <Col span={5}>
            <Item label="Prazo Médio Tinta e Comp.">{diasFormatter(data.prazo_medio_tintacomp)}</Item>
          </Col>
          {!!data.programado && (
            <Col span={3}>
              <Item label="Programado">{dateFormat(data.programado)}</Item>
            </Col>
          )}
          <Col span={6}>
            <Item label="Status do Pedido">{options.tipoStatus[data.status_pedido] || "Sem Status"}</Item>
          </Col>
          {!!data.tipo_transp && (
            <Col span={6}>
              <Item label="Tipo Transporte">{options.tipoTransporte[data.tipo_transp] || "Sem Tipo Definido"}</Item>
            </Col>
          )}
        </Row>
        <Row gutter={12}>
          <Col span={4}>
              <Item label="Data da Última Compra">{dateFormat(data.ultima_compra)}</Item>
          </Col>
          <Col span={5}>
            <Item label="Último Faturamento">R$ {n(data.ultimo_faturamento)}</Item>
          </Col>
          <Col span={6}>
            <Item label="Último Prazo">{data.ultimo_prazo} Dias</Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={4}>
            <Item label="Tipo Frete">{options.tipoFrete[data.tipo_frete] || "CIF"}</Item>
          </Col>
          {data.tipo_frete === "F" && (
            <Col span={8}>
              <Item label="Transportadora">
                {!!data.codigo_transp ? `${data.codigo_transp} - ${data.nome_transp}` : "Sem Transp. Definida"}
              </Item>
            </Col>
          )}
          <Col span={4}>
            <Item label="Tipo Redesp.">{options.tipoRedesp[data.redesp_tipo] || "CIF"}</Item>
          </Col>
          {data.redesp_tipo === "F" && (
            <Col span={8}>
              <Item label="Transp. Redespacho">
                {!!data.redesp_transp
                  ? `${data.redesp_transp} - ${data.redesp_nome_transp}`
                  : "Sem Redespacho Definido"}
              </Item>
            </Col>
          )}
        </Row>
        <Row gutter={12}>
          <Col span={4}>
            <Item label="Forma de Pagto">{options.formaPagto[data.forma_pagto] || "Indef."}</Item>
          </Col>
          {!!pedido.isB2B && (condicaoExists(pedido.condicao_tc) || condicaoExists(pedido.condicao_sol)) ? (
            <Fragment>
              {condicaoExists(pedido.condicao_tc) && (
                <Col span={5} style={{ paddingLeft: 6, paddingRight: 6 }}>
                  <Item label="Condição de Pagto (T.C.)">{`${pedido.condicao_tc.codigo_condicao} - ${pedido.condicao_tc.descricao_condicao}`}</Item>
                </Col>
              )}
              {condicaoExists(pedido.condicao_sol) && (
                <Col span={5} style={{ paddingLeft: 6, paddingRight: 6 }}>
                  <Item label="Condição de Pagto (Solv.)">{`${pedido.condicao_sol.codigo_condicao} - ${pedido.condicao_sol.descricao_condicao}`}</Item>
                </Col>
              )}
            </Fragment>
          ) : (
            <Col span={8}>
              <Item label="Condição de Pagto">
                {!!data.codigo_condicao
                  ? `${data.codigo_condicao} - ${!!data.nome_condicao ? data.nome_condicao : "Carregando..."}`
                  : "Indef."}
              </Item>
            </Col>
          )}
          <Col span={4}>
            <Item label="Op. Logística">{options.logistica[data.op_logistica] || "Indef."}</Item>
          </Col>
          {!!data.bonif_amostra_brinde && (
            <Col span={6}>
              <Item label="Mot. Bonif / Amostra / Brinde">
                {options.bonifAmostraBrinde[data.bonif_amostra_brinde] || ""}
              </Item>
            </Col>
          )}
        </Row>
        {(!!data.obs_carregto || !!data.obs_nota) && (
          <Row gutter={12}>
            {!!data.obs_carregto && (
              <Col span={12}>
                <Item label="Obs. Carregamento">{data.obs_carregto}</Item>
              </Col>
            )}
            {!!data.obs_nota && (
              <Col span={12}>
                <Item label="Obs. para NF">{data.obs_nota}</Item>
              </Col>
            )}
          </Row>
        )}
      </PedidoResumoWrapper>
    );
  }
}
