import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import moment from "moment";

@observer
class MaquinasReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Máquinas",
      uri: "/r/maquinas",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  
  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "Maquina.cod_produto",
        key: "Maquina.cod_produto",
        sorter: true,
        filteredValue: this.ds.filterValue("cod_produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por código de cod_produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "cod_produto", value: `%${filter.value}%`, op: "LIKE" }),
        width: "6%",
      },
      {
        title: "Produto",
        dataIndex: "Maquina.produto",
        key: "Maquina.produto",
        sorter: false,
        filteredValue: this.ds.filterValue("produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome do produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "produto", value: `%${filter.value}%`, op: "LIKE" }),
        width: "24%",
      },
      {
        title: "Nm. série",
        dataIndex: "Maquina.serie",
        key: "Maquina.serie",
        sorter: true,
        filteredValue: this.ds.filterValue("serie"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo número de série" {...props} />,
        filterParser: filter => ({ ...filter, field: "serie", value: `%${filter.value}%`, op: "LIKE" }),
        width: "10%",
      },
      {
        title: "Cód. cliente",
        dataIndex: "Maquina.cod_cliente",
        key: "Maquina.cod_cliente",
        sorter: true,
        filteredValue: this.ds.filterValue("cod_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código do Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cod_cliente", value: `%${filter.value}%`, op: "LIKE" }),
        width: "6%"
      },
      {
        title: "Cliente",
        dataIndex: "Maquina.cliente",
        key: "Maquina.cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome do cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}%`, op: "LIKE" }),
        width: "20%"
      },
      {
        title: "Gerente",
        dataIndex: "Maquina.gerente",
        key: "Maquina.gerente",
        sorter: false,
        filteredValue: this.ds.filterValue("gerente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome do gerente" {...props} />,
        filterParser: filter => ({ ...filter, field: "gerente", value: `%${filter.value}%`, op: "LIKE" }),
        width: "12%"
      },
      {
        title: "Representante",
        dataIndex: "Maquina.representante",
        key: "Maquina.representante",
        sorter: false,
        filteredValue: this.ds.filterValue("representante"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome do representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "representante", value: `%${filter.value}%`, op: "LIKE" }),
        width: "12%"
      },
      {
        title: "Emissão",
        dataIndex: "Maquina.emissao",
        key: "Maquina.emissao",
        width: "6%",
        dataType: "date",
        render: (text, item) => (<WrappedValue value={`${moment(item.Maquina.emissao, 'YYYYMMDD').format('DD-MM-YYYY')}`}/>)
      },
      {
        title: "Modalidade",
        dataIndex: "Maquina.modalidade",
        key: "Maquina.modalidade",
        sorter: false,
        filteredValue: this.ds.filterValue("modalidade"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pela modalidade" {...props} />,
        filterParser: filter => ({ ...filter, field: "modalidade", value: `%${filter.value}%`, op: "LIKE" }),
        width: "10%"
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          loading={this.props.store.isLoading}
          rowKey={item => `${item.Maquina.id}` }
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas maquinas no momento"
        />
      </div>
    );
  }
}

export default withStore(MaquinasReportScreen);
