import React, { createRef } from "react";
import { Button, Card, Col, Icon, List, Modal, Row, Spin, Upload, message } from "antd";
import { observer } from "mobx-react";
import { PureComponent } from "react";
import withStore from "withStore";
import { fetchApi, fetchPostApi } from "actions";
import moment from "moment";
import styled from "styled-components";

const LabelGridText = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #363636;
`;
class FileInput extends PureComponent {
  render() {
    const { isLoading = false, disabled = false, ...props } = this.props;

    return (
      <Upload beforeUpload={file => false} accept='.jpg, .jpeg, .png, .xls, .pdf, .docx, .xlsx' multiple={true} action={null} {...props}>
        <Button disabled={disabled}>
          <Icon type="upload" /> Selecione um arquivo
        </Button>
      </Upload>
    );
  }
}

@observer
class AnexosModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anexos: [],
      atividades: [],
      fotosVideosVSI: [],
      isLoading: false
    }

    this.inputFileRefLista = createRef();
    this.inputFileRefFotoVideo = createRef();

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleClose() {
    this.props.handleClose();
  }

  fetchAnexos = async () => {
    try {
      this.setState({ isLoading: true });
      const { data } = await fetchApi(`/promotoria/buscar-anexos-promotoria/${this.props.promotoria.Promotoria.r_e_c_n_o_}`);
      this.setState({ anexos: data.anexos, atividades: data.atividades, fotosVideosVSI: data.fotosVideosVSI });
    } catch (error) {
      console.log(error)
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.fetchAnexos();
  }

  handleSave = async (tipo, file, id) => {
    try {
      this.setState({ isLoading: true });
      this.props.promotoria.isLoading = true;

      const formData = new FormData();

      formData.append('cod_sol', this.props.promotoria.Promotoria.zy7_codsol);
      formData.append('recno', id);
      formData.append('anexoFile', file);
      formData.append('tipo', tipo);

      await fetchPostApi(`/promotoria/salvar-lancamentos`, formData, true);
      
      if (this.inputFileRefLista.current) {
        this.inputFileRefLista.current.value = null;
      }
      
      if (this.inputFileRefFotoVideo.current) {
        this.inputFileRefFotoVideo.current.value = null;
      }

      Modal.success({
        title: 'Anexo',
        content: 'Anexo enviado com sucesso!'
      });
      this.fetchAnexos();
      this.forceUpdate();
    } catch (error) {
      console.log(error)
      if(!!error.response){
        return message.error(error.response.data.message);
      }
      
      message.error("Erro ao tentar salvar anexo");
    } finally {
      this.props.promotoria.isLoading = false;
      this.setState({ isLoading: false });
    }
  }

  downloadFile = async (anexo) => {
    try {
      this.setState({ isLoading: true });
      const response = await fetchApi(`/promotoria/download-documento/${anexo.codigo}/${anexo.referencia}`, {
        responseType: 'base64',
        method: 'GET'
      });

      // Decodifica a resposta da API de base64
      const decodedData = atob(response.data.base64);

      // Converte a string decodificada em um ArrayBuffer
      const buffer = new ArrayBuffer(decodedData.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < decodedData.length; i++) {
        view[i] = decodedData.charCodeAt(i);
      }

      // Cria um Blob a partir do ArrayBuffer
      const blob = new Blob([buffer], { type: `application/${response.data.nome_arquivo.extension}` });

      // Cria um URL temporário para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${response.data.nome_arquivo.basename}.${response.data.nome_arquivo.extension}`; // Substitua pelo nome do arquivo e extensão desejados

      // Dispara o clique no link para iniciar o download
      a.click();

      // Libera o URL temporário
      URL.revokeObjectURL(url);
    } catch (error) {
      if (!!error.response) {
        Modal.warning({
          title: 'Anexo',
          content: error.response.data?.message
        });
        return;
      }

      Modal.warning({
        title: 'Anexo',
        content: "Erro ao baixar o anexo"
      })
    } finally {
      this.setState({ isLoading: false });
    }
  };


  async downloadPDF(anexo) {

    if (anexo === null) {
      message.info("Documento é nulo. Não é possível realizar o download.");
      return;
    }

    await this.downloadFile(anexo);
  }

  returnTipoAtividade(atividade) {
    if (atividade === "V") return "Tipo atividade: Visitas";

    if (atividade === "P") return "Tipo atividade: Palestra/Treinamento";

    // if (atividade === "T") return "Tipo atividade: Treinamento";

    if (atividade === "I") return "Tipo atividade: Instalação";

    if (atividade === "S") return "Tipo atividade: Sac";

    if (atividade === "F") return "Tipo atividade: Treinamento fábrica";
  }

  handleRemove = async (anexo) => {
    try {
      this.setState({ isLoading: true });
      const formData = new FormData();

      formData.append('recno', anexo.codigo);
      formData.append('diretorio', anexo.diretorio_portal)

      await fetchPostApi(`/promotoria/remove-anexo`, formData);
      Modal.success({
        title: 'Anexo',
        content: 'Anexo removido com sucesso!'
      });
      this.fetchAnexos();
      this.forceUpdate();
    } catch (error) {
      Modal.error({
        title: 'Anexo',
        content: 'Erro ao remover anexo!'
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Modal
        title="Anexos"
        visible={this.props.isVisible}
        okButtonProps={{ hidden: true }}
        onCancel={this.handleClose}
        width="90%"
        cancelText="Fechar"
      >
        <Spin spinning={this.state.isLoading}>
          {this.state.atividades.map(atividade => (
            <Row gutter={6}>
              <Col span={8}>
                <Card title={this.returnTipoAtividade(atividade.zy8_tpativ)} bordered={false} style={{ width: 400 }}>
                  <h3>LISTA PRESENÇA</h3>
                  <h4>Código: {atividade.r_e_c_n_o_} | Data: {moment(atividade.zy8_dtvisi).format('DD/MM/YYYY')}</h4>
                  {!(this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible) && <input accept="image/*, .xls, .pdf, .docx, .xlsx" ref={this.inputFileRefLista} onChange={e => this.handleSave("PROMOTORIA_LISTA_PRESENCA", e.target.files[0], atividade.r_e_c_n_o_)} type="file" />}
                </Card>
              </Col>
              <Col span={8}>
                <Card title={this.returnTipoAtividade(atividade.zy8_tpativ)} bordered={false} style={{ width: 400 }}>
                <h3>FOTO OU VÍDEO</h3>
                  <h4>Código: {atividade.r_e_c_n_o_} | Data: {moment(atividade.zy8_dtvisi).format('DD/MM/YYYY')}</h4>
                  {!(this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible) && <input accept="image/*, .xls, .pdf, .docx, .xlsx, .mp4, .wav" ref={this.inputFileRefFotoVideo} onChange={e => this.handleSave("PROMOTORIA_FOTO_VIDEO", e.target.files[0], atividade.r_e_c_n_o_)} type="file" />}
                </Card>
              </Col>
              <Col span={8}>
                <Card title={this.returnTipoAtividade(atividade.zy8_tpativ)} bordered={false} style={{ width: 400 }}>
                <h3>CUPOM FISCAL</h3>
                  <h4>Código: {atividade.r_e_c_n_o_} | Data: {moment(atividade.zy8_dtvisi).format('DD/MM/YYYY')}</h4>
                  {!(this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible) && <input accept="image/*, .xls, .pdf, .docx, .xlsx, .mp4, .wav" ref={this.inputFileRefFotoVideo} onChange={e => this.handleSave("CUPOM_FISCAL", e.target.files[0], atividade.r_e_c_n_o_)} type="file" />}
                </Card>
              </Col>
            </Row>
          ))}
          {this.state.fotosVideosVSI.map(atividade => (
            <Row gutter={6}>
              <Col span={12}>
                <Card title={this.returnTipoAtividade(atividade.zy8_tpativ)} bordered={false} style={{ width: 400 }}>
                <h3>FOTO OU VÍDEO</h3>
                  <h4>Código: {atividade.r_e_c_n_o_} | Data: {moment(atividade.zy8_dtvisi).format('DD/MM/YYYY')}</h4>
                  {!(this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible) && <input accept="image/*, .xls, .pdf, .docx, .xlsx, .mp4, .wav" ref={this.inputFileRefFotoVideo} onChange={e => this.handleSave("PROMOTORIA_FOTO_VIDEO", e.target.files[0], atividade.r_e_c_n_o_)} type="file" />}
                </Card>
              </Col>
            </Row>
          ))}
          <List
            header={<div>Anexos</div>}
            bordered
            dataSource={this.state.anexos}
            renderItem={item => (
              <List.Item>
                <Col span={1}>
                  <Button
                    type="danger"
                    ghost
                    disabled={this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonsForVendendor || !this.props.store.isPromotor || this.props.promotoria.isDisabledPromotorNotResponsible}
                    onClick={() => this.handleRemove(item)}
                    style={{
                      width: "calc(5% - 4px)",
                      minWidth: 20,
                      height: 20,
                      padding: "3px 8px",
                      display: 'flex', // Tornar o container um flex container
                      justifyContent: 'center', // Centralizar horizontalmente
                      alignItems: 'center' // Centralizar verticalmente
                    }}
                  >
                    <Icon type="close" />
                  </Button>
                </Col>
                <Col span={4}>
                  <LabelGridText>Código: {item.codigo_registro}</LabelGridText>
                </Col>
                <Col span={17}>
                  <LabelGridText>Referência: {item.tipo_documento}</LabelGridText>
                </Col>
                <Col span={2}>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => this.downloadPDF(item)}
                    style={{
                      width: "calc(5% - 4px)",
                      minWidth: 30,
                      height: 30,
                      padding: "3px 8px",
                      display: 'flex', // Tornar o container um flex container
                      justifyContent: 'center', // Centralizar horizontalmente
                      alignItems: 'center' // Centralizar verticalmente
                    }}
                  >
                    <Icon type="cloud-download" />
                  </Button>
                </Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    );
  }
}

export default withStore(AnexosModal);
