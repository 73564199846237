/**
 * @flow
 */

import { action, observable } from "mobx";
import { getJsonHeaders } from "@env";
import _snakeCase from "lodash/snakeCase";
import saveAs from "file-saver";

class ExportableStore {
  @observable action: string = "print";
  @observable title: string = null;
  @observable canCancel: boolean = true;
  @observable started: boolean = false;
  @observable cancelled: boolean = false;
  @observable progress: number = 0.0;

  worker;

  constructor() {
    this.worker = window.exportableWorker;
    this.worker.addEventListener("message", event => {
      const data = event.data;
      switch (data.action) {
        case "progress":
          this.progress = +data.data.progress;
          if (+this.progress >= 100) {
            this.canCancel = false;
          }
          break;
        case "finish":
          this.progress = 100;
          this.canCancel = false;

          if (this.action === "export") {
            saveAs(data.data.blob, (!!this.title ? _snakeCase(this.title) : "export") + ".xlsx");
          }

          setTimeout(() => {
            this.started = false;
            this.cancelled = true;
          }, 750);
          break;
        case "cancel":
          this.started = false;
          this.cancelled = true;
          break;
        case "error":
          console.error(data);
          break;
        case "log":
        default:
          console.log(data);
          break;
      }
    });
  }

  @action
  export({ title = "Exportação", source = null, columns = [], extraData = null }) {
    this.action = "export";
    this.canCancel = true;
    this.started = true;
    this.cancelled = false;
    this.title = title;

    if (typeof source === "function") {
      source = source();
    }

    source.headers = getJsonHeaders(source.headers || {});

    this.worker.postMessage({
      action: "start",
      format: "xlsx",
      title,
      source,
      columns,
      extraData,
    });
  }

  @action
  cancel() {
    try {
      this.worker.postMessage({ action: "cancel" });
    } catch (err) {
      this.started = false;
      this.cancelled = true;
    }
  }
}

export default new ExportableStore();
