import React, { Component } from "react";
import { fetchApi } from "actions";
import { Table } from "antd";
import moment from "moment";
import { WrappedValue } from "components/FullTable";

class ExpandedRowMixCliente extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Cód. Cliente",
        key: "cliente",
        dataIndex: "cliente",
        width: '11%'
      },
      {
        title: "Nome Cliente",
        key: "nome",
        dataIndex: "nome_cliente",
      },
      {
        title: "Volume",
        dataIndex: "volume",
        key: "volume",
        align: 'right',
        width: '10%',
        className: 'text-center',
        render: (uc) => (
          <WrappedValue type="decimal" value={uc} />
        )
      },
      {
        title: "Ult. Venda",
        key: "ult_venda",
        dataIndex: "ult_venda",
        width: '12%',
        className: 'text-center',
        render: (uc) => (
          <div>{moment(uc, 'YYYYMMDD').format('DD/MM/YYYY')}</div>
        )
      },
    ]

    this.state = {
      loading: false,
      data: [],
      item: props.item || {},
      expanded: props.expanded || false,
      mes: props.mes || moment()
    };
  }

  fetchClasse = async () => {
    this.setState({ loading: true });
    try {
      const { data: response } = await fetchApi("/pedidos/expanded_cliente_mix/" + this.state.mes.format("YYYYMMDD") + "/" + this.state.item?.classe);

      this.setState({ data: response.data })
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    if (this.state.expanded) {
      this.fetchClasse();
    }
  }

  render() {
    const { loading, data } = this.state;
    const { columns } = this;

    return (
      <Table
        rowKey={(item, i) => `${item.cliente}_${item.nome_cliente}:${i}`}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        className="ant-table-bordered" />
    );
  }
}

export default ExpandedRowMixCliente;
