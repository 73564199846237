import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import TicketsTasks from "components/dashboard/TicketsTasks";
import MixPanel from "components/dashboard/MixPanel";
import IndicadoresPanel from "components/dashboard/IndicadoresPanel";


@observer
class HomeScreen extends Component {
  render() {
    const {store} = this.props;
    const relacionadoData = toJS(store.relacionadoData);
    return (
      <div className="home-screen home-screen__vendedor home-screen__diretor">
         <MixPanel relacionadoData={relacionadoData}/>
         <IndicadoresPanel isTipoVendaInterna={store.isTipoVendaInterna} relacionadoData={relacionadoData} />
      </div>
    );
  }
}

export default withStore(HomeScreen);