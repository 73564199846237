import React, { Component } from "react";
import { Icon, Dropdown, Menu, Modal, Tag, Alert, Button } from "antd";
import { observer } from "mobx-react";
import _size from "lodash/size";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchApi } from "actions";
import AnexosModal from "../AnexosModal";
import classNames from "classnames";

const decorateRows = (item) =>
  classNames("base-table--row", {
    "base-table--row__danger": item.situacao == 'REPROVADO',
  });

@observer
class PendenciaScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: `Pendências`,
      uri: "/treinamento/pendencia/",
      defaultFilter: {
        empresa: this.props.store.treinamentoFornecedorData.codigo,
      },
    });

    this.state = {
      status: null
    };
  }

  tryDelete = async (id) => {
    try {
      this.props.store.isLoading = true;
      const { data: response } = await fetchApi("/treinamento/funcionario/deletar/" + id);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Funcionario #${id} excluido com sucesso`
        });
        this.ds.fetch();
      }
    } catch (error) {
      Modal.error({
        title: "Atenção",
        content: error
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleDelete = (funcionarioId) => {
    Modal.confirm({
      title: "Atenção",
      content: `Você realmente deseja excluir o funcionario #${funcionarioId}`,
      okType: 'danger',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        this.tryDelete(funcionarioId)
      }
    })
  }

  onClose = () => {
    this._anexosModal._reactInternals.child.stateNode.close();
    this.ds.fetch();
  }

  render() {
    const { match } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "id_treino",
        key: "id_treino",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("id_treino"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por cod. treinamento" {...props} />,
        filterParser: filter => ({ ...filter, field: "id_treino", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Funcionário",
        dataIndex: "funcionario",
        key: "funcionario",
        sorter: true,
        width: "30%",
        filteredValue: this.ds.filterValue("funcionario"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Funcionário" {...props} />,
        filterParser: filter => ({ ...filter, field: "funcionario", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (func, item) => {
          return (
            <WrappedValue value={<><Tag color="blue">{item.cpf}</Tag>{func}</>} />
          )
        }
      },
      {
        title: "Treinamento",
        dataIndex: "treinamento",
        key: "treinamento",
        sorter: true,
        width: "15%",
        filteredValue: this.ds.filterValue("treinamento"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por treinamento" {...props} />,
        filterParser: filter => ({ ...filter, field: "treinamento", value: `%${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Obs.",
        dataIndex: "obs",
        key: "obs",
        width: "13%",
        export: false,
        render: obs => {
          return (
            <WrappedValue value={obs || "--"} />
          )
        }
      },
      {
        title: "Vigência",
        dataIndex: "vigencia",
        key: "vigencia",
        width: "13%",
        export: false,
        render: (vig) => {
          if (vig) {
            return (
              <WrappedValue
                value={vig}
                type="date"
              />
            )
          }
          return <div>--</div>
        }
      },
      {
        title: "",
        key: "actionbar",
        width: "5%",
        export: false,
        render: (_, item) => {
          return (
            <Button
              onClick={() => this._anexosModal._reactInternals.child.stateNode.open(item.id_treino, "S", item.necessita_vigencia, item.id_funcio)}
              title="Monitorar anexos"
              size="small"
              type="primary">
              <Icon type="edit" />
            </Button>
          )
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        {/* <PageHeader title={this.ds.name}
          headerLeft={<BackButton onClick={() => history.push(`/empresa`)} />}
          headerRight={<Button onClick={() => history.push(`${history.location.pathname}/add`)} type="primary" icon="plus">Novo funcionário</Button>}>
        </PageHeader> */}
        <Alert
          message={`Somente treinamentos com pendência de anexos serão mostrados aqui, para avaliar os anexos de cada funcionário visite a página "Funcionários"`}
          style={{ fontSize: 16, letterSpacing: -0.5, fontWeight: "500", marginBottom: 21 }}
          type="info"
          showIcon
        />
        <Alert
          message={`Anexos nesta cor foram reprovados!`}
          style={{ fontSize: 16, letterSpacing: -0.5, fontWeight: "400", marginBottom: 21 }}
          type="error"
          showIcon
        />
        <FullTable
          rowKey={item => (`${item.codigo}:${item.nome}`)}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          rowClassName={decorateRows}
          errorMessage="Não foi possível obter os funcionários no momento"
        />
        <AnexosModal ref={_ref => (this._anexosModal = _ref)} isLoading={this.props.store.isLoading} match={match} onClose={this.onClose} />
      </div>
    );
  }
  _anexosModal
}

export default withStore(PendenciaScreen);
