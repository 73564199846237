import React, { Component, Fragment } from "react";
import { Modal, Table, Button, Icon, Tag } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";
import styled from "styled-components";

import { number_format, moneyFixed, dateFormat, n } from "@util";
import { WrappedValue, mapColumn } from "components/FullTable";
import { default as StyledProdutoTag } from "components/pedidos/ProdutoTag";

import "./ProdutoTable.less";

const nf = (n, d = 2) => number_format(+n, d, ",", ".");

const StyledTag = styled(({ invalid, ...props }) => <Tag {...props} />)`
  &.ant-tag {
    cursor: default;
  }
`;

//const rowKey = item =>
//  `${item.codigo_produto}:${item.classe_produto}:${!!item.codigo_tabela ? item.codigo_tabela : "0"}:${item.quantidade}`;

const rowKey = item =>
  `${item.item_produto}:${item.codigo_produto}:${item.classe_produto}:${item.neg_anterior}:${item.bonficado}:${!!item.codigo_tabela ? item.codigo_tabela : "0"}:${item.quantidade}`;

const _desc = (policy, k, label, min = 0) => {
  const desc = !!policy ? moneyFixed(Math.max(+policy[`desc_${k}`], min)) : 0.0;
  if (+desc > min) {
    return `${label}: ${Math.abs(desc)}%`;
  }

  return null;
};

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 9px;
  padding-right: 9px;
  margin-top: -1px;

  .footer-item {
    text-align: center;
    padding: 2px 5px;
    font-size: 11px;
    line-height: 17px;

    &--label {
      font-size: 16px;
      font-weight: 800;
    }
    &--value {
      font-size: 14px;
    }
  }
`;

const FooterItem = ({ label, children, ...props }) => (
  <div className="footer-item">
    <div className="footer-item--label">{label}</div>
    <div className="footer-item--value">{children}</div>
  </div>
);

const _retTitulo = (classe) => {
  if (classe === "03") {
    return "TINTAS - Faixas de desconto por volume";
  }
  else if (classe === "02") {
    return "COMPLEMENTOS - Faixas de desconto por volume";
  }
  else if (classe === "01") {
    return "SOLVENTES - Faixas de desconto por volume";
  }
  return null;
};

const CLASSES = {
  "01": "Solvente",
  "02": "Complemento",
  "03": "Tinta",
};

const pintaDesconto = (item, pedido, index) =>
  classnames("produto-table--row", {
    "produto-table--row__campanha": classifica(item.classe, item.desconto, pedido),
  });

function classifica(classe, desconto, pedido) {

  if (classe === "01" && desconto === pedido.descVolumeSolvente) {
    return true;
  }
  else if (classe === "02" && desconto === pedido.descVolumeComplemento) {
    return true;
  }
  else if (classe === "03" && desconto === pedido.descVolumeTinta) {
    return true;
  }
  return false;
}

const TableDetalheDescVol = ({ descontos, pedido, ...props }) => (
  <Table
    pagination={false}
    columns={[
      {
        title: "Faixa Inicial",
        dataIndex: "faixa_inicial",
        className: "text-center",
        render: value => `${n(value)} KG`,
      },
      {
        title: "Faixa final",
        dataIndex: "faixa_final",
        className: "text-center",
        render: value => `${n(value)} KG`,
      },
      {
        title: "Desconto",
        dataIndex: "desconto",
        className: "text-center",
        render: value => `${n(value)} %`,
      },
    ]}
    dataSource={descontos}
    rowClassName={item => (pintaDesconto(item, pedido))}
    rowKey={item => `${item.codigo}`}
    size="small"
    {...props}
  />
);


const ProdutoTag = styled(StyledProdutoTag)`
  &.ant-tag {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.3px;
    padding: 1px 3px;
    margin: 3px 3px 1px 0;
    height: auto;
    cursor: default;
  }
`;

const ProdutoExtra = observer(({ item }) => {
  const tags = [];
  if (item.neg_anterior === "N" && item.precoTabelaChanged) {
    tags.push({
      key: "preco_tabela",
      invalid: true,
      color: "#33af1a",
      children: `Prc. Tabela [expirado]`,
    });
  }
  if (item.neg_anterior === "S") {
    tags.push({
      key: "residuo",
      color: "#828282",
      children: `Resíduo`,
    });
  }
  if (item.bonificado === "S") {
    tags.push({
      key: "bonificado",
      color: "#c90076",
      children: `Bonificado`,
    });
  }
  if (item.hasCampanha) {
    tags.push({
      key: "camp",
      invalid: item.campanhaErrors.length > 0,
      color: "#33af1a",
      children: item.shortTagCampanha(),
    });
  }
  if (item.hasCampKit) {
    tags.push({
      key: "kit",
      invalid: item.campKitErrors.length > 0,
      color: "#5da0c6",
      children: item.campKitErrors.indexOf("data_final") !== -1 ? "KIT [expirado]" : "KIT",
    });
  }
  if (!!item.ped_compra) tags.push({ key: "ped_compra", color: "#0a67ea", children: `P.Compra: ${item.ped_compra}` });
  if (!!item.item_pc) tags.push({ key: "item_pc", color: "#0a67ea", children: `Item: ${item.item_pc}` });
  if (!!item.canChangeDesconto) {
    if (+item.desc_perfil > 0) {
      tags.push({
        key: `desc_perfil`,
        invalid: item.descontoErrors.indexOf(`desc_perfil`) !== -1,
        color: "#AC1A22",
        title: "Desconto Perfil",
        children: `Perfil: ${item.desc_perfil}%`,
      });
    }

    const descExtra = ["logis", "retir", "finan", "mix", "kit", "extra"];
    for (let j = 0; j < descExtra.length; j++) {
      const k = descExtra[j];
      const l = k[0].toUpperCase() + k.substr(1);

      tags.push({
        key: `desc_${k}`,
        invalid: item.descontoErrors.indexOf(`desc_${k}`) !== -1,
        color: "#AC1A22",
        title: "Desconto " + l,
        children: _desc(item, k, l),
      });
    }

    if (!!item.desc_geren && +item.desc_geren > 0) {
      const isDescGerenInvalid = item.descontoErrors.indexOf(`desc_geren`) !== -1;

      tags.push({
        key: `desc_geren`,
        invalid: isDescGerenInvalid,
        color: "#AC1A22",
        title: isDescGerenInvalid ? "Desc. Geren. [expirado]" : "Desconto Gerente",
        children: _desc(item, "geren", isDescGerenInvalid ? "Geren. [expirado]" : "Gerente"),
      });
    }
  }

  if (+item.desc_taxa !== 0) {
    tags.push({
      key: `desc_taxa`,
      color: +item.desc_taxa < 0 ? "#DE6710" : "#7AD166",
      title: +item.desc_taxa < 0 ? "Acréscimo Cond. Pagto" : "Decréscimo Cond. Pagto",
      children: _desc(item, "taxa", +item.desc_taxa < 0 ? "Acrésc" : "Decrésc", -100),
    });
  }

  //Tag do cashback
  if (+item.cashback !== 0) {
    tags.push({
      key: `cashback`,
      color: "#195488",
      title: "Cashback",
      children: `CASHBACK: ${item.cashback}%`,
    });
  }

  return <div className="produto-extra-tags">{tags.map(tag => !!tag.children && <ProdutoTag {...tag} />)}</div>;
});

@observer
export default class ProdutoTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisibleDescVolModal: false,
      classe_desc_vol: null,
    };
  }

  renderFooter = () => {
    const { classe_desc_vol } = this.state;
    const { pedido } = this.props;

    let desconto = 0;
    let volume = 0;

    if (classe_desc_vol === "01") {
      desconto = pedido.descVolumeSolvente;
      volume = pedido.volume_solvente;
    }
    else if (classe_desc_vol === "02") {
      desconto = pedido.descVolumeComplemento;
      volume = pedido.volume_complemento;
    }
    else if (classe_desc_vol === "03") {
      desconto = pedido.descVolumeTinta;
      volume = pedido.volume_tinta;
    }

    return (
      <FooterWrapper>
        <FooterItem label="Volume Total">{n(volume)}</FooterItem>
        <FooterItem label="Desconto Atual">{n(desconto)}</FooterItem>
      </FooterWrapper>
    );
  };

  openDescVolModal = (classe) => {
    this.setState({ isVisibleDescVolModal: true, classe_desc_vol: classe });
  };

  closeDescVolModal = () => {
    this.setState({ isVisibleDescVolModal: false, classe_desc_vol: null });
  };

  decorateRows = (item, index) =>
    classnames("produto-table--row", {
      "produto-table--row__odd": index % 2 !== 0,
      "produto-table--row__campanha": item.hasCampanha,
      "produto-table--row__invalid": !item.isValid,
    });

  render() {
    const {
      pedido,
      items,
      onEdit,
      onEditBonif,
      onRemove,
      className,
      rowClassName = this.decorateRows,
      loading = false,
      locale = {},
      bordered = true,
      size = "middle",
      ...tableProps
    } = this.props;

    const largura_acoes = !!pedido.data.verba_codigo && pedido.isPedidoNormal ? "115px" : "80px";

    let columns = [
      {
        title: "Ações",
        key: "actions",
        render: (_, item) => (
          <div className="actions">
            <Button icon="edit" disabled={item.bonificado === "S" || item.neg_anterior === "S"} ghost={item.bonificado === "S" || item.neg_anterior === "S"} size="small" type="primary" onClick={() => onEdit(item)} />
            <Button icon="close" size="small" type="danger" onClick={() => onRemove(item)} />
            {!!pedido.data.verba_codigo && pedido.isPedidoNormal && (
              <Button icon="bars" disabled={item.bonificado === "S" || item.neg_anterior === "S"} ghost={item.bonificado === "S" || item.neg_anterior === "S"} size="small" type="ghost" onClick={() => onEditBonif(item)} />
            )}
          </div>
        ),
        width: `${largura_acoes}`,
      },
      {
        title: "Item",
        key: "item_produto",
        dataIndex: "item_produto",
        width: "4%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "codigo_produto",
        width: "7.65%",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "descricao",
        render: (text, item) => (
          <Fragment>
            <WrappedValue value={text} type="text" />
            <ProdutoExtra item={item} />
          </Fragment>
        ),
        className: "text-left",
      },
      {
        title: "Linha",
        key: "tipo_produto",
        dataIndex: "tipo_produto",
        width: "7.1%",
      },
      {
        title: "Classe",
        key: "classe_produto",
        dataIndex: "classe_produto",
        width: "5.9%",
      },
      {
        title: "Peso",
        key: "peso_bruto",
        dataIndex: "peso_bruto",
        render: n => <WrappedValue value={nf(+n, 4)} type="text" />,
        width: "5.1%",
      },
      {
        title: "Qtd.",
        key: "quantidade",
        dataIndex: "quantidade",
        width: "4.9%",
      },
      {
        title: "Preço",
        key: "preco_vendido",
        dataIndex: "preco_vendido",
        render: (n, item) => {
          const ultMaior = item.ultimo_preco > item.preco_vendido;
          const ultMenor = item.ultimo_preco < item.preco_vendido;
          if (item.ultimo_preco > 0) {
            return (
              <ProdutoTag color={ultMenor ? "#AC1A22" : (ultMaior ? "#33af1a" : "#9A9A9A")}
                style={{
                  fontSize: 11,
                  lineHeight: "12px",
                  marginTop: 2,
                }}>
                <WrappedValue
                  value={nf(+n, 3)}
                  type="text"
                  icon={
                    <Icon
                      style={{ marginRight: 1, transform: `rotate(${item.ultimo_preco == item.preco_vendido ? "90deg" : "0deg"})` }}
                      type={ultMaior ? "down" : (ultMenor ? "up" : "pause")}
                    />
                  }
                />
              </ProdutoTag>
            )
          } else {
            return (
              <WrappedValue
                value={nf(+n, 3)}
                type="text"
              />
            )
          }

        },
        width: "6.6%",
      },
      {
        title: "Vlr.Total",
        key: "valor_total",
        dataIndex: "valor_total",
        render: n => <WrappedValue value={nf(+n)} type="text" />,
        width: "6.4%",
      },
      {
        title: "Ult.Preço",
        key: "ultimo_preco",
        dataIndex: "ultimo_preco",
        render: (n, item) => (
          <Fragment>
            <WrappedValue title="Último Preço" value={nf(+n)} type="text" />
            {!!item.data_ultimo_preco && (
              <ProdutoTag
                color="#9A9A9A"
                title="Data do Último Faturamento"
                style={{
                  fontSize: 10,
                  lineHeight: "12px",
                  marginTop: 2,
                }}>
                {dateFormat(item.data_ultimo_preco)}
              </ProdutoTag>
            )}
          </Fragment>
        ),
        width: "7.25%",
      },
      {
        title: "Tabela",
        key: "preco_tabela",
        dataIndex: "preco_tabela",
        render: n => <WrappedValue value={nf(+n)} type="text" />,
        width: "5.45%",
      },
      {
        title: "Prc.Imp.",
        key: "preco_imp",
        dataIndex: "valor_total_nf",
        render: (n, item) => (
          <WrappedValue
            value={+item.valor_total_nf > 0 ? nf(+item.valor_total_nf / +item.__quantidade) : 0}
            type="text"
          />
        ),
        width: "6%",
      },
      {
        title: "Total Imp.",
        key: "total_imp",
        dataIndex: "valor_total_nf",
        render: n => <WrappedValue value={nf(+n)} type="text" />,
        width: "6.4%",
      },
    ];

    if (pedido.isB2B) {
      columns = columns.concat([
        {
          title: "Desconto",
          key: "desconto_volume",
          dataIndex: "desconto_volume",
          render: n => <WrappedValue value={nf(+n, 2)} type="text" />,
          width: "6%",
        },
      ]);
    }

    return (
      <Fragment>
        {(pedido.hasDescVolumeEscalonadoSolvente &&
          <Button
            type={pedido.descVolumeSolvente > 0 ? "primary" : "danger"}
            style={{ marginRight: 9, marginBottom: 9 }}
            onClick={event => {
              event.preventDefault();
              this.openDescVolModal("01");
            }}>
            <Icon type={pedido.descVolumeSolvente > 0 ? "line-chart" : "warning"} />
            Solvente: {pedido.descVolumeSolvente > 0 ? `${pedido.descVolumeSolvente}% Desconto ` : `Desconto bloqueado`}
          </Button>
        )}
        {(pedido.hasDescVolumeEscalonadoComplemento &&
          <Button
            type={pedido.descVolumeComplemento > 0 ? "primary" : "danger"}
            style={{ marginRight: 9, marginBottom: 9 }}
            onClick={event => {
              event.preventDefault();
              this.openDescVolModal("02");
            }}>
            <Icon type={pedido.descVolumeComplemento > 0 ? "line-chart" : "warning"} />
            Complemento: {pedido.descVolumeComplemento > 0 ? `${pedido.descVolumeComplemento}% Desconto ` : `Desconto bloqueado`}
          </Button>
        )}
        {(pedido.hasDescVolumeEscalonadoTinta &&
          <Button
            type={pedido.descVolumeTinta > 0 ? "primary" : "danger"}
            style={{ marginRight: 9, marginBottom: 9 }}
            onClick={event => {
              event.preventDefault();
              this.openDescVolModal("03");
            }}>
            <Icon type={pedido.descVolumeTinta > 0 ? "line-chart" : "warning"} />
            Tinta: {pedido.descVolumeTinta > 0 ? `${pedido.descVolumeTinta}% Desconto ` : `Desconto bloqueado`}
          </Button>
        )}
        <Modal
          centered
          title={_retTitulo(this.state.classe_desc_vol)}
          visible={this.state.isVisibleDescVolModal}
          onCancel={this.closeDescVolModal}
          onOk={this.closeDescVolModal}
          width={500}
          destroyOnClose={true}
          cancelText="Entendi"
          okButtonProps={{ style: { display: 'none' } }}
          wrapClassName="proporcao-classe-modal">
          <TableDetalheDescVol
            descontos={!!pedido.detalhe_descvol ? pedido.detalhe_descvol.filter(desc => (desc.classe === this.state.classe_desc_vol)) : null}
            pedido={pedido}
            footer={this.renderFooter}
          />

        </Modal>
        <Table
          className={classnames(
            `produto-table produto-table__compact`,
            { "produto-table__loading": !!loading },
            className
          )}
          pagination={false}
          rowKey={rowKey}
          loading={!!loading}
          bordered={bordered}
          columns={columns.map(mapColumn)}
          rowClassName={rowClassName}
          dataSource={pedido.items}
          locale={{
            filterTitle: "Filtrar",
            filterConfirm: "OK",
            filterReset: "Limpar",
            emptyText: "Sem produtos adicionados até o momento",
            ...locale,
          }}
          size={size}
          {...tableProps}
        />
      </Fragment>
    );
  }
}
