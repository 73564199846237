import React, { Component, Fragment } from "react";
import { Table } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";

import { number_format } from "@util";
import { WrappedValue, mapColumn } from "components/FullTable";

import { META_VALID, META_INVALID } from "stores/PedidoStore/Kit";

const nf = (n, d = 2) => number_format(+n, d, ".", "");
const rowKey = item => `${item.codigo_kit}:${item.codigo_classe}:${item.linha}:${item.grupo_cor || "x"}`;

const decorateRows = (item, index) =>
  classnames("base-table--row kit-table--row", {
    "base-table--row__odd kit-table--row__odd": index % 2 !== 0,
  });

@observer
export default class MetaKitTable extends Component {
  render() {
    const {
      kit,
      items,
      className,
      wrapperClassName,
      rowClassName = decorateRows,
      loading = false,
      locale = {},
      bordered = true,
      size = "middle",
      ...tableProps
    } = this.props;

    const columns = [
      {
        title: "Classe",
        key: "desc_classe",
        dataIndex: "desc_classe",
        render: (text, m) => (
          <WrappedValue
            title={!!m.grupo_cor ? `${text} (${m.grupo_cor})` : text}
            value={
              !!m.grupo_cor ? (
                <Fragment>
                  {text} <abbr title={`Grupo Cor: ${m.grupo_cor} - ${m.desc_grupo_cor}`}>({m.grupo_cor})</abbr>
                </Fragment>
              ) : (
                text
              )
            }
            type="text"
          />
        ),
        className: "text-left",
      },
      {
        title: "Linha",
        key: "desc_linha",
        dataIndex: "desc_linha",
        render: text => (
          <WrappedValue
            value={text === "IMOBILIARIA" ? "IMOB" : text === "AUTOMOTIVA" || text === "AUTOMOTIVO" ? "AUTO" : text}
            type="text"
          />
        ),
        width: "8.25%",
      },
      {
        title: kit.tipo_qtd_total,
        key: "volating",
        dataIndex: "volating",
        render: n => <WrappedValue value={nf(+n, 2)} type="text" />,
        onCell: meta => ({
          className: classnames("text-center", {
            "td-dark-danger": meta.volatingStatus === META_INVALID,
            "td-dark-success": meta.volatingStatus === META_VALID,
          }),
        }),
        width: "9%",
      },
      {
        title: "Qtd. min.",
        key: "qtd_min",
        dataIndex: "qtd_min",
        onCell: meta => ({
          className: classnames("text-center", {
            "td-danger": meta.volatingStatus === META_INVALID,
            "td-success": meta.volatingStatus === META_VALID,
          }),
        }),
        width: "8.5%",
      },
      {
        title: "% Meta prop.",
        key: "meta_proporcao",
        dataIndex: "meta_proporcao",
        onCell: meta => ({
          className: classnames("text-center", {
            "td-danger": meta.propkitStatus === META_INVALID,
            "td-success": meta.propkitStatus === META_VALID,
          }),
        }),
        width: "9.5%",
      },
      {
        title: "% Prop. Kit",
        key: "propkit",
        dataIndex: "propkit",
        render: n => <WrappedValue value={nf(+n, 2)} type="text" />,
        onCell: meta => ({
          className: classnames("text-center", {
            "td-dark-danger": meta.propkitStatus === META_INVALID,
            "td-dark-success": meta.propkitStatus === META_VALID,
          }),
        }),
        width: "8.5%",
      },
      {
        title: "% Adicional",
        key: "adicional",
        dataIndex: "adicional",
        width: "9%",
      },
      {
        title: "Soma Prop.",
        key: "qtd_mpr",
        dataIndex: "qtd_mpr",
        width: "9%",
      },
      {
        title: "Soma Q.Min.",
        key: "soma_qtd_min",
        dataIndex: "soma_qtd_min",
        width: "9.5%",
      },
    ];

    return (
      <div className={classnames(`kit-table-wrapper meta-kit-table-wrapper`, wrapperClassName)}>
        <div className="ant-modal-title">Metas por Classe</div>
        <Table
          className={classnames(
            `base-table base-table__compact kit-table kit-table__compact`,
            { "base-table__loading kit-table__loading": !!loading },
            className
          )}
          pagination={false}
          rowKey={rowKey}
          loading={!!loading}
          bordered={bordered}
          columns={columns.map(mapColumn)}
          rowClassName={rowClassName}
          dataSource={kit.metas}
          scroll={{ y: 87 }}
          locale={{
            filterTitle: "Filtrar",
            filterConfirm: "OK",
            filterReset: "Limpar",
            emptyText: "Sem metas de classe neste kit",
            ...locale,
          }}
          size={size}
          {...tableProps}
        />
      </div>
    );
  }
}
