import React, { Component } from "react";
import { observer } from "mobx-react";

import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.razao_social}:${item.cnpj}`;

@observer
export default class ChooserEmpresa extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/treinamento/empresa/busca_cnpj_chooser");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Empresa",
        key: "razao_social",
        className: "text-left",
        width: "67.5%",
      },
      {
        title: "CNPJ",
        key: "cnpj",
        width: "19%",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Encontre uma Empresa"
        errorMessage="Não foi possível obter suas empresas no momento"
        searchPlaceholder="Busque pelo CNPJ/Nome"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        {...this.props}
      />
    );
  }
}
