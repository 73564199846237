import React, { PureComponent, Fragment } from "react";
import { Modal, Form, Input } from "antd";

const FormItem = Form.Item;

export default class ConsultaCNPJModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      captcha: null,
      inputCaptcha: "",
    };
  }
  open = captcha => {
    this.setState({ visible: true, captcha });
  };
  close = () => {
    this.setState({ visible: false, captcha: null, inputCaptcha: "" });
  };
  render() {
    const { isLoading = false, onConsulta } = this.props;
    const { captcha, inputCaptcha } = this.state;
    const valid = !isLoading && !!inputCaptcha;

    return (
      <Modal
        centered
        width={580}
        title="Verificação de Segurança"
        visible={this.state.visible}
        okText="Consultar"
        okButtonProps={{ disabled: !valid, loading: isLoading }}
        onOk={() => onConsulta(captcha.sid, inputCaptcha)}
        onCancel={this.close}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="consulta-cnpj-modal">
        {!!captcha && (
          <Fragment>
            <p>
              Para efetuar a consulta deste CNPJ no site da Receita Federal e obter os dados relacionados, por favor
              preencha a verificação de segurança abaixo repetindo os caracteres da imagem no campo designado:
            </p>
            <img
              src={captcha.img}
              style={{ width: 210, border: "1px solid #ddd", borderRadius: 4, display: "inline-block" }}
              alt="Verificação de Segurança"
            />
            <FormItem label={null} style={{ width: 210, marginTop: 6 }}>
              <Input
                placeholder="Digite os caracteres acima"
                value={inputCaptcha}
                onChange={event => this.setState({ inputCaptcha: event.target.value })}
              />
            </FormItem>
          </Fragment>
        )}
      </Modal>
    );
  }
}
