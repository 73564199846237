import React, { Component, Fragment } from "react";
import { Alert, Form, Button } from "antd";
import { observer } from "mobx-react";
import _size from "lodash/size";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, SearchForm } from "components/FullTable";

import Select from "components/Select";

const FormItem = Form.Item;

@observer
class SaldoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Saldo em Estoque",
      uri: "/r/saldo",
      state: {
        filiais: {},
      },
    });

    this.state = {};
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      disabled: dataSource.isLoading,
    };

    const { filiais } = dataSource.state;

    return (
      _size(filiais) > 0 && (
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          onUpdateDataSource={onUpdateDataSource}
          filterFields={{
            filial: "options",
          }}>
          {({ form }) => (
            <Fragment>
              <FormItem label="Filtrar por Filial">
                {form.getFieldDecorator("filial")(
                  <Select
                    {...inputProps}
                    defaultActiveFirstOption={false}
                    placeholder="Todas"
                    options={filiais}
                    style={{ width: 196 }}
                  />
                )}
              </FormItem>
              <FormItem>
                <Button type="danger" htmlType="submit" icon="search" />
              </FormItem>
            </Fragment>
          )}
        </SearchForm>
      )
    );
  };
  render() {
    const columns = [
      {
        title: "Filial",
        dataIndex: "Saldo.filial",
        key: "filial",
        sorter: true,
        width: "9%",
        filteredValue: this.ds.filterValue("filial"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Filial" {...props} />,
        filterParser: filter => ({ ...filter, field: "Saldo.filial", value: `${filter.value}` }),
      },
      {
        title: "Produto",
        dataIndex: "Saldo.produto",
        key: "produto",
        sorter: true,
        width: "11%",
        filteredValue: this.ds.filterValue("produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código Produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "Saldo.produto", value: `%${filter.value}`, op: ":LIKE" }),
      },
      {
        title: "Descrição",
        dataIndex: "Saldo.descricao",
        key: "descricao",
        sorter: true,
        className: "text-left",
        width: "auto",
        filteredValue: this.ds.filterValue("descricao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Descrição" {...props} />,
        filterParser: filter => ({ ...filter, field: "Saldo.descricao", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Qtd. Carteira",
        dataIndex: "Saldo.qtde_carteira",
        key: "qtde_carteira",
        sorter: true,
        width: "13%",
      },
      {
        title: "Qtd. Atual",
        dataIndex: "Saldo.qtde_atual",
        key: "qtde_atual",
        sorter: true,
        width: "12%",
      },
      {
        title: "Qtd. Prev. (OP)",
        dataIndex: "Saldo.qtd_op",
        key: "qtd_op",
        sorter: true,
        width: "13%",
      },
      {
        title: "Saldo Líquido",
        dataIndex: "Saldo.saldo_liquido",
        key: "saldo_liquido",
        sorter: true,
        width: "13%",
      },
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name} />
        {!this.ds.hasFilter && (
          <Alert
            message={
              <Fragment>
                A coluna Qtde. Carteira é atualizada a cada <em>10 minutos</em>.
              </Fragment>
            }
            type="info"
            showIcon
            style={{ margin: "-5px 0 16px" }}
          />
        )}
        <FullTable
          beforeComponent={this.renderSearchForm}
          rowKey={item => `${item.Saldo.filial}:${item.Saldo.produto}`}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter o relatório de saldo em estoque no momento"
        />
      </div>
    );
  }
}

export default withStore(SaldoScreen);
