import { fetchApi } from 'actions';
import { Divider, Icon, Modal } from 'antd';
import { autorun } from 'mobx';
import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${props => props.q1 ? "#d2dce5" : props.q2 ? "#d2d8ce" : props.q3 ? "#dbc5b6" : "#d9cfb3"};
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 60px;
  justify-content: center;
  align-items: center;
  margin: 4px;
  border-radius: 8px;
`;

class StepValidacao extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const Positvo = () => {
      return <Icon type="check" style={{ fontSize: 24, color: '#635894', marginBottom: 6, marginRight: 4 }} />
    }

    const Negativo = () => {
      return <Icon type="caret-down" style={{ fontSize: 24, color: '#ad4a46', marginBottom: 6, marginRight: 4 }} />
    }

    const Q1 = () => {
      return (
        <Container q1>
          <h3>Q1</h3>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Positvo />
            <h3>Margem</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Negativo />
            <h3>Volume</h3>
          </div>
        </Container>
      )
    }

    const Q2 = () => {
      return (
        <Container q2>
          <h3>Q2</h3>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Positvo />
            <h3>Margem</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Positvo />
            <h3>Volume</h3>
          </div>
        </Container>
      )
    }

    const Q3 = () => {
      return (
        <Container q3>
          <h3>Q3</h3>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Negativo />
            <h3>Margem</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Negativo />
            <h3>Volume</h3>
          </div>
        </Container>
      )
    }

    const Q4 = () => {
      return (
        <Container q4>
          <h3>Q4</h3>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Negativo />
            <h3>Margem</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 6 }}>
            <Positvo />
            <h3>Volume</h3>
          </div>
        </Container>
      )
    }

    return (
      <div>
        <h2>Quadrantes disponíveis</h2>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Q1 />
          <Q2 />
          <Q3 />
          <Q4 />
        </div>
        <Divider />
        <h2>Quadrante do cliente</h2>
        {this.props.promotoria.isLoading && <h4><strong>Carregando...</strong></h4>}
        {!this.props.promotoria.quadranteCliente && !this.props.promotoria.isLoading && <h4><strong>Cliente não possuí quadrante</strong></h4>}
        {this.props.promotoria.quadranteCliente === "Q1" && <Q1 />}
        {this.props.promotoria.quadranteCliente === "Q2" && <Q2 />}
        {this.props.promotoria.quadranteCliente === "Q3" && <Q3 />}
        {this.props.promotoria.quadranteCliente === "Q4" && <Q4 />}
      </div>
    );
  }
}

export default StepValidacao;
