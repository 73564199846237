import React, { Component } from "react";
import { Button, Icon, Dropdown, Menu, Modal, Tag, Alert } from "antd";
import { observer } from "mobx-react";
import _size from "lodash/size";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchApi } from "actions";
import PageHeader, { BackButton } from "components/PageHeader";
import { } from "react-router-dom";
import classNames from "classnames";
import { formataCPF } from "@util";

const decorateRows = (item) =>
  classNames("base-table--row", {
    "base-table--row__danger": item.vencido != 0,
    "base-table--row__sucess": item.vencido == 0 && item.vencendo == 0 && item.atualizado != 0,
    "base-table--row__invalid": item.vencido == 0 && item.vencendo != 0,
  });

@observer
class FuncionarioScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: `Funcionários para empresa #${this.props.match.params.empresa}`,
      uri: "/treinamento/funcionario/",
      defaultFilter: {
        empresa: this.props.match.params.empresa,
      },
      state: {
        empresa: null
      }
    });

    this.state = {
      status: null
    };
  }

  tryDelete = async (id) => {
    try {
      this.props.store.isLoading = true;
      const { data: response } = await fetchApi("/treinamento/funcionario/deletar/" + id);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Funcionario #${id} excluido com sucesso`
        });
        this.ds.fetch();
      }
    } catch (error) {
      Modal.error({
        title: "Atenção",
        content: error
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleDelete = (funcionarioId) => {
    Modal.confirm({
      title: "Atenção",
      content: `Você realmente deseja excluir o funcionario #${funcionarioId}`,
      okType: 'danger',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        this.tryDelete(funcionarioId)
      }
    })
  }

  render() {
    const { history, match } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "codigo",
        key: "codigo",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "CNPJ",
        dataIndex: "cnpj",
        exportOnly: true,
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        className: "text-left",
        width: "30%",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (nome, item) => {
          return (
            <WrappedValue value={<><Tag color="blue">{formataCPF(item.cpf)}</Tag>{nome}</>} />
          )
        }
      },
      {
        title: "Regime / Cargo",
        dataIndex: "cargo",
        key: "cargo",
        className: "text-left",
        width: "15%",
        filteredValue: this.ds.filterValue("cargo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cargo" {...props} />,
        filterParser: filter => ({ ...filter, field: "cargo", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (cargo, item) => {
          return (
            <WrappedValue value={<><Tag color="orange">{item.regime}</Tag>{cargo}</>} />
          )
        }
      },
      {
        title: "Localidade",
        dataIndex: "",
        key: "localidade",
        width: "13%",
        export: false,
        render: (_, item) => {
          return (
            <div>{item.cidade} / {item.uf}</div>
          )
        }
      },
      {
        title: "Cidade",
        dataIndex: "cidade",
        exportOnly: true,
      },
      {
        title: "UF",
        dataIndex: "uf",
        exportOnly: true,
      },
      {
        title: "Tel.",
        key: "telefone",
        dataIndex: "telefone",
        width: '10%',
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (email) => {
          return (
            <WrappedValue value={email} />
          )
        }
      },
      {
        title: "",
        key: "actionbar",
        width: "12%",
        export: false,
        render: (_, item) => {
          const menu =
            <Menu>
              <Menu.Item key="menu-delete"
                onClick={() => this.handleDelete(item.codigo)}>
                <Icon type="delete" />
                Deletar funcionário
              </Menu.Item>
              <Menu.Item key="menu-treinamentos"
                onClick={() => history.push(`${history.location.pathname}/atrelados/${item.codigo}`)}>
                <Icon type="check" />
                Monitorar Treinamentos
              </Menu.Item>
              <Menu.Item key="menu-anexos"
                onClick={() => history.push(`${history.location.pathname}/anexos/${item.codigo}`)}>
                <Icon type="paper-clip" />
                Anexar à Treinamentos
              </Menu.Item>
            </Menu>
          return (
            <Dropdown.Button
              onClick={() => history.push(`${history.location.pathname}/${item.codigo}`)}
              title="Alterar este funcionário"
              size="small"
              type="primary"
              overlay={menu}>
              <Icon type="edit" />
              Alterar
            </Dropdown.Button>
          )
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageHeader title={`Funcionários para empresa #${this.props.match.params.empresa} - ${this.ds.state?.empresa ? this.ds.state?.empresa?.nome_fantasia : 'Carregando ...'}`}
          headerLeft={<BackButton onClick={() => history.push(`/empresadash/empresa`)} />}
          headerRight={<Button onClick={() => history.push(`${history.location.pathname}/add`)} type="primary" icon="plus">Novo funcionário</Button>}>
        </PageHeader>
        <FullTable
          rowKey={item => (`${item.codigo}:${item.nome}`)}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          rowClassName={decorateRows}
          errorMessage="Não foi possível obter os funcionários no momento"
        />
      </div >
    );
  }
}

export default withStore(FuncionarioScreen);
