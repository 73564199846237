import React, { Component } from "react";
import { Modal, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import styled from "styled-components";
import { darken } from "polished";

import { isDev, isStaging } from "@env";
import { getData } from "@util";
import { fetchLogin } from "actions";
import withStore from "withStore";

const FormItem = Form.Item;

function showError(content) {
  Modal.error({
    title: "Erro em seu login",
    content,
  });
}

const LOGIN_BUTTON_COLOR = "#ec9e1a";

const LoginButton = styled(Button)`
  &.ant-btn {
    background-color: ${LOGIN_BUTTON_COLOR};
    border-color: ${darken(0.05, LOGIN_BUTTON_COLOR)};
    text-transform: uppercase;
    margin-top: 9px;
    width: 100%;
    height: 44px;
  }
`;

const LoginForm = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.35);
  min-width: 380px;
  padding: 38px;
  text-align: center;

  .ant-form {
    .ant-form-item {
      margin-bottom: 11px;
    }
    .ant-form-item-with-help {
      margin-bottom: 6px;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: -114px;
  left: 50%;
  margin-left: -112px;
  z-index: 10;

  > img {
    width: 224px;
    height: 77px;
  }
`;

const ForgetButton = styled(Link)`
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    color: ${LOGIN_BUTTON_COLOR};
  }
`;

@observer
class LoginScreen extends Component {
  state = {
    isLoading: false,
  };
  async fetchLogin(login, senha) {
    this.setState({ isLoading: true });
    try {
      const { data: response } = await fetchLogin(login, senha);
      if (!!response.success) {
        const { store } = this.props;
        const { data } = response;

        store.setLoginData(data);
        if (!store.isLogged) {
          throw new Error("Verique seu login e senha e tente novamente");
        }

        requestAnimationFrame(() => this.props.history.replace(store.isOperadorAdmin ? "/operadores" : "/"));
      } else {
        throw new Error("Verique seu login e senha e tente novamente");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 1) : 1;
      const errorMessages = {
        1: "Verique seu login e senha e tente novamente",
        2: "Cadastro desativado, entre em contato com o suporte Anjo Tintas",
        3: "Cadastro desativado, entre em contato com o suporte Anjo Tintas",
      };

      showError(errorMessages[+errorCode] || err.message);

      this.setState({ isLoading: false }, () =>
        this.props.form.setFieldsValue({
          senha: "",
        })
      );
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.fetchLogin(formData.login, formData.senha);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { isLoading } = this.state;

    return (
      <LoginForm className="login-form">
        <Helmet>
          <title>ANJO NEXXUS - Acesso Restrito</title>
        </Helmet>
        <LogoWrapper>
          <img
            src={
              isStaging
                ? require("img/logoLogin_staging.png")
                : isDev
                  ? require("img/logoLogin_dev.png")
                  : require("img/logoLogin.png")
            }
            alt="ANJO NEXXUS"
            className="login-logo--img"
          />
        </LogoWrapper>
        <Form noValidate layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator("login", {
              rules: [{ required: true, message: "Por favor insira seu nome de usuário" }],
            })(<Input readOnly={isLoading} size="large" placeholder="Login" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator("senha", {
              rules: [{ required: true, message: "Por favor insira sua senha" }],
            })(<Input readOnly={isLoading} size="large" type="password" placeholder="Senha" />)}
          </FormItem>
          <LoginButton loading={isLoading} size="large" type="primary" htmlType="submit">
            Acessar
          </LoginButton>
          <div style={{ marginTop: 24, marginBottom: -16 }}>
            <ForgetButton to="/auth/password-forgot">Esqueci minha Senha</ForgetButton>
          </div>
        </Form>
      </LoginForm>
    );
  }
}

export default Form.create()(withStore(LoginScreen));
