import React, { PureComponent, Fragment } from "react";
import { Route } from "react-router-dom";

import HomeScreen from "screens/promotor/HomeScreen";
import ArquivosScreen from "screens/common/ArquivosScreen";
import CargaEditorScreen from "screens/vendedor/pedidos/CargaEditorScreen";
import CargaViewScreen from "screens/vendedor/pedidos/CargaViewScreen";
import PedidoEditorScreen from "screens/vendedor/pedidos/PedidoEditorScreen";
import PedidosScreen from "screens/vendedor/pedidos/PedidosScreen";
import PromotoriaIndex from "screens/common/Promotoria/PromotoriaIndex";
import PromotoriaScreenSelected from "screens/common/Promotoria/PromotoriaScreenSelected";
import AgendaPromotoria from "screens/common/AgendaPromotoria";
import TabelaPrecoScreen from "screens/vendedor/TabelaPrecoScreen";
import SolicitacaoPromotoria from "screens/common/Promotoria/SolicitacaoPromotoria";

export default class Promotor extends PureComponent {
  render() {
    const { match } = this.props;

    const PROMOTOR_RELATORIOS_LINK = {
      "/r/saldo": require("screens/vendedor/relatorios/SaldoScreen").default,
      "/r/clientes": require("screens/vendedor/relatorios/ClientesScreen").default,
    }

    return (
      <Fragment>
        {Object.keys(PROMOTOR_RELATORIOS_LINK).map(path => (
          <Route exact key={path} path={path} component={PROMOTOR_RELATORIOS_LINK[path]} />
        ))}
        <Route exact path={match.url} component={HomeScreen} />
        <Route exact path="/arquivos-comercial" component={ArquivosScreen} />

        <Route exact path="/pedidos" component={PedidosScreen} />
        <Route exact path="/pedidos/carga/add/:tipo" component={CargaEditorScreen} />
        <Route exact path="/pedidos/carga/edit/:tipo/:slug" component={CargaEditorScreen} />
        <Route exact path="/pedidos/carga/view/:tipo/:slug" component={CargaViewScreen} />
        <Route exact path="/pedidos/add/:slug" component={PedidoEditorScreen} />
        <Route exact path="/pedidos/add" component={PedidoEditorScreen} />
        <Route exact path="/simulacoes/add/:slug" component={PedidoEditorScreen} />
        <Route exact path="/simulacoes/add" component={PedidoEditorScreen} />
        <Route path="/pedidos/residuo/edit/:slug" component={PedidoEditorScreen} />
        <Route path="/pedidos/edit/:slug" component={PedidoEditorScreen} />

        <Route exact path="/tabela-preco" component={TabelaPrecoScreen} />

        <Route exact path={'/promotoria/cadastro'} component={SolicitacaoPromotoria} />
        <Route exact path={'/promotoria/index'} component={PromotoriaIndex} />
        <Route exact path={'/promotoria/index/:id'} component={PromotoriaScreenSelected} />
        <Route exact path={'/promotoria/agenda'} component={AgendaPromotoria} />
      </Fragment>
    );
  }
}