import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";

import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MenuCliente extends PureComponent {
  render() {
    const { collapsed = false, selected = [], isB2B } = this.props;
    const submenuKeys = ["subServicos", "subCamp", "subLinks"];

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__cliente")}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-home">
            <Link to="/">
              <Icon type="home" />
              <span>Home</span>
            </Link>
          </MenuItem>
          <MenuItem key="nav-orcamentos" className="ant-menu-item__featured">
            <Link to="/orcamentos">
              <Icon type="star-o" />
              <span>Realizar Orçamento</span>
            </Link>
          </MenuItem>
          {!isB2B && (
            <MenuItem key="nav-arq-mkt" className="ant-menu-item__featured">
              <Link to="/arquivos-mkt">
                <Icon type="file-text" />
                <span>Campanhas</span>
              </Link>
            </MenuItem>
          )}
          <SubMenu
            key="subServicos"
            title={
              <span>
                <Icon type="appstore-o" />
                <span>Serviços</span>
              </span>
            }>
            <MenuItem key="nav-servicos-pedidos">
              <Link to="/r/pedidos">Meus Pedidos</Link>
            </MenuItem>
            <MenuItem key="nav-servicos-nf">
              <Link to="/notas-fiscais">Notas Fiscais - DANFE</Link>
            </MenuItem>
            <MenuItem key="nav-servicos-boletos">
              <Link to="/boletos">Boletos em Aberto</Link>
            </MenuItem>
          </SubMenu>
          {false && (
            <SubMenu
              key="subCamp"
              title={
                <span>
                  <Icon type="shop" />
                  <span>Campanha de Vendas</span>
                </span>
              }>
              <MenuItem key="nav-camp-jun">
                <Link to="/camp/jun">Campanha Junina</Link>
              </MenuItem>
              <MenuItem key="nav-camp-pontos">
                <Link to="/camp/pontos">Pontuação</Link>
              </MenuItem>
            </SubMenu>
          )}
          {/* <MenuItem key="nav-sac">
            <Link to="/sac">
              <Icon type="customer-service" />
              <span>Atendimento SAC</span>
            </Link>
          </MenuItem> */}
          {isB2B && (
            <SubMenu
              key="subTabelaPreco"
              title={
                <span>
                  <Icon type="tags" />
                  <span>Tabela de Preço</span>
                </span>
              }>
              <MenuItem key="nav-tabela-preco">
                <Link to="/tabela-preco">Tabela de Preço</Link>
              </MenuItem>
            </SubMenu>
          )}
          <MenuItem key="nav-cadastro">
            <Link to="/cliente/cadastro">
              <Icon type="user" />
              <span>Meu Cadastro</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subLinks"
            title={
              <span>
                <Icon type="link" />
                <span>Links Úteis</span>
              </span>
            }>
            <MenuItem key="nav-links-produtos">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvTHZTaWE5TGFYUEU?resourcekey=0-oL1MxFa_2UqT3YEk7DO9jw">Catálogo de Produtos</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvaWdvRDloc1FMUlk?resourcekey=0-FkGzi6rE9fFRO_iPDcR03A">Catálogo de Cor</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-arsenal">
              <ExternalLink to="https://arsenal.anjo.com.br">Arsenal ANJO</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://www.anjo.com.br/fale-conosco/telefones">Telefones Úteis</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://anjotintas.zendesk.com/hc/pt-br/requests/new">Abertura SAC - ZENDESK</ExternalLink>
            </MenuItem>
          </SubMenu>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuCliente);
