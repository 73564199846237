import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";

import { dateFormat } from "@util";

export const Item = ({ label, children, ...props }) => (
  <p {...props}>
    <strong>{label}:</strong> {children}
  </p>
);

export default class ResumoCliente extends PureComponent {
  render() {
    const {
      cliente,
      className,
      showEndereco = true,
      showPerfil = false,
      canais = {},
      perfis = {},
      children,
      ...restProps
    } = this.props;
    return (
      <div className={classnames("resumo-cliente", className)} {...restProps}>
        <Item label="CNPJ / I.E.">
          {cliente.cnpj} / {!!cliente.ie && cliente.ie.trim() !== "" ? cliente.ie : "Isento"}
        </Item>
        <Item label="Cliente/Loja - Razão Social">
          {cliente.codigo}/{cliente.loja} - {cliente.razao_social}
        </Item>
        {!!showEndereco && (
          <Item label="Endereço">
            {cliente.endereco}. {!!cliente.bairro ? `${cliente.bairro.trim()}, ` : ""} {cliente.cidade} / {cliente.uf} -
            CEP: {cliente.cep}
          </Item>
        )}
        {!!cliente.telefone && <Item label="Telefone">{cliente.telefone}</Item>}
        {!!cliente.categoria && <Item label="Categoria">{cliente.categoria}</Item>}
        <Item label="Nome proprietário">{cliente.nome_proprietario || "Não definido"}</Item>
        <Item label="Email proprietário">{cliente.email_prop || "Não definido"}</Item>
        {!!showPerfil && (
          <Fragment>
            {cliente.cod_canal && <Item label="Canal">{canais[cliente.cod_canal] || "Não definido"}</Item>}
            {cliente.cod_perfil_aut && (
              <Item label="Perfil Automotivo">{perfis[cliente.cod_perfil_aut] || "Não definido"}</Item>
            )}
            {cliente.cod_perfil_aut_forcado && (
              <Item label="Perfil Forçado Auto.">
                {perfis[cliente.cod_perfil_aut_forcado] || "Não definido"}
                {!!cliente.vig_perfil_aut_forcado && (
                  <em style={{ marginLeft: 4 }}>{`(até ${dateFormat(cliente.vig_perfil_aut_forcado)})`}</em>
                )}
              </Item>
            )}
            {cliente.cod_perfil_imob && (
              <Item label="Perfil Imobiliário">{perfis[cliente.cod_perfil_imob] || "Não definido"}</Item>
            )}
            {cliente.cod_perfil_imob_forcado && (
              <Item label="Perfil Forçado Imob.">
                {perfis[cliente.cod_perfil_imob_forcado] || "Não definido"}
                {!!cliente.vig_perfil_imb_forcado && (
                  <em style={{ marginLeft: 4 }}>{`(até ${dateFormat(cliente.vig_perfil_imb_forcado)})`}</em>
                )}
              </Item>
            )}
          </Fragment>
        )}
        {"condicao_descsv" in cliente && (
          <Item label="Cond. de Pagto. de Solv.">
            {!!cliente.condicao_descsv && cliente.condicao_descsv !== "- []"
              ? cliente.condicao_descsv
              : "Não Cadastrado"}
          </Item>
        )}
        {"condicao_desctc" in cliente && (
          <Item label="Cond. de Pagto. de T.C.">
            {!!cliente.condicao_desctc && cliente.condicao_desctc !== "- []"
              ? cliente.condicao_desctc
              : "Não Cadastrado"}
          </Item>
        )}
         {cliente.unidade_negocio === "B2B" && (
          <Fragment>
            <Item label="Potencial de compra Tinta/Compl (Kg)">{cliente.volume_projetado_tinta}</Item>
            <Item label="Potencial de compra Solvente: (Kg)">{cliente.volume_projetado_solvente}</Item>
          </Fragment>
        )}
        <Item label="Status">{cliente.status}</Item>
        {children}
      </div>
    );
  }
}
