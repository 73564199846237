import React, { Component, Fragment } from "react";
import { Button, Icon } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import FullTable, { TableDataSource, TextFilter, WrappedValue, Toolbar } from "components/FullTable";

const rowKey = item => `${item.Poff.codigo_cliente}:${item.Poff.loja_cliente}:${item.Poff.codigo_filial}`;

const IconConstrutora = props => (
  <span className="icon-contrutora" {...props}>
    <Icon type="home" title="Construtora" />
  </span>
);

@observer
export default class TabMonitorOff extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/monitor_offline");

    this.state = {};
  }
  componentDidMount() {
    const onRefresh = () => this.ds.fetch();

    this.props.appendTabBarExtraContent(
      <Button type="primary" icon="reload" ghost={true} onClick={onRefresh} style={{ display: "block", marginTop: -1 }}>
        Atualizar
      </Button>
    );
  }
  renderLimpar = ({ dataSource, onUpdateDataSource }) => {
    return (
      !!dataSource.hasFilter && (
        <div style={{ marginBottom: 14 }}>
          <Toolbar.Button size="small" icon="delete" ghost={true} onClick={() => onUpdateDataSource(null, {}, null)}>
            Limpar Filtros
          </Toolbar.Button>
        </div>
      )
    );
  };
  render() {
    const columns = [
      {
        title: "Operação",
        dataIndex: "Poff.operacao",
        key: "operacao",
        sorter: false,
        width: "10%",
        filteredValue: this.ds.filterValue("operacao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Tipo" {...props} />,
        filterParser: filter => ({ ...filter, field: "Poff.operacao", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Filial",
        dataIndex: "Filial.nome_filial",
        key: "nome_filial",
        sorter: true,
        render: (text, item) => (
          <WrappedValue value={`${item.Poff.codigo_filial} - ${item.Filial.nome_filial}`} type="text" />
        ),
        width: "11.5%",
        filteredValue: this.ds.filterValue("nome_filial"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Filial" {...props} />,
        filterParser: filter => ({ ...filter, field: "Filial.nome_filial", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Emissão",
        dataIndex: "Poff.data_emissao",
        dataType: "date",
        key: "data_emissao",
        sorter: true,
        width: "9.75%",
        filteredValue: this.ds.filterValue("data_emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, type: "date", value: filterDate(filter.value) }),
      },
      {
        title: "Código",
        dataIndex: "Poff.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (text, item) => (
          <WrappedValue value={`${item.Poff.codigo_cliente} / ${item.Poff.loja_cliente}`} type="text" />
        ),
        width: "9.75%",
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Poff.codigo_cliente", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Cliente",
        dataIndex: "Cliente.razao_social",
        key: "razao_social",
        sorter: true,
        render: (text, item) => (
          <WrappedValue
            value={
              <Fragment>
                {item.Cliente.construtora === "S" && <IconConstrutora style={{ marginRight: 5 }} />}
                {text}
              </Fragment>
            }
            title={text}
            type="text"
            style={{ fontSize: 11 }}
          />
        ),
        className: "text-left",
        filteredValue: this.ds.filterValue("razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Cliente.razao_social", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Cidade",
        dataIndex: "Cliente.cidade",
        key: "cidade",
        sorter: true,
        width: "11.5%",
        filteredValue: this.ds.filterValue("cidade"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cidade do Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Cliente.cidade", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "UF",
        dataIndex: "Cliente.uf",
        key: "uf",
        sorter: true,
        width: "8%",
        filteredValue: this.ds.filterValue("uf"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por UF do Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Cliente.uf", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Tipo",
        dataIndex: "Poff.tipo_pedido",
        key: "tipo_pedido",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("tipo_pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Tipo" {...props} />,
        filterParser: filter => ({ ...filter, field: "Poff.tipo_pedido", value: `%${filter.value}%`, op: "LIKE" }),
      },
    ];

    return (
      <FullTable
        className="full-table__compact"
        beforeComponent={this.renderLimpar}
        actionBar={"none"}
        rowKey={rowKey}
        loading={this.props.store.isLoading}
        columns={columns}
        dataSource={this.ds}
        errorMessage="Não foi possível obter seus pedidos no momento"
      />
    );
  }
}
