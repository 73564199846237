/**
 * @flow
 */
import { action, observable, computed, toJS } from "mobx";

import { OrcamentoError } from "./Orcamento";

export default class Item {
  @observable mode: string = "add";
  @observable item_orcamento: number = 0;
  @observable produto_orcamento: string = null;
  @observable classe_orcamento: string = null;
  @observable linha_produto: string = null;
  @observable produto_descricao: string = null;
  @observable quantidade_minima: number = 1;
  @observable quantidade_por_fardo: number = 1;
  @observable qtde_orcamento: number = 0.0;
  @observable preco_tabela: number = 0.0;
  @observable punit_orcamento: number = 0.0;
  @observable totalmerc_orcamento: number = 0.0;
  @observable pedido_compra: string = null;
  @observable itemped_compra: string = null;

  constructor(data = null) {
    if (!!data) {
      this.parse(data);
    }
  }

  @action
  checkQuantidade(silent = false) {
    const __errors = [];
    this.qtde_orcamento = this.__quantidade;

    if (+this.qtde_orcamento > 0) {
      const quantidade_por_fardo = Math.max(+this.quantidade_por_fardo, 1);
      const quantidade_minima = Math.max(+this.quantidade_minima, 1);

      if (+this.qtde_orcamento < +this.quantidade_minima) {
        const fardos = Math.max(Math.ceil(quantidade_minima / quantidade_por_fardo), 1);
        const quantidade_precisa = fardos * quantidade_por_fardo;

        if (quantidade_precisa !== +this.qtde_orcamento) {
          this.qtde_orcamento = quantidade_precisa;
          __errors.push("Item abaixo da quantidade mínima estipulada (" + quantidade_minima + " latas).");
        }
      }
      const fardos = Math.max(Math.ceil(+this.qtde_orcamento / quantidade_por_fardo), 1);
      const quantidade_precisa = fardos * quantidade_por_fardo;
      if (quantidade_precisa !== +this.qtde_orcamento) {
        this.qtde_orcamento = quantidade_precisa;
        __errors.push("Não pode ser vendido fardo quebrado");
      }
    }

    if (__errors.length > 0 && !silent) {
      throw new OrcamentoError(__errors.join(" / "));
    }
  }

  @action
  parse(data) {
    if (!!data.codigo_orcamento && !!data.item_orcamento) {
      this.item_orcamento = +data.item_orcamento;
      this.mode = "edit";
    }

    if (!!data.produto_orcamento) this.produto_orcamento = data.produto_orcamento;
    if (!!data.produto_descricao) this.produto_descricao = data.produto_descricao;
    if (!!data.classe_orcamento) this.classe_orcamento = data.classe_orcamento;
    if (!!data.linha_produto) this.linha_produto = data.linha_produto;
    if (!!data.preco_tabela) this.preco_tabela = data.preco_tabela;
    this.quantidade_por_fardo = +(data.quantidade_por_fardo || 1);
    this.qtde_orcamento = +(data.qtde_orcamento || 0.0);
    this.punit_orcamento = +(data.punit_orcamento || 0.0);
    this.totalmerc_orcamento = +(data.totalmerc_orcamento || 0.0);
    if (!!data.pedido_compra) this.pedido_compra = data.pedido_compra;
    if (!!data.itemped_compra) this.itemped_compra = data.itemped_compra;
  }

  @computed
  get isValid() {
    return !!this.produto_orcamento && +this.qtde_orcamento > 0;
  }

  @computed
  get __quantidade() {
    const value = parseFloat(this.qtde_orcamento || 0);
    return Math.max(!isNaN(value) ? value : 0, 0);
  }

  @computed
  get asJSON() {
    return toJS(this);
  }

  static fromJSON(itemJS) {
    return new Item(itemJS);
  }
}
