import React from "react";
import styled from "styled-components";
import { linearGradient } from "polished";

function backgroundMixin({ type = "vencido", theme }) {
  if (type === "vencido") {
    return linearGradient({
      colorStops: ["#F11D28", "#ff7d2c"],
      toDirection: "to right",
      fallback: theme.primary,
    });
  }

  if (type === "avencer") {
    return linearGradient({
      colorStops: ["#fe9e1c", "#FFE134"],
      toDirection: "to right",
      fallback: theme.primary,
    });
  }

  if (type === "atualizado") {
    return linearGradient({
      colorStops: ["#36d558", "#21ca9c"],
      toDirection: "to right",
      fallback: theme.primary,
    });
  }

  return `background-color: ${theme.primary};`;
}

const StyledBanner = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: -1px;
  text-align: center;
  border-radius: 5px;
  box-shadow: inset 0 0 13px rgba(0, 0, 0, 0.35);
  padding: 16px;
  min-height: 160px;
  color: #fff;

  ${backgroundMixin}

  ${props =>
    !!props.href
      ? `
        &:hover {
          box-shadow: inset 0 0 20px rgba(0, 0, 0, .4);
          color: #cfcfcf;
        }`
      : `
        cursor: default
    `}
`;

export default ({ href = null, ...props }) => <StyledBanner as={!!href ? "a" : "div"} href={href} {...props} />;
