import { fetchApiWithCache, fetchPostApi } from "actions";

export const fetchBase = async () => {
  const { data: response } = await fetchApiWithCache(`/memorandos/base`);
  return response;
};

export const aprovarOuReprovar = async (data) => {
  const { data: response } = await fetchPostApi(`/memorandos/aprovarOuReprovar`, data);
  return response;
}