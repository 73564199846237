import React, { Component } from "react";
import { Button, Checkbox, Col, Form, Icon, Input, Modal, Row, Select, Spin, message } from "antd";
import { observer } from "mobx-react";
import styled from 'styled-components';
import Img from '../../../img/logoWhite.png';
import moment from "moment";
import axios from "axios";

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  margin: 30px;
`;

const Frase = styled.h4`
    color: #ffffff;
    margin: 20px;
`

// Estilize o Form.Item e o label dentro dele
const StyledFormItem = styled(Form.Item)`
  label {
    /* Estilos para o rótulo (label) */
    color: #ffffff; /* Exemplo de cor para o rótulo */
    font-size: 16px; /* Exemplo de tamanho de fonte para o rótulo */
  }
`;

const StyledButton = styled(Button)`
    background-color: #ffffff;
    font-size: 16px; 
    color: '#0f73b9';
    width: 150px;
`;

const antIcon = <Icon type="loading" style={{ fontSize: 24, color: 'white' }} spin />;

@observer
class FormPromotoriaPintores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingForm: false,
            isValid: true,
            isChecked: false,
            promotoria: {}
        };

        this.handleCheck = this.handleCheck.bind(this);
    }

    componentDidMount() {
        this.fecthData();
    }

    fecthData = async () => {
        try {
            this.setState({ isLoading: true });
            const { promotoria } = this.props.match.params;
            const { data } = await axios.get(`${process.env.REACT_APP_API_DEFAULT_URL_NO_AUTH}/validar-qrcode-promotoria/${promotoria}`);

            const dataFimFormatada = moment(data.data.zy7_dtfimv).toDate();

            const dataFimFormatadaMaisUmDia = moment(dataFimFormatada).add(1, 'days').toDate();

            this.setState({ promotoria: data.data });

            if (moment(dataFimFormatadaMaisUmDia).isAfter(moment().toDate())) {
                this.setState({ isValid: true });
            } else {
                this.setState({ isValid: false });
            }
        } catch (error) {
            message.error('Erro ao buscar o formulário');
        } finally {
            this.setState({ isLoading: false });
        }
    }

    enviarDados = async (value) => {
        const { resetFields } = this.props.form;
        try {
            this.setState({ isLoadingForm: true });
            const { promotoria } = this.state;
            const data = {
                zy7_filial: promotoria.zy7_filial,
                zy7_codsol: this.props.match.params.promotoria,
                nome: value.nome,
                email: value.email,
                contato1: value.contato1,
                contato2: value.contato2,
                empregado: value.empregado
            }

            await axios.post(`${process.env.REACT_APP_API_DEFAULT_URL_NO_AUTH}/cadastrar-pintores-promotoria`, data);

            message.success('Cadastro efetuado com sucesso!');

            resetFields();
        } catch (error) {
            if (!!error?.response) {
                message.error(error.response.data.message);
                return;
            }

            message.error("Erro ao cadastrar");
        } finally {
            this.setState({ isLoadingForm: false });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                await this.enviarDados(values);
            }
        });
    };

    handleCheck(){
        if(!!this.state.isChecked){
            this.setState({ isChecked: false});
        }else{
            this.setState({ isChecked: true});
        }
    }

    render() {
        const { isLoading, isValid, isLoadingForm } = this.state
        const { getFieldDecorator } = this.props.form;

        if (isLoading) {
            return (
                <CenteredDiv style={{ backgroundColor: '#0f73b9' }}>
                    <Spin indicator={antIcon} spinning={isLoading} size="large"></Spin>
                </CenteredDiv>
            )
        }

        if (!isValid) {
            return (
                <div style={{ backgroundColor: '#0f73b9' }}>
                    <Spin spinning={isLoading} size="large">
                        <CenteredDiv>
                            <Modal
                                visible={!isValid}
                                title="Promotoria já finalizada!"
                                footer={null}
                                closable={false}
                            >
                                <p>A promotoria já passou da data de validade, por favor entre em contato com a Anjo Tintas</p>
                            </Modal>
                            <img width="180px" src={Img} alt="Logo" />
                        </CenteredDiv>
                    </Spin>
                </div>
            );
        }

        return (
            <div style={{ backgroundColor: '#0f73b9' }}>
                <Spin spinning={isLoading} size="large">
                    <CenteredDiv>
                        <img width="180px" src={Img} alt="Logo" />
                        <Frase>Insira suas informações para guardarmos no nosso banco para futuras possibilidades..</Frase>
                        <Form
                            autoComplete="off"
                            layout="vertical"
                            onSubmit={this.handleSubmit}
                            style={{ marginTop: '30px' }}
                        >
                            <Row gutter={8}>
                                <Col xs={24} sm={24}>
                                    <StyledFormItem label="Nome completo">
                                        {getFieldDecorator('nome', {
                                            rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                        })(<Input placeholder="Seu nome aqui..." maxLength="100" />)}
                                    </StyledFormItem>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <StyledFormItem label="Email">
                                        {getFieldDecorator('email', {
                                            rules: [
                                                { required: true, message: 'Informe o campo obrigatório' },
                                                { type: 'email', message: 'Email inválido' },
                                            ],
                                        })(<Input placeholder="exemplo@mail.com" maxLength="100" />)}
                                    </StyledFormItem>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <StyledFormItem label="Contato">
                                        {getFieldDecorator('contato1', {
                                            rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                        })(<Input placeholder="00000000000" maxLength="11" />)}
                                    </StyledFormItem>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <StyledFormItem label="Contato alternativo">
                                        {getFieldDecorator('contato2')(<Input placeholder="00000000000" maxLength="11" />)}
                                    </StyledFormItem>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <StyledFormItem label="Empregado">
                                        {getFieldDecorator('empregado', {
                                            rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                                        })(
                                            <Select
                                                placeholder="Selecione"
                                                showSearch={false}
                                            >
                                                <Select.Option value="S">Sim</Select.Option>
                                                <Select.Option value="N">Não</Select.Option>
                                            </Select>
                                        )}
                                    </StyledFormItem>
                                </Col>
                            </Row>
                            <Col span={24} style={{marginBottom: 20}}>
                                <Checkbox checked={this.state.isChecked} onChange={this.handleCheck}  style={{ color: '#fff' }}>Aceito o armazenamento das informações descritas a cima.</Checkbox>
                            </Col>
                            <StyledButton disabled={!this.state.isChecked} htmlType="submit" loading={isLoadingForm}>Salvar</StyledButton>
                        </Form>
                    </CenteredDiv>
                </Spin>
            </div>
        );
    }
}

export default Form.create({ name: 'promotoria' })(FormPromotoriaPintores);