import React, { Component, Fragment } from "react";
import { Modal, Spin } from "antd";
import { Helmet } from "react-helmet";
import { observable } from "mobx";
import { observer } from "mobx-react";

import withStore from "withStore";
import { debug } from "@env";
import { dateFormat, n } from "@util";
import { fetchBase, fetchEdit } from "actions/carga";
import { ProgramacaoCarga, PedidoError } from "stores/PedidoStore";

import PageHeader, { BackButton } from "components/PageHeader";
import CargaTable from "components/pedidos/cargas/CargaTable";
import CargaItemTable from "components/pedidos/cargas/CargaItemTable";

import "./CargaEditorScreen.less";

function onCargaError(errorMessage, onOk) {
  Modal.error({
    title: "Atenção",
    content: errorMessage || "Não foi possível continuar sua carga",
    onOk,
  });
  return false;
}

@observer
class CargaViewScreen extends Component {
  constructor(props) {
    super(props);

    const { isVendedor = true, vendedorData = {}, operadorData = {} } = props.store;
    const tipo = !!props.match.params.tipo && props.match.params.tipo === "safra" ? "safra" : "kit";

    this.programacao = new ProgramacaoCarga(
      !!isVendedor
        ? { tipo, codigo_vendedor: vendedorData.codigo }
        : {
            tipo,
            codigo_operador: operadorData.codigo_operador || operadorData.codigo || null,
          }
    );

    this.state = {
      started: false,
      carga: null,
    };
  }
  async componentDidMount() {
    const { store, match } = this.props;

    store.trackTab("/pedidos", this.programacao.tipo === "kit" ? "tab-camp-kit" : "tab-safra");

    await this.fetchBase();

    if (!!match.params && !!match.params.slug) {
      await this.fetchEdit(match.params.slug);
    }
  }
  async fetchBase() {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      const response = await fetchBase(this.programacao.tipo);
      if (!!response.success) {
        const { pedidos = [], tipos, validacao_clientes } = response.data;

        this.programacao.tipos = tipos;
        this.programacao.validacao_clientes = validacao_clientes;
        this.programacao.pedidos = observable(pedidos);

        this.setState({ started: true });
      } else {
        throw new PedidoError("Não foi possível visualizar esta carga no momento");
      }
    } catch (err) {
      onCargaError("Não foi possível visualizar esta carga no momento", () => history.replace("/pedidos"));
    } finally {
      store.isLoading = false;
    }
  }
  async fetchEdit(codigo) {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      const response = await fetchEdit(codigo);
      if (!!response.success) {
        const { carga, pedidos = [] } = response.data;
        this.programacao.parse(carga, pedidos);
      } else {
        throw new PedidoError("Não foi possível visualizar esta carga no momento");
      }
    } catch (err) {
      if (debug) console.warn(err);
      onCargaError("Não foi possível editar esta carga no momento", () => history.replace("/pedidos"));
    } finally {
      store.isLoading = false;
    }
  }
  stepBack = () => {
    this.props.history.goBack();
  };
  viewCarga = carga => {
    this.setState({ carga });
  };
  render() {
    const { store } = this.props;
    const { started } = this.state;
    const title = this.programacao.tipo === "safra" ? `Visualizando Safra` : `Visualizando Programação de Carga KIT`;
    const isLoading = !started || store.isLoading || this.programacao.isLoading;

    return (
      <Fragment>
        <div className="carga-editor-screen">
          {!isLoading && (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
          <Spin spinning={isLoading}>
            {started ? (
              <Fragment>
                <PageHeader title={title} headerLeft={<BackButton onClick={this.stepBack} />} inside={true} />
                <div className="carga-editor--content">
                  <CargaTable
                    isLoading={this.props.store.isLoading}
                    programacao={this.programacao}
                    cargas={this.programacao.cargas.map(carga => carga.asJSON)}
                    onView={this.viewCarga}
                    locale={{
                      emptyText: "Sem cargas cadastradas nesta programação.",
                    }}
                    scroll={!!this.state.carga && this.programacao.cargas.length > 4 ? { y: 148 } : undefined}
                  />
                  {!!this.state.carga && (
                    <Fragment>
                      <h5 className="carga-subtitle carga-subtitle__view">{`Carga ${dateFormat(
                        this.state.carga.data_entrega
                      )} | Peso Total (kg): ${n(this.state.carga.total_peso)} | ${this.state.carga.tipo_caminhao}`}</h5>
                      <CargaItemTable
                        showStatus={true}
                        items={this.state.carga.items}
                        locale={{
                          emptyText: "Selecione um pedido disponível na lista acima para visualizar uma carga",
                        }}
                      />
                    </Fragment>
                  )}
                </div>
                {!!this.programacao && (
                  <div className="carga-editor--footer">
                    <div>
                      <div className="footer-label">
                        <strong>Total NF (R$):</strong> {n(this.programacao.total_nota)}
                      </div>
                      <div className="footer-label">
                        <strong>Peso Total (KG):</strong> {n(this.programacao.total_peso)}
                      </div>
                    </div>
                    <div style={{ minWidth: 76, height: 32 }} />
                  </div>
                )}
              </Fragment>
            ) : (
              <div style={{ minHeight: 480 }} />
            )}
          </Spin>
        </div>
      </Fragment>
    );
  }
  _cargaModal;
}

export default withStore(CargaViewScreen);
