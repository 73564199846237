import React, { PureComponent, Fragment } from "react";
import { Route } from "react-router-dom";

import HomeScreen from "screens/vendedor/HomeScreen";

export default class SAC extends PureComponent {
  render() {
    const { match } = this.props;

    return (
      <Fragment>
        <Route exact path={match.url} component={HomeScreen} />
        <Route exact path="/sac" component={HomeScreen} />
      </Fragment>
    );
  }
}