import React, { Component } from "react";
import { Modal, Form, Input, Button } from "antd";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import styled from "styled-components";
import { darken } from "polished";

import { fetchFornecedorAcesso } from "actions";
import { getData } from "@util";
import withStore from "withStore";

import LogoLogin from "img/logoLogin.png";

const FormItem = Form.Item;

function showError(content) {
  Modal.error({
    title: "Erro em seu login",
    content,
  });
}

const LOGIN_BUTTON_COLOR = "#ec9e1a";

const LoginButton = styled(Button)`
  &.ant-btn {
    background-color: ${LOGIN_BUTTON_COLOR};
    border-color: ${darken(0.05, LOGIN_BUTTON_COLOR)};
    text-transform: uppercase;
    margin-top: 9px;
    width: 100%;
    height: 44px;
  }
`;

const LoginForm = styled.div`
  position: relative;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.35);
  min-width: 380px;
  padding: 38px;
  text-align: center;

  .ant-form {
    .ant-form-item {
      margin-bottom: 11px;
    }
    .ant-form-item-with-help {
      margin-bottom: 6px;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: -114px;
  left: 50%;
  margin-left: -112px;
  z-index: 10;

  > img {
    width: 224px;
    height: 77px;
  }
`;

@observer
class FornecedorAcesso extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {
    const { match, form } = this.props;

    form.setFieldsValue({
      login: match.params.hash,
    })

    this.handleSubmit();
  }

  async fetchFornecedorAcesso(login) {
    this.setState({ isLoading: true });
    try {
      const { data: response } = await fetchFornecedorAcesso(login);
      if (!!response.success) {
        const { store } = this.props;
        const { data } = response;

        store.setLoginData(data);
        if (!store.isLogged) {
          throw new Error("Verique seu login e senha e tente novamente");
        }

        requestAnimationFrame(() => this.props.history.replace("/pendencias"));
        return;
      }

      throw new Error("Login não encontrado, verique seu login e tente novamente");
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 1) : 1;
      const errorMessages = {
        1: "Login não encontrado, verique seu login e tente novamente",
      };

      showError(errorMessages[+errorCode] || err.message);

      this.setState({ isLoading: false }, () =>
        this.props.form.setFieldsValue({
          login: "",
        })
      );
    }
  }
  handleSubmit = () => {
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.fetchFornecedorAcesso(formData.login);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { isLoading } = this.state;

    return (
      <LoginForm className="login-form login-form__logout">
        <Helmet>
          <title>ANJO NEXXUS - Esqueci minha Senha</title>
        </Helmet>
        <LogoWrapper>
          <img src={LogoLogin} alt="ANJO NEXXUS" className="login-logo--img" />
        </LogoWrapper>
        <Form noValidate layout="vertical" onSubmit={this.handleSubmit}>
          <p>
            Informe a <strong>hash de acesso</strong> abaixo para acessar o sistema:
          </p>
          <FormItem>
            {getFieldDecorator("login", {
              rules: [{ required: true, message: "Por favor insira seu nome de usuário" }],
            })(<Input readOnly={isLoading} size="large" placeholder="Hash de Acesso" />)}
          </FormItem>
          <LoginButton loading={isLoading} size="large" type="primary" htmlType="submit">
            ACESSAR
          </LoginButton>
        </Form>
      </LoginForm>
    );
  }
}

export default Form.create()(withStore(FornecedorAcesso));
