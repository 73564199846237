import React, { Component, Fragment, PureComponent } from "react";
import { Modal, Form, Input, Spin, Row, Col, Button, Icon, Upload, Dropdown, Menu } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";

import { filterDate, getData } from "@util";
import { fetchApi, fetchPostApi } from "actions";
import { v } from "components/FormBuilder";
import FullTable, { TableDataSource, Toolbar, TextFilter, WrappedValue } from "components/FullTable";
import Anexos from "components/treinamento/Anexos";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const StyledForm = styled(Form)`
  &.ant-form {
    margin-bottom: 26px;
  }
  .ant-form-item {
    margin-bottom: 3px;
    padding-bottom: 6px;
  }
`;

const MensagemItem = styled.div`
  p {
    margin-bottom: 9px;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

@observer
class AnexosModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      visible: false,
      cliente: null,
      loja: null,
      treinamento: null,
      necessita_anexo: null,
      vigencia: null,
      funcionario: null
    };

  }
  open = (treinamento, necessita_anexo, vigencia, funcionario) => {
    this.setState({ visible: true, treinamento, necessita_anexo: necessita_anexo === 'S', vigencia: vigencia === 'S', funcionario });
  };
  close = () => {
    if (!this.state.isLoading) {
      this.setState({ visible: false }, () => {
      });
    }
  };
  render() {
    const { necessita_anexo, vigencia, treinamento } = this.state;

    return (
      <Modal
        centered
        width={990}
        footer={null}
        title={`Anexos`}
        visible={this.state.visible}
        onCancel={this.close}
        destroyOnClose={true}
        maskClosable={true}
        wrapClassName="interacoes-modal">
        <Anexos
          empresa={this.props.match.params.empresa || this.props?.store?.treinamentoFornecedorData?.codigo}
          funcionario={this.props.match.params.funcionario || this.state.funcionario}
          mostraTable={!this.state.funcionario}
          treinamento={treinamento}
          necessita_anexo={necessita_anexo}
          vigencia={vigencia}
          onClose={this.props.onClose && this.props.onClose}
        />
      </Modal >
    );
  }
}

export default Form.create()(AnexosModal);