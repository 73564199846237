import React from "react";
import { Tag, Icon } from "antd";
import styled from "styled-components";

export const STATUS_COLOR = {
  LIBERADA: "#33af1a",
  "LIBERADO PARCIALMENTE": "#eeb74b",
  REJEITADA: "#f50",
  "ANALISE DE ROTA": "#696969",
  "AGUARDANDO CLIENTE": "#2d96cf",
  CANCELADA: "#e82a2c",
  APROVADO: "#33af1a",
  REJEITADO: "#e82a2c",
  PENDENTE: "#eeb74b",
  NOVO: "#6871c4",
};

export const STATUS_LABEL = {
  LIBERADA: "Liberada",
  "LIBERADO PARCIALMENTE": "Lib. Parcialmente",
  REJEITADA: "Rejeitada",
  "ANALISE DE ROTA": "An. Comercial",
  "AGUARDANDO CLIENTE": "Aguardando Cliente",
  CANCELADA: "Cancelada",
  APROVADO: "Aprovado",
  REJEITADO: "Rejeitado",
  PENDENTE: "Pendente",
  NOVO: "Novo",
};

const CargaTag = styled(Tag)`
  &.ant-tag {
    font-size: 11px;
    line-height: 18px;
    height: 20px;
    padding: 0 5px;
    margin: 0;

    ${props => !!props.simple && `cursor: default;`}
  }
`;

const CargaStatusTag = ({ status, onClick = null, ...props }) => (
  <CargaTag
    title={!!onClick ? "Clique para ver os detalhes das aprovações" : null}
    color={STATUS_COLOR[status] || "#696969"}
    onClick={onClick}
    simple={!onClick}
    {...props}>
    {!!onClick && <Icon type="search" style={{ marginRight: 5 }} />}
    {STATUS_LABEL[status] || status}
  </CargaTag>
);

export default CargaStatusTag;
