import React, { Component, Fragment } from "react";
import { Table, Button } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";

import { n } from "@util";
import { WrappedValue, mapColumn } from "components/FullTable";

const MESES = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  "10": "Outubro",
  "11": "Novembro",
  "12": "Dezembro",
};

const rowKey = item => `${item.sequencia_carga}:${item.codigo_kit}:${item.data_entrega}`;

@observer
export default class CargaTable extends Component {
  decorateRows = (item, index) =>
    classnames("base-table--row carga-table--row", {
      "base-table--row__odd carga-table--row__odd": index % 2 !== 0,
      "base-table--row__invalid carga-table--row__invalid": !item.isValid,
    });
  render() {
    const {
      programacao,
      cargas,
      onView,
      onEdit,
      onRemove,
      className,
      rowClassName = this.decorateRows,
      loading = false,
      locale = {},
      bordered = true,
      size = "middle",
      ...tableProps
    } = this.props;

    const columns = [
      {
        title: "Ações",
        key: "actions",
        render: (_, item) => (
          <div className="actions">
            {!!onView && (
              <Button
                title="Clique para visualizar as cargas desta programação"
                icon="search"
                size="small"
                type="primary"
                onClick={() => onView(item)}
              />
            )}
            {!!onEdit && <Button icon="edit" size="small" type="primary" onClick={() => onEdit(item)} />}
            {!!onRemove && (
              <Button icon="close" size="small" type="primary" ghost={true} onClick={() => onRemove(item)} />
            )}
          </div>
        ),
        width: "74px",
      },
      {
        title: "Mês",
        key: "sequencia_carga",
        dataIndex: "sequencia_carga",
        render: (_, item) => {
          const mes = item.data_entrega.split("-")[1];
          return <WrappedValue value={MESES[mes]} type="text" />;
        },
      },
      {
        title: "Data Faturamento",
        key: "data_entrega",
        dataIndex: "data_entrega",
        dataType: "date",
        width: "16%",
      },
      {
        title: (
          <Fragment>
            Peso <em title="em Kilos">(KG)</em>
          </Fragment>
        ),
        key: "total_peso",
        dataIndex: "total_peso",
        render: v => <WrappedValue value={n(+v)} type="text" />,
        width: "12%",
      },
      {
        title: "Transporte",
        key: "tipo_caminhao",
        dataIndex: "tipo_caminhao",
        width: "16%",
      },
    ];

    return (
      <Table
        className={classnames(
          `base-table carga-table base-table__compact produto-table__compact`,
          { "base-table__loading produto-table__loading": !!loading },
          className
        )}
        pagination={false}
        rowKey={rowKey}
        loading={!!loading}
        bordered={bordered}
        columns={columns.map(mapColumn)}
        rowClassName={rowClassName}
        dataSource={programacao.sortedCargas}
        locale={{
          filterTitle: "Filtrar",
          filterConfirm: "OK",
          filterReset: "Limpar",
          emptyText: 'Sem cargas programadas até o momento. Clique em "+ Nova Carga" para adicionar uma.',
          ...locale,
        }}
        size={size}
        {...tableProps}
      />
    );
  }
}
