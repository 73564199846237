import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";

import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";
import styled from "styled-components";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MenuTreinamento extends PureComponent {
  render() {
    // eslint-disable-next-line
    const { tipoDiretor, collapsed = false, selected = [], Diretor_unidade_negocio } = this.props;
    const submenuKeys = [];

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__vendedor", "sidebar-nav__diretor")}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-home">
            <Link to="/">
              <Icon type="home" />
              <span>Home</span>
            </Link>
          </MenuItem>
          <MenuItem key="nav-pendente-aprovacao">
            <Link to="/pendente-aprovacao">
              <Icon type="check" />
              <span>Pendente Aprovação</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subAprov"
            title={
              <span>
                <Icon type="form" />
                <span>Cadastros</span>
              </span>
            }>
            <MenuItem key="nav-dir-treinamento">
              <Link to="/treinamento">Treinamento / Procedimentos</Link>
            </MenuItem>
            <MenuItem key="nav-dir-empresa">
              <Link to="/empresadash/empresa">Empresa</Link>
            </MenuItem>
          </SubMenu>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuTreinamento);
