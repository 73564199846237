import React, { PureComponent } from "react";
import classnames from "classnames";

import { n, dateFormat } from "@util";

export default class WrappedValue extends PureComponent {
  render() {
    const { value, className, type = "text", children, icon, ...props } = this.props;

    const parsedValue =
      type === "money"
        ? "R$ " + n(+`${value}`.split(",").join("."))
        : type === "decimal"
          ? n(+`${value}`.split(",").join("."))
          : type === "number-no-decimal"
            ? n(+`${value}`.split(","), 0)
            : type === "date"
              ? dateFormat(value)
              : type === "datetime"
                ? dateFormat(value, true)
                : value;

    return (
      <div
        className={classnames("text-nowrap wrapped-value", className)}
        title={typeof parsedValue === "string" ? parsedValue : null}
        {...props}>
        {icon}
        {parsedValue}
        {children}
      </div>
    );
  }
}
