import React, { Component } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";

import { TableDataSource } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.codigo_alvara}:${item.municipio_alvara}`;

@observer
export default class ChooserAlvara extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/alvaras/000000-00");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        const { data } = this.props.pedido;
        const codigo_cliente = data.codigo_cliente;
        const loja_cliente = !!data.loja_cliente ? data.loja_cliente : "01";

        this.ds.uri = "/pedidos/alvaras/" + [codigo_cliente, loja_cliente].join("-");
      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Código",
        key: "codigo_alvara",
        width: "14%",
      },
      {
        title: "Endereço",
        key: "endereco_alvara",
        className: "text-left",
      },
      {
        title: "Cidade",
        key: "municipio_alvara",
        width: "18%",
      },
      {
        title: "UF",
        key: "uf_alvara",
        width: "10%",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Encontre uma Código de Alvará"
        errorMessage="Não foi possível obter seus alvarás no momento"
        searchPlaceholder="Busque pelo código de alvará"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        {...this.props}
      />
    );
  }
}
