import React, { Component } from "react";
import { Row, Col, Form, Card, Radio, Spin, Badge, Icon, Divider } from "antd";
import { observer } from "mobx-react";
import CepTag from "components/pedidos/ProdutoTag";

@observer
export default class StepEntrega extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: false,
      selectedIndex: null,
      focused: null,
      data: [],
      loading: false
    };
  }

  async componentDidMount() {
    const { pedido } = this.props;
    let selectedIndex = null;

    if (pedido?.selectedEntrega?.codigo_entrega) {
      selectedIndex = 0;
      const entrega = pedido.entregas.find((ent, i) => {
        if (ent.codigo_entrega === pedido.selectedEntrega.codigo_entrega) {
          selectedIndex = i;
          return true;
        }
      });

      if (entrega) {
        this.setState({ selectedIndex, selected: entrega, data: pedido.entregas }, () => pedido.selectEntrega(entrega, selectedIndex));
        return;
      }
    } else if (pedido.isEdit) {
      selectedIndex = 0;
    }

    if (pedido?.selectedEntrega?.index || pedido?.selectedEntrega?.index === 0) {
      selectedIndex = pedido.selectedEntrega.index;
    }

    if (pedido.entregas?.length === 1) {
      const selectedEntrega = pedido.entregas[0];
      this.setState({ data: pedido.entregas, selectedIndex: 0, selected: selectedEntrega }, () => pedido.selectEntrega(selectedEntrega, 0));
      return;
    }

    this.setState({ data: pedido.entregas, selectedIndex, selectedEntrega: selectedIndex != null && pedido.entregas[0] }, () => selectedIndex != null && pedido.selectEntrega(pedido.entregas[0], selectedIndex));
  }

  handleSelect(ent, index) {
    const { pedido } = this.props;
    this.setState({ selected: ent, selectedIndex: index }, () => pedido.selectEntrega(ent, index));
  }

  render() {
    const { data, loading, selectedIndex } = this.state;

    return (
      <div className="pedido-step">
        <Spin spinning={loading}>
          <Form noValidate layout="vertical">
            <Card title="Seus endereços"
              bodyStyle={{ padding: '14px 14px 0px 14px' }}>
              <Radio.Group value={selectedIndex} style={{ display: 'block' }}>
                <Row gutter={12}>
                  {!data || data.length <= 0 ?
                    <p style={{ marginTop: 14, textAlign: 'center' }}>Você não possui nenhum endereço! contate a administração do sistema.</p>
                    :
                    data.map((ent, index) => {
                      const selected = index === selectedIndex;
                      const isSelected = selected ? { border: '1px solid #FBD8B4', backgroundColor: '#FCF5EE' } : {}
                      return (
                        <Col span={12} onClick={() => this.handleSelect(ent, index)}>
                          <Badge
                            style={{
                              background: 'none',
                              boxShadow: 'none',
                              display: !selected ? 'none' : 'flex',
                              height: 40,
                              right: 12
                            }}
                            className="dblock"
                            count={<Icon type="check" style={{ color: '#52c41a', fontSize: 40, animation: 'crescer 0.5s ease 0s', }} />}>
                            <Card style={{ borderRadius: 5, padding: 0, marginBottom: 14, cursor: 'pointer', ...isSelected }}>
                              <Row style={{ minHeight: 60 }}
                                type="flex"
                                justify="center"
                                align="middle">
                                <Col span={24}>
                                  <div style={{ fontSize: 20, fontWeight: 500 }}>
                                    {ent.cidade} / {ent.uf}
                                  </div>
                                  <Divider dashed style={{
                                    margin: '5px 0',
                                    height: 1,
                                    minHeight: '100%',
                                    borderColor: '#e8e8e8',
                                    borderStyle: 'dashed',
                                    borderWidth: '1px 0 0'
                                  }} />
                                  <div>
                                    End: {ent.endereco}, {ent.numero ? `${ent.numero}` : ''}
                                  </div>
                                  <div>
                                    Bairro: {ent.bairro}
                                  </div>
                                  <div>
                                    <CepTag color="#0f73b9" title="CEP">
                                      CEP:&nbsp;{ent.cep}
                                    </CepTag>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Badge>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Radio.Group>
            </Card>
          </Form>
        </Spin>
      </div >
    );
  }
  _inputs = {};
}
