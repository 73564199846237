import React, { Component, Fragment } from "react";
import { Modal, Button, Spin, Icon, Table, DatePicker, Form, Radio, Alert } from "antd";
import FullTable, { SearchForm, TableDataSource, TextFilter, Toolbar } from "components/FullTable";
import moment from "moment";

const antIcon = <Icon type="loading" style={{ fontSize: 42 }} spin />;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

class ModalCliente extends Component {
  constructor(props) {
    super(props);

    this.ds = null;

    this.state = {
      isLoading: false,
      visible: false,
      title: "Clientes Ativos",
      selected: "ativo",
      month: moment(),
      monthMoment: moment(),
      expandedRows: []
    };
  }

  open = (month) => {
    this.ds = new TableDataSource({
      name: "Retenção Cliente",
      uri: "/pedidos/classes",
    });

    const year = moment().year();

    this.setState({ visible: true, month: moment({ year, month, day: 1 }).format('YYYY-MM-DD'), monthMoment: moment({ year, month, day: 1 }) }, () => this.ds.fetch());
  }

  close = () => {
    this.setState({ visible: false }, () => {
      this.ds = null;
    });
  }

  expandedRowRender(item) {
    const columns = [
      { title: 'Cód. Classe', dataIndex: 'classe', key: 'classe' },
      { title: 'Descrição', dataIndex: 'desc_classe', key: 'desc_classe' },
      { title: 'Volume', dataIndex: 'volume', key: 'volume', align: 'right', },
      {
        title: 'Última compra',
        dataIndex: 'ult_venda',
        key: 'ult_venda',
        render: (uc) => (
          <div>{moment(uc, 'YYYYMMDD').format('DD/MM/YYYY')}</div>
        )
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={item.produtos}
        pagination={false}
        className="ant-table-bordered" />
    );
  };

  handleMessage = () => {
    const { selected } = this.state;

    if (selected === 'sem_compra') {
      return 'Clientes que não compraram no mês filtrado!';
    }

    return this.renderAnalise(selected);
  }

  renderAnalise = (selected) => {
    const periodoAtual = this.state.monthMoment.clone();
    const periodo = this.state.monthMoment.clone();
    const segundoPeriodo = periodoAtual.subtract(5, 'months').format("MM/YYYY");
    const segundaPartePeriodo = periodoAtual.subtract(1, 'months').format("MM/YYYY");

    if (selected === 'ativo') {
      return (
        <div>
          <div>Compras entre: {segundoPeriodo + ` - ` + periodo.format("MM/YYYY")}</div>
        </div>
      )
    }

    if (selected === 'inativo') {
      return (
        <div>
          <div>Compras até: {segundaPartePeriodo}</div>
        </div>
      )
    }

    return <div />
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const { selected } = this.state;
    const { MonthPicker } = DatePicker;

    const filterFields = {
      tipo: "options",
      mes: "date",
    };

    const options = [
      {
        key: "ativo",
        label: "Ativos",
      },
      {
        key: "inativo",
        label: "Inativos",
      },
      {
        key: "sem_compra",
        label: "Sem compras",
      },
      {
        key: "atendido",
        label: "Atendido no mês",
      },
    ]

    return (
      <Fragment>
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          onUpdateDataSource={(a, b, c) => { onUpdateDataSource(a, b, c); this.setState({ expandedRows: [] }) }}
          filterFields={filterFields}
          defaultFilters={{ tipo: 'ativo', mes: this.state.month }}>
          {({ form, submitForm }) => (
            <Fragment>
              <FormItem>
                {form.getFieldDecorator("tipo")(
                  <RadioGroup size="large" buttonStyle="solid" onChange={({ target: { value } }) => this.setState({ selected: value }, () => setTimeout(submitForm, 30))}>
                    {options.map((op) =>
                      <RadioButton key={op.key} value={op.key}>
                        {op.label}
                      </RadioButton>
                    )}
                  </RadioGroup>
                )}
              </FormItem>
              <FormItem>
                {form.getFieldDecorator("mes")(
                  <MonthPicker
                    size="large"
                    format="MMMM - YYYY"
                    allowClear={false}
                    onChange={m => this.setState({ monthMoment: m }, () => setTimeout(submitForm, 30))}
                    placeholder="Selecione o mês" />
                )}
              </FormItem>
              <FormItem>
                <Button size="large" type="danger" htmlType="submit" icon="search" />
              </FormItem>
            </Fragment>
          )}
        </SearchForm>
        {(selected !== 'atendido') &&
          <Alert
            message={this.handleMessage()}
            type={"info"}
            showIcon
            style={{ marginBottom: 20 }}
          />
        }
      </Fragment>
    );
  };

  render() {
    const { visible, title, selected } = this.state;

    const columns = [
      {
        title: "Cód. Cliente",
        key: "cliente",
        sorter: true,
        filteredValue: this.ds && this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
        render: (_, item) => (
          <div>
            {item.cliente}-{item.loja}
          </div>
        )
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        filteredValue: this.ds && this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Mês",
        key: "mes",
        dataIndex: "mes",
      },
      {
        title: "Ano",
        key: "ano",
        dataIndex: "ano",
      },
    ]

    return (
      <Modal
        width={1000}
        title={title}
        visible={visible}
        onCancel={this.close}
        footer={[
          <Button key="back" onClick={this.close}>
            Voltar
          </Button>
        ]}
      >
        <Spin spinning={this.state.isLoading} indicator={antIcon}>
          <FullTable
            rowKey={(item, i) => `${item.cliente}_${item.loja}:${i}`}
            beforeComponent={visible && this.renderSearchForm}
            columns={columns}
            dataSource={this.ds}
            expandedRowRender={this.state.selected !== 'sem_compra' && this.expandedRowRender}
            expandedRowKeys={this.state.expandedRows}
            onExpandedRowsChange={(e) => this.setState({ expandedRows: e })}
            actionBar={<Toolbar cleanable={false} />}
            bordered={false}
            rowClassName=""
          />
        </Spin>
      </Modal>
    );
  }
}

export default ModalCliente;
