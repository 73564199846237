/**
 * @flow
 */

import { action, observable, computed, toJS } from "mobx";
import { regraDescontoEscala } from "@util";

export default class Policy {
  @observable.shallow data: Object = null;
  @observable.shallow desconto_extra: Array = null;
  @observable.shallow desconto_geren: Array = null;

  @observable
  values: Object = {
    desc_tinta_auto: 0.0,
    desc_tinta_imob: 0.0,
    desc_solve_auto: 0.0,
    desc_solve_imob: 0.0,
    desc_logis_auto: 0.0,
    desc_logis_imob: 0.0,
    desc_extra_auto: 0.0,
    desc_extra_imob: 0.0,
    desc_rebat_auto: 0.0,
    desc_rebat_imob: 0.0,
    desc_retir_auto: 0.0,
    desc_retir_imob: 0.0,
    desc_geren_auto: 0.0,
    desc_geren_imob: 0.0,
    desc_finan_auto: 0.0,
    desc_finan_imob: 0.0,
    desc_mix_auto: 0.0,
    desc_mix_imob: 0.0,
  };

  constructor(policy, desconto_politica = null, cliente_desconto_extra = [] , cliente_desconto_geren = []) {
    this.update(policy, desconto_politica, cliente_desconto_extra, cliente_desconto_geren);
  }

  @action
  update(policy, desconto_politica = null, cliente_desconto_extra = [] , cliente_desconto_geren = []) {
    this.data = policy;
    this.desconto_extra = cliente_desconto_extra;
    this.desconto_geren = cliente_desconto_geren;

    this.togglePolitica(desconto_politica);
  }

  @action
  togglePolitica(desconto_politica = null) {
    if (!!desconto_politica && typeof desconto_politica === "string") {
      // descontos sem checkbox...
      const descBase = [
        "desc_tinta_auto",
        "desc_tinta_imob",
        "desc_solve_auto",
        "desc_solve_imob",
        "desc_geren_auto",
        "desc_geren_imob",
      ];

      descBase.forEach(this.toggleOn.bind(this));

      // verifica quais descontos com checkbox haviam sido checados...
      const p = desconto_politica.split("").reduce((acc, x, i) => {
        acc[i] = x;
        return acc;
      }, "xxxxxxxxxx".split(""));

      const descAcml = [
        "desc_retir_auto",
        "desc_retir_imob",
        "desc_finan_auto",
        "desc_finan_imob",
        "desc_logis_auto",
        "desc_logis_imob",
        "desc_mix_auto",
        "desc_mix_imob",
        "desc_extra_auto",
        "desc_extra_imob",
      ];
      descAcml.forEach((desc, i) => p[i] === "1" && this.toggleOn(desc));
    } else {
      // check all > 0 values...
      Object.keys(this.values).forEach(f => {
        if (f.indexOf("desc_extra_") !== -1) {
          if (this.desconto_extra.length > 0) this.toggleOn(f);
          else this.toggleOff(f);
        } else if (f.indexOf("desc_mix_") !== -1) {
          const s = this.data.desconto_mix_selecionado || "S";
          if (s === "S") this.toggleOn(f);
        } else {
          this.toggleOn(f);
        }
      });
    }
  }

  @action
  toggleOn(f) {
    if (f.indexOf("desc_logis_") !== -1) {
      this.values[f] = +this[`final_${f}`] > 0 ? +this[`final_${f}`] : 0.0;
    } else if (f.indexOf("desc_extra_") !== -1) {
      this.values[f] = 1; // apenas para checar a caixinha...
    } else {
      this.values[f] = f in this.data && +this.data[f] > 0 ? +this.data[f] : 0.0;
    }
  }

  @action
  toggleOff(field) {
    this.values[field] = 0.0;
  }

  @action
  toggle(field) {
    const exists =
      field.indexOf("desc_extra_") !== -1
        ? this.hasDescExtra
        : field.indexOf("desc_logis_") === -1
        ? field in this.data && +this.data[field] > 0
        : +this[`final_${field}`] > 0;
    if (exists && field in this.values && +this.values[field] <= 0) {
      this.toggleOn(field);
    } else {
      this.toggleOff(field);
    }
  }

  field(field, label = null) {
    if (!label) {
      label = field.indexOf("desc_logis_") === -1 ? +this.data[field] : +this[`final_${field}`];
    }

    return { field, label, value: +this.values[field] };
  }

  @computed
  get anppol() {
    const pol = [];

    pol.push(+this.values.desc_retir_auto > 0 ? "1" : "x");
    pol.push(+this.values.desc_retir_imob > 0 ? "1" : "x");
    pol.push(+this.values.desc_finan_auto > 0 ? "1" : "x");
    pol.push(+this.values.desc_finan_imob > 0 ? "1" : "x");
    pol.push(+this.values.desc_logis_auto > 0 ? "1" : "x");
    pol.push(+this.values.desc_logis_imob > 0 ? "1" : "x");
    pol.push(+this.values.desc_mix_auto > 0 ? "1" : "x");
    pol.push(+this.values.desc_mix_imob > 0 ? "1" : "x");
    pol.push(+this.values.desc_extra_auto > 0 ? "1" : "x");
    pol.push(+this.values.desc_extra_imob > 0 ? "1" : "x");

    return pol.join("");
  }

  @computed
  get hasDescLogisAuto() {
    return (
      !!this.data.desc_logis_regra_escal_auto &&
      Array.isArray(this.data.desc_logis_regra_escal_auto) &&
      this.data.desc_logis_regra_escal_auto.length > 0
    );
  }

  @computed
  get hasDescLogisImob() {
    return (
      !!this.data.desc_logis_regra_escal_imob &&
      Array.isArray(this.data.desc_logis_regra_escal_imob) &&
      this.data.desc_logis_regra_escal_imob.length > 0
    );
  }

  @computed
  get hasDescLogisEscalonado() {
    return this.hasDescLogisAuto || this.hasDescLogisImob;
  }

  @computed
  get hasDescExtra() {
    return this.desconto_extra.length > 0;
  }

  @computed
  get hasDescGeren() {
    return this.data.desc_geren_auto + this.data.desc_geren_imob > 0;
  }

  @computed
  get hasDescCliRetira() {
    return (this.desc_retir_auto + this.desc_retir_imob) > 0;
  }

  @computed
  get hasDescMix() {
    return (this.data.desc_mix_auto + this.data.desc_mix_imob) > 0;
  }

  @computed
  get hasDescFinanceiro() {
    return (this.desc_finan_auto + this.desc_finan_imob) > 0;
  }

  @computed
  get final_desc_logis_auto() {
    return regraDescontoEscala(this.data.desc_logis_regra_escal_auto, this.data.desc_logis_auto);
  }

  @computed
  get final_desc_logis_imob() {
    return regraDescontoEscala(this.data.desc_logis_regra_escal_imob, this.data.desc_logis_imob);
  }

  @computed
  get asJSON() {
    const data = toJS(this.data);
    const desconto_extra = toJS(this.desconto_extra);
    const values = toJS(this.values);
    const desconto_geren = toJS(this.desconto_geren);

    return { data, desconto_extra, values, anppol: this.anppol , desconto_geren };
  }
}
