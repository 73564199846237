export const ENV = process.env.NODE_ENV;
export const APP_ENV = window.$data["APP_ENV"] || "development";

export const isDev = ENV !== "production" && APP_ENV === "development";
export const isStaging = ENV === "production" && APP_ENV === "staging";
export const isProd = ENV === "production" && APP_ENV === "production";
export const debug = APP_ENV !== "production";

export const DEFAULT_URL = window.$data["DEFAULT_URL"];
export const API_DEFAULT_URL = window.$data["API_DEFAULT_URL"];
export const APP_VERSION = require("../package.json").version;

export const JSON_HEADERS = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const REGION_STATES = {
  AC: "Acre",
  AL: "Alagoas",
  AM: "Amazonas",
  AP: "Amapá",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RO: "Rondônia",
  RS: "Rio Grande do Sul",
  RR: "Roraima",
  SC: "Santa Catarina",
  SE: "Sergipe",
  SP: "São Paulo",
  TO: "Tocantins",
};

let _authToken = null;
export const setAuthToken = (token = null) => {
  _authToken = token;
};

export const getJsonHeaders = (custom = {}) => {
  const authHeader = !!_authToken
    ? {
        Authorization: `Bearer ${_authToken}`,
      }
    : {};

  return {
    ...JSON_HEADERS,
    ...custom,
    ...authHeader,
  };
};

export const fetchOptions = (options, customHeaders) => {
  return {
    headers: getJsonHeaders(customHeaders || {}),
    responseType: "json",
    ...options,
  };
};

export const fetchPostOptions = (data = {}, options, customHeaders) => {
  return {
    headers: getJsonHeaders({
      "Content-Type": "application/json",
      ...customHeaders,
    }),
    responseType: "json",
    method: "POST",
    data: typeof data === "string" ? JSON.stringify(data) : data,
    ...options,
  };
};

export const getUrl = (url, params = {}) => {
  return (
    DEFAULT_URL +
    url +
    (url.indexOf("?") === -1 ? "?" : "&") +
    Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join("&")
  );
};

export const getApiUrl = (url, params = {}) => {
  return (
    API_DEFAULT_URL +
    url +
    (url.indexOf("?") === -1 ? "?" : "&") +
    Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join("&")
  );
};
