import React, { Component } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";

import { dateFormat } from "@util";

import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.codigo_condicao}:${item.referencia}`;

const decorateRows = (ultimaCondicao, record, index) => {
  let className = index % 2 !== 0 ? `chooser-table--row chooser-table--row__odd` : `chooser-table--row`;
  if (!!ultimaCondicao.codigo_condicao && ultimaCondicao.codigo_condicao === record.codigo_condicao) {
    className += " chooser-table--row__success";
  }

  return className;
};

const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataTag = styled.div`
  display: inline-block;
  background-color: #c00;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

@observer
export default class ChooserCondicao extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/condicoes/1/0/000000-00");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        this.defineUri();
      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  defineUri = (formaPagto) => {
    const { prazosimu_acima_politica = false, pmp, data } = this.props.pedido;
    const codigo_cliente = data.codigo_cliente;
    const loja_cliente = !!data.loja_cliente ? data.loja_cliente : "01";
    const status_pedido = data.status_pedido === "S" && !prazosimu_acima_politica ? "2" : data.status_pedido;

    if (this.props.pedido.isEdit && !!this.props.pedido.codigo_solprazo) {
      this.ds.uri = `/pedidos/condicoes/${status_pedido}/${pmp}/0/${[codigo_cliente, loja_cliente].join("-")}/${formaPagto}/${this.props.pedido.codigo_solprazo}`;
    } else {
      this.ds.uri = `/pedidos/condicoes/${status_pedido}/${pmp}/0/${[codigo_cliente, loja_cliente].join("-")}/${formaPagto}`;
    }
  }
  open = (onChoose = null, formaPagto) => {
    this.defineUri(formaPagto)
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const { pedido } = this.props;
    const ultimaCondicao = {
      codigo_condicao: pedido.cliente_cod_ultima_cond_pgto || null,
      data: pedido.cliente_dt_ultima_cond_pgto || null,
    };

    const columns = [
      {
        title: "Código",
        key: "codigo_condicao",
        width: "13%",
      },
      {
        title: "Condição",
        key: "referencia",
        width: "17%",
      },
      {
        title: "Descrição",
        key: "descricao_condicao",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {!!ultimaCondicao.codigo_condicao &&
              ultimaCondicao.codigo_condicao === item.codigo_condicao &&
              !!ultimaCondicao.data && <DataTag>{dateFormat(ultimaCondicao.data)}</DataTag>}
            {item.codigo_solicitacao && <DataTag>Condição Especial</DataTag>}
          </DescricaoCell>
        ),
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title={
          pedido.data.status_pedido !== "S"
            ? "Política de Prazo para " + (pedido.pmp > 1 ? pedido.pmp + " dias" : "1 dia")
            : "Política de Prazo"
        }
        errorMessage="Não foi possível obter as condições de pagto no momento"
        searchPlaceholder="Busque pelo código ou descrição"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        rowClassName={decorateRows.bind(null, ultimaCondicao)}
        {...this.props}
      />
    );
  }
}
