import React, { Component } from "react";
import { observer } from "mobx-react";

import FormProduto from "components/pedidos/FormProduto";

@observer
export default class FormProdutoHeader extends Component {
  componentDidMount() {
    requestAnimationFrame(() => this.requestFocus());
  }
  componentWillUnmount() {
    if (!!this._requestFocus) clearTimeout(this._requestFocus);
  }
  requestFocus = () => {
    if (this._forms.length === 1) {
      this._requestFocus = setTimeout(() => {
        try {
          this._forms[0]._inputs.codigo_produto.focus();
        } catch (ex) {}
      }, 1000 / 60);
    }
  };
  render() {
    const { chooser, pedido, onPedidoError, onFetch, onAdd, isLoading = false, isB2B = false } = this.props;

    return (
      <div className="form-produto--header">
        {pedido.pendingItems.map((item, k) => (
          <FormProduto
            ref={_ref => (this._forms[k] = _ref)}
            key={`pd-item-${k}`}
            isB2B={isB2B}
            item={item}
            pedido={pedido}
            chooser={chooser}
            isLoading={isLoading}
            requestFocus={this.requestFocus}
            onFetch={onFetch}
            onAdd={onAdd}
            onPedidoError={onPedidoError}
          />
        ))}
      </div>
    );
  }
  _forms = [];
}
