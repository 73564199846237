import React, { Component } from "react";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import styled from "styled-components";

import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.Produto.codigo}:${item.Produto.classe_produto}`;

const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataTag = styled.div`
  display: inline-block;
  background-color: #c00;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

@observer
export default class ChooserProduto extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/solicitacao_desconto/produtos/000000-00");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        const { cliente } = this.props;
        this.ds.uri = "/solicitacao_desconto/produtos/" + cliente;
      },
      { delay: 60 }
    );
  }

  onSelectRow = (produto, selectedRowKeys) => {
    const { onChoose } = this.state;
    if (!!produto && !!onChoose && typeof onChoose === "function") {
      onChoose(produto.Produto || produto, selectedRowKeys);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Linha",
        key: "linha_produto",
        dataIndex: "Produto.linha_produto",
        width: "14.5%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "Produto.codigo",
        width: "13%",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "Produto.descricao",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {item.Produto.permite_gerencial === 'NAO' && <DataTag>Solicitação bloqueada</DataTag>}
          </DescricaoCell>
        ),    
      },
      {
        title: "Tipo",
        key: "tipo_produto",
        dataIndex: "Produto.tipo_produto",
        width: "8%",
      },
      {
        title: "Permite Gerencial",
        key: "Produto.permite_gerencial",
        width: "18%",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Encontre um Produto"
        errorMessage="Não foi possível obter seus produtos no momento"
        searchPlaceholder="Busque pelo código ou descrição"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        width={860}
        disabledRowKeys={(item) => {
          if(item.Produto.permite_gerencial === 'NAO'){
            return true;
          }
        }}
        {...this.props}
      />
    );
  }
}
