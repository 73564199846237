import React, { Component, Fragment } from "react";
import { Table, Button, Icon } from "antd";
import { observer } from "mobx-react";
import classnames from "classnames";

import { n } from "@util";
import { WrappedValue, mapColumn } from "components/FullTable";

const pedidosRowKey = item => `${item.Pedido.codigo_kit}:${item.Pedido.numero_pedido}:${item.Pedido.codigo_filial}`;

const decorateRows = (item, index) =>
  classnames("base-table--row carga-table--row", {
    "base-table--row__odd carga-table--row__odd": index % 2 !== 0,
  });

@observer
export default class CargaPedidoTable extends Component {
  render() {
    const {
      items = [],
      rowKey = pedidosRowKey,
      className,
      wrapperClassName,
      rowClassName = decorateRows,
      loading = false,
      locale = {},
      bordered = true,
      size = "middle",
      onAddPedido,
      ...tableProps
    } = this.props;

    let columns = [
      {
        title: "Filial",
        key: "codigo_filial",
        dataIndex: "Pedido.codigo_filial",
        width: "4.5%",
      },
      {
        title: "Pedido",
        key: "numero_pedido",
        dataIndex: "Pedido.numero_pedido",
        width: "7%",
      },
      {
        title: "Código",
        key: "codigo_cliente",
        dataIndex: "Pedido.codigo_cliente",
        render: (_, pedido) => (
          <WrappedValue value={`${pedido.Pedido.codigo_cliente} / ${pedido.Pedido.loja_cliente}`} type="text" />
        ),
        width: "9.5%",
      },
      {
        title: "Cliente",
        key: "razao_social",
        className: "text-left",
        dataIndex: "Cliente.razao_social",
        render: (text, pedido) => (
          <WrappedValue
            value={
              <Fragment>
                {!!pedido.Pedido.shared && (
                  <Icon
                    type="share-alt"
                    title={`Pedido compartilhado por ${
                      !!pedido.Pedido.shared_from && !!pedido.Pedido.shared_from.razao_social
                        ? pedido.Pedido.shared_from.razao_social
                        : "outro usuário"
                    }`}
                    style={{ marginRight: 5, color: "#C00" }}
                  />
                )}
                {text}
              </Fragment>
            }
            type="text"
          />
        ),
      },
      {
        title: "Cidade",
        key: "cidade",
        dataIndex: "Cliente.cidade",
        width: "10%",
      },
      {
        title: "UF",
        key: "uf",
        dataIndex: "Cliente.uf",
        width: "4%",
      },
      {
        title: (
          <Fragment>
            Peso <em title="em Kilos">(KG)</em>
          </Fragment>
        ),
        key: "pesopv",
        dataIndex: "Pedido.pesopv",
        render: v => <WrappedValue value={n(+v)} type="text" />,
        width: "9.75%",
      },
      {
        title: "Total NF",
        key: "total_nota",
        dataIndex: "Pedido.total_nota",
        render: v => <WrappedValue value={n(+v)} type="text" />,
        width: "10.25%",
      },
      {
        title: "Transp.",
        key: "tipo_caminhao",
        dataIndex: "Pedido.tipo_caminhao",
        width: "9.5%",
      },
      {
        title: "Mod.Frete",
        key: "modalidade_frete",
        dataIndex: "Pedido.modalidade_frete",
        width: "9.5%",
      },
    ];

    if (!!onAddPedido) {
      columns = [
        ...columns,
        {
          title: "Ações",
          key: "actions",
          render: (_, pedido) => (
            <div className="actions">
              <Button
                icon="plus"
                size="small"
                type="primary"
                onClick={() => onAddPedido(pedido)}
                title="Adicionar este pedido a carga">
                Adicionar
              </Button>
            </div>
          ),
          width: "110px",
        },
      ];
    }

    return (
      <div className={classnames(`base-table-wrapper carga-table-wrapper`, wrapperClassName)}>
        <Table
          className={classnames(
            `base-table base-table__compact carga-table carga-table__compact`,
            { "base-table__loading carga-table__loading": !!loading },
            className
          )}
          pagination={false}
          rowKey={rowKey}
          loading={!!loading}
          bordered={bordered}
          columns={columns.map(mapColumn)}
          rowClassName={rowClassName}
          dataSource={items}
          locale={{
            filterTitle: "Filtrar",
            filterConfirm: "OK",
            filterReset: "Limpar",
            emptyText: "Sem itens disponíveis",
            ...locale,
          }}
          size={size}
          {...tableProps}
        />
      </div>
    );
  }
}
