import React, { Component, Fragment } from "react";
import { Modal, Button, Spin, Icon, Table, Row, Col, DatePicker, Form, Radio, Alert } from "antd";
import FullTable, { SearchForm, TableDataSource, TextFilter, Toolbar, WrappedValue } from "components/FullTable";
import moment from "moment";
import { fetchApi } from "actions";
import ExpandedRowMixCliente from "./ExpandedRowMixCliente";
import Select, { Option } from "components/Select";
import ExpandedRowMixClasse from "./ExpandedRowMixClasse";

const antIcon = <Icon type="loading" style={{ fontSize: 42 }} spin />;
const FormItem = Form.Item;

class ModalMix extends Component {
  constructor(props) {
    super(props);

    this.ds = null;

    this.state = {
      isLoading: false,
      visible: false,
      title: "Mix",
      month: moment(),
      monthMoment: moment(),
      expandedRows: [],
      expandedLoad: false,
      group: 'classe'
    };
  }

  open = (month) => {
    this.ds = new TableDataSource({
      name: "Retenção Cliente",
      uri: this.state.group === 'classe' ? "/pedidos/classes_mix" : '/pedidos/clientes_mix',
    });

    const year = moment().year();

    this.setState({ visible: true, month: moment({ year, month, day: 1 }).format('YYYY-MM-DD'), monthMoment: moment({ year, month, day: 1 }) }, () => this.ds.fetch());
  }

  close = () => {
    this.setState({ visible: false }, () => {
      this.ds = null;
    });
  }

  changeView = (value, callback) => {
    if (value === 'classe') {
      this.ds = new TableDataSource({
        name: "Retenção Cliente",
        uri: "/pedidos/classes_mix",
      });
    } else {
      this.ds = new TableDataSource({
        name: "Retenção Cliente",
        uri: "/pedidos/clientes_mix",
      });
    }

    this.setState({ group: value }, () => callback());
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const { MonthPicker } = DatePicker;
    const isLoading = this.ds.isLoading;

    const filterFields = {
      tipo: "options",
      mes: "date",
    };
    const options = [
      {
        key: "ativo",
        label: "Ativos",
      },
      {
        key: "inativo",
        label: "Inativos",
      },
      {
        key: "sem_compra",
        label: "Sem compras",
      },
      {
        key: "atendido",
        label: "Atendido no mês",
      },
    ]

    return (
      <Fragment>
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          width="300"
          onUpdateDataSource={(a, b, c) => { onUpdateDataSource(a, b, c); this.setState({ expandedRows: [] }) }}
          filterFields={filterFields}
          defaultFilters={{ tipo: 'atendido', mes: this.state.month }}>
          {({ form, submitForm }) => (
            <Fragment>
              <FormItem>
                {form.getFieldDecorator("mes")(
                  <MonthPicker
                    size="large"
                    format="MMMM - YYYY"
                    allowClear={false}
                    onChange={m => this.setState({ monthMoment: m }, () => setTimeout(submitForm, 30))}
                    placeholder="Selecione o mês" />
                )}
              </FormItem>
              <FormItem>
                <Select size="large"
                  style={{ width: '170px' }}
                  placeholder="Visualização por"
                  onChange={group => this.changeView(group, () => setTimeout(submitForm, 30))}
                  value={this.state.group}>
                  <Option value="classe">Classe x Cliente</Option>
                  <Option value="cliente">Cliente x Classe</Option>
                </Select>
              </FormItem>
              <FormItem>
                <Button size="large" type="danger" htmlType="submit" icon="search" />
              </FormItem>
              <div style={{ marginLeft: 40, float: 'right' }}>
                <Button
                  loading={isLoading}
                  title="Download"
                  type="danger"
                  icon="cloud-download-o"
                  onClick={event => {
                    event.preventDefault();
                    window.open(
                      `https://intranet.anjo.com.br/docs/mix.php?m=${(btoa(this.props.codigo))}${`&d=${btoa(this.state.month)}&t=${btoa(this.props.isOperador ? 'oper' : 'vend')}`}`,
                      "_blank"
                    );
                  }}>
                  Download detalhamento mix
                </Button>
              </div>
            </Fragment>
          )}
        </SearchForm>
      </Fragment>
    );
  };

  render() {
    const { visible, title } = this.state;
    //const isLoading = this.ds.isLoading;

    const columns = [
      { title: 'Cód. Classe', dataIndex: 'classe', key: 'classe', width: '11%' },
      { title: 'Descrição', dataIndex: 'desc_classe', key: 'desc_classe', className: '' },
      {
        title: 'Volume',
        dataIndex: 'volume',
        key: 'volume',
        align: 'right',
        width: '15%',
        render: (v) => (
          <WrappedValue value={v} type="decimal" />
        )
      },
      {
        title: 'Última compra',
        dataIndex: 'ult_venda',
        key: 'ult_venda',
        width: '12%',
        render: (uc) => (
          <div>{moment(uc, 'YYYYMMDD').format('DD/MM/YYYY')}</div>
        )
      },
    ];

    const columns_cliente = [
      { title: 'Cód. Cliente', dataIndex: 'codigo', key: 'codigo', width: '11%' },
      { title: 'Nome Cliente', dataIndex: 'nome_cliente', key: 'nome_cliente', className: '' },
      {
        title: 'Volume',
        dataIndex: 'volume',
        key: 'volume',
        align: 'right',
        width: '15%',
        render: (v) => (
          <WrappedValue value={v} type="decimal" />
        )
      },
      {
        title: 'Última compra',
        dataIndex: 'ult_compra',
        key: 'ult_compra',
        width: '15%',
        render: (uc) => (
          <div>{moment(uc, 'YYYYMMDD').format('DD/MM/YYYY')}</div>
        )
      },
      {
        title: 'Ações',
        key: 'actions',
        width: '13%',
        render: (text, item) => (
          <div className="actions">
            <Button
              title="Download"
              type="danger"
              icon="cloud-download-o"
              onClick={event => {
                event.preventDefault();
                window.open(
                  `https://intranet.anjo.com.br/docs/mix.php?m=${(btoa(this.props.codigo))}${`&d=${btoa(this.state.month)}&t=${btoa(this.props.isOperador ? 'oper' : 'vend')}&c=${btoa(item.codigo)}`}`,
                  "_blank"
                );
              }}>
              Excel
            </Button>
          </div>
        ),
      },
    ];

    return (
      <Modal
        width={1000}
        title={title}
        visible={visible}
        onCancel={this.close}
        footer={[
          <Button key="back" onClick={this.close}>
            Voltar
          </Button>
        ]}
      >
        <Spin spinning={this.state.isLoading} indicator={antIcon}>
          <FullTable
            rowKey={(item, i) => `${item.cliente}_${item.loja}:${i}`}
            beforeComponent={visible && this.renderSearchForm}
            columns={this.state.group === 'classe' ? columns : columns_cliente}
            dataSource={this.ds}
            expandedRowRender={(item, _, __, expanded) => expanded && (this.state.group === 'classe' ? <ExpandedRowMixCliente item={item} expanded={expanded} mes={this.state.monthMoment} /> : <ExpandedRowMixClasse item={item} expanded={expanded} mes={this.state.monthMoment} />)}
            // expandedRowKeys={this.state.expandedRows}
            // onExpandedRowsChange={(e) => this.setState({ expandedRows: e })}
            actionBar={<Toolbar cleanable={false} />}
            bordered={false}
            rowClassName=""
          />
        </Spin>
      </Modal>
    );
  }
}

export default ModalMix;
