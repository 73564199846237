import React, { Component, PureComponent } from "react";
import { observer } from "mobx-react";
import { Input, Form, Row, Col, Button, Tag, Icon, Upload, message, Modal } from "antd";
import withStore from "withStore";
import { fetchPostApi } from "actions";
import moment from "moment";

import { maskedInputValue } from "@util";

const FormItem = Form.Item;

class FileInput extends PureComponent {
    render() {
        const { isLoading = false, disabled = false, ...props } = this.props;

        return (
            <Upload beforeUpload={file => false} accept='.jpg, .jpeg, .png, .xls, .pdf, .docx, .xlsx' multiple={false} action={null} {...props}>
                <Button disabled={disabled} type="primary">
                    <Icon type="upload" />
                </Button>
            </Upload>
        );
    }
}

@observer
class FormLancamentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdate: false,
            isLoading: false,
            lancamento: props.lancamento ?? null,
            form: {
                zyb_qtdpar: props.lancamento?.zyb_qtdpar ?? 0,
                zyb_vlalim: props.lancamento ? props.lancamento?.zyb_vlalim : 0,
                zyb_vlbebi: props.lancamento ? props.lancamento?.zyb_vlbebi : 0,
                zyb_vldesp: props.lancamento ? props.lancamento?.zyb_vldesp : 0,
                zyb_totald: props.lancamento ? props.lancamento?.zyb_totald : 0,
                zyb_vlbonp: props.lancamento ? props.lancamento?.zyb_vlbonp : 0,
                zyb_totbon: props.lancamento ? props.lancamento?.zyb_totbon : 0,
                anexo: null
            },
            dataAtividade: moment(this.props?.atividade?.zy8_dtvisi).format('DD/MM/YYYY')
        };
    }

    handleUpdate = async () => {
        try {
            this.setState({ isLoading: true, isUpdate: true });
            const { form } = this.state;
            const formData = new FormData();


            if (this.props.atividade) {
                formData.append('atividadeRecno', this.props.atividade.r_e_c_n_o_);
                formData.append('codSolicitacao', this.props.atividade.zy8_codsol);
            }

            if (this.props.lancamento) {
                formData.append('lancamentoRecno', this.props.lancamento.r_e_c_n_o_);
            }

            formData.append('zyb_qtdpar', form.zyb_qtdpar);
            formData.append('zyb_totald', form.zyb_totald);
            formData.append('zyb_totbon', form.zyb_totbon);
            formData.append('zyb_vlalim', form.zyb_vlalim);
            formData.append('zyb_vlbebi', form.zyb_vlbebi);
            formData.append('zyb_vlbonp', form.zyb_vlbonp);
            formData.append('zyb_vldesp', form.zyb_vldesp);

            await fetchPostApi(`/promotoria/salvar-lancamentos-palestra`, formData, true);

            Modal.success({
                title: 'Lançamentos',
                content: 'Informações enviadas com sucesso!'
            });

            if (this.props.atividade) {
                this.props.promotoria.removeAtividadePalestraAposLancamento(this.props.atividade.r_e_c_n_o_);
            }

            if (this.props.lancamento) {
                this.props.promotoria.removeAtividadePalestraAposLancamento(this.props.lancamento.r_e_c_n_o_);
            }

        } catch (error) {
            console.log(error)
            message.error('Erro ao enviar os lançamentos');
            this.setState({ isUpdate: false });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9]+$/;

        if (!regex.test(keyValue)) {
            event.preventDefault();
        }
    };

    handleFormChange = changedFields => {
        const { form } = this.state;

        const valorAlimentacao = parseFloat(form.zyb_vlalim) || 0;
        const valorBebidas = parseFloat(form.zyb_vlbebi) || 0;
        const valorDespesasExtras = parseFloat(form.zyb_vldesp) || 0;

        const valorBonificacao = parseFloat(form.zyb_vlbonp) || 0;

        const quantidadeParticipantes = Number(form.zyb_qtdpar) || 0;

        const totalGasto = valorAlimentacao + valorBebidas + valorDespesasExtras;

        const totalGastoBonificacao = valorBonificacao * quantidadeParticipantes;

        this.setState({
            form: {
                ...form,
                zyb_totald: totalGasto,
                zyb_totbon: totalGastoBonificacao
            },
        });
        this.forceUpdate();
    };

    render() {
        const { isUpdate, isLoading, form, lancamento } = this.state;
        return (
            <Row gutter={4}>
                <Col span={2}>
                    <FormItem label="Data">
                        <Input disabled value={this.state.dataAtividade} />
                    </FormItem>
                </Col>
                <Col span={2}>
                    <FormItem label="Qtd parti.">
                        <Input
                            disabled={isUpdate || this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible}
                            value={form.zyb_qtdpar}
                            onBlur={this.handleFormChange}
                            onChange={(e) => this.setState((prevState) => ({
                                form: {
                                    ...prevState.form,
                                    zyb_qtdpar: Number(e.target.value)
                                }
                            }))}
                            onKeyPress={this.handleKeyPress}
                        />
                    </FormItem>
                </Col>
                <Col span={3}>
                    <FormItem label="Vlr alimen.">
                        <Input
                            disabled={isUpdate || this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible}
                            value={form.zyb_vlalim}
                            onBlur={this.handleFormChange}
                            onKeyPress={this.handleKeyPress}
                            onChange={(e) => this.setState((prevState) => ({
                                form: {
                                    ...prevState.form,
                                    zyb_vlalim: maskedInputValue(e.target.value)
                                }
                            }))}
                        />
                    </FormItem>
                </Col>
                <Col span={3}>
                    <FormItem label="Vlr bebidas">
                        <Input
                            disabled={isUpdate || this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible}
                            value={form.zyb_vlbebi}
                            onBlur={this.handleFormChange}
                            onKeyPress={this.handleKeyPress}
                            onChange={(e) => this.setState((prevState) => ({
                                form: {
                                    ...prevState.form,
                                    zyb_vlbebi: maskedInputValue(e.target.value)
                                }
                            }))}
                        />
                    </FormItem>
                </Col>
                <Col span={3}>
                    <FormItem label="Qtd desp">
                        <Input
                            disabled={isUpdate || this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledPromotorNotResponsible}
                            value={form.zyb_vldesp}
                            onBlur={this.handleFormChange}
                            onKeyPress={this.handleKeyPress}
                            onChange={(e) => this.setState((prevState) => ({
                                form: {
                                    ...prevState.form,
                                    zyb_vldesp: maskedInputValue(e.target.value)
                                }
                            }))}
                        />
                    </FormItem>
                </Col>
                <Col span={3}>
                    <FormItem label="Tol. gasto">
                        <Input disabled value={form.zyb_totald} onKeyPress={this.handleKeyPress} onChange={(e) => this.handleInputChange('zyb_totald', maskedInputValue(e.target.value))} />
                    </FormItem>
                </Col>
                {(this.props.store.isSupervisor || this.props.promotoria.isFinishPromotoria) && (
                    <Col span={3}>
                        <FormItem label="Vlr bonif. partici.">
                            <Input
                                disabled={this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento}
                                value={form.zyb_vlbonp}
                                onBlur={this.handleFormChange}
                                onKeyPress={this.handleKeyPress}
                                onChange={(e) => this.setState((prevState) => ({
                                    form: {
                                        ...prevState.form,
                                        zyb_vlbonp: maskedInputValue(e.target.value)
                                    }
                                }))}
                            />
                        </FormItem>
                    </Col>
                )}
                {(this.props.store.isSupervisor || this.props.promotoria.isFinishPromotoria) && (
                    <Col span={2}>
                        <FormItem label="Tol. em Bonif.">
                            <Input disabled value={form.zyb_totbon} onKeyPress={this.handleKeyPress} onChange={(e) => this.setState((prevState) => ({
                                form: {
                                    ...prevState.form,
                                    zyb_totbon: maskedInputValue(e.target.value)
                                }
                            }))} />
                        </FormItem>
                    </Col>
                )}
                <Col span={2}>
                    <FormItem label="Ação">
                        <Button type="primary" onClick={this.handleUpdate} loading={isLoading} disabled={this.props.promotoria.isFinishPromotoria || this.props.isDisableButton || this.props.promotoria.isDisabledAntesLancamento || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}>
                            Enviar
                        </Button>
                    </FormItem>
                </Col>
            </Row>
        );
    }
}

export default withStore(FormLancamentos);
