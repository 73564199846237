import { fetchApi } from "actions";


export const fetchNfMail = async (hash, ...args) => {
  const { data: response } = await fetchApi(`/relatorios/notasfiscais/email/${hash}`, ...args);
  return response;
};

export const fetchBolMail = async (hash, ...args) => {
  const { data: response } = await fetchApi(`/relatorios/boletos/email/${hash}`, ...args);
  return response;
};

export const fetchVoltaPedido = async (slug, ...args) => {
  const { data: response } = await fetchApi(`/relatorios/pedidos/volta/${slug}`, ...args);
  return response;
};

export const fetchFolderMail = async (hash, ...args) => {
  const { data: response } = await fetchApi(`/relatorios/folders/email/${hash}`, ...args);
  return response;
};

