import React, { PureComponent, Fragment } from "react";
import { Route } from "react-router-dom";

import MemorandoScreen from "screens/vendedor/relatorios/MemorandoScreen";

export default class Aprovador extends PureComponent {
  render() {
    return (
      <Fragment>
        <Route path="/memorando" component={MemorandoScreen} />
      </Fragment>
    );
  }
}