import React, { Component, Fragment } from "react";
import { Modal, Button } from "antd";
import { observer } from "mobx-react";

import { fetchApi } from "actions";
import { getData, filterDate } from "@util";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import CargaStatusTag from "components/pedidos/cargas/CargaStatusTag";
import CargaAprovDetails from "components/pedidos/cargas/CargaAprovDetails";

const rowKey = item => `${item.Carga.codigo_carga}:${item.Carga.codigo_filial}`;

@observer
export default class TabCargas extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/cargas/" + (props.tipo || "kit"));

    this.state = {};
  }
  componentDidMount() {
    const onAdd = () => this.props.history.push(`/pedidos/carga/add/${this.props.tipo || "kit"}`);
    const tipoLabel = !!this.props.tipo && this.props.tipo === "safra" ? `Nova Safra` : `Nova Carga`;
    const { store } = this.props;
    const habilitaPedido = store.habilitaPedido;

    this.props.appendTabBarExtraContent(
      <Fragment>
      {habilitaPedido && (  
        <Button type="primary" icon="plus" onClick={onAdd} style={{ display: "block", marginTop: -1 }}>
          { habilitaPedido ? tipoLabel : tipoLabel + ' desabilitada temporariamente !'}
        </Button>
      )}
      {!habilitaPedido && (  
        <Button type="danger" icon="close" style={{ display: "block", marginTop: -1 }}>
          { tipoLabel + ' desabilitada temporariamente !'}
        </Button>
      )}
      </Fragment>
    );
  }
  onCargaAprovDetails = aprovs => {
    if (!!this._cargaAprovDetails) {
      this._cargaAprovDetails.open(aprovs);
    }
  };
  viewProgramacao = carga => {
    this.props.history.push(`/pedidos/carga/view/${this.props.tipo || "kit"}/${carga.Carga.codigo_carga}`);
  };
  editProgramacao = carga => {
    if (carga.Carga.status_carga.toUpperCase() !== "ANALISE DE ROTA") {
      Modal.warning({
        title: "Carga bloqueada para edição",
        content: "Não é possível editar uma campanha já movimentada!",
      });
      return;
    }

    this.props.history.push(`/pedidos/carga/edit/${this.props.tipo || "kit"}/${carga.Carga.codigo_carga}`);
  };
  removeProgramacao = carga => {
    if (carga.Carga.status_carga.toUpperCase() !== "ANALISE DE ROTA") {
      Modal.warning({
        title: "Carga bloqueada para exclusão",
        content: "Não é possível excluir uma campanha já movimentada!",
      });
      return;
    }

    Modal.confirm({
      title: "Você confirma esta operação?",
      content: "Clicando no botão abaixo você irá excluir este pedido/orçamento.",
      cancelText: "Cancelar",
      okText: "Excluir Pedido",
      onOk: () => this.deleteCarga(carga.Carga.codigo_carga),
    });
  };
  async deleteCarga(codigo_carga) {
    const { store } = this.props;
    store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/pedidos/cargas/deletar/" + codigo_carga);
      if (!!response.success) {
        Modal.success({
          title: "Carga excluída com sucesso",
          content: "Sua campanha foi excluída do sistema",
        });

        store.ping();
        store.isLoading = false;

        this.ds.fetch();
      } else {
        throw new Error("Não foi possível excluir sua carga neste momento. Tente novamente mais tarde");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Apenas vendedores podem solicitar exclusão de pedidos",
        5: "Não foi possível excluir sua carga neste momento. Tente novamente mais tarde",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || err.message,
      });

      store.isLoading = false;
    }
  }
  render() {
    const { store } = this.props;
    const habilitaPedido = store.habilitaPedido;

    const columns = [
      {
        title: "Status",
        dataIndex: "Carga.status_carga",
        key: "status_carga",
        render: (text, item) => (
          <CargaStatusTag
            status={text}
            onClick={
              !!item.CargaAprov && item.CargaAprov.length > 0
                ? event => {
                    event.preventDefault();
                    this.onCargaAprovDetails(item.CargaAprov);
                  }
                : null
            }
          />
        ),
        width: "18%",
      },
      {
        title: "Filial",
        dataIndex: "Carga.codigo_filial",
        key: "codigo_filial",
        sorter: false,
        width: "9%",
        filteredValue: this.ds.filterValue("codigo_filial"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Filial" {...props} />,
      },
      {
        title: "Código",
        dataIndex: "Carga.codigo_carga",
        key: "codigo_carga",
        sorter: true,
        filteredValue: this.ds.filterValue("codigo_carga"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Carga" {...props} />,
        filterParser: filter => ({ ...filter, field: "Carga.codigo_carga", value: `%${filter.value}`, op: "LIKE" }),
      },
      {
        title: "Inclusão",
        dataIndex: "Carga.data_inclusao",
        key: "data_inclusao",
        sorter: true,
        render: text => <WrappedValue value={text} type="datetime" />,
        width: "15%",
        filteredValue: this.ds.filterValue("data_inclusao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Inclusão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({ ...filter, type: "str_date", value: filterDate(filter.value) }),
      },
      {
        title: "Ações",
        key: "actions",
        width: 246,
        render: (text, record) => (
          <div className="actions">
            <Button
              title="Visualizar esta programação de carga"
              onClick={() => this.viewProgramacao(record)}
              icon="search"
              size="small"
              type="primary">
              Ver
            </Button>
            <Button onClick={() => this.editProgramacao(record)} disabled={!habilitaPedido} icon="edit" size="small" type="primary" ghost={true}>
              Editar
            </Button>
            <Button onClick={() => this.removeProgramacao(record)} disabled={!habilitaPedido} icon="delete"  size="small" type="danger">
              Excluir
            </Button>
          </div>
        ),
      },
    ];

    return (
      <Fragment>
        <FullTable
          className="full-table__compact"
          actionBar={"none"}
          rowKey={rowKey}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas cargas no momento"
        />
        <CargaAprovDetails
          ref={_ref => (this._cargaAprovDetails = _ref)}
          isLoading={this.props.store.isLoading}
          title={`Aprovações da ${!!this.props.tipo && this.props.tipo === "safra" ? "Safra" : "Kit"}`}
        />
      </Fragment>
    );
  }
  _cargaAprovDetails;
}
