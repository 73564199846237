import React from "react";
import { Select } from "antd";

import { flatObjectToOptions } from "@util";

export const Option = Select.Option;
export const OptGroup = Select.OptGroup;

function CustomSelect({ value = undefined, options = [], children = null, ...props }, ref) {
  if (!Array.isArray(options)) {
    options = flatObjectToOptions(options);
  }

  return (
    <Select ref={ref} value={value === null || value === undefined ? undefined : value} {...props}>
      {!!children
        ? React.Children.map(children, child => React.cloneElement(child))
        : (Array.isArray(options) ? options : []).map((option, k) => {
            let optionProps = {};
            if (typeof option === "string") {
              optionProps.key = option;
              optionProps.value = option;
              optionProps.children = option;
            } else {
              optionProps.key = option.key || option.value || k;
              optionProps.value = "value" in option ? option.value : option.label || "";
              optionProps.children = option.label || option.value || "";
            }

            return <Option {...optionProps} />;
          })}
    </Select>
  );
}

export default React.forwardRef(CustomSelect);
