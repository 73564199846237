import React, { Component } from "react";
import { autorun } from "mobx";
import { Button } from "antd";
import { observer } from "mobx-react";
import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";
import styled from "styled-components";

const rowKey = itemkey => `${itemkey.Cab.numero_pedido}:${itemkey.Produto.codigo_produto}:${itemkey.Item.item_produto}`;

const DataTag = styled.div`
  display: inline-block;
  background-color: #c00;
  color: #fff;
  font-weight: 600;
  font-size: 9px;
  line-height: 9px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

@observer
export default class ChooserResiduo extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/residuo/000000-00-00/1/C/REVENDA");
    this.residuo = this.ds; //.map(itemData => new Item(itemData, this.props.pedido.policy.values));
    this.carrinhoResiduo = [];

    this.state = {
      visible: false,
      onChoose: null,
      qtdItens: 0,
    };
  }
  componentDidMount() {

    this._watchDS = autorun(
      () => {
        const { data, tipoFreteTabela } = this.props.pedido;
        const codigo_cliente = `${data.codigo_cliente}`.padStart(6, "0");
        const loja_cliente = !!data.loja_cliente ? `${data.loja_cliente}`.padStart(2, "0") : "01";
        if (!!data.codigo_cliente) {
          const filial_faturamento = !!data.filial_faturamento ? `${data.filial_faturamento}`.padStart(2, "0") : "01";
          const tipo_transp = tipoFreteTabela;
          const status_pedido = data.status_pedido;
          const codigo = [codigo_cliente, loja_cliente, filial_faturamento].join("-");
          this.ds.uri = "/pedidos/residuo/" + codigo + "/" + status_pedido + "/" + tipo_transp + "/" + data.unidade_negocio;
        }
      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {

    let achou = false;

    for (let i = 0; i < this.carrinhoResiduo.length; i++) {
      if (this.carrinhoResiduo[i].key === args[0].key) {
        this.carrinhoResiduo.splice(i, 1);
        this.setState({ qtdItens: this.carrinhoResiduo.length });
        achou = true;
      }
    }

    if (!achou) {
      this.carrinhoResiduo.push(args[0]);
      this.carrinhoResiduo[this.carrinhoResiduo.length - 1].Produto.rastro_residuo_item = this.carrinhoResiduo[this.carrinhoResiduo.length - 1].key;
      this.setState({ qtdItens: this.carrinhoResiduo.length })
    }

  };

  onUpdate = () => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(this.carrinhoResiduo);
    }
    this.close();
  };

  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false, qtdItens: 0 });
    this.carrinhoResiduo = [];

  };
  render() {
    const { qtdItens } = this.state;
    const columns = [
      {
        title: "Tipo",
        key: "tipo_pedido",
        dataIndex: "Cab.tipo_pedido",
        width: "11%",
      },
      {
        title: "Emissao",
        key: "pedido_emissao",
        dataIndex: "Cab.data_emissao",
        width: "11%",
      },
      {
        title: "Faturamento",
        key: "data_faturamento",
        dataIndex: "Item.data_faturamento",
        width: "11%",
      },
      {
        title: "Pedido",
        key: "numero_pedido",
        dataIndex: "Cab.numero_pedido",
        width: "11%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "Produto.codigo_produto",
        width: "12%",
      },
      {
        title: "Item",
        key: "item_produto",
        dataIndex: "Item.item_produto",
        width: "6%",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "Produto.descricao",
        render: (text, item) => (
          <>
            <WrappedValue value={text} />
            {item.Produto.status === 'INATIVO' && <DataTag>Produto Bloqueado</DataTag>}
          </>
        )
      },
      {
        title: "Saldo",
        key: "quantidade",
        dataIndex: "Produto.quantidade_vendida",
        width: "7%",
      },
      {
        title: "Preço",
        key: "preco_vendido",
        dataType: "money",
        dataIndex: "Produto.preco_vendido",
        width: "12%",
      },
    ];
    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.residuo}
        title="Encontre um item de Residuo"
        errorMessage="Não foi possível obter os itens de residuo no momento"
        searchPlaceholder="Busque pelo código ou descrição do produto"
        onSelectRow={this.onSelectRow}
        allowMultipleSelection={true}
        onCancel={this.close}
        width={860}
        disabledRowKeys={(item) => {
          if (item.Produto.status === 'INATIVO') {
            return true;
          }
        }}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <strong>Total de itens selecionados: {!!this.carrinhoResiduo ? qtdItens : '...'}</strong>
            <div>
              <Button onClick={this.close}>Cancelar</Button>
              <Button
                type="primary"
                loading={false}
                disabled={!!this.carrinhoResiduo && qtdItens == 0}
                onClick={this.onUpdate}>
                Selecionar
              </Button>
            </div>
          </div>
        }
        {...this.props}
      />
    );
  }
}
