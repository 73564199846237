import styled from "styled-components";
import { darken, lighten } from "polished";

import { default as PageTitleBase } from "components/PageTitle";

export const PageTitle = styled(PageTitleBase)`
  border-bottom: 1px solid ${props => lighten(0.1, props.theme.borderColor)};
  padding-bottom: 6px;
  margin-bottom: 12px;

  > h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    color: #323232;
    letter-spacing: 0.3px;
  }
`;

export const PageTitleExtra = styled.strong`
  display: inline-block;
  color: #969696;
  font-style: italic;
  font-size: 13px;
  padding-top: 9px;
`;

export const ChartGroup = styled.div`
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 8px 0 5px;
  text-align: center;

  & + & {
    margin-left: 15px;
  }
`;

export const ChartGroupTitle = styled.h4`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding: 0 0 3px;
  border-bottom: 1px solid ${darken(0.06, "#f7f7f7")};
  cursor: default;
`;

export const ChartValue = styled.div`
  font-size: 16px;
  margin: 8px 0 -3px;

  > strong {
    font-size: 24px;
    letter-spacing: -0.5px;
  }

  ${props =>
    !!props.pct &&
    `
    display: inline-block;
    background-color: ${lighten(0.05, "#312675")};
    padding: 6px 9px;
    border-radius: 8px;
    font-size: 15px;
    color: #fff;
    margin: -1px 0 9px;
  `}
`;

export const ChartValueLinha = styled.div`
  font-size: 12px;
  margin: 8px 0 -3px;

  > strong {
    font-size: 24px;
    letter-spacing: -0.5px;
  }

  ${props =>
    !!props.pct &&
    `
    display: inline-block;
    background-color: ${lighten(0.05, "#312675")};
    padding: 6px 9px;
    border-radius: 8px;
    font-size: 15px;
    color: #fff;
    margin: -1px 0 9px;
  `}
`;
