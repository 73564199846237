import React from "react";
import { Button, Icon } from "antd";
import styled from "styled-components";

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding: 12px;
  min-height: 57px;
  margin: ${props => (!!props.inside ? "0" : "-18px -28px 18px")};

  .ant-btn {
    padding: 0 9px;
  }

  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 4px;
  }
`;

const PageHeaderTitle = styled.h4`
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  cursor: default;
`;

export const BackButton = React.memo(function BackButton({ label = "Voltar", icon = "left", ...props }) {
  return (
    <Button type="primary" ghost={true} {...props}>
      <Icon type={icon} />
      {label}
    </Button>
  );
});

function PageHeader({ title, headerLeft, headerRight, inside = false, ...props }) {
  return (
    <PageHeaderWrapper inside={inside} {...props}>
      <div style={{ minWidth: 284, minHeight: 30, textAlign: "left" }}>{headerLeft}</div>
      <PageHeaderTitle>{title}</PageHeaderTitle>
      <div style={{ minWidth: 284, minHeight: 30, textAlign: "right" }}>{headerRight}</div>
    </PageHeaderWrapper>
  );
}

export default React.memo(PageHeader);
