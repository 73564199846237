import { fetchApi, fetchApiWithCache, fetchPostApi } from "actions";

export const fetchBase = async (...args) => {
  const { data: response } = await fetchApiWithCache(`/cliente/orcamentos/base`, ...args);
  return response;
};

export const fetchBaseLinha = async (...args) => {
  const { data: response } = await fetchApiWithCache(`/cliente/orcamentos/base`, ...args);
  return response;
};

export const fetchClasses = async (linha = "01", ...args) => {
  const { data: response } = await fetchApiWithCache(`/cliente/orcamentos/classes/${linha}`, ...args);
  return response;
};

export const fetchEdit = async (codigo, reset = false, ...args) => {
  const { data: response } = await fetchApi(
    !!reset ? `/cliente/orcamentos/edit/${codigo}/reset` : `/cliente/orcamentos/edit/${codigo}`,
    ...args
  );
  return response;
};

export const fetchProdutosCsv = async (filial = "01", formData, ...args) => {
  const { data: response } = await fetchPostApi(`/cliente/orcamentos/produtos/${filial}`, formData, ...args);
  return response;
};

export const saveOrcamento = async (send = false, formData, ...args) => {
  const { data: response } = await fetchPostApi(
    !!send ? "/cliente/orcamentos/save/send" : "/cliente/orcamentos/save",
    formData,
    ...args
  );
  return response;
};
