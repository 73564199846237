import React, { Component } from "react";
import { Modal, Row, Col, Form, Button } from "antd";
import { observer } from "mobx-react";

import Select, { Option, OptGroup } from "components/Select";

const FormItem = Form.Item;

const formItemLayout = {};

@observer
export default class SharePedidoModal extends Component {
  constructor(props) {
    super(props);

    this.item = null;
    this.onUpdate = null;

    this.state = {
      visible: false,
      confirmLoading: false,
      compartilhado: null,
    };
  }
  open = (item, onUpdate) => {
    this.item = item;
    this.onUpdate = onUpdate;

    let compartilhado = item.vendedor_compartilhado || item.operador_compartilhado || "";
    if ([...this.props.vendedores, ...this.props.operadores].findIndex(c => c.codigo === compartilhado) === -1)
      compartilhado = null;

    this.setState({ visible: true, compartilhado });
  };
  close = () => {
    if (!this.state.confirmLoading) {
      this.setState({ visible: false }, () => {
        this.item = null;
        this.onUpdate = null;
      });
    }
  };
  update = async () => {
    this.setState({ confirmLoading: true });

    try {
      const { compartilhado } = this.state;
      const tipo = [...this.props.vendedores, ...this.props.operadores].reduce((tipo, item) => {
        if (item.codigo === compartilhado && "login_operador" in item) {
          return "OPERADOR_TELEVENDAS";
        }
        return tipo;
      }, this.props.tipo || "VENDEDOR");
      await this.onUpdate(this.item.codigo_hash, compartilhado, tipo);
      this.setState({ confirmLoading: false }, () => this.close());
    } catch (ex) {
      this.setState({ confirmLoading: false });
    }
  };
  render() {
    const { tipo, isLoading = false, vendedores = [], operadores = [], ...props } = this.props;
    const hasItem = !!this.item && (this.item.tipo_pedido === "CAMPANHA KIT" || this.item.tipo_pedido === "ANJO SAFRA");
    const canRemove = hasItem && !!(this.item.vendedor_compartilhado || this.item.operador_compartilhado);
    const valid = !isLoading && hasItem;

    const inputProps = {
      readOnly: isLoading || this.state.confirmLoading,
      disabled: !valid || this.state.confirmLoading,
    };

    const vendedoresOptions = (
      <OptGroup key="vendedoresOptions" label="Vendedores">
        {vendedores.map(p => (
          <Option key={p.codigo} value={p.codigo}>
            {`${p.codigo} - ${p.razao_social}`}
          </Option>
        ))}
      </OptGroup>
    );

    const operadoresOptions = (
      <OptGroup key="operadoresOptions" label="Operadores">
        {operadores.map(p => (
          <Option key={p.codigo} value={p.codigo}>
            {`${p.codigo} - ${p.razao_social}`}
          </Option>
        ))}
      </OptGroup>
    );

    return (
      <Modal
        centered
        width={560}
        title={hasItem ? `Compartilhar Pedido #${this.item.numero_pedido}` : `Compartilhar Pedido`}
        visible={this.state.visible}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {!!canRemove ? (
              <Button
                type="danger"
                title="Desfazer Compartilhamento"
                disabled={isLoading || this.state.confirmLoading}
                onClick={() => this.setState({ compartilhado: "" }, () => this.update())}
                icon="delete">
                Desfazer
              </Button>
            ) : (
              <span />
            )}
            <Button
              type="primary"
              icon="save"
              title="Definir compartilhamento com usuário selecionado"
              loading={isLoading || this.state.confirmLoading}
              disabled={!valid || !this.state.compartilhado}
              onClick={this.update}>
              Compartilhar
            </Button>
          </div>
        }
        onCancel={this.close}
        confirmLoading={this.state.confirmLoading}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="ant-modal-default share-pedido-modal"
        {...props}>
        {hasItem && (
          <Form layout="vertical" className="form-share-pedido-modal">
            <Row gutter={12}>
              <Col span={24}>
                <FormItem
                  {...formItemLayout}
                  label={`Selecione um ${
                    tipo === "VENDEDOR" ? "vendedor ou operador" : "operador ou vendedor"
                  } abaixo para compartilhar este pedido:`}>
                  <Select
                    {...inputProps}
                    value={this.state.compartilhado}
                    onSelect={compartilhado => this.setState({ compartilhado })}>
                    {tipo === "VENDEDOR"
                      ? [vendedoresOptions, operadoresOptions]
                      : [operadoresOptions, vendedoresOptions]}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}
