import React, { Component } from "react";
import { fetchApi } from "actions";
import { Table } from "antd";
import moment from "moment";
import { WrappedValue } from "components/FullTable";
import classNames from "classnames";

const decorateRows = (item) =>
  classNames("base-table--row", {
    "base-table--row__danger": item.volume <= 0,
  });

class ExpandedRowMixClasse extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Cód. Classe",
        key: "classe",
        dataIndex: "classe",
        width: '11%'
      },
      {
        title: "Desc Classe",
        key: "descricao",
        dataIndex: "descricao",
      },
      {
        title: "Volume",
        dataIndex: "volume",
        key: "volume",
        align: 'right',
        width: '10%',
        className: 'text-center',
        render: (uc) => (
          <WrappedValue type="decimal" value={uc} />
        )
      },
      {
        title: "Ult. Venda",
        key: "ultima_data_compra",
        dataIndex: "ultima_data_compra",
        width: '12%',
        className: 'text-center',
        render: (uc) => (
          <div>{uc ? moment(uc, 'YYYYMMDD').format('DD/MM/YYYY') : '--'}</div>
        )
      },
    ]

    this.state = {
      loading: false,
      data: [],
      item: props.item || {},
      expanded: props.expanded || false,
      mes: props.mes || moment()
    };
  }

  fetchClasse = async () => {
    this.setState({ loading: true });
    try {
      const { data: { data } } = await fetchApi("/pedidos/expanded_classe_mix/" + this.state.mes.format("YYYYMMDD") + "/" + this.state.item?.codigo);
      this.setState({ data })
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    if (this.state.expanded) {
      this.fetchClasse();
    }
  }

  render() {
    const { loading, data } = this.state;
    const { columns } = this;

    return (
      <Table
        rowKey={(item, i) => `${item.cliente}_${item.nome_cliente}:${i}`}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName={decorateRows}
        className="ant-table-bordered" />
    );
  }
}

export default ExpandedRowMixClasse;
