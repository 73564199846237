const Theme = {
  main: {
    primary: "#0f73b9",
    primaryDark: "#09609c",
    borderColor: "#D9D9D9",
    headerHeight: "75px",
  },
};

module.exports = Theme;
