import { fetchApi } from "actions";

export const fetchMeta = async (codigo_vendedor = null, mes = null, ...args) => {
  const queryParams = new URLSearchParams();

  if (codigo_vendedor) queryParams.append('codigo_vendedor', codigo_vendedor);
  if (mes) queryParams.append('mes', mes);

  const { data: response } = await fetchApi(
    `/dashboard/meta?` + queryParams.toString(),
    ...args
  );
  return response;
};

export const fetchMetaVolume = async (codigo_vendedor = null, mes = null, ...args) => {
  const queryParams = new URLSearchParams();

  if (codigo_vendedor) queryParams.append('codigo_vendedor', codigo_vendedor);
  if (mes) queryParams.append('mes', mes);

  const { data: response } = await fetchApi(
    `/dashboard/metavolume?` + queryParams.toString(),
    ...args
  );
  return response;
};

export const fetchMetaLinha = async (tipo = null, codigo = null, ...args) => {
  const queryParams = new URLSearchParams();
  if (tipo) queryParams.append('tipo', tipo);
  if (codigo) queryParams.append('codigo_vendedor', codigo);
  const { data: response } = await fetchApi(
    `/dashboard/metaLinha?` + queryParams.toString(),
    ...args
  );
  return response;
};


export const fetchTickets = async (codigo_vendedor = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/tickets` + (!!codigo_vendedor ? `?codigo_vendedor=${codigo_vendedor}` : ""),
    ...args
  );
  return response;
};

export const fetchTasks = async (codigo_vendedor = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/tasks` + (!!codigo_vendedor ? `?codigo_vendedor=${codigo_vendedor}` : ""),
    ...args
  );
  return response;
};

export const fetchTrimestre = async (codigo_vendedor = null, tipo = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/trimestre/${tipo}` + (!!codigo_vendedor ? `?codigo_vendedor=${codigo_vendedor}` : ""),
    ...args
  );
  return response;
};


export const fetchMix = async (codigo_vendedor = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/mix` + (!!codigo_vendedor ? `?codigo_vendedor=${codigo_vendedor}` : ""),
    ...args
  );
  return response;
};

export const fetchMixCliente = async (cliente = null, loja = null, trimestral = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/mixcliente` + (!!cliente ? `?cliente=${cliente}-${loja}-${trimestral}` : ""),
    ...args
  );
  return response;
};


export const fetchIndicadores = async (codigo_vendedor = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/indicadores` + (!!codigo_vendedor ? `?codigo_vendedor=${codigo_vendedor}` : ""),
    ...args
  );
  return response;
};

export const fetchSemaforos = async (codigo_vendedor = null, ano = null, ...args) => {
  const { data: response } = await fetchApi(
    `/dashboard/semaforo` + (!!codigo_vendedor ? `?codigo_vendedor=${codigo_vendedor}` : "") + (!!ano ? `?ano=${ano}` : ""),
    ...args
  );
  return response;
};
