import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { lighten } from "polished";
import _isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";

import { fetchTasks } from "actions/dashboard";
import { PageTitle } from "./Styled";

const DashboardTicketsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0 30px;
`;

const TicketWrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${lighten(0.05, "#312675")};
  -webkit-box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.2);
  min-width: 186px;
  color: #fff;
  border-radius: 8px;
  padding: 10px 12px;
  cursor: default;

  > a,h4 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: #fff;
    margin: 0;
  }

  & + & {
    margin-left: 3.25%;
  }
`;

const TicketValue = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  margin: 6px 0;

  > small {
    font-size: 16px;
    vertical-align: top;
  }
`;

const Ticket = ({ title, label, value, link, style, ...props }) => (
  <TicketWrapper style={style}>
    <Link to={link}>
      <h4 title={title || label}>{label}</h4>
      <TicketValue title={title || label}>{value}</TicketValue>
    </Link>
  </TicketWrapper>
);

export default class TicketsPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchTasks();
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data } = this.state;
    const { isDiretorPresidente, isB2B, isPricing } = this.props;

    return (
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 160 }}>
          <PageTitle title="Atividades" meta={false} />
          {isLoading ? (
            <p>
              <strong>Carregando...</strong>
            </p>
          ) : !!data && !!data.tasks ? (
            <Fragment>
              {!isB2B && !isPricing && (
                <Fragment>
                  <DashboardTicketsWrapper>
                    <Ticket link="/simulacoes" title="Simulações p/campanhas" label="Análise de Simulações" value={data.tasks.qtd_simulacao} />
                    <Ticket link="/bonificacoes" title="Pedidos" label="Bonificações/Amostras/Brindes" value={data.tasks.qtd_bonificacao} />
                    <Ticket link="/solicitacao-prazo" title="Prazo" label="Exceções de Prazo" value={data.tasks.qtd_solprazo} />
                  </DashboardTicketsWrapper>
                  <DashboardTicketsWrapper>
                    <Ticket link="/solicitacao-desconto" title="Desconto Gerencial" label="Descontos de Marketing" value={data.tasks.qtd_marketing} />
                    <Ticket link="/margens" title="Margem" label="Avaliação de Margem" value={data.tasks.qtd_margem} />
                    <Ticket link="/memorando" title="Memorandos" label="Memorandos" value={data.tasks.qtd_processos} />
                  </DashboardTicketsWrapper>
                </Fragment>
              )}
              {isB2B && isDiretorPresidente && (
                <Fragment>
                  <DashboardTicketsWrapper>
                    <Ticket link="/margens" title="Margem" label="Liberação de Margem" value={data.tasks.qtd_margem} />
                    <Ticket link="/bonificacoes" title="Pedidos" label="Bonificações/Amostras/Brindes" value={data.tasks.qtd_bonificacao} />
                    <Ticket link="/solicitacao-prazo" title="Prazo" label="Exceções de Prazo" value={data.tasks.qtd_solprazo} />
                  </DashboardTicketsWrapper>
                  <DashboardTicketsWrapper>
                    <Ticket link="/memorando" title="Memorandos" label="Memorandos" value={data.tasks.qtd_processos} />
                  </DashboardTicketsWrapper>
                </Fragment>
              )}
              {isPricing &&
                <Fragment>
                  <DashboardTicketsWrapper>
                    <Ticket style={{ maxWidth: 340 }} link="/simulacoes" title="Simulações p/campanhas" label="Análise de Simulações" value={data.tasks.qtd_simulacao} />
                  </DashboardTicketsWrapper>
                </Fragment>
              }
            </Fragment>
          ) : (
            <p>
              <strong>Dados insuficientes para mostrar todas as suas informações.</strong>
            </p>
          )}
        </div>
      </Spin>
    );
  }
}
