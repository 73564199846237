import React, { Component, Fragment } from "react";
import { Spin, Modal, Form, DatePicker } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";
import moment from "moment";

import { dateFn, number_format, n } from "@util";
import { Carga, CargaItem } from "stores/PedidoStore";

import CargaPedidoTable from "./CargaPedidoTable";
import CargaItemTable from "./CargaItemTable";

import "./CargaModal.less";

const FormItem = Form.Item;

const nf = (n, d = 2) => number_format(+n, d, ".", "");

const FormCargaToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const FormCargaLabel = styled.div`
  float: left;
  font-size: 13px;
  padding-right: 6px;
  color: ${props => (!!props.danger && props.theme.primary) || (!!props.success && "#33af1a") || "#636363"};
  cursor: default;

  & > strong {
    font-weight: 600;
  }

  & + & {
    border-left: 1px solid ${props => props.theme.borderColor};
    padding-left: 6px;
  }
`;

@observer
export default class CargaModal extends Component {
  constructor(props) {
    super(props);

    this.carga = null;
    this.today = null;

    this.state = {
      mode: "add",
      modalLoading: false,
      visible: false,
      pedidoSoltos: [],
    };
  }
  componentWillUnmount() {
    this.carga = null;
  }
  open = (carga = {}, mode = "add") => {
    const { programacao } = this.props;
    const codigo_filial = programacao.codigoFilial();
    const codigo_kit = programacao.codigoKit();

    this.today = moment(programacao.today, "YYYY-MM-DD").endOf("day");

    this.carga = new Carga(programacao, {
      codigo_filial,
      codigo_kit,
      ...carga,
    });

    this.setState({ mode, visible: true, modalLoading: false, pedidoSoltos: [] });
  };
  close = () => {
    if (!!this.state.modalLoading) {
      this.props.onCargaError(`Por favor aguarde o final da operação!`);
      return;
    }

    this.setState({ visible: false, modalLoading: false, pedidoSoltos: [] }, () => {
      this.carga = null;
    });
  };
  addPedido = pedido => {
    const { onCargaError } = this.props;

    try {
      this.carga.addItem(new CargaItem(pedido));
    } catch (err) {
      onCargaError(err.name === "PedidoError" && !!err.message ? err.message : `Não é possível adicionar esta carga.`);
    }
  };
  removeCarga = carga => {
    const { onCargaError } = this.props;

    try {
      this.carga.removeItem(carga);

      if (this.state.mode === "edit") {
        this.setState({ pedidoSoltos: [...this.state.pedidoSoltos, carga.asPedido] });
      }
    } catch (err) {
      onCargaError(err.name === "PedidoError" && !!err.message ? err.message : `Não é possível remover esta carga.`);
    }
  };
  update = async () => {
    const { onCargaSave, onCargaError } = this.props;

    this.setState({ modalLoading: true });

    try {
      await onCargaSave(this.carga, this.state.pedidoSoltos.slice());
      this.setState({ modalLoading: false, pedidoSoltos: [] }, () => this.close());
    } catch (err) {
      onCargaError(
        err.name === "PedidoError" && !!err.message ? err.message : `Não é possível adicionar esta carga.`,
        () => this.setState({ modalLoading: false })
      );
    }
  };
  disabledDate = current => {
    if (!!current) {
      // Can not select days before today and today
      const currentNew = this.carga.codigo_filial === '04' ? current.endOf("day") : current ;

      if (!!this.today && currentNew < this.today) {
        return true;
      }
      if (!!this.today && current.endOf("day") < this.today) {
        return true;
      }

      // Can not select weekend days
      const weekDay = current.day();
      if (weekDay === 0 || weekDay === 6) {
        return true;
      }
    }
    return false;
  };
  render() {
    const { width = 980, programacao, isLoading = false } = this.props;
    const isValid = !isLoading && !!this.carga && this.carga.isValid;

    return (
      <Modal
        centered
        title={this.state.mode === "add" ? `Adicionar Nova Carga` : `Alterando Carga`}
        visible={this.state.visible}
        okText={this.state.mode === "add" ? "Adicionar Carga" : "Alterar Carga"}
        confirmLoading={!!this.state.modalLoading}
        okButtonProps={{ disabled: !isValid }}
        cancelButtonProps={{ disabled: !!this.state.modalLoading }}
        onOk={this.update}
        onCancel={this.close}
        width={width}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="carga-modal">
        <Spin spinning={!!isLoading || programacao.isLoading || !!this.state.modalLoading}>
          <div style={{ minHeight: 348 }}>
            {!!this.carga && (
              <Fragment>
                <CargaPedidoTable
                  onAddPedido={this.addPedido}
                  items={programacao.pedidosPendentes
                    .slice()
                    .concat(!this.state.modalLoading ? this.state.pedidoSoltos : [])
                    .filter(
                      pedido =>
                        !this.carga.items.find(
                          item =>
                            item.numero_pedido === pedido.Pedido.numero_pedido &&
                            item.codigo_filial === pedido.Pedido.codigo_filial
                        )
                    )}
                  scroll={{ y: 148 }}
                  locale={{
                    emptyText: "Nenhum pedido disponível para iniciar uma carga",
                  }}
                />
                <Form noValidate layout="inline" style={{ marginTop: 9, paddingTop: 6 }}>
                  <FormCargaToolbar className="form-carga-toolbar">
                    <FormItem required={true} label="Data de Faturamento">
                      <DatePicker
                        value={dateFn(this.carga.data_entrega)}
                        onChange={value => (this.carga.data_entrega = value.format("YYYY-MM-DD"))}
                        format="DD/MM/YYYY"
                        allowClear={false}
                        disabled={this.carga.items.length === 0}
                        disabledDate={this.disabledDate}
                      />
                    </FormItem>
                    <div>
                      <FormCargaLabel>
                        <strong>Total NF (R$):</strong> {n(this.carga.total_nota)}
                      </FormCargaLabel>
                      <FormCargaLabel danger={!this.carga.isPesoValid}>
                        <strong>Peso Total (KG):</strong> {n(this.carga.total_peso)}
                      </FormCargaLabel>
                      {!!this.carga.tipo_caminhao && (
                        <FormCargaLabel>
                          <strong>Transp.:</strong> {this.carga.tipo_caminhao}
                        </FormCargaLabel>
                      )}
                      {!!this.carga.modalidade_frete && (
                        <FormCargaLabel>
                          {this.carga.modalidade_frete}
                        </FormCargaLabel>
                      )}

                    </div>
                  </FormCargaToolbar>
                  <CargaItemTable
                    onRemoveCarga={this.removeCarga}
                    items={this.carga.items}
                    scroll={{ y: 185 }}
                    locale={{
                      emptyText: "Selecione um pedido disponível na lista acima para iniciar esta carga",
                    }}
                  />
                </Form>
              </Fragment>
            )}
          </div>
        </Spin>
      </Modal>
    );
  }
}
