import React, { Component, PureComponent } from "react";
import { Modal, Row, Col, Form, Input, Button, Spin, Checkbox, Upload, Icon, message } from "antd";
import { observer } from "mobx-react";

import { fetchApi, fetchPostApi } from "actions";
import { getData, validateCNPJ } from "@util";
import withStore from "withStore";

import { v } from "components/FormBuilder";
import PageHeader, { BackButton } from "components/PageHeader";
import InputSearch from "components/InputSearch";
import Select, { Option } from "components/Select";
import { REGION_STATES, getApiUrl } from "@env";
import { MaskedInput } from "antd-mask-input";

const FormItem = Form.Item;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class FileInput extends PureComponent {
  render() {
    const { downloadUri, files, isLoading = false, onPreview, onChange } = this.props;

    const uploadButton = (
      <div>
        <Icon type={isLoading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Adicionar foto</div>
      </div>
    );

    !!files && !!downloadUri && files.push({
      uid: '-1',
      name: 'foto.jpg',
      status: 'done',
      url: downloadUri,
    })

    const types = ["image/jpeg", "image/png", "image/jpg"];

    return (
      <Upload name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        beforeUpload={(file) => {
          if (!types.includes(file.type)) {
            message.error(`${file.name} não é um PDF, é aceito apenas PDF`);
            return true;
          } else {
            return false
          }
        }}
        fileList={files}
        action={null}
        onPreview={onPreview}
        onChange={onChange}
      >
        {files && files.length >= 1 ? null : uploadButton}
      </Upload>
    );
  }
}

@observer
class FuncionarioEditorScreen extends Component {
  state = {
    fileList: null,
    download_uri: null,
    removing: false
  };
  async componentDidMount() {
    if (this.props.match.params.edit !== 'add') {
      await this.fetchBase();
    }
  }
  checkImage(url) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img.height)
      img.onerror = () => resolve(false)
      img.src = url
    })
  }
  async fetchBase() {
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/treinamento/funcionario/edit/" + this.props.match.params.edit);
      if (!!response.success) {
        const { download_uri, nome, cpf, regime, cargo, uf, cidade, telefone, email, sexo } = response.data;
        this.props.form.setFieldsValue({
          nome,
          cpf,
          regime,
          cargo,
          uf,
          cidade,
          telefone,
          email,
          sexo
        });

        const url = download_uri + "/" + this.props.match.params.edit + ".jpg";

        const isValidIMG = await this.checkImage(url);

        this.setState({ download_uri: !!isValidIMG ? url : false });
      } else {
        throw new Error("Não foi possível obter dados atualizados para este formulário");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados para este formulário",
        onOk: () => this.props.history.replace("/funcionario/" + this.props.match.params.empresa || this.props.store.treinamentoFornecedorData.codigo),
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }
  async saveFuncionario(data) {
    const { store, history, match } = this.props;

    const errorMessages = {
      10: "Não foi possível registrar sua funcionário neste momento. Tente novamente mais tarde",
    };

    if (!!data.foto) {
      for (let i = 0; i < data.foto.length; i++) {
        const el = data.foto[i];
        if (!!el.error) {
          return;
        }
      }
    }

    store.isLoading = true;

    try {
      if (match.params.edit !== 'add') {
        data = {
          ...data,
          codigo: match.params.edit,
        }
      }

      data = {
        ...data,
        empresa: match.params.empresa || store.treinamentoFornecedorData.codigo,
        removing: this.state.removing
      }

      if (!Array.isArray(data.foto)) {
        delete data.foto;
      }

      const inputFiles = ["foto"];
      const formData = new FormData();
      Object.keys(data).forEach(field => {
        const isFile = inputFiles.indexOf(field) !== -1;
        if (!isFile && !Array.isArray(data[field])) {
          formData.append(field, data[field]);
        } else {
          data[field].forEach((file, k) => {
            if (!!file.originFileObj) formData.append(`${field}[${k}]`, file.originFileObj);
          });
        }
      });

      const { data: response } = await fetchPostApi("/treinamento/funcionario/" + (match.params.edit === 'add' ? "save" : "update"), formData, true);
      if (!!response.success) {
        Modal.success({
          title: "Funcionário registrado com sucesso",
          onOk() {
            history.replace(`/funcionario${match.params.empresa ? ('/' + match.params.empresa) : ''}`);
          },
        });

        store.isLoading = false;
      } else {
        throw new Error(errorMessages[10]);
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || (typeof err === "string" ? err : errorMessages[10]),
      });

      store.isLoading = false;
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveFuncionario(Object.keys(formData).reduce((acc, k) => ({ ...acc, [k]: formData[k] || "" }), {}));
      }
    });
  };
  handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };
  normalizeFiles = event => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };
  handleChange = ({ fileList }) => {
    this.setState({ fileList, download_uri: null, removing: fileList.length === 0 });
  };
  render() {
    const { isLoading } = this.props.store;
    const { getFieldDecorator } = this.props.form;
    const { match } = this.props;

    const inputProps = {
      readOnly: isLoading,
      autoComplete: "off",
    };

    const isAdd = match.params.edit === 'add';

    const optionsUF = Object.keys(REGION_STATES).map(uf => (
      <Option key={uf} value={uf}>
        {REGION_STATES[uf]}
      </Option>
    ));

    const REGIME = { CLT: 'CLT', PJ: 'PJ' };

    const optionsRegime = Object.keys(REGIME).map(uf => (
      <Option key={uf} value={uf}>
        {REGIME[uf]}
      </Option>
    ));

    return (
      <div className="cadastro-cliente-screen">
        <PageHeader title={isAdd ? "Novo Funcionário" : `Alterar funcionário #${match.params.edit}`} headerLeft={<BackButton onClick={() => this.props.history.goBack()} />} />
        <Spin spinning={!!isLoading}>
          <Form
            autoComplete="off"
            layout="vertical"
            hideRequiredMark={false}
            onSubmit={this.handleSubmit}
            className="cadastro-cliente--form">
            <div>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label="">
                    {getFieldDecorator("foto", {
                      valuePropName: "fileList",
                      getValueFromEvent: this.normalizeFiles,
                    })(<FileInput
                      {...inputProps}
                      match={match}
                      downloadUri={this.state.download_uri}
                      isLoading={isLoading}
                      files={this.state.fileList || []}
                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                    />)}
                  </FormItem>
                </Col>
                <Col span={21}>
                  <Row gutter={12}>
                    <Col span={9}>
                      <FormItem label="Nome">
                        {getFieldDecorator("nome", { rules: [v.required] })(
                          <Input maxLength={100} {...inputProps} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="CPF">
                        {getFieldDecorator("cpf", { rules: [v.required, v.cpf] })(
                          <Input maxLength={11} onKeyPress={this.handleKeyPress} {...inputProps} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="Regime">
                        {getFieldDecorator("regime", { rules: [v.required] })(
                          <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Selecione um Regime">
                            {optionsRegime}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={7}>
                      <FormItem label="Cargo">
                        {getFieldDecorator("cargo", { rules: [v.required] })(
                          <Input maxLength={100} {...inputProps} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={5}>
                      <FormItem label="Estado">
                        {getFieldDecorator("uf", { rules: [v.required] })(
                          <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Selecione um Estado">
                            {optionsUF}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="Cidade">
                        {getFieldDecorator("cidade", { rules: [v.required] })(
                          <Input maxLength={100} {...inputProps} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="Telefone">
                        {getFieldDecorator("telefone", { rules: [v.required] })(
                          <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={7}>
                      <FormItem label="Email">
                        {getFieldDecorator("email", { rules: [v.email, v.required] })(<Input {...inputProps} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="Sexo">
                        {getFieldDecorator("sexo", { rules: [v.required] })(
                          <Select {...inputProps} defaultActiveFirstOption={true}>
                            <Option key='M' value='M'>
                              Masculino
                            </Option>
                            <Option key='F' value='F'>
                              Feminino
                            </Option>
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 6, marginBottom: 16 }}>
              <Button
                style={{ marginTop: 9 }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="cadastro-cliente--button">
                {isAdd ? 'Enviar' : 'Alterar'} cadastro
              </Button>
            </div>
          </Form>
          <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.setState({ previewVisible: false })}>
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
          </Modal>
        </Spin>
      </div >
    );
  }
}

export default Form.create()(withStore(FuncionarioEditorScreen));
