import React, { PureComponent, Fragment } from "react";
import { Route } from "react-router-dom";
import AtrelarTreinamentoScreen from "screens/treinamento/AtrelarTreinamentoScreen";
import AtrelarTreinamentoEmpresaScreen from "screens/treinamento/empresa/AtrelarTreinamentoScreen";
import EmpresaEditorScreen from "screens/treinamento/empresa/EmpresaEditorScreen";
import EmpresaScreen from "screens/treinamento/empresa/EmpresaScreen";
import MonitoraFuncionarioScreen from "screens/treinamento/empresa/MonitoraFuncionarioScreen";
import PendenciaScreen from "screens/treinamento/empresa/PendenciaScreen";
import FuncionarioEditorScreen from "screens/treinamento/funcionario/FuncionarioEditorScreen";

export default class TreinamentoFornecedor extends PureComponent {
  render() {
    return (
      <Fragment>
        <Route exact path="/pendencias" component={PendenciaScreen} />
        <Route exact path="/funcionario" component={MonitoraFuncionarioScreen} />
        <Route exact path="/funcionario/:edit" component={FuncionarioEditorScreen} />
        <Route exact path="/funcionario/:edit/:funcionario" component={AtrelarTreinamentoScreen} />
        <Route exact path="/perfil" component={AtrelarTreinamentoEmpresaScreen} />
      </Fragment>
    );
  }
}